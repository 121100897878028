export const CUSTOM_FONTS = [
    {
        "font_family": "ABeeZee",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ABeeZee-Italic.ttf"
                },
                "font_name": "ABeeZee Italic",
                "font_family": "ABeeZee",
                "font_postscript": "ABeeZee-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ABeeZee-Regular.ttf"
                },
                "font_name": "ABeeZee Regular",
                "font_family": "ABeeZee",
                "font_postscript": "ABeeZee-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ADLaMDisplay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ADLaMDisplay-Regular.ttf"
                },
                "font_name": "ADLaMDisplay Regular",
                "font_family": "ADLaMDisplay",
                "font_postscript": "ADLaMDisplay-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AROneSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AROneSans-Regular.ttf"
                },
                "font_name": "AROneSans Regular",
                "font_family": "AROneSans",
                "font_postscript": "AROneSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Abel",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Abel-Regular.ttf"
                },
                "font_name": "Abel Regular",
                "font_family": "Abel",
                "font_postscript": "Abel-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AbhayaLibre",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AbhayaLibre-Bold.ttf"
                },
                "font_name": "AbhayaLibre Bold",
                "font_family": "AbhayaLibre",
                "font_postscript": "AbhayaLibre-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AbhayaLibre-Regular.ttf"
                },
                "font_name": "AbhayaLibre Regular",
                "font_family": "AbhayaLibre",
                "font_postscript": "AbhayaLibre-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AbhayaLibre-Medium.ttf"
                },
                "font_name": "AbhayaLibre Medium",
                "font_family": "AbhayaLibre",
                "font_postscript": "AbhayaLibre-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AbhayaLibre-ExtraBold.ttf"
                },
                "font_name": "AbhayaLibre ExtraBold",
                "font_family": "AbhayaLibre",
                "font_postscript": "AbhayaLibre-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AbhayaLibre-SemiBold.ttf"
                },
                "font_name": "AbhayaLibre SemiBold",
                "font_family": "AbhayaLibre",
                "font_postscript": "AbhayaLibre-SemiBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Aboreto",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Aboreto-Regular.ttf"
                },
                "font_name": "Aboreto Regular",
                "font_family": "Aboreto",
                "font_postscript": "Aboreto-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AbrilFatface",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AbrilFatface-Regular.ttf"
                },
                "font_name": "AbrilFatface Regular",
                "font_family": "AbrilFatface",
                "font_postscript": "AbrilFatface-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AbyssinicaSIL",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AbyssinicaSIL-Regular.ttf"
                },
                "font_name": "AbyssinicaSIL Regular",
                "font_family": "AbyssinicaSIL",
                "font_postscript": "AbyssinicaSIL-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Aclonica",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Aclonica-Regular.ttf"
                },
                "font_name": "Aclonica Regular",
                "font_family": "Aclonica",
                "font_postscript": "Aclonica-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Acme",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Acme-Regular.ttf"
                },
                "font_name": "Acme Regular",
                "font_family": "Acme",
                "font_postscript": "Acme-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Actor",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Actor-Regular.ttf"
                },
                "font_name": "Actor Regular",
                "font_family": "Actor",
                "font_postscript": "Actor-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Adamina",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Adamina-Regular.ttf"
                },
                "font_name": "Adamina Regular",
                "font_family": "Adamina",
                "font_postscript": "Adamina-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AdobeBlank",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AdobeBlank-Regular.ttf"
                },
                "font_name": "AdobeBlank Regular",
                "font_family": "AdobeBlank",
                "font_postscript": "AdobeBlank-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AdventPro",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AdventPro-Regular.ttf"
                },
                "font_name": "AdventPro Regular",
                "font_family": "AdventPro",
                "font_postscript": "AdventPro-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AdventPro-Italic.ttf"
                },
                "font_name": "AdventPro Italic",
                "font_family": "AdventPro",
                "font_postscript": "AdventPro-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Afacad",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Afacad-Regular.ttf"
                },
                "font_name": "Afacad Regular",
                "font_family": "Afacad",
                "font_postscript": "Afacad-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Afacad-Italic.ttf"
                },
                "font_name": "Afacad Italic",
                "font_family": "Afacad",
                "font_postscript": "Afacad-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Agbalumo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Agbalumo-Regular.ttf"
                },
                "font_name": "Agbalumo Regular",
                "font_family": "Agbalumo",
                "font_postscript": "Agbalumo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Agdasima",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Agdasima-Bold.ttf"
                },
                "font_name": "Agdasima Bold",
                "font_family": "Agdasima",
                "font_postscript": "Agdasima-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Agdasima-Regular.ttf"
                },
                "font_name": "Agdasima Regular",
                "font_family": "Agdasima",
                "font_postscript": "Agdasima-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AguafinaScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AguafinaScript-Regular.ttf"
                },
                "font_name": "AguafinaScript Regular",
                "font_family": "AguafinaScript",
                "font_postscript": "AguafinaScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Akatab",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Akatab-ExtraBold.ttf"
                },
                "font_name": "Akatab ExtraBold",
                "font_family": "Akatab",
                "font_postscript": "Akatab-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Akatab-SemiBold.ttf"
                },
                "font_name": "Akatab SemiBold",
                "font_family": "Akatab",
                "font_postscript": "Akatab-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Akatab-Black.ttf"
                },
                "font_name": "Akatab Black",
                "font_family": "Akatab",
                "font_postscript": "Akatab-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Akatab-Regular.ttf"
                },
                "font_name": "Akatab Regular",
                "font_family": "Akatab",
                "font_postscript": "Akatab-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Akatab-Medium.ttf"
                },
                "font_name": "Akatab Medium",
                "font_family": "Akatab",
                "font_postscript": "Akatab-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Akatab-Bold.ttf"
                },
                "font_name": "Akatab Bold",
                "font_family": "Akatab",
                "font_postscript": "Akatab-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AkayaKanadaka",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AkayaKanadaka-Regular.ttf"
                },
                "font_name": "AkayaKanadaka Regular",
                "font_family": "AkayaKanadaka",
                "font_postscript": "AkayaKanadaka-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AkayaTelivigala",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AkayaTelivigala-Regular.ttf"
                },
                "font_name": "AkayaTelivigala Regular",
                "font_family": "AkayaTelivigala",
                "font_postscript": "AkayaTelivigala-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Akronim",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Akronim-Regular.ttf"
                },
                "font_name": "Akronim Regular",
                "font_family": "Akronim",
                "font_postscript": "Akronim-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AksaraBaliGalang",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AksaraBaliGalang-Regular.ttf"
                },
                "font_name": "AksaraBaliGalang Regular",
                "font_family": "AksaraBaliGalang",
                "font_postscript": "AksaraBaliGalang-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Akshar",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Akshar-Regular.ttf"
                },
                "font_name": "Akshar Regular",
                "font_family": "Akshar",
                "font_postscript": "Akshar-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Aladin",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Aladin-Regular.ttf"
                },
                "font_name": "Aladin Regular",
                "font_family": "Aladin",
                "font_postscript": "Aladin-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Alata",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Alata-Regular.ttf"
                },
                "font_name": "Alata Regular",
                "font_family": "Alata",
                "font_postscript": "Alata-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Alatsi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Alatsi-Regular.ttf"
                },
                "font_name": "Alatsi Regular",
                "font_family": "Alatsi",
                "font_postscript": "Alatsi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AlbertSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlbertSans-Regular.ttf"
                },
                "font_name": "AlbertSans Regular",
                "font_family": "AlbertSans",
                "font_postscript": "AlbertSans-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlbertSans-Italic.ttf"
                },
                "font_name": "AlbertSans Italic",
                "font_family": "AlbertSans",
                "font_postscript": "AlbertSans-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Aldrich",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Aldrich-Regular.ttf"
                },
                "font_name": "Aldrich Regular",
                "font_family": "Aldrich",
                "font_postscript": "Aldrich-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Alef",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Alef-Bold.ttf"
                },
                "font_name": "Alef Bold",
                "font_family": "Alef",
                "font_postscript": "Alef-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Alef-Regular.ttf"
                },
                "font_name": "Alef Regular",
                "font_family": "Alef",
                "font_postscript": "Alef-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Alegreya",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Alegreya-Italic.ttf"
                },
                "font_name": "Alegreya Italic",
                "font_family": "Alegreya",
                "font_postscript": "Alegreya-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Alegreya-Regular.ttf"
                },
                "font_name": "Alegreya Regular",
                "font_family": "Alegreya",
                "font_postscript": "Alegreya-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AlegreyaSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSC-Black.ttf"
                },
                "font_name": "AlegreyaSC Black",
                "font_family": "AlegreyaSC",
                "font_postscript": "AlegreyaSC-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSC-ExtraBoldItalic.ttf"
                },
                "font_name": "AlegreyaSC ExtraBoldItalic",
                "font_family": "AlegreyaSC",
                "font_postscript": "AlegreyaSC-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSC-Italic.ttf"
                },
                "font_name": "AlegreyaSC Italic",
                "font_family": "AlegreyaSC",
                "font_postscript": "AlegreyaSC-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSC-Regular.ttf"
                },
                "font_name": "AlegreyaSC Regular",
                "font_family": "AlegreyaSC",
                "font_postscript": "AlegreyaSC-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSC-ExtraBold.ttf"
                },
                "font_name": "AlegreyaSC ExtraBold",
                "font_family": "AlegreyaSC",
                "font_postscript": "AlegreyaSC-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSC-MediumItalic.ttf"
                },
                "font_name": "AlegreyaSC MediumItalic",
                "font_family": "AlegreyaSC",
                "font_postscript": "AlegreyaSC-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSC-Medium.ttf"
                },
                "font_name": "AlegreyaSC Medium",
                "font_family": "AlegreyaSC",
                "font_postscript": "AlegreyaSC-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSC-BlackItalic.ttf"
                },
                "font_name": "AlegreyaSC BlackItalic",
                "font_family": "AlegreyaSC",
                "font_postscript": "AlegreyaSC-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSC-Bold.ttf"
                },
                "font_name": "AlegreyaSC Bold",
                "font_family": "AlegreyaSC",
                "font_postscript": "AlegreyaSC-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSC-BoldItalic.ttf"
                },
                "font_name": "AlegreyaSC BoldItalic",
                "font_family": "AlegreyaSC",
                "font_postscript": "AlegreyaSC-BoldItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AlegreyaSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSans-BlackItalic.ttf"
                },
                "font_name": "AlegreyaSans BlackItalic",
                "font_family": "AlegreyaSans",
                "font_postscript": "AlegreyaSans-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSans-Medium.ttf"
                },
                "font_name": "AlegreyaSans Medium",
                "font_family": "AlegreyaSans",
                "font_postscript": "AlegreyaSans-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSans-ExtraBoldItalic.ttf"
                },
                "font_name": "AlegreyaSans ExtraBoldItalic",
                "font_family": "AlegreyaSans",
                "font_postscript": "AlegreyaSans-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSans-ThinItalic.ttf"
                },
                "font_name": "AlegreyaSans ThinItalic",
                "font_family": "AlegreyaSans",
                "font_postscript": "AlegreyaSans-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSans-BoldItalic.ttf"
                },
                "font_name": "AlegreyaSans BoldItalic",
                "font_family": "AlegreyaSans",
                "font_postscript": "AlegreyaSans-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSans-Light.ttf"
                },
                "font_name": "AlegreyaSans Light",
                "font_family": "AlegreyaSans",
                "font_postscript": "AlegreyaSans-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSans-LightItalic.ttf"
                },
                "font_name": "AlegreyaSans LightItalic",
                "font_family": "AlegreyaSans",
                "font_postscript": "AlegreyaSans-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSans-Regular.ttf"
                },
                "font_name": "AlegreyaSans Regular",
                "font_family": "AlegreyaSans",
                "font_postscript": "AlegreyaSans-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSans-Black.ttf"
                },
                "font_name": "AlegreyaSans Black",
                "font_family": "AlegreyaSans",
                "font_postscript": "AlegreyaSans-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSans-Italic.ttf"
                },
                "font_name": "AlegreyaSans Italic",
                "font_family": "AlegreyaSans",
                "font_postscript": "AlegreyaSans-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSans-ExtraBold.ttf"
                },
                "font_name": "AlegreyaSans ExtraBold",
                "font_family": "AlegreyaSans",
                "font_postscript": "AlegreyaSans-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSans-Bold.ttf"
                },
                "font_name": "AlegreyaSans Bold",
                "font_family": "AlegreyaSans",
                "font_postscript": "AlegreyaSans-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSans-Thin.ttf"
                },
                "font_name": "AlegreyaSans Thin",
                "font_family": "AlegreyaSans",
                "font_postscript": "AlegreyaSans-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSans-MediumItalic.ttf"
                },
                "font_name": "AlegreyaSans MediumItalic",
                "font_family": "AlegreyaSans",
                "font_postscript": "AlegreyaSans-MediumItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AlegreyaSansSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSansSC-BlackItalic.ttf"
                },
                "font_name": "AlegreyaSansSC BlackItalic",
                "font_family": "AlegreyaSansSC",
                "font_postscript": "AlegreyaSansSC-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSansSC-ThinItalic.ttf"
                },
                "font_name": "AlegreyaSansSC ThinItalic",
                "font_family": "AlegreyaSansSC",
                "font_postscript": "AlegreyaSansSC-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSansSC-BoldItalic.ttf"
                },
                "font_name": "AlegreyaSansSC BoldItalic",
                "font_family": "AlegreyaSansSC",
                "font_postscript": "AlegreyaSansSC-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSansSC-Thin.ttf"
                },
                "font_name": "AlegreyaSansSC Thin",
                "font_family": "AlegreyaSansSC",
                "font_postscript": "AlegreyaSansSC-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSansSC-Regular.ttf"
                },
                "font_name": "AlegreyaSansSC Regular",
                "font_family": "AlegreyaSansSC",
                "font_postscript": "AlegreyaSansSC-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSansSC-Light.ttf"
                },
                "font_name": "AlegreyaSansSC Light",
                "font_family": "AlegreyaSansSC",
                "font_postscript": "AlegreyaSansSC-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSansSC-Bold.ttf"
                },
                "font_name": "AlegreyaSansSC Bold",
                "font_family": "AlegreyaSansSC",
                "font_postscript": "AlegreyaSansSC-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSansSC-Italic.ttf"
                },
                "font_name": "AlegreyaSansSC Italic",
                "font_family": "AlegreyaSansSC",
                "font_postscript": "AlegreyaSansSC-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSansSC-LightItalic.ttf"
                },
                "font_name": "AlegreyaSansSC LightItalic",
                "font_family": "AlegreyaSansSC",
                "font_postscript": "AlegreyaSansSC-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSansSC-ExtraBoldItalic.ttf"
                },
                "font_name": "AlegreyaSansSC ExtraBoldItalic",
                "font_family": "AlegreyaSansSC",
                "font_postscript": "AlegreyaSansSC-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSansSC-MediumItalic.ttf"
                },
                "font_name": "AlegreyaSansSC MediumItalic",
                "font_family": "AlegreyaSansSC",
                "font_postscript": "AlegreyaSansSC-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSansSC-Medium.ttf"
                },
                "font_name": "AlegreyaSansSC Medium",
                "font_family": "AlegreyaSansSC",
                "font_postscript": "AlegreyaSansSC-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSansSC-Black.ttf"
                },
                "font_name": "AlegreyaSansSC Black",
                "font_family": "AlegreyaSansSC",
                "font_postscript": "AlegreyaSansSC-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlegreyaSansSC-ExtraBold.ttf"
                },
                "font_name": "AlegreyaSansSC ExtraBold",
                "font_family": "AlegreyaSansSC",
                "font_postscript": "AlegreyaSansSC-ExtraBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Aleo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Aleo-Italic.ttf"
                },
                "font_name": "Aleo Italic",
                "font_family": "Aleo",
                "font_postscript": "Aleo-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Aleo-Regular.ttf"
                },
                "font_name": "Aleo Regular",
                "font_family": "Aleo",
                "font_postscript": "Aleo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AlexBrush",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlexBrush-Regular.ttf"
                },
                "font_name": "AlexBrush Regular",
                "font_family": "AlexBrush",
                "font_postscript": "AlexBrush-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Alexandria",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Alexandria-Regular.ttf"
                },
                "font_name": "Alexandria Regular",
                "font_family": "Alexandria",
                "font_postscript": "Alexandria-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AlfaSlabOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlfaSlabOne-Regular.ttf"
                },
                "font_name": "AlfaSlabOne Regular",
                "font_family": "AlfaSlabOne",
                "font_postscript": "AlfaSlabOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Alice",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Alice-Regular.ttf"
                },
                "font_name": "Alice Regular",
                "font_family": "Alice",
                "font_postscript": "Alice-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Alike",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Alike-Regular.ttf"
                },
                "font_name": "Alike Regular",
                "font_family": "Alike",
                "font_postscript": "Alike-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AlikeAngular",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlikeAngular-Regular.ttf"
                },
                "font_name": "AlikeAngular Regular",
                "font_family": "AlikeAngular",
                "font_postscript": "AlikeAngular-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Alkalami",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Alkalami-Regular.ttf"
                },
                "font_name": "Alkalami Regular",
                "font_family": "Alkalami",
                "font_postscript": "Alkalami-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Alkatra",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Alkatra-Regular.ttf"
                },
                "font_name": "Alkatra Regular",
                "font_family": "Alkatra",
                "font_postscript": "Alkatra-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Allan",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Allan-Bold.ttf"
                },
                "font_name": "Allan Bold",
                "font_family": "Allan",
                "font_postscript": "Allan-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Allan-Regular.ttf"
                },
                "font_name": "Allan Regular",
                "font_family": "Allan",
                "font_postscript": "Allan-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Allerta",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Allerta-Regular.ttf"
                },
                "font_name": "Allerta Regular",
                "font_family": "Allerta",
                "font_postscript": "Allerta-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AllertaStencil",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AllertaStencil-Regular.ttf"
                },
                "font_name": "AllertaStencil Regular",
                "font_family": "AllertaStencil",
                "font_postscript": "AllertaStencil-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Allison",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Allison-Regular.ttf"
                },
                "font_name": "Allison Regular",
                "font_family": "Allison",
                "font_postscript": "Allison-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Allura",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Allura-Regular.ttf"
                },
                "font_name": "Allura Regular",
                "font_family": "Allura",
                "font_postscript": "Allura-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Almarai",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Almarai-Bold.ttf"
                },
                "font_name": "Almarai Bold",
                "font_family": "Almarai",
                "font_postscript": "Almarai-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Almarai-ExtraBold.ttf"
                },
                "font_name": "Almarai ExtraBold",
                "font_family": "Almarai",
                "font_postscript": "Almarai-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Almarai-Regular.ttf"
                },
                "font_name": "Almarai Regular",
                "font_family": "Almarai",
                "font_postscript": "Almarai-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Almarai-Light.ttf"
                },
                "font_name": "Almarai Light",
                "font_family": "Almarai",
                "font_postscript": "Almarai-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Almendra",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Almendra-BoldItalic.ttf"
                },
                "font_name": "Almendra BoldItalic",
                "font_family": "Almendra",
                "font_postscript": "Almendra-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Almendra-Regular.ttf"
                },
                "font_name": "Almendra Regular",
                "font_family": "Almendra",
                "font_postscript": "Almendra-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Almendra-Bold.ttf"
                },
                "font_name": "Almendra Bold",
                "font_family": "Almendra",
                "font_postscript": "Almendra-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Almendra-Italic.ttf"
                },
                "font_name": "Almendra Italic",
                "font_family": "Almendra",
                "font_postscript": "Almendra-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AlmendraDisplay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlmendraDisplay-Regular.ttf"
                },
                "font_name": "AlmendraDisplay Regular",
                "font_family": "AlmendraDisplay",
                "font_postscript": "AlmendraDisplay-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AlmendraSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlmendraSC-Regular.ttf"
                },
                "font_name": "AlmendraSC Regular",
                "font_family": "AlmendraSC",
                "font_postscript": "AlmendraSC-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AlumniSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlumniSans-Italic.ttf"
                },
                "font_name": "AlumniSans Italic",
                "font_family": "AlumniSans",
                "font_postscript": "AlumniSans-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlumniSans-Regular.ttf"
                },
                "font_name": "AlumniSans Regular",
                "font_family": "AlumniSans",
                "font_postscript": "AlumniSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AlumniSansCollegiateOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlumniSansCollegiateOne-Italic.ttf"
                },
                "font_name": "AlumniSansCollegiateOne Italic",
                "font_family": "AlumniSansCollegiateOne",
                "font_postscript": "AlumniSansCollegiateOne-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlumniSansCollegiateOne-Regular.ttf"
                },
                "font_name": "AlumniSansCollegiateOne Regular",
                "font_family": "AlumniSansCollegiateOne",
                "font_postscript": "AlumniSansCollegiateOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AlumniSansInlineOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlumniSansInlineOne-Italic.ttf"
                },
                "font_name": "AlumniSansInlineOne Italic",
                "font_family": "AlumniSansInlineOne",
                "font_postscript": "AlumniSansInlineOne-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlumniSansInlineOne-Regular.ttf"
                },
                "font_name": "AlumniSansInlineOne Regular",
                "font_family": "AlumniSansInlineOne",
                "font_postscript": "AlumniSansInlineOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AlumniSansPinstripe",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlumniSansPinstripe-Italic.ttf"
                },
                "font_name": "AlumniSansPinstripe Italic",
                "font_family": "AlumniSansPinstripe",
                "font_postscript": "AlumniSansPinstripe-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AlumniSansPinstripe-Regular.ttf"
                },
                "font_name": "AlumniSansPinstripe Regular",
                "font_family": "AlumniSansPinstripe",
                "font_postscript": "AlumniSansPinstripe-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Amarante",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Amarante-Regular.ttf"
                },
                "font_name": "Amarante Regular",
                "font_family": "Amarante",
                "font_postscript": "Amarante-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Amaranth",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Amaranth-BoldItalic.ttf"
                },
                "font_name": "Amaranth BoldItalic",
                "font_family": "Amaranth",
                "font_postscript": "Amaranth-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Amaranth-Bold.ttf"
                },
                "font_name": "Amaranth Bold",
                "font_family": "Amaranth",
                "font_postscript": "Amaranth-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Amaranth-Italic.ttf"
                },
                "font_name": "Amaranth Italic",
                "font_family": "Amaranth",
                "font_postscript": "Amaranth-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Amaranth-Regular.ttf"
                },
                "font_name": "Amaranth Regular",
                "font_family": "Amaranth",
                "font_postscript": "Amaranth-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AmaticSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AmaticSC-Regular.ttf"
                },
                "font_name": "AmaticSC Regular",
                "font_family": "AmaticSC",
                "font_postscript": "AmaticSC-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AmaticSC-Bold.ttf"
                },
                "font_name": "AmaticSC Bold",
                "font_family": "AmaticSC",
                "font_postscript": "AmaticSC-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Amethysta",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Amethysta-Regular.ttf"
                },
                "font_name": "Amethysta Regular",
                "font_family": "Amethysta",
                "font_postscript": "Amethysta-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Amiko",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Amiko-Bold.ttf"
                },
                "font_name": "Amiko Bold",
                "font_family": "Amiko",
                "font_postscript": "Amiko-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Amiko-Regular.ttf"
                },
                "font_name": "Amiko Regular",
                "font_family": "Amiko",
                "font_postscript": "Amiko-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Amiko-SemiBold.ttf"
                },
                "font_name": "Amiko SemiBold",
                "font_family": "Amiko",
                "font_postscript": "Amiko-SemiBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Amiri",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Amiri-Bold.ttf"
                },
                "font_name": "Amiri Bold",
                "font_family": "Amiri",
                "font_postscript": "Amiri-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Amiri-BoldItalic.ttf"
                },
                "font_name": "Amiri BoldItalic",
                "font_family": "Amiri",
                "font_postscript": "Amiri-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Amiri-Italic.ttf"
                },
                "font_name": "Amiri Italic",
                "font_family": "Amiri",
                "font_postscript": "Amiri-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Amiri-Regular.ttf"
                },
                "font_name": "Amiri Regular",
                "font_family": "Amiri",
                "font_postscript": "Amiri-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AmiriQuran",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AmiriQuran-Regular.ttf"
                },
                "font_name": "AmiriQuran Regular",
                "font_family": "AmiriQuran",
                "font_postscript": "AmiriQuran-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Amita",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Amita-Regular.ttf"
                },
                "font_name": "Amita Regular",
                "font_family": "Amita",
                "font_postscript": "Amita-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Amita-Bold.ttf"
                },
                "font_name": "Amita Bold",
                "font_family": "Amita",
                "font_postscript": "Amita-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AmstelvarAlpha",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AmstelvarAlpha-VF.ttf"
                },
                "font_name": "AmstelvarAlpha VF",
                "font_family": "AmstelvarAlpha",
                "font_postscript": "AmstelvarAlpha-VF",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Anaheim",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Anaheim-Regular.ttf"
                },
                "font_name": "Anaheim Regular",
                "font_family": "Anaheim",
                "font_postscript": "Anaheim-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AndadaPro",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AndadaPro-Regular.ttf"
                },
                "font_name": "AndadaPro Regular",
                "font_family": "AndadaPro",
                "font_postscript": "AndadaPro-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AndadaPro-Italic.ttf"
                },
                "font_name": "AndadaPro Italic",
                "font_family": "AndadaPro",
                "font_postscript": "AndadaPro-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Andika",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Andika-Bold.ttf"
                },
                "font_name": "Andika Bold",
                "font_family": "Andika",
                "font_postscript": "Andika-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Andika-BoldItalic.ttf"
                },
                "font_name": "Andika BoldItalic",
                "font_family": "Andika",
                "font_postscript": "Andika-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Andika-Italic.ttf"
                },
                "font_name": "Andika Italic",
                "font_family": "Andika",
                "font_postscript": "Andika-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Andika-Regular.ttf"
                },
                "font_name": "Andika Regular",
                "font_family": "Andika",
                "font_postscript": "Andika-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AnekBangla",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AnekBangla-Regular.ttf"
                },
                "font_name": "AnekBangla Regular",
                "font_family": "AnekBangla",
                "font_postscript": "AnekBangla-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AnekDevanagari",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AnekDevanagari-Regular.ttf"
                },
                "font_name": "AnekDevanagari Regular",
                "font_family": "AnekDevanagari",
                "font_postscript": "AnekDevanagari-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AnekGujarati",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AnekGujarati-Regular.ttf"
                },
                "font_name": "AnekGujarati Regular",
                "font_family": "AnekGujarati",
                "font_postscript": "AnekGujarati-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AnekGurmukhi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AnekGurmukhi-Regular.ttf"
                },
                "font_name": "AnekGurmukhi Regular",
                "font_family": "AnekGurmukhi",
                "font_postscript": "AnekGurmukhi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AnekKannada",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AnekKannada-Regular.ttf"
                },
                "font_name": "AnekKannada Regular",
                "font_family": "AnekKannada",
                "font_postscript": "AnekKannada-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AnekLatin",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AnekLatin-Regular.ttf"
                },
                "font_name": "AnekLatin Regular",
                "font_family": "AnekLatin",
                "font_postscript": "AnekLatin-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AnekMalayalam",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AnekMalayalam-Regular.ttf"
                },
                "font_name": "AnekMalayalam Regular",
                "font_family": "AnekMalayalam",
                "font_postscript": "AnekMalayalam-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AnekOdia",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AnekOdia-Regular.ttf"
                },
                "font_name": "AnekOdia Regular",
                "font_family": "AnekOdia",
                "font_postscript": "AnekOdia-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AnekTamil",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AnekTamil-Regular.ttf"
                },
                "font_name": "AnekTamil Regular",
                "font_family": "AnekTamil",
                "font_postscript": "AnekTamil-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AnekTelugu",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AnekTelugu-Regular.ttf"
                },
                "font_name": "AnekTelugu Regular",
                "font_family": "AnekTelugu",
                "font_postscript": "AnekTelugu-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Angkor",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Angkor-Regular.ttf"
                },
                "font_name": "Angkor Regular",
                "font_family": "Angkor",
                "font_postscript": "Angkor-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AnnapurnaSIL",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AnnapurnaSIL-Bold.ttf"
                },
                "font_name": "AnnapurnaSIL Bold",
                "font_family": "AnnapurnaSIL",
                "font_postscript": "AnnapurnaSIL-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AnnapurnaSIL-Regular.ttf"
                },
                "font_name": "AnnapurnaSIL Regular",
                "font_family": "AnnapurnaSIL",
                "font_postscript": "AnnapurnaSIL-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AnnieUseYourTelescope",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AnnieUseYourTelescope-Regular.ttf"
                },
                "font_name": "AnnieUseYourTelescope Regular",
                "font_family": "AnnieUseYourTelescope",
                "font_postscript": "AnnieUseYourTelescope-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AnonymousPro",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AnonymousPro-Italic.ttf"
                },
                "font_name": "AnonymousPro Italic",
                "font_family": "AnonymousPro",
                "font_postscript": "AnonymousPro-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AnonymousPro-Bold.ttf"
                },
                "font_name": "AnonymousPro Bold",
                "font_family": "AnonymousPro",
                "font_postscript": "AnonymousPro-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AnonymousPro-BoldItalic.ttf"
                },
                "font_name": "AnonymousPro BoldItalic",
                "font_family": "AnonymousPro",
                "font_postscript": "AnonymousPro-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AnonymousPro-Regular.ttf"
                },
                "font_name": "AnonymousPro Regular",
                "font_family": "AnonymousPro",
                "font_postscript": "AnonymousPro-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Anta",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Anta-Regular.ttf"
                },
                "font_name": "Anta Regular",
                "font_family": "Anta",
                "font_postscript": "Anta-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Antic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Antic-Regular.ttf"
                },
                "font_name": "Antic Regular",
                "font_family": "Antic",
                "font_postscript": "Antic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AnticDidone",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AnticDidone-Regular.ttf"
                },
                "font_name": "AnticDidone Regular",
                "font_family": "AnticDidone",
                "font_postscript": "AnticDidone-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AnticSlab",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AnticSlab-Regular.ttf"
                },
                "font_name": "AnticSlab Regular",
                "font_family": "AnticSlab",
                "font_postscript": "AnticSlab-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Anton",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Anton-Regular.ttf"
                },
                "font_name": "Anton Regular",
                "font_family": "Anton",
                "font_postscript": "Anton-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Antonio",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Antonio-Regular.ttf"
                },
                "font_name": "Antonio Regular",
                "font_family": "Antonio",
                "font_postscript": "Antonio-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Anuphan",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Anuphan-Regular.ttf"
                },
                "font_name": "Anuphan Regular",
                "font_family": "Anuphan",
                "font_postscript": "Anuphan-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Anybody",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Anybody-Regular.ttf"
                },
                "font_name": "Anybody Regular",
                "font_family": "Anybody",
                "font_postscript": "Anybody-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Anybody-Italic.ttf"
                },
                "font_name": "Anybody Italic",
                "font_family": "Anybody",
                "font_postscript": "Anybody-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AoboshiOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AoboshiOne-Regular.ttf"
                },
                "font_name": "AoboshiOne Regular",
                "font_family": "AoboshiOne",
                "font_postscript": "AoboshiOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Arapey",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Arapey-Regular.ttf"
                },
                "font_name": "Arapey Regular",
                "font_family": "Arapey",
                "font_postscript": "Arapey-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Arapey-Italic.ttf"
                },
                "font_name": "Arapey Italic",
                "font_family": "Arapey",
                "font_postscript": "Arapey-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Arbutus",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Arbutus-Regular.ttf"
                },
                "font_name": "Arbutus Regular",
                "font_family": "Arbutus",
                "font_postscript": "Arbutus-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ArbutusSlab",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ArbutusSlab-Regular.ttf"
                },
                "font_name": "ArbutusSlab Regular",
                "font_family": "ArbutusSlab",
                "font_postscript": "ArbutusSlab-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ArchitectsDaughter",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ArchitectsDaughter-Regular.ttf"
                },
                "font_name": "ArchitectsDaughter Regular",
                "font_family": "ArchitectsDaughter",
                "font_postscript": "ArchitectsDaughter-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Archivo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Archivo-Italic.ttf"
                },
                "font_name": "Archivo Italic",
                "font_family": "Archivo",
                "font_postscript": "Archivo-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Archivo-Regular.ttf"
                },
                "font_name": "Archivo Regular",
                "font_family": "Archivo",
                "font_postscript": "Archivo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ArchivoBlack",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ArchivoBlack-Regular.ttf"
                },
                "font_name": "ArchivoBlack Regular",
                "font_family": "ArchivoBlack",
                "font_postscript": "ArchivoBlack-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ArchivoNarrow",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ArchivoNarrow-Italic.ttf"
                },
                "font_name": "ArchivoNarrow Italic",
                "font_family": "ArchivoNarrow",
                "font_postscript": "ArchivoNarrow-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ArchivoNarrow-Regular.ttf"
                },
                "font_name": "ArchivoNarrow Regular",
                "font_family": "ArchivoNarrow",
                "font_postscript": "ArchivoNarrow-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AreYouSerious",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AreYouSerious-Regular.ttf"
                },
                "font_name": "AreYouSerious Regular",
                "font_family": "AreYouSerious",
                "font_postscript": "AreYouSerious-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ArefRuqaa",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ArefRuqaa-Regular.ttf"
                },
                "font_name": "ArefRuqaa Regular",
                "font_family": "ArefRuqaa",
                "font_postscript": "ArefRuqaa-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ArefRuqaa-Bold.ttf"
                },
                "font_name": "ArefRuqaa Bold",
                "font_family": "ArefRuqaa",
                "font_postscript": "ArefRuqaa-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ArefRuqaaInk",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ArefRuqaaInk-Regular.ttf"
                },
                "font_name": "ArefRuqaaInk Regular",
                "font_family": "ArefRuqaaInk",
                "font_postscript": "ArefRuqaaInk-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ArefRuqaaInk-Bold.ttf"
                },
                "font_name": "ArefRuqaaInk Bold",
                "font_family": "ArefRuqaaInk",
                "font_postscript": "ArefRuqaaInk-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Arial Unicode",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Arial Unicode-Regular.ttf"
                },
                "font_name": "Arial Unicode Regular",
                "font_family": "Arial Unicode",
                "font_postscript": "Arial Unicode-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Arima",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Arima-Regular.ttf"
                },
                "font_name": "Arima Regular",
                "font_family": "Arima",
                "font_postscript": "Arima-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Arimo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Arimo-Regular.ttf"
                },
                "font_name": "Arimo Regular",
                "font_family": "Arimo",
                "font_postscript": "Arimo-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Arimo-Italic.ttf"
                },
                "font_name": "Arimo Italic",
                "font_family": "Arimo",
                "font_postscript": "Arimo-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Arizonia",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Arizonia-Regular.ttf"
                },
                "font_name": "Arizonia Regular",
                "font_family": "Arizonia",
                "font_postscript": "Arizonia-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Armata",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Armata-Regular.ttf"
                },
                "font_name": "Armata Regular",
                "font_family": "Armata",
                "font_postscript": "Armata-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Arsenal",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Arsenal-Italic.ttf"
                },
                "font_name": "Arsenal Italic",
                "font_family": "Arsenal",
                "font_postscript": "Arsenal-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Arsenal-Regular.ttf"
                },
                "font_name": "Arsenal Regular",
                "font_family": "Arsenal",
                "font_postscript": "Arsenal-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Arsenal-Bold.ttf"
                },
                "font_name": "Arsenal Bold",
                "font_family": "Arsenal",
                "font_postscript": "Arsenal-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Arsenal-BoldItalic.ttf"
                },
                "font_name": "Arsenal BoldItalic",
                "font_family": "Arsenal",
                "font_postscript": "Arsenal-BoldItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Artifika",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Artifika-Regular.ttf"
                },
                "font_name": "Artifika Regular",
                "font_family": "Artifika",
                "font_postscript": "Artifika-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Arvo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Arvo-Regular.ttf"
                },
                "font_name": "Arvo Regular",
                "font_family": "Arvo",
                "font_postscript": "Arvo-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Arvo-Bold.ttf"
                },
                "font_name": "Arvo Bold",
                "font_family": "Arvo",
                "font_postscript": "Arvo-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Arvo-BoldItalic.ttf"
                },
                "font_name": "Arvo BoldItalic",
                "font_family": "Arvo",
                "font_postscript": "Arvo-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Arvo-Italic.ttf"
                },
                "font_name": "Arvo Italic",
                "font_family": "Arvo",
                "font_postscript": "Arvo-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Arya",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Arya-Regular.ttf"
                },
                "font_name": "Arya Regular",
                "font_family": "Arya",
                "font_postscript": "Arya-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Arya-Bold.ttf"
                },
                "font_name": "Arya Bold",
                "font_family": "Arya",
                "font_postscript": "Arya-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Asap",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Asap-Regular.ttf"
                },
                "font_name": "Asap Regular",
                "font_family": "Asap",
                "font_postscript": "Asap-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Asap-Italic.ttf"
                },
                "font_name": "Asap Italic",
                "font_family": "Asap",
                "font_postscript": "Asap-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AsapCondensed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AsapCondensed-Light.ttf"
                },
                "font_name": "AsapCondensed Light",
                "font_family": "AsapCondensed",
                "font_postscript": "AsapCondensed-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AsapCondensed-LightItalic.ttf"
                },
                "font_name": "AsapCondensed LightItalic",
                "font_family": "AsapCondensed",
                "font_postscript": "AsapCondensed-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AsapCondensed-ExtraLightItalic.ttf"
                },
                "font_name": "AsapCondensed ExtraLightItalic",
                "font_family": "AsapCondensed",
                "font_postscript": "AsapCondensed-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AsapCondensed-MediumItalic.ttf"
                },
                "font_name": "AsapCondensed MediumItalic",
                "font_family": "AsapCondensed",
                "font_postscript": "AsapCondensed-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AsapCondensed-ExtraBold.ttf"
                },
                "font_name": "AsapCondensed ExtraBold",
                "font_family": "AsapCondensed",
                "font_postscript": "AsapCondensed-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AsapCondensed-SemiBoldItalic.ttf"
                },
                "font_name": "AsapCondensed SemiBoldItalic",
                "font_family": "AsapCondensed",
                "font_postscript": "AsapCondensed-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AsapCondensed-SemiBold.ttf"
                },
                "font_name": "AsapCondensed SemiBold",
                "font_family": "AsapCondensed",
                "font_postscript": "AsapCondensed-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AsapCondensed-BoldItalic.ttf"
                },
                "font_name": "AsapCondensed BoldItalic",
                "font_family": "AsapCondensed",
                "font_postscript": "AsapCondensed-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AsapCondensed-Regular.ttf"
                },
                "font_name": "AsapCondensed Regular",
                "font_family": "AsapCondensed",
                "font_postscript": "AsapCondensed-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AsapCondensed-BlackItalic.ttf"
                },
                "font_name": "AsapCondensed BlackItalic",
                "font_family": "AsapCondensed",
                "font_postscript": "AsapCondensed-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AsapCondensed-ExtraLight.ttf"
                },
                "font_name": "AsapCondensed ExtraLight",
                "font_family": "AsapCondensed",
                "font_postscript": "AsapCondensed-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AsapCondensed-Italic.ttf"
                },
                "font_name": "AsapCondensed Italic",
                "font_family": "AsapCondensed",
                "font_postscript": "AsapCondensed-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AsapCondensed-Medium.ttf"
                },
                "font_name": "AsapCondensed Medium",
                "font_family": "AsapCondensed",
                "font_postscript": "AsapCondensed-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AsapCondensed-ExtraBoldItalic.ttf"
                },
                "font_name": "AsapCondensed ExtraBoldItalic",
                "font_family": "AsapCondensed",
                "font_postscript": "AsapCondensed-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AsapCondensed-Bold.ttf"
                },
                "font_name": "AsapCondensed Bold",
                "font_family": "AsapCondensed",
                "font_postscript": "AsapCondensed-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AsapCondensed-Black.ttf"
                },
                "font_name": "AsapCondensed Black",
                "font_family": "AsapCondensed",
                "font_postscript": "AsapCondensed-Black",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Asar",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Asar-Regular.ttf"
                },
                "font_name": "Asar Regular",
                "font_family": "Asar",
                "font_postscript": "Asar-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Asset",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Asset-Regular.ttf"
                },
                "font_name": "Asset Regular",
                "font_family": "Asset",
                "font_postscript": "Asset-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Assistant",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Assistant-Regular.ttf"
                },
                "font_name": "Assistant Regular",
                "font_family": "Assistant",
                "font_postscript": "Assistant-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Astloch",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Astloch-Bold.ttf"
                },
                "font_name": "Astloch Bold",
                "font_family": "Astloch",
                "font_postscript": "Astloch-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Astloch-Regular.ttf"
                },
                "font_name": "Astloch Regular",
                "font_family": "Astloch",
                "font_postscript": "Astloch-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Asul",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Asul-Regular.ttf"
                },
                "font_name": "Asul Regular",
                "font_family": "Asul",
                "font_postscript": "Asul-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Asul-Bold.ttf"
                },
                "font_name": "Asul Bold",
                "font_family": "Asul",
                "font_postscript": "Asul-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Athiti",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Athiti-Bold.ttf"
                },
                "font_name": "Athiti Bold",
                "font_family": "Athiti",
                "font_postscript": "Athiti-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Athiti-Medium.ttf"
                },
                "font_name": "Athiti Medium",
                "font_family": "Athiti",
                "font_postscript": "Athiti-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Athiti-Regular.ttf"
                },
                "font_name": "Athiti Regular",
                "font_family": "Athiti",
                "font_postscript": "Athiti-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Athiti-ExtraLight.ttf"
                },
                "font_name": "Athiti ExtraLight",
                "font_family": "Athiti",
                "font_postscript": "Athiti-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Athiti-Light.ttf"
                },
                "font_name": "Athiti Light",
                "font_family": "Athiti",
                "font_postscript": "Athiti-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Athiti-SemiBold.ttf"
                },
                "font_name": "Athiti SemiBold",
                "font_family": "Athiti",
                "font_postscript": "Athiti-SemiBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AtkinsonHyperlegible",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AtkinsonHyperlegible-Regular.ttf"
                },
                "font_name": "AtkinsonHyperlegible Regular",
                "font_family": "AtkinsonHyperlegible",
                "font_postscript": "AtkinsonHyperlegible-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AtkinsonHyperlegible-Bold.ttf"
                },
                "font_name": "AtkinsonHyperlegible Bold",
                "font_family": "AtkinsonHyperlegible",
                "font_postscript": "AtkinsonHyperlegible-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AtkinsonHyperlegible-BoldItalic.ttf"
                },
                "font_name": "AtkinsonHyperlegible BoldItalic",
                "font_family": "AtkinsonHyperlegible",
                "font_postscript": "AtkinsonHyperlegible-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AtkinsonHyperlegible-Italic.ttf"
                },
                "font_name": "AtkinsonHyperlegible Italic",
                "font_family": "AtkinsonHyperlegible",
                "font_postscript": "AtkinsonHyperlegible-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Atma",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Atma-Medium.ttf"
                },
                "font_name": "Atma Medium",
                "font_family": "Atma",
                "font_postscript": "Atma-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Atma-SemiBold.ttf"
                },
                "font_name": "Atma SemiBold",
                "font_family": "Atma",
                "font_postscript": "Atma-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Atma-Light.ttf"
                },
                "font_name": "Atma Light",
                "font_family": "Atma",
                "font_postscript": "Atma-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Atma-Bold.ttf"
                },
                "font_name": "Atma Bold",
                "font_family": "Atma",
                "font_postscript": "Atma-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Atma-Regular.ttf"
                },
                "font_name": "Atma Regular",
                "font_family": "Atma",
                "font_postscript": "Atma-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AtomicAge",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AtomicAge-Regular.ttf"
                },
                "font_name": "AtomicAge Regular",
                "font_family": "AtomicAge",
                "font_postscript": "AtomicAge-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Aubrey",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Aubrey-Regular.ttf"
                },
                "font_name": "Aubrey Regular",
                "font_family": "Aubrey",
                "font_postscript": "Aubrey-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Audiowide",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Audiowide-Regular.ttf"
                },
                "font_name": "Audiowide Regular",
                "font_family": "Audiowide",
                "font_postscript": "Audiowide-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AutourOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AutourOne-Regular.ttf"
                },
                "font_name": "AutourOne Regular",
                "font_family": "AutourOne",
                "font_postscript": "AutourOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Average",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Average-Regular.ttf"
                },
                "font_name": "Average Regular",
                "font_family": "Average",
                "font_postscript": "Average-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AverageSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AverageSans-Regular.ttf"
                },
                "font_name": "AverageSans Regular",
                "font_family": "AverageSans",
                "font_postscript": "AverageSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AveriaGruesaLibre",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AveriaGruesaLibre-Regular.ttf"
                },
                "font_name": "AveriaGruesaLibre Regular",
                "font_family": "AveriaGruesaLibre",
                "font_postscript": "AveriaGruesaLibre-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AveriaLibre",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AveriaLibre-BoldItalic.ttf"
                },
                "font_name": "AveriaLibre BoldItalic",
                "font_family": "AveriaLibre",
                "font_postscript": "AveriaLibre-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AveriaLibre-Regular.ttf"
                },
                "font_name": "AveriaLibre Regular",
                "font_family": "AveriaLibre",
                "font_postscript": "AveriaLibre-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AveriaLibre-LightItalic.ttf"
                },
                "font_name": "AveriaLibre LightItalic",
                "font_family": "AveriaLibre",
                "font_postscript": "AveriaLibre-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AveriaLibre-Italic.ttf"
                },
                "font_name": "AveriaLibre Italic",
                "font_family": "AveriaLibre",
                "font_postscript": "AveriaLibre-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AveriaLibre-Light.ttf"
                },
                "font_name": "AveriaLibre Light",
                "font_family": "AveriaLibre",
                "font_postscript": "AveriaLibre-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AveriaLibre-Bold.ttf"
                },
                "font_name": "AveriaLibre Bold",
                "font_family": "AveriaLibre",
                "font_postscript": "AveriaLibre-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AveriaSansLibre",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AveriaSansLibre-Italic.ttf"
                },
                "font_name": "AveriaSansLibre Italic",
                "font_family": "AveriaSansLibre",
                "font_postscript": "AveriaSansLibre-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AveriaSansLibre-Light.ttf"
                },
                "font_name": "AveriaSansLibre Light",
                "font_family": "AveriaSansLibre",
                "font_postscript": "AveriaSansLibre-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AveriaSansLibre-Bold.ttf"
                },
                "font_name": "AveriaSansLibre Bold",
                "font_family": "AveriaSansLibre",
                "font_postscript": "AveriaSansLibre-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AveriaSansLibre-BoldItalic.ttf"
                },
                "font_name": "AveriaSansLibre BoldItalic",
                "font_family": "AveriaSansLibre",
                "font_postscript": "AveriaSansLibre-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AveriaSansLibre-Regular.ttf"
                },
                "font_name": "AveriaSansLibre Regular",
                "font_family": "AveriaSansLibre",
                "font_postscript": "AveriaSansLibre-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AveriaSansLibre-LightItalic.ttf"
                },
                "font_name": "AveriaSansLibre LightItalic",
                "font_family": "AveriaSansLibre",
                "font_postscript": "AveriaSansLibre-LightItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AveriaSerifLibre",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AveriaSerifLibre-LightItalic.ttf"
                },
                "font_name": "AveriaSerifLibre LightItalic",
                "font_family": "AveriaSerifLibre",
                "font_postscript": "AveriaSerifLibre-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AveriaSerifLibre-BoldItalic.ttf"
                },
                "font_name": "AveriaSerifLibre BoldItalic",
                "font_family": "AveriaSerifLibre",
                "font_postscript": "AveriaSerifLibre-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AveriaSerifLibre-Bold.ttf"
                },
                "font_name": "AveriaSerifLibre Bold",
                "font_family": "AveriaSerifLibre",
                "font_postscript": "AveriaSerifLibre-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AveriaSerifLibre-Regular.ttf"
                },
                "font_name": "AveriaSerifLibre Regular",
                "font_family": "AveriaSerifLibre",
                "font_postscript": "AveriaSerifLibre-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AveriaSerifLibre-Light.ttf"
                },
                "font_name": "AveriaSerifLibre Light",
                "font_family": "AveriaSerifLibre",
                "font_postscript": "AveriaSerifLibre-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AveriaSerifLibre-Italic.ttf"
                },
                "font_name": "AveriaSerifLibre Italic",
                "font_family": "AveriaSerifLibre",
                "font_postscript": "AveriaSerifLibre-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "AzeretMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "AzeretMono-Italic.ttf"
                },
                "font_name": "AzeretMono Italic",
                "font_family": "AzeretMono",
                "font_postscript": "AzeretMono-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "AzeretMono-Regular.ttf"
                },
                "font_name": "AzeretMono Regular",
                "font_family": "AzeretMono",
                "font_postscript": "AzeretMono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "B612",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "B612-Italic.ttf"
                },
                "font_name": "B612 Italic",
                "font_family": "B612",
                "font_postscript": "B612-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "B612-BoldItalic.ttf"
                },
                "font_name": "B612 BoldItalic",
                "font_family": "B612",
                "font_postscript": "B612-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "B612-Bold.ttf"
                },
                "font_name": "B612 Bold",
                "font_family": "B612",
                "font_postscript": "B612-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "B612-Regular.ttf"
                },
                "font_name": "B612 Regular",
                "font_family": "B612",
                "font_postscript": "B612-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "B612Mono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "B612Mono-Bold.ttf"
                },
                "font_name": "B612Mono Bold",
                "font_family": "B612Mono",
                "font_postscript": "B612Mono-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "B612Mono-Italic.ttf"
                },
                "font_name": "B612Mono Italic",
                "font_family": "B612Mono",
                "font_postscript": "B612Mono-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "B612Mono-BoldItalic.ttf"
                },
                "font_name": "B612Mono BoldItalic",
                "font_family": "B612Mono",
                "font_postscript": "B612Mono-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "B612Mono-Regular.ttf"
                },
                "font_name": "B612Mono Regular",
                "font_family": "B612Mono",
                "font_postscript": "B612Mono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BIZUDGothic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BIZUDGothic-Bold.ttf"
                },
                "font_name": "BIZUDGothic Bold",
                "font_family": "BIZUDGothic",
                "font_postscript": "BIZUDGothic-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BIZUDGothic-Regular.ttf"
                },
                "font_name": "BIZUDGothic Regular",
                "font_family": "BIZUDGothic",
                "font_postscript": "BIZUDGothic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BIZUDMincho",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BIZUDMincho-Bold.ttf"
                },
                "font_name": "BIZUDMincho Bold",
                "font_family": "BIZUDMincho",
                "font_postscript": "BIZUDMincho-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BIZUDMincho-Regular.ttf"
                },
                "font_name": "BIZUDMincho Regular",
                "font_family": "BIZUDMincho",
                "font_postscript": "BIZUDMincho-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BIZUDPGothic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BIZUDPGothic-Regular.ttf"
                },
                "font_name": "BIZUDPGothic Regular",
                "font_family": "BIZUDPGothic",
                "font_postscript": "BIZUDPGothic-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BIZUDPGothic-Bold.ttf"
                },
                "font_name": "BIZUDPGothic Bold",
                "font_family": "BIZUDPGothic",
                "font_postscript": "BIZUDPGothic-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BIZUDPMincho",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BIZUDPMincho-Bold.ttf"
                },
                "font_name": "BIZUDPMincho Bold",
                "font_family": "BIZUDPMincho",
                "font_postscript": "BIZUDPMincho-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BIZUDPMincho-Regular.ttf"
                },
                "font_name": "BIZUDPMincho Regular",
                "font_family": "BIZUDPMincho",
                "font_postscript": "BIZUDPMincho-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BM",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BM-HANNA.ttf"
                },
                "font_name": "BM HANNA",
                "font_family": "BM",
                "font_postscript": "BM-HANNA",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Babylonica",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Babylonica-Regular.ttf"
                },
                "font_name": "Babylonica Regular",
                "font_family": "Babylonica",
                "font_postscript": "Babylonica-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BacasimeAntique",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BacasimeAntique-Regular.ttf"
                },
                "font_name": "BacasimeAntique Regular",
                "font_family": "BacasimeAntique",
                "font_postscript": "BacasimeAntique-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BadScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BadScript-Regular.ttf"
                },
                "font_name": "BadScript Regular",
                "font_family": "BadScript",
                "font_postscript": "BadScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BagelFatOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BagelFatOne-Regular.ttf"
                },
                "font_name": "BagelFatOne Regular",
                "font_family": "BagelFatOne",
                "font_postscript": "BagelFatOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Bahiana",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Bahiana-Regular.ttf"
                },
                "font_name": "Bahiana Regular",
                "font_family": "Bahiana",
                "font_postscript": "Bahiana-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Bahianita",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Bahianita-Regular.ttf"
                },
                "font_name": "Bahianita Regular",
                "font_family": "Bahianita",
                "font_postscript": "Bahianita-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BaiJamjuree",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BaiJamjuree-Italic.ttf"
                },
                "font_name": "BaiJamjuree Italic",
                "font_family": "BaiJamjuree",
                "font_postscript": "BaiJamjuree-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BaiJamjuree-Regular.ttf"
                },
                "font_name": "BaiJamjuree Regular",
                "font_family": "BaiJamjuree",
                "font_postscript": "BaiJamjuree-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BaiJamjuree-Light.ttf"
                },
                "font_name": "BaiJamjuree Light",
                "font_family": "BaiJamjuree",
                "font_postscript": "BaiJamjuree-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BaiJamjuree-SemiBoldItalic.ttf"
                },
                "font_name": "BaiJamjuree SemiBoldItalic",
                "font_family": "BaiJamjuree",
                "font_postscript": "BaiJamjuree-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BaiJamjuree-MediumItalic.ttf"
                },
                "font_name": "BaiJamjuree MediumItalic",
                "font_family": "BaiJamjuree",
                "font_postscript": "BaiJamjuree-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BaiJamjuree-LightItalic.ttf"
                },
                "font_name": "BaiJamjuree LightItalic",
                "font_family": "BaiJamjuree",
                "font_postscript": "BaiJamjuree-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BaiJamjuree-ExtraLightItalic.ttf"
                },
                "font_name": "BaiJamjuree ExtraLightItalic",
                "font_family": "BaiJamjuree",
                "font_postscript": "BaiJamjuree-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BaiJamjuree-Bold.ttf"
                },
                "font_name": "BaiJamjuree Bold",
                "font_family": "BaiJamjuree",
                "font_postscript": "BaiJamjuree-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BaiJamjuree-ExtraLight.ttf"
                },
                "font_name": "BaiJamjuree ExtraLight",
                "font_family": "BaiJamjuree",
                "font_postscript": "BaiJamjuree-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BaiJamjuree-SemiBold.ttf"
                },
                "font_name": "BaiJamjuree SemiBold",
                "font_family": "BaiJamjuree",
                "font_postscript": "BaiJamjuree-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BaiJamjuree-BoldItalic.ttf"
                },
                "font_name": "BaiJamjuree BoldItalic",
                "font_family": "BaiJamjuree",
                "font_postscript": "BaiJamjuree-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BaiJamjuree-Medium.ttf"
                },
                "font_name": "BaiJamjuree Medium",
                "font_family": "BaiJamjuree",
                "font_postscript": "BaiJamjuree-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BakbakOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BakbakOne-Regular.ttf"
                },
                "font_name": "BakbakOne Regular",
                "font_family": "BakbakOne",
                "font_postscript": "BakbakOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Ballet",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ballet-Regular.ttf"
                },
                "font_name": "Ballet Regular",
                "font_family": "Ballet",
                "font_postscript": "Ballet-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Baloo2",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Baloo2-Regular.ttf"
                },
                "font_name": "Baloo2 Regular",
                "font_family": "Baloo2",
                "font_postscript": "Baloo2-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BalooBhai2",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BalooBhai2-Regular.ttf"
                },
                "font_name": "BalooBhai2 Regular",
                "font_family": "BalooBhai2",
                "font_postscript": "BalooBhai2-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BalooBhaijaan2",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BalooBhaijaan2-Regular.ttf"
                },
                "font_name": "BalooBhaijaan2 Regular",
                "font_family": "BalooBhaijaan2",
                "font_postscript": "BalooBhaijaan2-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BalooBhaina2",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BalooBhaina2-Regular.ttf"
                },
                "font_name": "BalooBhaina2 Regular",
                "font_family": "BalooBhaina2",
                "font_postscript": "BalooBhaina2-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BalooChettan2",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BalooChettan2-Regular.ttf"
                },
                "font_name": "BalooChettan2 Regular",
                "font_family": "BalooChettan2",
                "font_postscript": "BalooChettan2-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BalooDa2",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BalooDa2-Regular.ttf"
                },
                "font_name": "BalooDa2 Regular",
                "font_family": "BalooDa2",
                "font_postscript": "BalooDa2-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BalooPaaji2",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BalooPaaji2-Regular.ttf"
                },
                "font_name": "BalooPaaji2 Regular",
                "font_family": "BalooPaaji2",
                "font_postscript": "BalooPaaji2-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BalooTamma2",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BalooTamma2-Regular.ttf"
                },
                "font_name": "BalooTamma2 Regular",
                "font_family": "BalooTamma2",
                "font_postscript": "BalooTamma2-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BalooTammudu2",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BalooTammudu2-Regular.ttf"
                },
                "font_name": "BalooTammudu2 Regular",
                "font_family": "BalooTammudu2",
                "font_postscript": "BalooTammudu2-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BalooThambi2",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BalooThambi2-Regular.ttf"
                },
                "font_name": "BalooThambi2 Regular",
                "font_family": "BalooThambi2",
                "font_postscript": "BalooThambi2-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BalsamiqSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BalsamiqSans-Bold.ttf"
                },
                "font_name": "BalsamiqSans Bold",
                "font_family": "BalsamiqSans",
                "font_postscript": "BalsamiqSans-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BalsamiqSans-Italic.ttf"
                },
                "font_name": "BalsamiqSans Italic",
                "font_family": "BalsamiqSans",
                "font_postscript": "BalsamiqSans-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BalsamiqSans-BoldItalic.ttf"
                },
                "font_name": "BalsamiqSans BoldItalic",
                "font_family": "BalsamiqSans",
                "font_postscript": "BalsamiqSans-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BalsamiqSans-Regular.ttf"
                },
                "font_name": "BalsamiqSans Regular",
                "font_family": "BalsamiqSans",
                "font_postscript": "BalsamiqSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Balthazar",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Balthazar-Regular.ttf"
                },
                "font_name": "Balthazar Regular",
                "font_family": "Balthazar",
                "font_postscript": "Balthazar-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Bangers",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Bangers-Regular.ttf"
                },
                "font_name": "Bangers Regular",
                "font_family": "Bangers",
                "font_postscript": "Bangers-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Barlow",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Barlow-Light.ttf"
                },
                "font_name": "Barlow Light",
                "font_family": "Barlow",
                "font_postscript": "Barlow-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Barlow-SemiBoldItalic.ttf"
                },
                "font_name": "Barlow SemiBoldItalic",
                "font_family": "Barlow",
                "font_postscript": "Barlow-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Barlow-BoldItalic.ttf"
                },
                "font_name": "Barlow BoldItalic",
                "font_family": "Barlow",
                "font_postscript": "Barlow-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Barlow-ExtraLight.ttf"
                },
                "font_name": "Barlow ExtraLight",
                "font_family": "Barlow",
                "font_postscript": "Barlow-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Barlow-ThinItalic.ttf"
                },
                "font_name": "Barlow ThinItalic",
                "font_family": "Barlow",
                "font_postscript": "Barlow-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Barlow-MediumItalic.ttf"
                },
                "font_name": "Barlow MediumItalic",
                "font_family": "Barlow",
                "font_postscript": "Barlow-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Barlow-Italic.ttf"
                },
                "font_name": "Barlow Italic",
                "font_family": "Barlow",
                "font_postscript": "Barlow-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Barlow-ExtraLightItalic.ttf"
                },
                "font_name": "Barlow ExtraLightItalic",
                "font_family": "Barlow",
                "font_postscript": "Barlow-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Barlow-ExtraBoldItalic.ttf"
                },
                "font_name": "Barlow ExtraBoldItalic",
                "font_family": "Barlow",
                "font_postscript": "Barlow-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Barlow-Thin.ttf"
                },
                "font_name": "Barlow Thin",
                "font_family": "Barlow",
                "font_postscript": "Barlow-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Barlow-ExtraBold.ttf"
                },
                "font_name": "Barlow ExtraBold",
                "font_family": "Barlow",
                "font_postscript": "Barlow-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Barlow-LightItalic.ttf"
                },
                "font_name": "Barlow LightItalic",
                "font_family": "Barlow",
                "font_postscript": "Barlow-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Barlow-Bold.ttf"
                },
                "font_name": "Barlow Bold",
                "font_family": "Barlow",
                "font_postscript": "Barlow-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Barlow-Medium.ttf"
                },
                "font_name": "Barlow Medium",
                "font_family": "Barlow",
                "font_postscript": "Barlow-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Barlow-Regular.ttf"
                },
                "font_name": "Barlow Regular",
                "font_family": "Barlow",
                "font_postscript": "Barlow-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Barlow-BlackItalic.ttf"
                },
                "font_name": "Barlow BlackItalic",
                "font_family": "Barlow",
                "font_postscript": "Barlow-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Barlow-SemiBold.ttf"
                },
                "font_name": "Barlow SemiBold",
                "font_family": "Barlow",
                "font_postscript": "Barlow-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Barlow-Black.ttf"
                },
                "font_name": "Barlow Black",
                "font_family": "Barlow",
                "font_postscript": "Barlow-Black",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BarlowCondensed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowCondensed-BoldItalic.ttf"
                },
                "font_name": "BarlowCondensed BoldItalic",
                "font_family": "BarlowCondensed",
                "font_postscript": "BarlowCondensed-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowCondensed-ThinItalic.ttf"
                },
                "font_name": "BarlowCondensed ThinItalic",
                "font_family": "BarlowCondensed",
                "font_postscript": "BarlowCondensed-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowCondensed-BlackItalic.ttf"
                },
                "font_name": "BarlowCondensed BlackItalic",
                "font_family": "BarlowCondensed",
                "font_postscript": "BarlowCondensed-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowCondensed-ExtraLight.ttf"
                },
                "font_name": "BarlowCondensed ExtraLight",
                "font_family": "BarlowCondensed",
                "font_postscript": "BarlowCondensed-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowCondensed-Thin.ttf"
                },
                "font_name": "BarlowCondensed Thin",
                "font_family": "BarlowCondensed",
                "font_postscript": "BarlowCondensed-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowCondensed-Bold.ttf"
                },
                "font_name": "BarlowCondensed Bold",
                "font_family": "BarlowCondensed",
                "font_postscript": "BarlowCondensed-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowCondensed-Light.ttf"
                },
                "font_name": "BarlowCondensed Light",
                "font_family": "BarlowCondensed",
                "font_postscript": "BarlowCondensed-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowCondensed-ExtraLightItalic.ttf"
                },
                "font_name": "BarlowCondensed ExtraLightItalic",
                "font_family": "BarlowCondensed",
                "font_postscript": "BarlowCondensed-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowCondensed-Medium.ttf"
                },
                "font_name": "BarlowCondensed Medium",
                "font_family": "BarlowCondensed",
                "font_postscript": "BarlowCondensed-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowCondensed-SemiBoldItalic.ttf"
                },
                "font_name": "BarlowCondensed SemiBoldItalic",
                "font_family": "BarlowCondensed",
                "font_postscript": "BarlowCondensed-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowCondensed-MediumItalic.ttf"
                },
                "font_name": "BarlowCondensed MediumItalic",
                "font_family": "BarlowCondensed",
                "font_postscript": "BarlowCondensed-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowCondensed-LightItalic.ttf"
                },
                "font_name": "BarlowCondensed LightItalic",
                "font_family": "BarlowCondensed",
                "font_postscript": "BarlowCondensed-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowCondensed-Italic.ttf"
                },
                "font_name": "BarlowCondensed Italic",
                "font_family": "BarlowCondensed",
                "font_postscript": "BarlowCondensed-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowCondensed-Black.ttf"
                },
                "font_name": "BarlowCondensed Black",
                "font_family": "BarlowCondensed",
                "font_postscript": "BarlowCondensed-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowCondensed-Regular.ttf"
                },
                "font_name": "BarlowCondensed Regular",
                "font_family": "BarlowCondensed",
                "font_postscript": "BarlowCondensed-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowCondensed-SemiBold.ttf"
                },
                "font_name": "BarlowCondensed SemiBold",
                "font_family": "BarlowCondensed",
                "font_postscript": "BarlowCondensed-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowCondensed-ExtraBoldItalic.ttf"
                },
                "font_name": "BarlowCondensed ExtraBoldItalic",
                "font_family": "BarlowCondensed",
                "font_postscript": "BarlowCondensed-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowCondensed-ExtraBold.ttf"
                },
                "font_name": "BarlowCondensed ExtraBold",
                "font_family": "BarlowCondensed",
                "font_postscript": "BarlowCondensed-ExtraBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BarlowSemiCondensed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowSemiCondensed-Black.ttf"
                },
                "font_name": "BarlowSemiCondensed Black",
                "font_family": "BarlowSemiCondensed",
                "font_postscript": "BarlowSemiCondensed-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowSemiCondensed-Regular.ttf"
                },
                "font_name": "BarlowSemiCondensed Regular",
                "font_family": "BarlowSemiCondensed",
                "font_postscript": "BarlowSemiCondensed-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowSemiCondensed-Italic.ttf"
                },
                "font_name": "BarlowSemiCondensed Italic",
                "font_family": "BarlowSemiCondensed",
                "font_postscript": "BarlowSemiCondensed-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowSemiCondensed-ExtraBold.ttf"
                },
                "font_name": "BarlowSemiCondensed ExtraBold",
                "font_family": "BarlowSemiCondensed",
                "font_postscript": "BarlowSemiCondensed-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowSemiCondensed-LightItalic.ttf"
                },
                "font_name": "BarlowSemiCondensed LightItalic",
                "font_family": "BarlowSemiCondensed",
                "font_postscript": "BarlowSemiCondensed-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowSemiCondensed-ExtraBoldItalic.ttf"
                },
                "font_name": "BarlowSemiCondensed ExtraBoldItalic",
                "font_family": "BarlowSemiCondensed",
                "font_postscript": "BarlowSemiCondensed-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowSemiCondensed-Thin.ttf"
                },
                "font_name": "BarlowSemiCondensed Thin",
                "font_family": "BarlowSemiCondensed",
                "font_postscript": "BarlowSemiCondensed-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowSemiCondensed-Bold.ttf"
                },
                "font_name": "BarlowSemiCondensed Bold",
                "font_family": "BarlowSemiCondensed",
                "font_postscript": "BarlowSemiCondensed-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowSemiCondensed-ExtraLightItalic.ttf"
                },
                "font_name": "BarlowSemiCondensed ExtraLightItalic",
                "font_family": "BarlowSemiCondensed",
                "font_postscript": "BarlowSemiCondensed-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowSemiCondensed-MediumItalic.ttf"
                },
                "font_name": "BarlowSemiCondensed MediumItalic",
                "font_family": "BarlowSemiCondensed",
                "font_postscript": "BarlowSemiCondensed-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowSemiCondensed-SemiBoldItalic.ttf"
                },
                "font_name": "BarlowSemiCondensed SemiBoldItalic",
                "font_family": "BarlowSemiCondensed",
                "font_postscript": "BarlowSemiCondensed-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowSemiCondensed-ThinItalic.ttf"
                },
                "font_name": "BarlowSemiCondensed ThinItalic",
                "font_family": "BarlowSemiCondensed",
                "font_postscript": "BarlowSemiCondensed-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowSemiCondensed-Medium.ttf"
                },
                "font_name": "BarlowSemiCondensed Medium",
                "font_family": "BarlowSemiCondensed",
                "font_postscript": "BarlowSemiCondensed-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowSemiCondensed-Light.ttf"
                },
                "font_name": "BarlowSemiCondensed Light",
                "font_family": "BarlowSemiCondensed",
                "font_postscript": "BarlowSemiCondensed-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowSemiCondensed-SemiBold.ttf"
                },
                "font_name": "BarlowSemiCondensed SemiBold",
                "font_family": "BarlowSemiCondensed",
                "font_postscript": "BarlowSemiCondensed-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowSemiCondensed-ExtraLight.ttf"
                },
                "font_name": "BarlowSemiCondensed ExtraLight",
                "font_family": "BarlowSemiCondensed",
                "font_postscript": "BarlowSemiCondensed-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowSemiCondensed-BlackItalic.ttf"
                },
                "font_name": "BarlowSemiCondensed BlackItalic",
                "font_family": "BarlowSemiCondensed",
                "font_postscript": "BarlowSemiCondensed-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BarlowSemiCondensed-BoldItalic.ttf"
                },
                "font_name": "BarlowSemiCondensed BoldItalic",
                "font_family": "BarlowSemiCondensed",
                "font_postscript": "BarlowSemiCondensed-BoldItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Barriecito",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Barriecito-Regular.ttf"
                },
                "font_name": "Barriecito Regular",
                "font_family": "Barriecito",
                "font_postscript": "Barriecito-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Barrio",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Barrio-Regular.ttf"
                },
                "font_name": "Barrio Regular",
                "font_family": "Barrio",
                "font_postscript": "Barrio-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Basic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Basic-Regular.ttf"
                },
                "font_name": "Basic Regular",
                "font_family": "Basic",
                "font_postscript": "Basic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Baskervville",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Baskervville-Regular.ttf"
                },
                "font_name": "Baskervville Regular",
                "font_family": "Baskervville",
                "font_postscript": "Baskervville-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Baskervville-Italic.ttf"
                },
                "font_name": "Baskervville Italic",
                "font_family": "Baskervville",
                "font_postscript": "Baskervville-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Battambang",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Battambang-Black.ttf"
                },
                "font_name": "Battambang Black",
                "font_family": "Battambang",
                "font_postscript": "Battambang-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Battambang-Thin.ttf"
                },
                "font_name": "Battambang Thin",
                "font_family": "Battambang",
                "font_postscript": "Battambang-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Battambang-Bold.ttf"
                },
                "font_name": "Battambang Bold",
                "font_family": "Battambang",
                "font_postscript": "Battambang-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Battambang-Regular.ttf"
                },
                "font_name": "Battambang Regular",
                "font_family": "Battambang",
                "font_postscript": "Battambang-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Battambang-Light.ttf"
                },
                "font_name": "Battambang Light",
                "font_family": "Battambang",
                "font_postscript": "Battambang-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Baumans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Baumans-Regular.ttf"
                },
                "font_name": "Baumans Regular",
                "font_family": "Baumans",
                "font_postscript": "Baumans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Bayon",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Bayon-Regular.ttf"
                },
                "font_name": "Bayon Regular",
                "font_family": "Bayon",
                "font_postscript": "Bayon-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BeVietnamPro",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BeVietnamPro-SemiBold.ttf"
                },
                "font_name": "BeVietnamPro SemiBold",
                "font_family": "BeVietnamPro",
                "font_postscript": "BeVietnamPro-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BeVietnamPro-Light.ttf"
                },
                "font_name": "BeVietnamPro Light",
                "font_family": "BeVietnamPro",
                "font_postscript": "BeVietnamPro-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BeVietnamPro-Regular.ttf"
                },
                "font_name": "BeVietnamPro Regular",
                "font_family": "BeVietnamPro",
                "font_postscript": "BeVietnamPro-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BeVietnamPro-Medium.ttf"
                },
                "font_name": "BeVietnamPro Medium",
                "font_family": "BeVietnamPro",
                "font_postscript": "BeVietnamPro-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BeVietnamPro-Bold.ttf"
                },
                "font_name": "BeVietnamPro Bold",
                "font_family": "BeVietnamPro",
                "font_postscript": "BeVietnamPro-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BeVietnamPro-BlackItalic.ttf"
                },
                "font_name": "BeVietnamPro BlackItalic",
                "font_family": "BeVietnamPro",
                "font_postscript": "BeVietnamPro-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BeVietnamPro-Thin.ttf"
                },
                "font_name": "BeVietnamPro Thin",
                "font_family": "BeVietnamPro",
                "font_postscript": "BeVietnamPro-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BeVietnamPro-Italic.ttf"
                },
                "font_name": "BeVietnamPro Italic",
                "font_family": "BeVietnamPro",
                "font_postscript": "BeVietnamPro-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BeVietnamPro-ExtraLightItalic.ttf"
                },
                "font_name": "BeVietnamPro ExtraLightItalic",
                "font_family": "BeVietnamPro",
                "font_postscript": "BeVietnamPro-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BeVietnamPro-MediumItalic.ttf"
                },
                "font_name": "BeVietnamPro MediumItalic",
                "font_family": "BeVietnamPro",
                "font_postscript": "BeVietnamPro-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BeVietnamPro-BoldItalic.ttf"
                },
                "font_name": "BeVietnamPro BoldItalic",
                "font_family": "BeVietnamPro",
                "font_postscript": "BeVietnamPro-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BeVietnamPro-SemiBoldItalic.ttf"
                },
                "font_name": "BeVietnamPro SemiBoldItalic",
                "font_family": "BeVietnamPro",
                "font_postscript": "BeVietnamPro-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BeVietnamPro-ExtraLight.ttf"
                },
                "font_name": "BeVietnamPro ExtraLight",
                "font_family": "BeVietnamPro",
                "font_postscript": "BeVietnamPro-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BeVietnamPro-ThinItalic.ttf"
                },
                "font_name": "BeVietnamPro ThinItalic",
                "font_family": "BeVietnamPro",
                "font_postscript": "BeVietnamPro-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BeVietnamPro-ExtraBold.ttf"
                },
                "font_name": "BeVietnamPro ExtraBold",
                "font_family": "BeVietnamPro",
                "font_postscript": "BeVietnamPro-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BeVietnamPro-ExtraBoldItalic.ttf"
                },
                "font_name": "BeVietnamPro ExtraBoldItalic",
                "font_family": "BeVietnamPro",
                "font_postscript": "BeVietnamPro-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BeVietnamPro-Black.ttf"
                },
                "font_name": "BeVietnamPro Black",
                "font_family": "BeVietnamPro",
                "font_postscript": "BeVietnamPro-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BeVietnamPro-LightItalic.ttf"
                },
                "font_name": "BeVietnamPro LightItalic",
                "font_family": "BeVietnamPro",
                "font_postscript": "BeVietnamPro-LightItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BeauRivage",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BeauRivage-Regular.ttf"
                },
                "font_name": "BeauRivage Regular",
                "font_family": "BeauRivage",
                "font_postscript": "BeauRivage-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BebasNeue",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BebasNeue-Regular.ttf"
                },
                "font_name": "BebasNeue Regular",
                "font_family": "BebasNeue",
                "font_postscript": "BebasNeue-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Belanosima",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Belanosima-Bold.ttf"
                },
                "font_name": "Belanosima Bold",
                "font_family": "Belanosima",
                "font_postscript": "Belanosima-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Belanosima-SemiBold.ttf"
                },
                "font_name": "Belanosima SemiBold",
                "font_family": "Belanosima",
                "font_postscript": "Belanosima-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Belanosima-Regular.ttf"
                },
                "font_name": "Belanosima Regular",
                "font_family": "Belanosima",
                "font_postscript": "Belanosima-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Belgrano",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Belgrano-Regular.ttf"
                },
                "font_name": "Belgrano Regular",
                "font_family": "Belgrano",
                "font_postscript": "Belgrano-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Bellefair",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Bellefair-Regular.ttf"
                },
                "font_name": "Bellefair Regular",
                "font_family": "Bellefair",
                "font_postscript": "Bellefair-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Belleza",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Belleza-Regular.ttf"
                },
                "font_name": "Belleza Regular",
                "font_family": "Belleza",
                "font_postscript": "Belleza-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Bellota",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Bellota-Regular.ttf"
                },
                "font_name": "Bellota Regular",
                "font_family": "Bellota",
                "font_postscript": "Bellota-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Bellota-LightItalic.ttf"
                },
                "font_name": "Bellota LightItalic",
                "font_family": "Bellota",
                "font_postscript": "Bellota-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Bellota-Light.ttf"
                },
                "font_name": "Bellota Light",
                "font_family": "Bellota",
                "font_postscript": "Bellota-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Bellota-Italic.ttf"
                },
                "font_name": "Bellota Italic",
                "font_family": "Bellota",
                "font_postscript": "Bellota-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Bellota-Bold.ttf"
                },
                "font_name": "Bellota Bold",
                "font_family": "Bellota",
                "font_postscript": "Bellota-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Bellota-BoldItalic.ttf"
                },
                "font_name": "Bellota BoldItalic",
                "font_family": "Bellota",
                "font_postscript": "Bellota-BoldItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BellotaText",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BellotaText-LightItalic.ttf"
                },
                "font_name": "BellotaText LightItalic",
                "font_family": "BellotaText",
                "font_postscript": "BellotaText-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BellotaText-Italic.ttf"
                },
                "font_name": "BellotaText Italic",
                "font_family": "BellotaText",
                "font_postscript": "BellotaText-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BellotaText-Regular.ttf"
                },
                "font_name": "BellotaText Regular",
                "font_family": "BellotaText",
                "font_postscript": "BellotaText-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BellotaText-Bold.ttf"
                },
                "font_name": "BellotaText Bold",
                "font_family": "BellotaText",
                "font_postscript": "BellotaText-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BellotaText-BoldItalic.ttf"
                },
                "font_name": "BellotaText BoldItalic",
                "font_family": "BellotaText",
                "font_postscript": "BellotaText-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BellotaText-Light.ttf"
                },
                "font_name": "BellotaText Light",
                "font_family": "BellotaText",
                "font_postscript": "BellotaText-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BenchNine",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BenchNine-Regular.ttf"
                },
                "font_name": "BenchNine Regular",
                "font_family": "BenchNine",
                "font_postscript": "BenchNine-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BenchNine-Bold.ttf"
                },
                "font_name": "BenchNine Bold",
                "font_family": "BenchNine",
                "font_postscript": "BenchNine-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BenchNine-Light.ttf"
                },
                "font_name": "BenchNine Light",
                "font_family": "BenchNine",
                "font_postscript": "BenchNine-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Benne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Benne-Regular.ttf"
                },
                "font_name": "Benne Regular",
                "font_family": "Benne",
                "font_postscript": "Benne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Bentham",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Bentham-Regular.ttf"
                },
                "font_name": "Bentham Regular",
                "font_family": "Bentham",
                "font_postscript": "Bentham-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BerkshireSwash",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BerkshireSwash-Regular.ttf"
                },
                "font_name": "BerkshireSwash Regular",
                "font_family": "BerkshireSwash",
                "font_postscript": "BerkshireSwash-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Besley",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Besley-Regular.ttf"
                },
                "font_name": "Besley Regular",
                "font_family": "Besley",
                "font_postscript": "Besley-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Besley-Italic.ttf"
                },
                "font_name": "Besley Italic",
                "font_family": "Besley",
                "font_postscript": "Besley-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BethEllen",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BethEllen-Regular.ttf"
                },
                "font_name": "BethEllen Regular",
                "font_family": "BethEllen",
                "font_postscript": "BethEllen-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Bevan",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Bevan-Italic.ttf"
                },
                "font_name": "Bevan Italic",
                "font_family": "Bevan",
                "font_postscript": "Bevan-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Bevan-Regular.ttf"
                },
                "font_name": "Bevan Regular",
                "font_family": "Bevan",
                "font_postscript": "Bevan-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Bhavuka",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Bhavuka-Regular.ttf"
                },
                "font_name": "Bhavuka Regular",
                "font_family": "Bhavuka",
                "font_postscript": "Bhavuka-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BhuTukaExpandedOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BhuTukaExpandedOne-Regular.ttf"
                },
                "font_name": "BhuTukaExpandedOne Regular",
                "font_family": "BhuTukaExpandedOne",
                "font_postscript": "BhuTukaExpandedOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BigShouldersDisplay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BigShouldersDisplay-Regular.ttf"
                },
                "font_name": "BigShouldersDisplay Regular",
                "font_family": "BigShouldersDisplay",
                "font_postscript": "BigShouldersDisplay-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BigShouldersInlineDisplay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BigShouldersInlineDisplay-Regular.ttf"
                },
                "font_name": "BigShouldersInlineDisplay Regular",
                "font_family": "BigShouldersInlineDisplay",
                "font_postscript": "BigShouldersInlineDisplay-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BigShouldersInlineText",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BigShouldersInlineText-Regular.ttf"
                },
                "font_name": "BigShouldersInlineText Regular",
                "font_family": "BigShouldersInlineText",
                "font_postscript": "BigShouldersInlineText-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BigShouldersStencilDisplay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BigShouldersStencilDisplay-Regular.ttf"
                },
                "font_name": "BigShouldersStencilDisplay Regular",
                "font_family": "BigShouldersStencilDisplay",
                "font_postscript": "BigShouldersStencilDisplay-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BigShouldersStencilText",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BigShouldersStencilText-Regular.ttf"
                },
                "font_name": "BigShouldersStencilText Regular",
                "font_family": "BigShouldersStencilText",
                "font_postscript": "BigShouldersStencilText-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BigShouldersText",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BigShouldersText-Regular.ttf"
                },
                "font_name": "BigShouldersText Regular",
                "font_family": "BigShouldersText",
                "font_postscript": "BigShouldersText-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BigelowRules",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BigelowRules-Regular.ttf"
                },
                "font_name": "BigelowRules Regular",
                "font_family": "BigelowRules",
                "font_postscript": "BigelowRules-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BigshotOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BigshotOne-Regular.ttf"
                },
                "font_name": "BigshotOne Regular",
                "font_family": "BigshotOne",
                "font_postscript": "BigshotOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Bilbo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Bilbo-Regular.ttf"
                },
                "font_name": "Bilbo Regular",
                "font_family": "Bilbo",
                "font_postscript": "Bilbo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BilboSwashCaps",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BilboSwashCaps-Regular.ttf"
                },
                "font_name": "BilboSwashCaps Regular",
                "font_family": "BilboSwashCaps",
                "font_postscript": "BilboSwashCaps-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BioRhyme",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BioRhyme-Regular.ttf"
                },
                "font_name": "BioRhyme Regular",
                "font_family": "BioRhyme",
                "font_postscript": "BioRhyme-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BioRhymeExpanded",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BioRhymeExpanded-ExtraBold.ttf"
                },
                "font_name": "BioRhymeExpanded ExtraBold",
                "font_family": "BioRhymeExpanded",
                "font_postscript": "BioRhymeExpanded-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BioRhymeExpanded-ExtraLight.ttf"
                },
                "font_name": "BioRhymeExpanded ExtraLight",
                "font_family": "BioRhymeExpanded",
                "font_postscript": "BioRhymeExpanded-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BioRhymeExpanded-Regular.ttf"
                },
                "font_name": "BioRhymeExpanded Regular",
                "font_family": "BioRhymeExpanded",
                "font_postscript": "BioRhymeExpanded-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BioRhymeExpanded-Bold.ttf"
                },
                "font_name": "BioRhymeExpanded Bold",
                "font_family": "BioRhymeExpanded",
                "font_postscript": "BioRhymeExpanded-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BioRhymeExpanded-Light.ttf"
                },
                "font_name": "BioRhymeExpanded Light",
                "font_family": "BioRhymeExpanded",
                "font_postscript": "BioRhymeExpanded-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Birthstone",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Birthstone-Regular.ttf"
                },
                "font_name": "Birthstone Regular",
                "font_family": "Birthstone",
                "font_postscript": "Birthstone-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BirthstoneBounce",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BirthstoneBounce-Medium.ttf"
                },
                "font_name": "BirthstoneBounce Medium",
                "font_family": "BirthstoneBounce",
                "font_postscript": "BirthstoneBounce-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BirthstoneBounce-Regular.ttf"
                },
                "font_name": "BirthstoneBounce Regular",
                "font_family": "BirthstoneBounce",
                "font_postscript": "BirthstoneBounce-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Biryani",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Biryani-Light.ttf"
                },
                "font_name": "Biryani Light",
                "font_family": "Biryani",
                "font_postscript": "Biryani-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Biryani-ExtraBold.ttf"
                },
                "font_name": "Biryani ExtraBold",
                "font_family": "Biryani",
                "font_postscript": "Biryani-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Biryani-Regular.ttf"
                },
                "font_name": "Biryani Regular",
                "font_family": "Biryani",
                "font_postscript": "Biryani-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Biryani-Black.ttf"
                },
                "font_name": "Biryani Black",
                "font_family": "Biryani",
                "font_postscript": "Biryani-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Biryani-Bold.ttf"
                },
                "font_name": "Biryani Bold",
                "font_family": "Biryani",
                "font_postscript": "Biryani-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Biryani-ExtraLight.ttf"
                },
                "font_name": "Biryani ExtraLight",
                "font_family": "Biryani",
                "font_postscript": "Biryani-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Biryani-SemiBold.ttf"
                },
                "font_name": "Biryani SemiBold",
                "font_family": "Biryani",
                "font_postscript": "Biryani-SemiBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Bitter",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Bitter-Regular.ttf"
                },
                "font_name": "Bitter Regular",
                "font_family": "Bitter",
                "font_postscript": "Bitter-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Bitter-Italic.ttf"
                },
                "font_name": "Bitter Italic",
                "font_family": "Bitter",
                "font_postscript": "Bitter-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BlackAndWhitePicture",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BlackAndWhitePicture-Regular.ttf"
                },
                "font_name": "BlackAndWhitePicture Regular",
                "font_family": "BlackAndWhitePicture",
                "font_postscript": "BlackAndWhitePicture-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BlackHanSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BlackHanSans-Regular.ttf"
                },
                "font_name": "BlackHanSans Regular",
                "font_family": "BlackHanSans",
                "font_postscript": "BlackHanSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BlackOpsOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BlackOpsOne-Regular.ttf"
                },
                "font_name": "BlackOpsOne Regular",
                "font_family": "BlackOpsOne",
                "font_postscript": "BlackOpsOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Blaka",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Blaka-Regular.ttf"
                },
                "font_name": "Blaka Regular",
                "font_family": "Blaka",
                "font_postscript": "Blaka-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BlakaHollow",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BlakaHollow-Regular.ttf"
                },
                "font_name": "BlakaHollow Regular",
                "font_family": "BlakaHollow",
                "font_postscript": "BlakaHollow-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BlakaInk",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BlakaInk-Regular.ttf"
                },
                "font_name": "BlakaInk Regular",
                "font_family": "BlakaInk",
                "font_postscript": "BlakaInk-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Blinker",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Blinker-SemiBold.ttf"
                },
                "font_name": "Blinker SemiBold",
                "font_family": "Blinker",
                "font_postscript": "Blinker-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Blinker-Black.ttf"
                },
                "font_name": "Blinker Black",
                "font_family": "Blinker",
                "font_postscript": "Blinker-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Blinker-Regular.ttf"
                },
                "font_name": "Blinker Regular",
                "font_family": "Blinker",
                "font_postscript": "Blinker-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Blinker-Light.ttf"
                },
                "font_name": "Blinker Light",
                "font_family": "Blinker",
                "font_postscript": "Blinker-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Blinker-Bold.ttf"
                },
                "font_name": "Blinker Bold",
                "font_family": "Blinker",
                "font_postscript": "Blinker-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Blinker-ExtraBold.ttf"
                },
                "font_name": "Blinker ExtraBold",
                "font_family": "Blinker",
                "font_postscript": "Blinker-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Blinker-Thin.ttf"
                },
                "font_name": "Blinker Thin",
                "font_family": "Blinker",
                "font_postscript": "Blinker-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Blinker-ExtraLight.ttf"
                },
                "font_name": "Blinker ExtraLight",
                "font_family": "Blinker",
                "font_postscript": "Blinker-ExtraLight",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BodoniModa",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BodoniModa-Italic.ttf"
                },
                "font_name": "BodoniModa Italic",
                "font_family": "BodoniModa",
                "font_postscript": "BodoniModa-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BodoniModa-Regular.ttf"
                },
                "font_name": "BodoniModa Regular",
                "font_family": "BodoniModa",
                "font_postscript": "BodoniModa-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Bokor",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Bokor-Regular.ttf"
                },
                "font_name": "Bokor Regular",
                "font_family": "Bokor",
                "font_postscript": "Bokor-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BonaNova",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BonaNova-Italic.ttf"
                },
                "font_name": "BonaNova Italic",
                "font_family": "BonaNova",
                "font_postscript": "BonaNova-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BonaNova-Bold.ttf"
                },
                "font_name": "BonaNova Bold",
                "font_family": "BonaNova",
                "font_postscript": "BonaNova-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "BonaNova-Regular.ttf"
                },
                "font_name": "BonaNova Regular",
                "font_family": "BonaNova",
                "font_postscript": "BonaNova-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Bonbon",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Bonbon-Regular.ttf"
                },
                "font_name": "Bonbon Regular",
                "font_family": "Bonbon",
                "font_postscript": "Bonbon-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BonheurRoyale",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BonheurRoyale-Regular.ttf"
                },
                "font_name": "BonheurRoyale Regular",
                "font_family": "BonheurRoyale",
                "font_postscript": "BonheurRoyale-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Boogaloo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Boogaloo-Regular.ttf"
                },
                "font_name": "Boogaloo Regular",
                "font_family": "Boogaloo",
                "font_postscript": "Boogaloo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Borel",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Borel-Regular.ttf"
                },
                "font_name": "Borel Regular",
                "font_family": "Borel",
                "font_postscript": "Borel-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BowlbyOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BowlbyOne-Regular.ttf"
                },
                "font_name": "BowlbyOne Regular",
                "font_family": "BowlbyOne",
                "font_postscript": "BowlbyOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BowlbyOneSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BowlbyOneSC-Regular.ttf"
                },
                "font_name": "BowlbyOneSC Regular",
                "font_family": "BowlbyOneSC",
                "font_postscript": "BowlbyOneSC-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BraahOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BraahOne-Regular.ttf"
                },
                "font_name": "BraahOne Regular",
                "font_family": "BraahOne",
                "font_postscript": "BraahOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Brawler",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Brawler-Regular.ttf"
                },
                "font_name": "Brawler Regular",
                "font_family": "Brawler",
                "font_postscript": "Brawler-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Brawler-Bold.ttf"
                },
                "font_name": "Brawler Bold",
                "font_family": "Brawler",
                "font_postscript": "Brawler-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BreeSerif",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BreeSerif-Regular.ttf"
                },
                "font_name": "BreeSerif Regular",
                "font_family": "BreeSerif",
                "font_postscript": "BreeSerif-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BricolageGrotesque",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BricolageGrotesque-Regular.ttf"
                },
                "font_name": "BricolageGrotesque Regular",
                "font_family": "BricolageGrotesque",
                "font_postscript": "BricolageGrotesque-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BriemHand",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BriemHand-Regular.ttf"
                },
                "font_name": "BriemHand Regular",
                "font_family": "BriemHand",
                "font_postscript": "BriemHand-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BrunoAce",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BrunoAce-Regular.ttf"
                },
                "font_name": "BrunoAce Regular",
                "font_family": "BrunoAce",
                "font_postscript": "BrunoAce-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BrunoAceSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BrunoAceSC-Regular.ttf"
                },
                "font_name": "BrunoAceSC Regular",
                "font_family": "BrunoAceSC",
                "font_postscript": "BrunoAceSC-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Brygada1918",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Brygada1918-Italic.ttf"
                },
                "font_name": "Brygada1918 Italic",
                "font_family": "Brygada1918",
                "font_postscript": "Brygada1918-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Brygada1918-Regular.ttf"
                },
                "font_name": "Brygada1918 Regular",
                "font_family": "Brygada1918",
                "font_postscript": "Brygada1918-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BubblegumSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BubblegumSans-Regular.ttf"
                },
                "font_name": "BubblegumSans Regular",
                "font_family": "BubblegumSans",
                "font_postscript": "BubblegumSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BubblerOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BubblerOne-Regular.ttf"
                },
                "font_name": "BubblerOne Regular",
                "font_family": "BubblerOne",
                "font_postscript": "BubblerOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Buda",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Buda-Light.ttf"
                },
                "font_name": "Buda Light",
                "font_family": "Buda",
                "font_postscript": "Buda-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Buenard",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Buenard-Bold.ttf"
                },
                "font_name": "Buenard Bold",
                "font_family": "Buenard",
                "font_postscript": "Buenard-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Buenard-Regular.ttf"
                },
                "font_name": "Buenard Regular",
                "font_family": "Buenard",
                "font_postscript": "Buenard-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Bungee",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Bungee-Regular.ttf"
                },
                "font_name": "Bungee Regular",
                "font_family": "Bungee",
                "font_postscript": "Bungee-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BungeeColor",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BungeeColor-Regular.ttf"
                },
                "font_name": "BungeeColor Regular",
                "font_family": "BungeeColor",
                "font_postscript": "BungeeColor-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BungeeHairline",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BungeeHairline-Regular.ttf"
                },
                "font_name": "BungeeHairline Regular",
                "font_family": "BungeeHairline",
                "font_postscript": "BungeeHairline-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BungeeInline",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BungeeInline-Regular.ttf"
                },
                "font_name": "BungeeInline Regular",
                "font_family": "BungeeInline",
                "font_postscript": "BungeeInline-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BungeeOutline",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BungeeOutline-Regular.ttf"
                },
                "font_name": "BungeeOutline Regular",
                "font_family": "BungeeOutline",
                "font_postscript": "BungeeOutline-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BungeeShade",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BungeeShade-Regular.ttf"
                },
                "font_name": "BungeeShade Regular",
                "font_family": "BungeeShade",
                "font_postscript": "BungeeShade-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "BungeeSpice",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "BungeeSpice-Regular.ttf"
                },
                "font_name": "BungeeSpice Regular",
                "font_family": "BungeeSpice",
                "font_postscript": "BungeeSpice-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Butcherman",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Butcherman-Regular.ttf"
                },
                "font_name": "Butcherman Regular",
                "font_family": "Butcherman",
                "font_postscript": "Butcherman-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ButterflyKids",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ButterflyKids-Regular.ttf"
                },
                "font_name": "ButterflyKids Regular",
                "font_family": "ButterflyKids",
                "font_postscript": "ButterflyKids-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Cabin",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cabin-Regular.ttf"
                },
                "font_name": "Cabin Regular",
                "font_family": "Cabin",
                "font_postscript": "Cabin-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cabin-Italic.ttf"
                },
                "font_name": "Cabin Italic",
                "font_family": "Cabin",
                "font_postscript": "Cabin-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CabinCondensed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CabinCondensed-Bold.ttf"
                },
                "font_name": "CabinCondensed Bold",
                "font_family": "CabinCondensed",
                "font_postscript": "CabinCondensed-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CabinCondensed-SemiBold.ttf"
                },
                "font_name": "CabinCondensed SemiBold",
                "font_family": "CabinCondensed",
                "font_postscript": "CabinCondensed-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CabinCondensed-Medium.ttf"
                },
                "font_name": "CabinCondensed Medium",
                "font_family": "CabinCondensed",
                "font_postscript": "CabinCondensed-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CabinCondensed-Regular.ttf"
                },
                "font_name": "CabinCondensed Regular",
                "font_family": "CabinCondensed",
                "font_postscript": "CabinCondensed-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CabinSketch",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CabinSketch-Regular.ttf"
                },
                "font_name": "CabinSketch Regular",
                "font_family": "CabinSketch",
                "font_postscript": "CabinSketch-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CabinSketch-Bold.ttf"
                },
                "font_name": "CabinSketch Bold",
                "font_family": "CabinSketch",
                "font_postscript": "CabinSketch-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CactusClassicalSerif",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CactusClassicalSerif-Regular.ttf"
                },
                "font_name": "CactusClassicalSerif Regular",
                "font_family": "CactusClassicalSerif",
                "font_postscript": "CactusClassicalSerif-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CaesarDressing",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CaesarDressing-Regular.ttf"
                },
                "font_name": "CaesarDressing Regular",
                "font_family": "CaesarDressing",
                "font_postscript": "CaesarDressing-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Cagliostro",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cagliostro-Regular.ttf"
                },
                "font_name": "Cagliostro Regular",
                "font_family": "Cagliostro",
                "font_postscript": "Cagliostro-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Cairo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cairo-Regular.ttf"
                },
                "font_name": "Cairo Regular",
                "font_family": "Cairo",
                "font_postscript": "Cairo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CairoPlay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CairoPlay-Regular.ttf"
                },
                "font_name": "CairoPlay Regular",
                "font_family": "CairoPlay",
                "font_postscript": "CairoPlay-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Caladea",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Caladea-Bold.ttf"
                },
                "font_name": "Caladea Bold",
                "font_family": "Caladea",
                "font_postscript": "Caladea-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Caladea-Regular.ttf"
                },
                "font_name": "Caladea Regular",
                "font_family": "Caladea",
                "font_postscript": "Caladea-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Caladea-Italic.ttf"
                },
                "font_name": "Caladea Italic",
                "font_family": "Caladea",
                "font_postscript": "Caladea-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Caladea-BoldItalic.ttf"
                },
                "font_name": "Caladea BoldItalic",
                "font_family": "Caladea",
                "font_postscript": "Caladea-BoldItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Calistoga",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Calistoga-Regular.ttf"
                },
                "font_name": "Calistoga Regular",
                "font_family": "Calistoga",
                "font_postscript": "Calistoga-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Calligraffitti",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Calligraffitti-Regular.ttf"
                },
                "font_name": "Calligraffitti Regular",
                "font_family": "Calligraffitti",
                "font_postscript": "Calligraffitti-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Cambay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cambay-Regular.ttf"
                },
                "font_name": "Cambay Regular",
                "font_family": "Cambay",
                "font_postscript": "Cambay-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cambay-BoldItalic.ttf"
                },
                "font_name": "Cambay BoldItalic",
                "font_family": "Cambay",
                "font_postscript": "Cambay-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cambay-Bold.ttf"
                },
                "font_name": "Cambay Bold",
                "font_family": "Cambay",
                "font_postscript": "Cambay-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cambay-Italic.ttf"
                },
                "font_name": "Cambay Italic",
                "font_family": "Cambay",
                "font_postscript": "Cambay-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Cambo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cambo-Regular.ttf"
                },
                "font_name": "Cambo Regular",
                "font_family": "Cambo",
                "font_postscript": "Cambo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Candal",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Candal-Regular.ttf"
                },
                "font_name": "Candal Regular",
                "font_family": "Candal",
                "font_postscript": "Candal-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Cantarell",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cantarell-Bold.ttf"
                },
                "font_name": "Cantarell Bold",
                "font_family": "Cantarell",
                "font_postscript": "Cantarell-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cantarell-BoldItalic.ttf"
                },
                "font_name": "Cantarell BoldItalic",
                "font_family": "Cantarell",
                "font_postscript": "Cantarell-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cantarell-Regular.ttf"
                },
                "font_name": "Cantarell Regular",
                "font_family": "Cantarell",
                "font_postscript": "Cantarell-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cantarell-Italic.ttf"
                },
                "font_name": "Cantarell Italic",
                "font_family": "Cantarell",
                "font_postscript": "Cantarell-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CantataOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CantataOne-Regular.ttf"
                },
                "font_name": "CantataOne Regular",
                "font_family": "CantataOne",
                "font_postscript": "CantataOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CantoraOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CantoraOne-Regular.ttf"
                },
                "font_name": "CantoraOne Regular",
                "font_family": "CantoraOne",
                "font_postscript": "CantoraOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Caprasimo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Caprasimo-Regular.ttf"
                },
                "font_name": "Caprasimo Regular",
                "font_family": "Caprasimo",
                "font_postscript": "Caprasimo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Capriola",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Capriola-Regular.ttf"
                },
                "font_name": "Capriola Regular",
                "font_family": "Capriola",
                "font_postscript": "Capriola-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Caramel",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Caramel-Regular.ttf"
                },
                "font_name": "Caramel Regular",
                "font_family": "Caramel",
                "font_postscript": "Caramel-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Carattere",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Carattere-Regular.ttf"
                },
                "font_name": "Carattere Regular",
                "font_family": "Carattere",
                "font_postscript": "Carattere-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Cardo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cardo-Italic.ttf"
                },
                "font_name": "Cardo Italic",
                "font_family": "Cardo",
                "font_postscript": "Cardo-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cardo-Bold.ttf"
                },
                "font_name": "Cardo Bold",
                "font_family": "Cardo",
                "font_postscript": "Cardo-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cardo-Regular.ttf"
                },
                "font_name": "Cardo Regular",
                "font_family": "Cardo",
                "font_postscript": "Cardo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Carlito",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Carlito-Italic.ttf"
                },
                "font_name": "Carlito Italic",
                "font_family": "Carlito",
                "font_postscript": "Carlito-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Carlito-Regular.ttf"
                },
                "font_name": "Carlito Regular",
                "font_family": "Carlito",
                "font_postscript": "Carlito-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Carlito-BoldItalic.ttf"
                },
                "font_name": "Carlito BoldItalic",
                "font_family": "Carlito",
                "font_postscript": "Carlito-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Carlito-Bold.ttf"
                },
                "font_name": "Carlito Bold",
                "font_family": "Carlito",
                "font_postscript": "Carlito-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Carme",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Carme-Regular.ttf"
                },
                "font_name": "Carme Regular",
                "font_family": "Carme",
                "font_postscript": "Carme-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CarroisGothic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CarroisGothic-Regular.ttf"
                },
                "font_name": "CarroisGothic Regular",
                "font_family": "CarroisGothic",
                "font_postscript": "CarroisGothic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CarroisGothicSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CarroisGothicSC-Regular.ttf"
                },
                "font_name": "CarroisGothicSC Regular",
                "font_family": "CarroisGothicSC",
                "font_postscript": "CarroisGothicSC-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CarterOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CarterOne-Regular.ttf"
                },
                "font_name": "CarterOne Regular",
                "font_family": "CarterOne",
                "font_postscript": "CarterOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Castoro",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Castoro-Italic.ttf"
                },
                "font_name": "Castoro Italic",
                "font_family": "Castoro",
                "font_postscript": "Castoro-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Castoro-Regular.ttf"
                },
                "font_name": "Castoro Regular",
                "font_family": "Castoro",
                "font_postscript": "Castoro-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CastoroTitling",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CastoroTitling-Regular.ttf"
                },
                "font_name": "CastoroTitling Regular",
                "font_family": "CastoroTitling",
                "font_postscript": "CastoroTitling-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Catamaran",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Catamaran-Regular.ttf"
                },
                "font_name": "Catamaran Regular",
                "font_family": "Catamaran",
                "font_postscript": "Catamaran-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Caudex",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Caudex-Regular.ttf"
                },
                "font_name": "Caudex Regular",
                "font_family": "Caudex",
                "font_postscript": "Caudex-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Caudex-Bold.ttf"
                },
                "font_name": "Caudex Bold",
                "font_family": "Caudex",
                "font_postscript": "Caudex-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Caudex-BoldItalic.ttf"
                },
                "font_name": "Caudex BoldItalic",
                "font_family": "Caudex",
                "font_postscript": "Caudex-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Caudex-Italic.ttf"
                },
                "font_name": "Caudex Italic",
                "font_family": "Caudex",
                "font_postscript": "Caudex-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Caveat",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Caveat-Regular.ttf"
                },
                "font_name": "Caveat Regular",
                "font_family": "Caveat",
                "font_postscript": "Caveat-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CaveatBrush",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CaveatBrush-Regular.ttf"
                },
                "font_name": "CaveatBrush Regular",
                "font_family": "CaveatBrush",
                "font_postscript": "CaveatBrush-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Cedarville",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cedarville-Cursive.ttf"
                },
                "font_name": "Cedarville Cursive",
                "font_family": "Cedarville",
                "font_postscript": "Cedarville-Cursive",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CevicheOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CevicheOne-Regular.ttf"
                },
                "font_name": "CevicheOne Regular",
                "font_family": "CevicheOne",
                "font_postscript": "CevicheOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ChakraPetch",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ChakraPetch-BoldItalic.ttf"
                },
                "font_name": "ChakraPetch BoldItalic",
                "font_family": "ChakraPetch",
                "font_postscript": "ChakraPetch-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ChakraPetch-Regular.ttf"
                },
                "font_name": "ChakraPetch Regular",
                "font_family": "ChakraPetch",
                "font_postscript": "ChakraPetch-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ChakraPetch-SemiBoldItalic.ttf"
                },
                "font_name": "ChakraPetch SemiBoldItalic",
                "font_family": "ChakraPetch",
                "font_postscript": "ChakraPetch-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ChakraPetch-ExtraLight.ttf"
                },
                "font_name": "ChakraPetch ExtraLight",
                "font_family": "ChakraPetch",
                "font_postscript": "ChakraPetch-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ChakraPetch-Bold.ttf"
                },
                "font_name": "ChakraPetch Bold",
                "font_family": "ChakraPetch",
                "font_postscript": "ChakraPetch-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ChakraPetch-Italic.ttf"
                },
                "font_name": "ChakraPetch Italic",
                "font_family": "ChakraPetch",
                "font_postscript": "ChakraPetch-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ChakraPetch-MediumItalic.ttf"
                },
                "font_name": "ChakraPetch MediumItalic",
                "font_family": "ChakraPetch",
                "font_postscript": "ChakraPetch-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ChakraPetch-ExtraLightItalic.ttf"
                },
                "font_name": "ChakraPetch ExtraLightItalic",
                "font_family": "ChakraPetch",
                "font_postscript": "ChakraPetch-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ChakraPetch-Light.ttf"
                },
                "font_name": "ChakraPetch Light",
                "font_family": "ChakraPetch",
                "font_postscript": "ChakraPetch-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ChakraPetch-SemiBold.ttf"
                },
                "font_name": "ChakraPetch SemiBold",
                "font_family": "ChakraPetch",
                "font_postscript": "ChakraPetch-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ChakraPetch-Medium.ttf"
                },
                "font_name": "ChakraPetch Medium",
                "font_family": "ChakraPetch",
                "font_postscript": "ChakraPetch-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ChakraPetch-LightItalic.ttf"
                },
                "font_name": "ChakraPetch LightItalic",
                "font_family": "ChakraPetch",
                "font_postscript": "ChakraPetch-LightItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Changa",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Changa-Regular.ttf"
                },
                "font_name": "Changa Regular",
                "font_family": "Changa",
                "font_postscript": "Changa-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ChangaOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ChangaOne-Regular.ttf"
                },
                "font_name": "ChangaOne Regular",
                "font_family": "ChangaOne",
                "font_postscript": "ChangaOne-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ChangaOne-Italic.ttf"
                },
                "font_name": "ChangaOne Italic",
                "font_family": "ChangaOne",
                "font_postscript": "ChangaOne-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Chango",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Chango-Regular.ttf"
                },
                "font_name": "Chango Regular",
                "font_family": "Chango",
                "font_postscript": "Chango-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CharisSIL",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CharisSIL-Bold.ttf"
                },
                "font_name": "CharisSIL Bold",
                "font_family": "CharisSIL",
                "font_postscript": "CharisSIL-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CharisSIL-Italic.ttf"
                },
                "font_name": "CharisSIL Italic",
                "font_family": "CharisSIL",
                "font_postscript": "CharisSIL-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CharisSIL-BoldItalic.ttf"
                },
                "font_name": "CharisSIL BoldItalic",
                "font_family": "CharisSIL",
                "font_postscript": "CharisSIL-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CharisSIL-Regular.ttf"
                },
                "font_name": "CharisSIL Regular",
                "font_family": "CharisSIL",
                "font_postscript": "CharisSIL-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Charm",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Charm-Regular.ttf"
                },
                "font_name": "Charm Regular",
                "font_family": "Charm",
                "font_postscript": "Charm-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Charm-Bold.ttf"
                },
                "font_name": "Charm Bold",
                "font_family": "Charm",
                "font_postscript": "Charm-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Charmonman",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Charmonman-Regular.ttf"
                },
                "font_name": "Charmonman Regular",
                "font_family": "Charmonman",
                "font_postscript": "Charmonman-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Charmonman-Bold.ttf"
                },
                "font_name": "Charmonman Bold",
                "font_family": "Charmonman",
                "font_postscript": "Charmonman-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Chathura",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Chathura-ExtraBold.ttf"
                },
                "font_name": "Chathura ExtraBold",
                "font_family": "Chathura",
                "font_postscript": "Chathura-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Chathura-Regular.ttf"
                },
                "font_name": "Chathura Regular",
                "font_family": "Chathura",
                "font_postscript": "Chathura-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Chathura-Bold.ttf"
                },
                "font_name": "Chathura Bold",
                "font_family": "Chathura",
                "font_postscript": "Chathura-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Chathura-Thin.ttf"
                },
                "font_name": "Chathura Thin",
                "font_family": "Chathura",
                "font_postscript": "Chathura-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Chathura-Light.ttf"
                },
                "font_name": "Chathura Light",
                "font_family": "Chathura",
                "font_postscript": "Chathura-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ChauPhilomeneOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ChauPhilomeneOne-Italic.ttf"
                },
                "font_name": "ChauPhilomeneOne Italic",
                "font_family": "ChauPhilomeneOne",
                "font_postscript": "ChauPhilomeneOne-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ChauPhilomeneOne-Regular.ttf"
                },
                "font_name": "ChauPhilomeneOne Regular",
                "font_family": "ChauPhilomeneOne",
                "font_postscript": "ChauPhilomeneOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ChelaOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ChelaOne-Regular.ttf"
                },
                "font_name": "ChelaOne Regular",
                "font_family": "ChelaOne",
                "font_postscript": "ChelaOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ChelseaMarket",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ChelseaMarket-Regular.ttf"
                },
                "font_name": "ChelseaMarket Regular",
                "font_family": "ChelseaMarket",
                "font_postscript": "ChelseaMarket-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Chenla",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Chenla-Regular.ttf"
                },
                "font_name": "Chenla Regular",
                "font_family": "Chenla",
                "font_postscript": "Chenla-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Cherish",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cherish-Regular.ttf"
                },
                "font_name": "Cherish Regular",
                "font_family": "Cherish",
                "font_postscript": "Cherish-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CherryBombOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CherryBombOne-Regular.ttf"
                },
                "font_name": "CherryBombOne Regular",
                "font_family": "CherryBombOne",
                "font_postscript": "CherryBombOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CherryCreamSoda",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CherryCreamSoda-Regular.ttf"
                },
                "font_name": "CherryCreamSoda Regular",
                "font_family": "CherryCreamSoda",
                "font_postscript": "CherryCreamSoda-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CherrySwash",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CherrySwash-Regular.ttf"
                },
                "font_name": "CherrySwash Regular",
                "font_family": "CherrySwash",
                "font_postscript": "CherrySwash-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CherrySwash-Bold.ttf"
                },
                "font_name": "CherrySwash Bold",
                "font_family": "CherrySwash",
                "font_postscript": "CherrySwash-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Chewy",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Chewy-Regular.ttf"
                },
                "font_name": "Chewy Regular",
                "font_family": "Chewy",
                "font_postscript": "Chewy-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Chicle",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Chicle-Regular.ttf"
                },
                "font_name": "Chicle Regular",
                "font_family": "Chicle",
                "font_postscript": "Chicle-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Chilanka",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Chilanka-Regular.ttf"
                },
                "font_name": "Chilanka Regular",
                "font_family": "Chilanka",
                "font_postscript": "Chilanka-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Chivo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Chivo-Italic.ttf"
                },
                "font_name": "Chivo Italic",
                "font_family": "Chivo",
                "font_postscript": "Chivo-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Chivo-Regular.ttf"
                },
                "font_name": "Chivo Regular",
                "font_family": "Chivo",
                "font_postscript": "Chivo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ChivoMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ChivoMono-Italic.ttf"
                },
                "font_name": "ChivoMono Italic",
                "font_family": "ChivoMono",
                "font_postscript": "ChivoMono-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ChivoMono-Regular.ttf"
                },
                "font_name": "ChivoMono Regular",
                "font_family": "ChivoMono",
                "font_postscript": "ChivoMono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ChocolateClassicalSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ChocolateClassicalSans-Regular.ttf"
                },
                "font_name": "ChocolateClassicalSans Regular",
                "font_family": "ChocolateClassicalSans",
                "font_postscript": "ChocolateClassicalSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Chokokutai",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Chokokutai-Regular.ttf"
                },
                "font_name": "Chokokutai Regular",
                "font_family": "Chokokutai",
                "font_postscript": "Chokokutai-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Chonburi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Chonburi-Regular.ttf"
                },
                "font_name": "Chonburi Regular",
                "font_family": "Chonburi",
                "font_postscript": "Chonburi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Cinzel",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cinzel-Regular.ttf"
                },
                "font_name": "Cinzel Regular",
                "font_family": "Cinzel",
                "font_postscript": "Cinzel-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CinzelDecorative",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CinzelDecorative-Black.ttf"
                },
                "font_name": "CinzelDecorative Black",
                "font_family": "CinzelDecorative",
                "font_postscript": "CinzelDecorative-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CinzelDecorative-Bold.ttf"
                },
                "font_name": "CinzelDecorative Bold",
                "font_family": "CinzelDecorative",
                "font_postscript": "CinzelDecorative-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CinzelDecorative-Regular.ttf"
                },
                "font_name": "CinzelDecorative Regular",
                "font_family": "CinzelDecorative",
                "font_postscript": "CinzelDecorative-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ClickerScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ClickerScript-Regular.ttf"
                },
                "font_name": "ClickerScript Regular",
                "font_family": "ClickerScript",
                "font_postscript": "ClickerScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ClimateCrisis",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ClimateCrisis-Regular.ttf"
                },
                "font_name": "ClimateCrisis Regular",
                "font_family": "ClimateCrisis",
                "font_postscript": "ClimateCrisis-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Coda",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Coda-Regular.ttf"
                },
                "font_name": "Coda Regular",
                "font_family": "Coda",
                "font_postscript": "Coda-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Coda-ExtraBold.ttf"
                },
                "font_name": "Coda ExtraBold",
                "font_family": "Coda",
                "font_postscript": "Coda-ExtraBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Codystar",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Codystar-Regular.ttf"
                },
                "font_name": "Codystar Regular",
                "font_family": "Codystar",
                "font_postscript": "Codystar-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Codystar-Light.ttf"
                },
                "font_name": "Codystar Light",
                "font_family": "Codystar",
                "font_postscript": "Codystar-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Coiny",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Coiny-Regular.ttf"
                },
                "font_name": "Coiny Regular",
                "font_family": "Coiny",
                "font_postscript": "Coiny-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Combo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Combo-Regular.ttf"
                },
                "font_name": "Combo Regular",
                "font_family": "Combo",
                "font_postscript": "Combo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Comfortaa",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Comfortaa-Regular.ttf"
                },
                "font_name": "Comfortaa Regular",
                "font_family": "Comfortaa",
                "font_postscript": "Comfortaa-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Comforter",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Comforter-Regular.ttf"
                },
                "font_name": "Comforter Regular",
                "font_family": "Comforter",
                "font_postscript": "Comforter-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ComforterBrush",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ComforterBrush-Regular.ttf"
                },
                "font_name": "ComforterBrush Regular",
                "font_family": "ComforterBrush",
                "font_postscript": "ComforterBrush-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ComicNeue",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ComicNeue-Italic.ttf"
                },
                "font_name": "ComicNeue Italic",
                "font_family": "ComicNeue",
                "font_postscript": "ComicNeue-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ComicNeue-BoldItalic.ttf"
                },
                "font_name": "ComicNeue BoldItalic",
                "font_family": "ComicNeue",
                "font_postscript": "ComicNeue-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ComicNeue-Light.ttf"
                },
                "font_name": "ComicNeue Light",
                "font_family": "ComicNeue",
                "font_postscript": "ComicNeue-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ComicNeue-Regular.ttf"
                },
                "font_name": "ComicNeue Regular",
                "font_family": "ComicNeue",
                "font_postscript": "ComicNeue-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ComicNeue-Bold.ttf"
                },
                "font_name": "ComicNeue Bold",
                "font_family": "ComicNeue",
                "font_postscript": "ComicNeue-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ComicNeue-LightItalic.ttf"
                },
                "font_name": "ComicNeue LightItalic",
                "font_family": "ComicNeue",
                "font_postscript": "ComicNeue-LightItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ComingSoon",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ComingSoon-Regular.ttf"
                },
                "font_name": "ComingSoon Regular",
                "font_family": "ComingSoon",
                "font_postscript": "ComingSoon-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Comme",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Comme-Regular.ttf"
                },
                "font_name": "Comme Regular",
                "font_family": "Comme",
                "font_postscript": "Comme-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Commissioner",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Commissioner-Regular.ttf"
                },
                "font_name": "Commissioner Regular",
                "font_family": "Commissioner",
                "font_postscript": "Commissioner-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ConcertOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ConcertOne-Regular.ttf"
                },
                "font_name": "ConcertOne Regular",
                "font_family": "ConcertOne",
                "font_postscript": "ConcertOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Condiment",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Condiment-Regular.ttf"
                },
                "font_name": "Condiment Regular",
                "font_family": "Condiment",
                "font_postscript": "Condiment-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Content",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Content-Bold.ttf"
                },
                "font_name": "Content Bold",
                "font_family": "Content",
                "font_postscript": "Content-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Content-Regular.ttf"
                },
                "font_name": "Content Regular",
                "font_family": "Content",
                "font_postscript": "Content-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ContrailOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ContrailOne-Regular.ttf"
                },
                "font_name": "ContrailOne Regular",
                "font_family": "ContrailOne",
                "font_postscript": "ContrailOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Convergence",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Convergence-Regular.ttf"
                },
                "font_name": "Convergence Regular",
                "font_family": "Convergence",
                "font_postscript": "Convergence-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Cookie",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cookie-Regular.ttf"
                },
                "font_name": "Cookie Regular",
                "font_family": "Cookie",
                "font_postscript": "Cookie-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Copse",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Copse-Regular.ttf"
                },
                "font_name": "Copse Regular",
                "font_family": "Copse",
                "font_postscript": "Copse-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Corben",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Corben-Bold.ttf"
                },
                "font_name": "Corben Bold",
                "font_family": "Corben",
                "font_postscript": "Corben-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Corben-Regular.ttf"
                },
                "font_name": "Corben Regular",
                "font_family": "Corben",
                "font_postscript": "Corben-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Corinthia",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Corinthia-Bold.ttf"
                },
                "font_name": "Corinthia Bold",
                "font_family": "Corinthia",
                "font_postscript": "Corinthia-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Corinthia-Regular.ttf"
                },
                "font_name": "Corinthia Regular",
                "font_family": "Corinthia",
                "font_postscript": "Corinthia-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Cormorant",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cormorant-Regular.ttf"
                },
                "font_name": "Cormorant Regular",
                "font_family": "Cormorant",
                "font_postscript": "Cormorant-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cormorant-Italic.ttf"
                },
                "font_name": "Cormorant Italic",
                "font_family": "Cormorant",
                "font_postscript": "Cormorant-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CormorantGaramond",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantGaramond-Italic.ttf"
                },
                "font_name": "CormorantGaramond Italic",
                "font_family": "CormorantGaramond",
                "font_postscript": "CormorantGaramond-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantGaramond-BoldItalic.ttf"
                },
                "font_name": "CormorantGaramond BoldItalic",
                "font_family": "CormorantGaramond",
                "font_postscript": "CormorantGaramond-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantGaramond-LightItalic.ttf"
                },
                "font_name": "CormorantGaramond LightItalic",
                "font_family": "CormorantGaramond",
                "font_postscript": "CormorantGaramond-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantGaramond-Light.ttf"
                },
                "font_name": "CormorantGaramond Light",
                "font_family": "CormorantGaramond",
                "font_postscript": "CormorantGaramond-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantGaramond-SemiBold.ttf"
                },
                "font_name": "CormorantGaramond SemiBold",
                "font_family": "CormorantGaramond",
                "font_postscript": "CormorantGaramond-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantGaramond-MediumItalic.ttf"
                },
                "font_name": "CormorantGaramond MediumItalic",
                "font_family": "CormorantGaramond",
                "font_postscript": "CormorantGaramond-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantGaramond-Regular.ttf"
                },
                "font_name": "CormorantGaramond Regular",
                "font_family": "CormorantGaramond",
                "font_postscript": "CormorantGaramond-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantGaramond-Bold.ttf"
                },
                "font_name": "CormorantGaramond Bold",
                "font_family": "CormorantGaramond",
                "font_postscript": "CormorantGaramond-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantGaramond-SemiBoldItalic.ttf"
                },
                "font_name": "CormorantGaramond SemiBoldItalic",
                "font_family": "CormorantGaramond",
                "font_postscript": "CormorantGaramond-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantGaramond-Medium.ttf"
                },
                "font_name": "CormorantGaramond Medium",
                "font_family": "CormorantGaramond",
                "font_postscript": "CormorantGaramond-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CormorantInfant",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantInfant-BoldItalic.ttf"
                },
                "font_name": "CormorantInfant BoldItalic",
                "font_family": "CormorantInfant",
                "font_postscript": "CormorantInfant-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantInfant-Light.ttf"
                },
                "font_name": "CormorantInfant Light",
                "font_family": "CormorantInfant",
                "font_postscript": "CormorantInfant-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantInfant-Italic.ttf"
                },
                "font_name": "CormorantInfant Italic",
                "font_family": "CormorantInfant",
                "font_postscript": "CormorantInfant-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantInfant-Regular.ttf"
                },
                "font_name": "CormorantInfant Regular",
                "font_family": "CormorantInfant",
                "font_postscript": "CormorantInfant-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantInfant-SemiBoldItalic.ttf"
                },
                "font_name": "CormorantInfant SemiBoldItalic",
                "font_family": "CormorantInfant",
                "font_postscript": "CormorantInfant-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantInfant-LightItalic.ttf"
                },
                "font_name": "CormorantInfant LightItalic",
                "font_family": "CormorantInfant",
                "font_postscript": "CormorantInfant-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantInfant-MediumItalic.ttf"
                },
                "font_name": "CormorantInfant MediumItalic",
                "font_family": "CormorantInfant",
                "font_postscript": "CormorantInfant-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantInfant-Bold.ttf"
                },
                "font_name": "CormorantInfant Bold",
                "font_family": "CormorantInfant",
                "font_postscript": "CormorantInfant-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantInfant-Medium.ttf"
                },
                "font_name": "CormorantInfant Medium",
                "font_family": "CormorantInfant",
                "font_postscript": "CormorantInfant-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantInfant-SemiBold.ttf"
                },
                "font_name": "CormorantInfant SemiBold",
                "font_family": "CormorantInfant",
                "font_postscript": "CormorantInfant-SemiBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CormorantSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantSC-Medium.ttf"
                },
                "font_name": "CormorantSC Medium",
                "font_family": "CormorantSC",
                "font_postscript": "CormorantSC-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantSC-Bold.ttf"
                },
                "font_name": "CormorantSC Bold",
                "font_family": "CormorantSC",
                "font_postscript": "CormorantSC-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantSC-Light.ttf"
                },
                "font_name": "CormorantSC Light",
                "font_family": "CormorantSC",
                "font_postscript": "CormorantSC-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantSC-Regular.ttf"
                },
                "font_name": "CormorantSC Regular",
                "font_family": "CormorantSC",
                "font_postscript": "CormorantSC-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantSC-SemiBold.ttf"
                },
                "font_name": "CormorantSC SemiBold",
                "font_family": "CormorantSC",
                "font_postscript": "CormorantSC-SemiBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CormorantUnicase",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantUnicase-Medium.ttf"
                },
                "font_name": "CormorantUnicase Medium",
                "font_family": "CormorantUnicase",
                "font_postscript": "CormorantUnicase-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantUnicase-Bold.ttf"
                },
                "font_name": "CormorantUnicase Bold",
                "font_family": "CormorantUnicase",
                "font_postscript": "CormorantUnicase-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantUnicase-SemiBold.ttf"
                },
                "font_name": "CormorantUnicase SemiBold",
                "font_family": "CormorantUnicase",
                "font_postscript": "CormorantUnicase-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantUnicase-Regular.ttf"
                },
                "font_name": "CormorantUnicase Regular",
                "font_family": "CormorantUnicase",
                "font_postscript": "CormorantUnicase-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantUnicase-Light.ttf"
                },
                "font_name": "CormorantUnicase Light",
                "font_family": "CormorantUnicase",
                "font_postscript": "CormorantUnicase-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CormorantUpright",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantUpright-SemiBold.ttf"
                },
                "font_name": "CormorantUpright SemiBold",
                "font_family": "CormorantUpright",
                "font_postscript": "CormorantUpright-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantUpright-Bold.ttf"
                },
                "font_name": "CormorantUpright Bold",
                "font_family": "CormorantUpright",
                "font_postscript": "CormorantUpright-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantUpright-Regular.ttf"
                },
                "font_name": "CormorantUpright Regular",
                "font_family": "CormorantUpright",
                "font_postscript": "CormorantUpright-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantUpright-Light.ttf"
                },
                "font_name": "CormorantUpright Light",
                "font_family": "CormorantUpright",
                "font_postscript": "CormorantUpright-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CormorantUpright-Medium.ttf"
                },
                "font_name": "CormorantUpright Medium",
                "font_family": "CormorantUpright",
                "font_postscript": "CormorantUpright-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Courgette",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Courgette-Regular.ttf"
                },
                "font_name": "Courgette Regular",
                "font_family": "Courgette",
                "font_postscript": "Courgette-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CourierPrime",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CourierPrime-BoldItalic.ttf"
                },
                "font_name": "CourierPrime BoldItalic",
                "font_family": "CourierPrime",
                "font_postscript": "CourierPrime-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CourierPrime-Bold.ttf"
                },
                "font_name": "CourierPrime Bold",
                "font_family": "CourierPrime",
                "font_postscript": "CourierPrime-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CourierPrime-Italic.ttf"
                },
                "font_name": "CourierPrime Italic",
                "font_family": "CourierPrime",
                "font_postscript": "CourierPrime-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CourierPrime-Regular.ttf"
                },
                "font_name": "CourierPrime Regular",
                "font_family": "CourierPrime",
                "font_postscript": "CourierPrime-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Cousine",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cousine-BoldItalic.ttf"
                },
                "font_name": "Cousine BoldItalic",
                "font_family": "Cousine",
                "font_postscript": "Cousine-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cousine-Bold.ttf"
                },
                "font_name": "Cousine Bold",
                "font_family": "Cousine",
                "font_postscript": "Cousine-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cousine-Italic.ttf"
                },
                "font_name": "Cousine Italic",
                "font_family": "Cousine",
                "font_postscript": "Cousine-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cousine-Regular.ttf"
                },
                "font_name": "Cousine Regular",
                "font_family": "Cousine",
                "font_postscript": "Cousine-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Coustard",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Coustard-Black.ttf"
                },
                "font_name": "Coustard Black",
                "font_family": "Coustard",
                "font_postscript": "Coustard-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Coustard-Regular.ttf"
                },
                "font_name": "Coustard Regular",
                "font_family": "Coustard",
                "font_postscript": "Coustard-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CoveredByYourGrace",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CoveredByYourGrace-Regular.ttf"
                },
                "font_name": "CoveredByYourGrace Regular",
                "font_family": "CoveredByYourGrace",
                "font_postscript": "CoveredByYourGrace-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CraftyGirls",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CraftyGirls-Regular.ttf"
                },
                "font_name": "CraftyGirls Regular",
                "font_family": "CraftyGirls",
                "font_postscript": "CraftyGirls-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Creepster",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Creepster-Regular.ttf"
                },
                "font_name": "Creepster Regular",
                "font_family": "Creepster",
                "font_postscript": "Creepster-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CreepsterCaps",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CreepsterCaps-Regular.ttf"
                },
                "font_name": "CreepsterCaps Regular",
                "font_family": "CreepsterCaps",
                "font_postscript": "CreepsterCaps-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CreteRound",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CreteRound-Italic.ttf"
                },
                "font_name": "CreteRound Italic",
                "font_family": "CreteRound",
                "font_postscript": "CreteRound-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CreteRound-Regular.ttf"
                },
                "font_name": "CreteRound Regular",
                "font_family": "CreteRound",
                "font_postscript": "CreteRound-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CrimsonPro",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CrimsonPro-Regular.ttf"
                },
                "font_name": "CrimsonPro Regular",
                "font_family": "CrimsonPro",
                "font_postscript": "CrimsonPro-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CrimsonPro-Italic.ttf"
                },
                "font_name": "CrimsonPro Italic",
                "font_family": "CrimsonPro",
                "font_postscript": "CrimsonPro-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CrimsonText",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CrimsonText-SemiBold.ttf"
                },
                "font_name": "CrimsonText SemiBold",
                "font_family": "CrimsonText",
                "font_postscript": "CrimsonText-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CrimsonText-Bold.ttf"
                },
                "font_name": "CrimsonText Bold",
                "font_family": "CrimsonText",
                "font_postscript": "CrimsonText-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CrimsonText-Regular.ttf"
                },
                "font_name": "CrimsonText Regular",
                "font_family": "CrimsonText",
                "font_postscript": "CrimsonText-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CrimsonText-SemiBoldItalic.ttf"
                },
                "font_name": "CrimsonText SemiBoldItalic",
                "font_family": "CrimsonText",
                "font_postscript": "CrimsonText-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CrimsonText-BoldItalic.ttf"
                },
                "font_name": "CrimsonText BoldItalic",
                "font_family": "CrimsonText",
                "font_postscript": "CrimsonText-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "CrimsonText-Italic.ttf"
                },
                "font_name": "CrimsonText Italic",
                "font_family": "CrimsonText",
                "font_postscript": "CrimsonText-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CroissantOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CroissantOne-Regular.ttf"
                },
                "font_name": "CroissantOne Regular",
                "font_family": "CroissantOne",
                "font_postscript": "CroissantOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Crushed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Crushed-Regular.ttf"
                },
                "font_name": "Crushed Regular",
                "font_family": "Crushed",
                "font_postscript": "Crushed-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Cuprum",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cuprum-Italic.ttf"
                },
                "font_name": "Cuprum Italic",
                "font_family": "Cuprum",
                "font_postscript": "Cuprum-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cuprum-Regular.ttf"
                },
                "font_name": "Cuprum Regular",
                "font_family": "Cuprum",
                "font_postscript": "Cuprum-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CuteFont",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CuteFont-Regular.ttf"
                },
                "font_name": "CuteFont Regular",
                "font_family": "CuteFont",
                "font_postscript": "CuteFont-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Cutive",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Cutive-Regular.ttf"
                },
                "font_name": "Cutive Regular",
                "font_family": "Cutive",
                "font_postscript": "Cutive-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "CutiveMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "CutiveMono-Regular.ttf"
                },
                "font_name": "CutiveMono Regular",
                "font_family": "CutiveMono",
                "font_postscript": "CutiveMono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DMMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DMMono-Light.ttf"
                },
                "font_name": "DMMono Light",
                "font_family": "DMMono",
                "font_postscript": "DMMono-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "DMMono-MediumItalic.ttf"
                },
                "font_name": "DMMono MediumItalic",
                "font_family": "DMMono",
                "font_postscript": "DMMono-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "DMMono-Medium.ttf"
                },
                "font_name": "DMMono Medium",
                "font_family": "DMMono",
                "font_postscript": "DMMono-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "DMMono-Regular.ttf"
                },
                "font_name": "DMMono Regular",
                "font_family": "DMMono",
                "font_postscript": "DMMono-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "DMMono-LightItalic.ttf"
                },
                "font_name": "DMMono LightItalic",
                "font_family": "DMMono",
                "font_postscript": "DMMono-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "DMMono-Italic.ttf"
                },
                "font_name": "DMMono Italic",
                "font_family": "DMMono",
                "font_postscript": "DMMono-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DMSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DMSans-Italic.ttf"
                },
                "font_name": "DMSans Italic",
                "font_family": "DMSans",
                "font_postscript": "DMSans-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "DMSans-Regular.ttf"
                },
                "font_name": "DMSans Regular",
                "font_family": "DMSans",
                "font_postscript": "DMSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DMSerifDisplay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DMSerifDisplay-Italic.ttf"
                },
                "font_name": "DMSerifDisplay Italic",
                "font_family": "DMSerifDisplay",
                "font_postscript": "DMSerifDisplay-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "DMSerifDisplay-Regular.ttf"
                },
                "font_name": "DMSerifDisplay Regular",
                "font_family": "DMSerifDisplay",
                "font_postscript": "DMSerifDisplay-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DMSerifText",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DMSerifText-Regular.ttf"
                },
                "font_name": "DMSerifText Regular",
                "font_family": "DMSerifText",
                "font_postscript": "DMSerifText-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "DMSerifText-Italic.ttf"
                },
                "font_name": "DMSerifText Italic",
                "font_family": "DMSerifText",
                "font_postscript": "DMSerifText-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DaiBannaSIL",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DaiBannaSIL-Regular.ttf"
                },
                "font_name": "DaiBannaSIL Regular",
                "font_family": "DaiBannaSIL",
                "font_postscript": "DaiBannaSIL-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "DaiBannaSIL-MediumItalic.ttf"
                },
                "font_name": "DaiBannaSIL MediumItalic",
                "font_family": "DaiBannaSIL",
                "font_postscript": "DaiBannaSIL-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "DaiBannaSIL-SemiBoldItalic.ttf"
                },
                "font_name": "DaiBannaSIL SemiBoldItalic",
                "font_family": "DaiBannaSIL",
                "font_postscript": "DaiBannaSIL-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "DaiBannaSIL-Light.ttf"
                },
                "font_name": "DaiBannaSIL Light",
                "font_family": "DaiBannaSIL",
                "font_postscript": "DaiBannaSIL-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "DaiBannaSIL-Medium.ttf"
                },
                "font_name": "DaiBannaSIL Medium",
                "font_family": "DaiBannaSIL",
                "font_postscript": "DaiBannaSIL-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "DaiBannaSIL-LightItalic.ttf"
                },
                "font_name": "DaiBannaSIL LightItalic",
                "font_family": "DaiBannaSIL",
                "font_postscript": "DaiBannaSIL-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "DaiBannaSIL-SemiBold.ttf"
                },
                "font_name": "DaiBannaSIL SemiBold",
                "font_family": "DaiBannaSIL",
                "font_postscript": "DaiBannaSIL-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "DaiBannaSIL-BoldItalic.ttf"
                },
                "font_name": "DaiBannaSIL BoldItalic",
                "font_family": "DaiBannaSIL",
                "font_postscript": "DaiBannaSIL-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "DaiBannaSIL-Italic.ttf"
                },
                "font_name": "DaiBannaSIL Italic",
                "font_family": "DaiBannaSIL",
                "font_postscript": "DaiBannaSIL-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "DaiBannaSIL-Bold.ttf"
                },
                "font_name": "DaiBannaSIL Bold",
                "font_family": "DaiBannaSIL",
                "font_postscript": "DaiBannaSIL-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Damion",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Damion-Regular.ttf"
                },
                "font_name": "Damion Regular",
                "font_family": "Damion",
                "font_postscript": "Damion-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DancingScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DancingScript-Regular.ttf"
                },
                "font_name": "DancingScript Regular",
                "font_family": "DancingScript",
                "font_postscript": "DancingScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Danfo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Danfo-Regular.ttf"
                },
                "font_name": "Danfo Regular",
                "font_family": "Danfo",
                "font_postscript": "Danfo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Dangrek",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Dangrek-Regular.ttf"
                },
                "font_name": "Dangrek Regular",
                "font_family": "Dangrek",
                "font_postscript": "Dangrek-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DarkerGrotesque",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DarkerGrotesque-Regular.ttf"
                },
                "font_name": "DarkerGrotesque Regular",
                "font_family": "DarkerGrotesque",
                "font_postscript": "DarkerGrotesque-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DarumadropOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DarumadropOne-Regular.ttf"
                },
                "font_name": "DarumadropOne Regular",
                "font_family": "DarumadropOne",
                "font_postscript": "DarumadropOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DavidLibre",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DavidLibre-Bold.ttf"
                },
                "font_name": "DavidLibre Bold",
                "font_family": "DavidLibre",
                "font_postscript": "DavidLibre-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "DavidLibre-Regular.ttf"
                },
                "font_name": "DavidLibre Regular",
                "font_family": "DavidLibre",
                "font_postscript": "DavidLibre-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "DavidLibre-Medium.ttf"
                },
                "font_name": "DavidLibre Medium",
                "font_family": "DavidLibre",
                "font_postscript": "DavidLibre-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DawningofaNewDay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DawningofaNewDay-Regular.ttf"
                },
                "font_name": "DawningofaNewDay Regular",
                "font_family": "DawningofaNewDay",
                "font_postscript": "DawningofaNewDay-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DaysOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DaysOne-Regular.ttf"
                },
                "font_name": "DaysOne Regular",
                "font_family": "DaysOne",
                "font_postscript": "DaysOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DecovarAlpha",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DecovarAlpha-VF.ttf"
                },
                "font_name": "DecovarAlpha VF",
                "font_family": "DecovarAlpha",
                "font_postscript": "DecovarAlpha-VF",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Dekko",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Dekko-Regular.ttf"
                },
                "font_name": "Dekko Regular",
                "font_family": "Dekko",
                "font_postscript": "Dekko-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DelaGothicOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DelaGothicOne-Regular.ttf"
                },
                "font_name": "DelaGothicOne Regular",
                "font_family": "DelaGothicOne",
                "font_postscript": "DelaGothicOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DeliciousHandrawn",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DeliciousHandrawn-Regular.ttf"
                },
                "font_name": "DeliciousHandrawn Regular",
                "font_family": "DeliciousHandrawn",
                "font_postscript": "DeliciousHandrawn-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Delius",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Delius-Regular.ttf"
                },
                "font_name": "Delius Regular",
                "font_family": "Delius",
                "font_postscript": "Delius-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DeliusSwashCaps",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DeliusSwashCaps-Regular.ttf"
                },
                "font_name": "DeliusSwashCaps Regular",
                "font_family": "DeliusSwashCaps",
                "font_postscript": "DeliusSwashCaps-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DeliusUnicase",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DeliusUnicase-Bold.ttf"
                },
                "font_name": "DeliusUnicase Bold",
                "font_family": "DeliusUnicase",
                "font_postscript": "DeliusUnicase-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "DeliusUnicase-Regular.ttf"
                },
                "font_name": "DeliusUnicase Regular",
                "font_family": "DeliusUnicase",
                "font_postscript": "DeliusUnicase-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DellaRespira",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DellaRespira-Regular.ttf"
                },
                "font_name": "DellaRespira Regular",
                "font_family": "DellaRespira",
                "font_postscript": "DellaRespira-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DenkOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DenkOne-Regular.ttf"
                },
                "font_name": "DenkOne Regular",
                "font_family": "DenkOne",
                "font_postscript": "DenkOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Devonshire",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Devonshire-Regular.ttf"
                },
                "font_name": "Devonshire Regular",
                "font_family": "Devonshire",
                "font_postscript": "Devonshire-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Dhurjati",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Dhurjati-Regular.ttf"
                },
                "font_name": "Dhurjati Regular",
                "font_family": "Dhurjati",
                "font_postscript": "Dhurjati-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Dhyana",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Dhyana-Regular.ttf"
                },
                "font_name": "Dhyana Regular",
                "font_family": "Dhyana",
                "font_postscript": "Dhyana-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Dhyana-Bold.ttf"
                },
                "font_name": "Dhyana Bold",
                "font_family": "Dhyana",
                "font_postscript": "Dhyana-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DidactGothic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DidactGothic-Regular.ttf"
                },
                "font_name": "DidactGothic Regular",
                "font_family": "DidactGothic",
                "font_postscript": "DidactGothic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DigitalNumbers",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DigitalNumbers-Regular.ttf"
                },
                "font_name": "DigitalNumbers Regular",
                "font_family": "DigitalNumbers",
                "font_postscript": "DigitalNumbers-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Diphylleia",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Diphylleia-Regular.ttf"
                },
                "font_name": "Diphylleia Regular",
                "font_family": "Diphylleia",
                "font_postscript": "Diphylleia-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Diplomata",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Diplomata-Regular.ttf"
                },
                "font_name": "Diplomata Regular",
                "font_family": "Diplomata",
                "font_postscript": "Diplomata-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DiplomataSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DiplomataSC-Regular.ttf"
                },
                "font_name": "DiplomataSC Regular",
                "font_family": "DiplomataSC",
                "font_postscript": "DiplomataSC-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DoHyeon",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DoHyeon-Regular.ttf"
                },
                "font_name": "DoHyeon Regular",
                "font_family": "DoHyeon",
                "font_postscript": "DoHyeon-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Dokdo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Dokdo-Regular.ttf"
                },
                "font_name": "Dokdo Regular",
                "font_family": "Dokdo",
                "font_postscript": "Dokdo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Domine",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Domine-Regular.ttf"
                },
                "font_name": "Domine Regular",
                "font_family": "Domine",
                "font_postscript": "Domine-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DonegalOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DonegalOne-Regular.ttf"
                },
                "font_name": "DonegalOne Regular",
                "font_family": "DonegalOne",
                "font_postscript": "DonegalOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Dongle",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Dongle-Regular.ttf"
                },
                "font_name": "Dongle Regular",
                "font_family": "Dongle",
                "font_postscript": "Dongle-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Dongle-Light.ttf"
                },
                "font_name": "Dongle Light",
                "font_family": "Dongle",
                "font_postscript": "Dongle-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Dongle-Bold.ttf"
                },
                "font_name": "Dongle Bold",
                "font_family": "Dongle",
                "font_postscript": "Dongle-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DoppioOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DoppioOne-Regular.ttf"
                },
                "font_name": "DoppioOne Regular",
                "font_family": "DoppioOne",
                "font_postscript": "DoppioOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Dorsa",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Dorsa-Regular.ttf"
                },
                "font_name": "Dorsa Regular",
                "font_family": "Dorsa",
                "font_postscript": "Dorsa-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Dosis",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Dosis-Regular.ttf"
                },
                "font_name": "Dosis Regular",
                "font_family": "Dosis",
                "font_postscript": "Dosis-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DotGothic16",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DotGothic16-Regular.ttf"
                },
                "font_name": "DotGothic16 Regular",
                "font_family": "DotGothic16",
                "font_postscript": "DotGothic16-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DrSugiyama",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DrSugiyama-Regular.ttf"
                },
                "font_name": "DrSugiyama Regular",
                "font_family": "DrSugiyama",
                "font_postscript": "DrSugiyama-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DuruSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DuruSans-Regular.ttf"
                },
                "font_name": "DuruSans Regular",
                "font_family": "DuruSans",
                "font_postscript": "DuruSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "DynaPuff",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "DynaPuff-Regular.ttf"
                },
                "font_name": "DynaPuff Regular",
                "font_family": "DynaPuff",
                "font_postscript": "DynaPuff-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Dynalight",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Dynalight-Regular.ttf"
                },
                "font_name": "Dynalight Regular",
                "font_family": "Dynalight",
                "font_postscript": "Dynalight-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "EBGaramond",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "EBGaramond-Italic.ttf"
                },
                "font_name": "EBGaramond Italic",
                "font_family": "EBGaramond",
                "font_postscript": "EBGaramond-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EBGaramond-Regular.ttf"
                },
                "font_name": "EBGaramond Regular",
                "font_family": "EBGaramond",
                "font_postscript": "EBGaramond-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "EagleLake",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "EagleLake-Regular.ttf"
                },
                "font_name": "EagleLake Regular",
                "font_family": "EagleLake",
                "font_postscript": "EagleLake-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "EastSeaDokdo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "EastSeaDokdo-Regular.ttf"
                },
                "font_name": "EastSeaDokdo Regular",
                "font_family": "EastSeaDokdo",
                "font_postscript": "EastSeaDokdo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Eater",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Eater-Regular.ttf"
                },
                "font_name": "Eater Regular",
                "font_family": "Eater",
                "font_postscript": "Eater-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Economica",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Economica-BoldItalic.ttf"
                },
                "font_name": "Economica BoldItalic",
                "font_family": "Economica",
                "font_postscript": "Economica-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Economica-Bold.ttf"
                },
                "font_name": "Economica Bold",
                "font_family": "Economica",
                "font_postscript": "Economica-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Economica-Regular.ttf"
                },
                "font_name": "Economica Regular",
                "font_family": "Economica",
                "font_postscript": "Economica-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Economica-Italic.ttf"
                },
                "font_name": "Economica Italic",
                "font_family": "Economica",
                "font_postscript": "Economica-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Eczar",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Eczar-Regular.ttf"
                },
                "font_name": "Eczar Regular",
                "font_family": "Eczar",
                "font_postscript": "Eczar-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "EduNSWACTFoundation",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "EduNSWACTFoundation-Regular.ttf"
                },
                "font_name": "EduNSWACTFoundation Regular",
                "font_family": "EduNSWACTFoundation",
                "font_postscript": "EduNSWACTFoundation-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "EduQLDBeginner",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "EduQLDBeginner-Regular.ttf"
                },
                "font_name": "EduQLDBeginner Regular",
                "font_family": "EduQLDBeginner",
                "font_postscript": "EduQLDBeginner-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "EduSABeginner",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "EduSABeginner-Regular.ttf"
                },
                "font_name": "EduSABeginner Regular",
                "font_family": "EduSABeginner",
                "font_postscript": "EduSABeginner-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "EduTASBeginner",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "EduTASBeginner-Regular.ttf"
                },
                "font_name": "EduTASBeginner Regular",
                "font_family": "EduTASBeginner",
                "font_postscript": "EduTASBeginner-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "EduVICWANTBeginner",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "EduVICWANTBeginner-Regular.ttf"
                },
                "font_name": "EduVICWANTBeginner Regular",
                "font_family": "EduVICWANTBeginner",
                "font_postscript": "EduVICWANTBeginner-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "EkMukta",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "EkMukta-Light.ttf"
                },
                "font_name": "EkMukta Light",
                "font_family": "EkMukta",
                "font_postscript": "EkMukta-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EkMukta-ExtraBold.ttf"
                },
                "font_name": "EkMukta ExtraBold",
                "font_family": "EkMukta",
                "font_postscript": "EkMukta-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EkMukta-Bold.ttf"
                },
                "font_name": "EkMukta Bold",
                "font_family": "EkMukta",
                "font_postscript": "EkMukta-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EkMukta-Medium.ttf"
                },
                "font_name": "EkMukta Medium",
                "font_family": "EkMukta",
                "font_postscript": "EkMukta-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EkMukta-ExtraLight.ttf"
                },
                "font_name": "EkMukta ExtraLight",
                "font_family": "EkMukta",
                "font_postscript": "EkMukta-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EkMukta-Regular.ttf"
                },
                "font_name": "EkMukta Regular",
                "font_family": "EkMukta",
                "font_postscript": "EkMukta-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EkMukta-SemiBold.ttf"
                },
                "font_name": "EkMukta SemiBold",
                "font_family": "EkMukta",
                "font_postscript": "EkMukta-SemiBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ElMessiri",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ElMessiri-Regular.ttf"
                },
                "font_name": "ElMessiri Regular",
                "font_family": "ElMessiri",
                "font_postscript": "ElMessiri-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Electrolize",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Electrolize-Regular.ttf"
                },
                "font_name": "Electrolize Regular",
                "font_family": "Electrolize",
                "font_postscript": "Electrolize-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Elsie",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Elsie-Black.ttf"
                },
                "font_name": "Elsie Black",
                "font_family": "Elsie",
                "font_postscript": "Elsie-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Elsie-Regular.ttf"
                },
                "font_name": "Elsie Regular",
                "font_family": "Elsie",
                "font_postscript": "Elsie-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ElsieSwashCaps",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ElsieSwashCaps-Black.ttf"
                },
                "font_name": "ElsieSwashCaps Black",
                "font_family": "ElsieSwashCaps",
                "font_postscript": "ElsieSwashCaps-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ElsieSwashCaps-Regular.ttf"
                },
                "font_name": "ElsieSwashCaps Regular",
                "font_family": "ElsieSwashCaps",
                "font_postscript": "ElsieSwashCaps-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "EmblemaOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "EmblemaOne-Regular.ttf"
                },
                "font_name": "EmblemaOne Regular",
                "font_family": "EmblemaOne",
                "font_postscript": "EmblemaOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "EmilysCandy",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "EmilysCandy-Regular.ttf"
                },
                "font_name": "EmilysCandy Regular",
                "font_family": "EmilysCandy",
                "font_postscript": "EmilysCandy-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "EncodeSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSans-Regular.ttf"
                },
                "font_name": "EncodeSans Regular",
                "font_family": "EncodeSans",
                "font_postscript": "EncodeSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "EncodeSansCondensed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansCondensed-Regular.ttf"
                },
                "font_name": "EncodeSansCondensed Regular",
                "font_family": "EncodeSansCondensed",
                "font_postscript": "EncodeSansCondensed-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansCondensed-SemiBold.ttf"
                },
                "font_name": "EncodeSansCondensed SemiBold",
                "font_family": "EncodeSansCondensed",
                "font_postscript": "EncodeSansCondensed-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansCondensed-Thin.ttf"
                },
                "font_name": "EncodeSansCondensed Thin",
                "font_family": "EncodeSansCondensed",
                "font_postscript": "EncodeSansCondensed-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansCondensed-Black.ttf"
                },
                "font_name": "EncodeSansCondensed Black",
                "font_family": "EncodeSansCondensed",
                "font_postscript": "EncodeSansCondensed-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansCondensed-Bold.ttf"
                },
                "font_name": "EncodeSansCondensed Bold",
                "font_family": "EncodeSansCondensed",
                "font_postscript": "EncodeSansCondensed-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansCondensed-Medium.ttf"
                },
                "font_name": "EncodeSansCondensed Medium",
                "font_family": "EncodeSansCondensed",
                "font_postscript": "EncodeSansCondensed-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansCondensed-Light.ttf"
                },
                "font_name": "EncodeSansCondensed Light",
                "font_family": "EncodeSansCondensed",
                "font_postscript": "EncodeSansCondensed-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansCondensed-ExtraBold.ttf"
                },
                "font_name": "EncodeSansCondensed ExtraBold",
                "font_family": "EncodeSansCondensed",
                "font_postscript": "EncodeSansCondensed-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansCondensed-ExtraLight.ttf"
                },
                "font_name": "EncodeSansCondensed ExtraLight",
                "font_family": "EncodeSansCondensed",
                "font_postscript": "EncodeSansCondensed-ExtraLight",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "EncodeSansExpanded",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansExpanded-ExtraLight.ttf"
                },
                "font_name": "EncodeSansExpanded ExtraLight",
                "font_family": "EncodeSansExpanded",
                "font_postscript": "EncodeSansExpanded-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansExpanded-SemiBold.ttf"
                },
                "font_name": "EncodeSansExpanded SemiBold",
                "font_family": "EncodeSansExpanded",
                "font_postscript": "EncodeSansExpanded-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansExpanded-Black.ttf"
                },
                "font_name": "EncodeSansExpanded Black",
                "font_family": "EncodeSansExpanded",
                "font_postscript": "EncodeSansExpanded-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansExpanded-Regular.ttf"
                },
                "font_name": "EncodeSansExpanded Regular",
                "font_family": "EncodeSansExpanded",
                "font_postscript": "EncodeSansExpanded-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansExpanded-Medium.ttf"
                },
                "font_name": "EncodeSansExpanded Medium",
                "font_family": "EncodeSansExpanded",
                "font_postscript": "EncodeSansExpanded-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansExpanded-ExtraBold.ttf"
                },
                "font_name": "EncodeSansExpanded ExtraBold",
                "font_family": "EncodeSansExpanded",
                "font_postscript": "EncodeSansExpanded-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansExpanded-Light.ttf"
                },
                "font_name": "EncodeSansExpanded Light",
                "font_family": "EncodeSansExpanded",
                "font_postscript": "EncodeSansExpanded-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansExpanded-Thin.ttf"
                },
                "font_name": "EncodeSansExpanded Thin",
                "font_family": "EncodeSansExpanded",
                "font_postscript": "EncodeSansExpanded-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansExpanded-Bold.ttf"
                },
                "font_name": "EncodeSansExpanded Bold",
                "font_family": "EncodeSansExpanded",
                "font_postscript": "EncodeSansExpanded-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "EncodeSansSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansSC-Regular.ttf"
                },
                "font_name": "EncodeSansSC Regular",
                "font_family": "EncodeSansSC",
                "font_postscript": "EncodeSansSC-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "EncodeSansSemiCondensed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansSemiCondensed-Bold.ttf"
                },
                "font_name": "EncodeSansSemiCondensed Bold",
                "font_family": "EncodeSansSemiCondensed",
                "font_postscript": "EncodeSansSemiCondensed-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansSemiCondensed-Medium.ttf"
                },
                "font_name": "EncodeSansSemiCondensed Medium",
                "font_family": "EncodeSansSemiCondensed",
                "font_postscript": "EncodeSansSemiCondensed-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansSemiCondensed-Black.ttf"
                },
                "font_name": "EncodeSansSemiCondensed Black",
                "font_family": "EncodeSansSemiCondensed",
                "font_postscript": "EncodeSansSemiCondensed-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansSemiCondensed-Thin.ttf"
                },
                "font_name": "EncodeSansSemiCondensed Thin",
                "font_family": "EncodeSansSemiCondensed",
                "font_postscript": "EncodeSansSemiCondensed-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansSemiCondensed-Regular.ttf"
                },
                "font_name": "EncodeSansSemiCondensed Regular",
                "font_family": "EncodeSansSemiCondensed",
                "font_postscript": "EncodeSansSemiCondensed-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansSemiCondensed-SemiBold.ttf"
                },
                "font_name": "EncodeSansSemiCondensed SemiBold",
                "font_family": "EncodeSansSemiCondensed",
                "font_postscript": "EncodeSansSemiCondensed-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansSemiCondensed-ExtraLight.ttf"
                },
                "font_name": "EncodeSansSemiCondensed ExtraLight",
                "font_family": "EncodeSansSemiCondensed",
                "font_postscript": "EncodeSansSemiCondensed-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansSemiCondensed-ExtraBold.ttf"
                },
                "font_name": "EncodeSansSemiCondensed ExtraBold",
                "font_family": "EncodeSansSemiCondensed",
                "font_postscript": "EncodeSansSemiCondensed-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansSemiCondensed-Light.ttf"
                },
                "font_name": "EncodeSansSemiCondensed Light",
                "font_family": "EncodeSansSemiCondensed",
                "font_postscript": "EncodeSansSemiCondensed-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "EncodeSansSemiExpanded",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansSemiExpanded-ExtraBold.ttf"
                },
                "font_name": "EncodeSansSemiExpanded ExtraBold",
                "font_family": "EncodeSansSemiExpanded",
                "font_postscript": "EncodeSansSemiExpanded-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansSemiExpanded-Thin.ttf"
                },
                "font_name": "EncodeSansSemiExpanded Thin",
                "font_family": "EncodeSansSemiExpanded",
                "font_postscript": "EncodeSansSemiExpanded-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansSemiExpanded-ExtraLight.ttf"
                },
                "font_name": "EncodeSansSemiExpanded ExtraLight",
                "font_family": "EncodeSansSemiExpanded",
                "font_postscript": "EncodeSansSemiExpanded-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansSemiExpanded-Bold.ttf"
                },
                "font_name": "EncodeSansSemiExpanded Bold",
                "font_family": "EncodeSansSemiExpanded",
                "font_postscript": "EncodeSansSemiExpanded-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansSemiExpanded-Black.ttf"
                },
                "font_name": "EncodeSansSemiExpanded Black",
                "font_family": "EncodeSansSemiExpanded",
                "font_postscript": "EncodeSansSemiExpanded-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansSemiExpanded-SemiBold.ttf"
                },
                "font_name": "EncodeSansSemiExpanded SemiBold",
                "font_family": "EncodeSansSemiExpanded",
                "font_postscript": "EncodeSansSemiExpanded-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansSemiExpanded-Light.ttf"
                },
                "font_name": "EncodeSansSemiExpanded Light",
                "font_family": "EncodeSansSemiExpanded",
                "font_postscript": "EncodeSansSemiExpanded-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansSemiExpanded-Regular.ttf"
                },
                "font_name": "EncodeSansSemiExpanded Regular",
                "font_family": "EncodeSansSemiExpanded",
                "font_postscript": "EncodeSansSemiExpanded-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "EncodeSansSemiExpanded-Medium.ttf"
                },
                "font_name": "EncodeSansSemiExpanded Medium",
                "font_family": "EncodeSansSemiExpanded",
                "font_postscript": "EncodeSansSemiExpanded-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Engagement",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Engagement-Regular.ttf"
                },
                "font_name": "Engagement Regular",
                "font_family": "Engagement",
                "font_postscript": "Engagement-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Englebert",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Englebert-Regular.ttf"
                },
                "font_name": "Englebert Regular",
                "font_family": "Englebert",
                "font_postscript": "Englebert-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Enriqueta",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Enriqueta-Medium.ttf"
                },
                "font_name": "Enriqueta Medium",
                "font_family": "Enriqueta",
                "font_postscript": "Enriqueta-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Enriqueta-Bold.ttf"
                },
                "font_name": "Enriqueta Bold",
                "font_family": "Enriqueta",
                "font_postscript": "Enriqueta-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Enriqueta-SemiBold.ttf"
                },
                "font_name": "Enriqueta SemiBold",
                "font_family": "Enriqueta",
                "font_postscript": "Enriqueta-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Enriqueta-Regular.ttf"
                },
                "font_name": "Enriqueta Regular",
                "font_family": "Enriqueta",
                "font_postscript": "Enriqueta-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Ephesis",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ephesis-Regular.ttf"
                },
                "font_name": "Ephesis Regular",
                "font_family": "Ephesis",
                "font_postscript": "Ephesis-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Epilogue",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Epilogue-Regular.ttf"
                },
                "font_name": "Epilogue Regular",
                "font_family": "Epilogue",
                "font_postscript": "Epilogue-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Epilogue-Italic.ttf"
                },
                "font_name": "Epilogue Italic",
                "font_family": "Epilogue",
                "font_postscript": "Epilogue-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "EricaOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "EricaOne-Regular.ttf"
                },
                "font_name": "EricaOne Regular",
                "font_family": "EricaOne",
                "font_postscript": "EricaOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Esteban",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Esteban-Regular.ttf"
                },
                "font_name": "Esteban Regular",
                "font_family": "Esteban",
                "font_postscript": "Esteban-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Estonia",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Estonia-Regular.ttf"
                },
                "font_name": "Estonia Regular",
                "font_family": "Estonia",
                "font_postscript": "Estonia-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "EuphoriaScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "EuphoriaScript-Regular.ttf"
                },
                "font_name": "EuphoriaScript Regular",
                "font_family": "EuphoriaScript",
                "font_postscript": "EuphoriaScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Ewert",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ewert-Regular.ttf"
                },
                "font_name": "Ewert Regular",
                "font_family": "Ewert",
                "font_postscript": "Ewert-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Exo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Exo-Italic.ttf"
                },
                "font_name": "Exo Italic",
                "font_family": "Exo",
                "font_postscript": "Exo-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Exo-Regular.ttf"
                },
                "font_name": "Exo Regular",
                "font_family": "Exo",
                "font_postscript": "Exo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Exo2",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Exo2-Regular.ttf"
                },
                "font_name": "Exo2 Regular",
                "font_family": "Exo2",
                "font_postscript": "Exo2-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Exo2-Italic.ttf"
                },
                "font_name": "Exo2 Italic",
                "font_family": "Exo2",
                "font_postscript": "Exo2-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ExpletusSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ExpletusSans-Regular.ttf"
                },
                "font_name": "ExpletusSans Regular",
                "font_family": "ExpletusSans",
                "font_postscript": "ExpletusSans-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ExpletusSans-Italic.ttf"
                },
                "font_name": "ExpletusSans Italic",
                "font_family": "ExpletusSans",
                "font_postscript": "ExpletusSans-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Explora",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Explora-Regular.ttf"
                },
                "font_name": "Explora Regular",
                "font_family": "Explora",
                "font_postscript": "Explora-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Fahkwang",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fahkwang-Regular.ttf"
                },
                "font_name": "Fahkwang Regular",
                "font_family": "Fahkwang",
                "font_postscript": "Fahkwang-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fahkwang-Italic.ttf"
                },
                "font_name": "Fahkwang Italic",
                "font_family": "Fahkwang",
                "font_postscript": "Fahkwang-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fahkwang-SemiBold.ttf"
                },
                "font_name": "Fahkwang SemiBold",
                "font_family": "Fahkwang",
                "font_postscript": "Fahkwang-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fahkwang-Bold.ttf"
                },
                "font_name": "Fahkwang Bold",
                "font_family": "Fahkwang",
                "font_postscript": "Fahkwang-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fahkwang-Light.ttf"
                },
                "font_name": "Fahkwang Light",
                "font_family": "Fahkwang",
                "font_postscript": "Fahkwang-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fahkwang-BoldItalic.ttf"
                },
                "font_name": "Fahkwang BoldItalic",
                "font_family": "Fahkwang",
                "font_postscript": "Fahkwang-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fahkwang-MediumItalic.ttf"
                },
                "font_name": "Fahkwang MediumItalic",
                "font_family": "Fahkwang",
                "font_postscript": "Fahkwang-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fahkwang-ExtraLight.ttf"
                },
                "font_name": "Fahkwang ExtraLight",
                "font_family": "Fahkwang",
                "font_postscript": "Fahkwang-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fahkwang-ExtraLightItalic.ttf"
                },
                "font_name": "Fahkwang ExtraLightItalic",
                "font_family": "Fahkwang",
                "font_postscript": "Fahkwang-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fahkwang-Medium.ttf"
                },
                "font_name": "Fahkwang Medium",
                "font_family": "Fahkwang",
                "font_postscript": "Fahkwang-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fahkwang-SemiBoldItalic.ttf"
                },
                "font_name": "Fahkwang SemiBoldItalic",
                "font_family": "Fahkwang",
                "font_postscript": "Fahkwang-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fahkwang-LightItalic.ttf"
                },
                "font_name": "Fahkwang LightItalic",
                "font_family": "Fahkwang",
                "font_postscript": "Fahkwang-LightItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FamiljenGrotesk",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FamiljenGrotesk-Italic.ttf"
                },
                "font_name": "FamiljenGrotesk Italic",
                "font_family": "FamiljenGrotesk",
                "font_postscript": "FamiljenGrotesk-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FamiljenGrotesk-Regular.ttf"
                },
                "font_name": "FamiljenGrotesk Regular",
                "font_family": "FamiljenGrotesk",
                "font_postscript": "FamiljenGrotesk-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FanwoodText",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FanwoodText-Regular.ttf"
                },
                "font_name": "FanwoodText Regular",
                "font_family": "FanwoodText",
                "font_postscript": "FanwoodText-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FanwoodText-Italic.ttf"
                },
                "font_name": "FanwoodText Italic",
                "font_family": "FanwoodText",
                "font_postscript": "FanwoodText-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Farro",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Farro-Light.ttf"
                },
                "font_name": "Farro Light",
                "font_family": "Farro",
                "font_postscript": "Farro-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Farro-Bold.ttf"
                },
                "font_name": "Farro Bold",
                "font_family": "Farro",
                "font_postscript": "Farro-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Farro-Medium.ttf"
                },
                "font_name": "Farro Medium",
                "font_family": "Farro",
                "font_postscript": "Farro-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Farro-Regular.ttf"
                },
                "font_name": "Farro Regular",
                "font_family": "Farro",
                "font_postscript": "Farro-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Farsan",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Farsan-Regular.ttf"
                },
                "font_name": "Farsan Regular",
                "font_family": "Farsan",
                "font_postscript": "Farsan-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Fascinate",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fascinate-Regular.ttf"
                },
                "font_name": "Fascinate Regular",
                "font_family": "Fascinate",
                "font_postscript": "Fascinate-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FascinateInline",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FascinateInline-Regular.ttf"
                },
                "font_name": "FascinateInline Regular",
                "font_family": "FascinateInline",
                "font_postscript": "FascinateInline-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FasterOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FasterOne-Regular.ttf"
                },
                "font_name": "FasterOne Regular",
                "font_family": "FasterOne",
                "font_postscript": "FasterOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Fasthand",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fasthand-Regular.ttf"
                },
                "font_name": "Fasthand Regular",
                "font_family": "Fasthand",
                "font_postscript": "Fasthand-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FaunaOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FaunaOne-Regular.ttf"
                },
                "font_name": "FaunaOne Regular",
                "font_family": "FaunaOne",
                "font_postscript": "FaunaOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Faustina",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Faustina-Regular.ttf"
                },
                "font_name": "Faustina Regular",
                "font_family": "Faustina",
                "font_postscript": "Faustina-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Faustina-Italic.ttf"
                },
                "font_name": "Faustina Italic",
                "font_family": "Faustina",
                "font_postscript": "Faustina-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Federant",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Federant-Regular.ttf"
                },
                "font_name": "Federant Regular",
                "font_family": "Federant",
                "font_postscript": "Federant-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Federo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Federo-Regular.ttf"
                },
                "font_name": "Federo Regular",
                "font_family": "Federo",
                "font_postscript": "Federo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Felipa",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Felipa-Regular.ttf"
                },
                "font_name": "Felipa Regular",
                "font_family": "Felipa",
                "font_postscript": "Felipa-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Fenix",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fenix-Regular.ttf"
                },
                "font_name": "Fenix Regular",
                "font_family": "Fenix",
                "font_postscript": "Fenix-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Festive",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Festive-Regular.ttf"
                },
                "font_name": "Festive Regular",
                "font_family": "Festive",
                "font_postscript": "Festive-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Figtree",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Figtree-Italic.ttf"
                },
                "font_name": "Figtree Italic",
                "font_family": "Figtree",
                "font_postscript": "Figtree-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Figtree-Regular.ttf"
                },
                "font_name": "Figtree Regular",
                "font_family": "Figtree",
                "font_postscript": "Figtree-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FingerPaint",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FingerPaint-Regular.ttf"
                },
                "font_name": "FingerPaint Regular",
                "font_family": "FingerPaint",
                "font_postscript": "FingerPaint-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Finlandica",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Finlandica-Regular.ttf"
                },
                "font_name": "Finlandica Regular",
                "font_family": "Finlandica",
                "font_postscript": "Finlandica-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Finlandica-Italic.ttf"
                },
                "font_name": "Finlandica Italic",
                "font_family": "Finlandica",
                "font_postscript": "Finlandica-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FiraCode",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraCode-Regular.ttf"
                },
                "font_name": "FiraCode Regular",
                "font_family": "FiraCode",
                "font_postscript": "FiraCode-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FiraMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraMono-Medium.ttf"
                },
                "font_name": "FiraMono Medium",
                "font_family": "FiraMono",
                "font_postscript": "FiraMono-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraMono-Regular.ttf"
                },
                "font_name": "FiraMono Regular",
                "font_family": "FiraMono",
                "font_postscript": "FiraMono-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraMono-Bold.ttf"
                },
                "font_name": "FiraMono Bold",
                "font_family": "FiraMono",
                "font_postscript": "FiraMono-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FiraSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSans-Thin.ttf"
                },
                "font_name": "FiraSans Thin",
                "font_family": "FiraSans",
                "font_postscript": "FiraSans-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSans-ExtraLightItalic.ttf"
                },
                "font_name": "FiraSans ExtraLightItalic",
                "font_family": "FiraSans",
                "font_postscript": "FiraSans-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSans-LightItalic.ttf"
                },
                "font_name": "FiraSans LightItalic",
                "font_family": "FiraSans",
                "font_postscript": "FiraSans-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSans-Medium.ttf"
                },
                "font_name": "FiraSans Medium",
                "font_family": "FiraSans",
                "font_postscript": "FiraSans-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSans-BoldItalic.ttf"
                },
                "font_name": "FiraSans BoldItalic",
                "font_family": "FiraSans",
                "font_postscript": "FiraSans-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSans-Bold.ttf"
                },
                "font_name": "FiraSans Bold",
                "font_family": "FiraSans",
                "font_postscript": "FiraSans-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSans-ExtraLight.ttf"
                },
                "font_name": "FiraSans ExtraLight",
                "font_family": "FiraSans",
                "font_postscript": "FiraSans-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSans-ThinItalic.ttf"
                },
                "font_name": "FiraSans ThinItalic",
                "font_family": "FiraSans",
                "font_postscript": "FiraSans-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSans-MediumItalic.ttf"
                },
                "font_name": "FiraSans MediumItalic",
                "font_family": "FiraSans",
                "font_postscript": "FiraSans-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSans-BlackItalic.ttf"
                },
                "font_name": "FiraSans BlackItalic",
                "font_family": "FiraSans",
                "font_postscript": "FiraSans-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSans-Light.ttf"
                },
                "font_name": "FiraSans Light",
                "font_family": "FiraSans",
                "font_postscript": "FiraSans-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSans-Black.ttf"
                },
                "font_name": "FiraSans Black",
                "font_family": "FiraSans",
                "font_postscript": "FiraSans-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSans-ExtraBold.ttf"
                },
                "font_name": "FiraSans ExtraBold",
                "font_family": "FiraSans",
                "font_postscript": "FiraSans-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSans-SemiBold.ttf"
                },
                "font_name": "FiraSans SemiBold",
                "font_family": "FiraSans",
                "font_postscript": "FiraSans-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSans-SemiBoldItalic.ttf"
                },
                "font_name": "FiraSans SemiBoldItalic",
                "font_family": "FiraSans",
                "font_postscript": "FiraSans-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSans-ExtraBoldItalic.ttf"
                },
                "font_name": "FiraSans ExtraBoldItalic",
                "font_family": "FiraSans",
                "font_postscript": "FiraSans-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSans-Regular.ttf"
                },
                "font_name": "FiraSans Regular",
                "font_family": "FiraSans",
                "font_postscript": "FiraSans-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSans-Italic.ttf"
                },
                "font_name": "FiraSans Italic",
                "font_family": "FiraSans",
                "font_postscript": "FiraSans-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FiraSansCondensed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansCondensed-Black.ttf"
                },
                "font_name": "FiraSansCondensed Black",
                "font_family": "FiraSansCondensed",
                "font_postscript": "FiraSansCondensed-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansCondensed-Regular.ttf"
                },
                "font_name": "FiraSansCondensed Regular",
                "font_family": "FiraSansCondensed",
                "font_postscript": "FiraSansCondensed-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansCondensed-ExtraBold.ttf"
                },
                "font_name": "FiraSansCondensed ExtraBold",
                "font_family": "FiraSansCondensed",
                "font_postscript": "FiraSansCondensed-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansCondensed-Medium.ttf"
                },
                "font_name": "FiraSansCondensed Medium",
                "font_family": "FiraSansCondensed",
                "font_postscript": "FiraSansCondensed-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansCondensed-Bold.ttf"
                },
                "font_name": "FiraSansCondensed Bold",
                "font_family": "FiraSansCondensed",
                "font_postscript": "FiraSansCondensed-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansCondensed-Thin.ttf"
                },
                "font_name": "FiraSansCondensed Thin",
                "font_family": "FiraSansCondensed",
                "font_postscript": "FiraSansCondensed-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansCondensed-SemiBoldItalic.ttf"
                },
                "font_name": "FiraSansCondensed SemiBoldItalic",
                "font_family": "FiraSansCondensed",
                "font_postscript": "FiraSansCondensed-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansCondensed-ExtraLightItalic.ttf"
                },
                "font_name": "FiraSansCondensed ExtraLightItalic",
                "font_family": "FiraSansCondensed",
                "font_postscript": "FiraSansCondensed-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansCondensed-MediumItalic.ttf"
                },
                "font_name": "FiraSansCondensed MediumItalic",
                "font_family": "FiraSansCondensed",
                "font_postscript": "FiraSansCondensed-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansCondensed-LightItalic.ttf"
                },
                "font_name": "FiraSansCondensed LightItalic",
                "font_family": "FiraSansCondensed",
                "font_postscript": "FiraSansCondensed-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansCondensed-SemiBold.ttf"
                },
                "font_name": "FiraSansCondensed SemiBold",
                "font_family": "FiraSansCondensed",
                "font_postscript": "FiraSansCondensed-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansCondensed-ExtraBoldItalic.ttf"
                },
                "font_name": "FiraSansCondensed ExtraBoldItalic",
                "font_family": "FiraSansCondensed",
                "font_postscript": "FiraSansCondensed-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansCondensed-Light.ttf"
                },
                "font_name": "FiraSansCondensed Light",
                "font_family": "FiraSansCondensed",
                "font_postscript": "FiraSansCondensed-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansCondensed-BoldItalic.ttf"
                },
                "font_name": "FiraSansCondensed BoldItalic",
                "font_family": "FiraSansCondensed",
                "font_postscript": "FiraSansCondensed-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansCondensed-Italic.ttf"
                },
                "font_name": "FiraSansCondensed Italic",
                "font_family": "FiraSansCondensed",
                "font_postscript": "FiraSansCondensed-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansCondensed-ExtraLight.ttf"
                },
                "font_name": "FiraSansCondensed ExtraLight",
                "font_family": "FiraSansCondensed",
                "font_postscript": "FiraSansCondensed-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansCondensed-ThinItalic.ttf"
                },
                "font_name": "FiraSansCondensed ThinItalic",
                "font_family": "FiraSansCondensed",
                "font_postscript": "FiraSansCondensed-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansCondensed-BlackItalic.ttf"
                },
                "font_name": "FiraSansCondensed BlackItalic",
                "font_family": "FiraSansCondensed",
                "font_postscript": "FiraSansCondensed-BlackItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FiraSansExtraCondensed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansExtraCondensed-MediumItalic.ttf"
                },
                "font_name": "FiraSansExtraCondensed MediumItalic",
                "font_family": "FiraSansExtraCondensed",
                "font_postscript": "FiraSansExtraCondensed-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansExtraCondensed-Italic.ttf"
                },
                "font_name": "FiraSansExtraCondensed Italic",
                "font_family": "FiraSansExtraCondensed",
                "font_postscript": "FiraSansExtraCondensed-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansExtraCondensed-BlackItalic.ttf"
                },
                "font_name": "FiraSansExtraCondensed BlackItalic",
                "font_family": "FiraSansExtraCondensed",
                "font_postscript": "FiraSansExtraCondensed-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansExtraCondensed-Light.ttf"
                },
                "font_name": "FiraSansExtraCondensed Light",
                "font_family": "FiraSansExtraCondensed",
                "font_postscript": "FiraSansExtraCondensed-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansExtraCondensed-LightItalic.ttf"
                },
                "font_name": "FiraSansExtraCondensed LightItalic",
                "font_family": "FiraSansExtraCondensed",
                "font_postscript": "FiraSansExtraCondensed-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansExtraCondensed-BoldItalic.ttf"
                },
                "font_name": "FiraSansExtraCondensed BoldItalic",
                "font_family": "FiraSansExtraCondensed",
                "font_postscript": "FiraSansExtraCondensed-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansExtraCondensed-ThinItalic.ttf"
                },
                "font_name": "FiraSansExtraCondensed ThinItalic",
                "font_family": "FiraSansExtraCondensed",
                "font_postscript": "FiraSansExtraCondensed-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansExtraCondensed-ExtraLight.ttf"
                },
                "font_name": "FiraSansExtraCondensed ExtraLight",
                "font_family": "FiraSansExtraCondensed",
                "font_postscript": "FiraSansExtraCondensed-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansExtraCondensed-Medium.ttf"
                },
                "font_name": "FiraSansExtraCondensed Medium",
                "font_family": "FiraSansExtraCondensed",
                "font_postscript": "FiraSansExtraCondensed-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansExtraCondensed-Thin.ttf"
                },
                "font_name": "FiraSansExtraCondensed Thin",
                "font_family": "FiraSansExtraCondensed",
                "font_postscript": "FiraSansExtraCondensed-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansExtraCondensed-Black.ttf"
                },
                "font_name": "FiraSansExtraCondensed Black",
                "font_family": "FiraSansExtraCondensed",
                "font_postscript": "FiraSansExtraCondensed-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansExtraCondensed-Regular.ttf"
                },
                "font_name": "FiraSansExtraCondensed Regular",
                "font_family": "FiraSansExtraCondensed",
                "font_postscript": "FiraSansExtraCondensed-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansExtraCondensed-ExtraLightItalic.ttf"
                },
                "font_name": "FiraSansExtraCondensed ExtraLightItalic",
                "font_family": "FiraSansExtraCondensed",
                "font_postscript": "FiraSansExtraCondensed-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansExtraCondensed-SemiBoldItalic.ttf"
                },
                "font_name": "FiraSansExtraCondensed SemiBoldItalic",
                "font_family": "FiraSansExtraCondensed",
                "font_postscript": "FiraSansExtraCondensed-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansExtraCondensed-ExtraBold.ttf"
                },
                "font_name": "FiraSansExtraCondensed ExtraBold",
                "font_family": "FiraSansExtraCondensed",
                "font_postscript": "FiraSansExtraCondensed-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansExtraCondensed-Bold.ttf"
                },
                "font_name": "FiraSansExtraCondensed Bold",
                "font_family": "FiraSansExtraCondensed",
                "font_postscript": "FiraSansExtraCondensed-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansExtraCondensed-ExtraBoldItalic.ttf"
                },
                "font_name": "FiraSansExtraCondensed ExtraBoldItalic",
                "font_family": "FiraSansExtraCondensed",
                "font_postscript": "FiraSansExtraCondensed-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FiraSansExtraCondensed-SemiBold.ttf"
                },
                "font_name": "FiraSansExtraCondensed SemiBold",
                "font_family": "FiraSansExtraCondensed",
                "font_postscript": "FiraSansExtraCondensed-SemiBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FjallaOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FjallaOne-Regular.ttf"
                },
                "font_name": "FjallaOne Regular",
                "font_family": "FjallaOne",
                "font_postscript": "FjallaOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FjordOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FjordOne-Regular.ttf"
                },
                "font_name": "FjordOne Regular",
                "font_family": "FjordOne",
                "font_postscript": "FjordOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Flamenco",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Flamenco-Light.ttf"
                },
                "font_name": "Flamenco Light",
                "font_family": "Flamenco",
                "font_postscript": "Flamenco-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Flamenco-Regular.ttf"
                },
                "font_name": "Flamenco Regular",
                "font_family": "Flamenco",
                "font_postscript": "Flamenco-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Flavors",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Flavors-Regular.ttf"
                },
                "font_name": "Flavors Regular",
                "font_family": "Flavors",
                "font_postscript": "Flavors-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FleurDeLeah",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FleurDeLeah-Regular.ttf"
                },
                "font_name": "FleurDeLeah Regular",
                "font_family": "FleurDeLeah",
                "font_postscript": "FleurDeLeah-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FlowBlock",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FlowBlock-Regular.ttf"
                },
                "font_name": "FlowBlock Regular",
                "font_family": "FlowBlock",
                "font_postscript": "FlowBlock-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FlowCircular",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FlowCircular-Regular.ttf"
                },
                "font_name": "FlowCircular Regular",
                "font_family": "FlowCircular",
                "font_postscript": "FlowCircular-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FlowRounded",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FlowRounded-Regular.ttf"
                },
                "font_name": "FlowRounded Regular",
                "font_family": "FlowRounded",
                "font_postscript": "FlowRounded-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Foldit",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Foldit-Regular.ttf"
                },
                "font_name": "Foldit Regular",
                "font_family": "Foldit",
                "font_postscript": "Foldit-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Fondamento",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fondamento-Regular.ttf"
                },
                "font_name": "Fondamento Regular",
                "font_family": "Fondamento",
                "font_postscript": "Fondamento-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fondamento-Italic.ttf"
                },
                "font_name": "Fondamento Italic",
                "font_family": "Fondamento",
                "font_postscript": "Fondamento-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FontdinerSwanky",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FontdinerSwanky-Regular.ttf"
                },
                "font_name": "FontdinerSwanky Regular",
                "font_family": "FontdinerSwanky",
                "font_postscript": "FontdinerSwanky-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Forum",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Forum-Regular.ttf"
                },
                "font_name": "Forum Regular",
                "font_family": "Forum",
                "font_postscript": "Forum-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FragmentMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FragmentMono-Italic.ttf"
                },
                "font_name": "FragmentMono Italic",
                "font_family": "FragmentMono",
                "font_postscript": "FragmentMono-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FragmentMono-Regular.ttf"
                },
                "font_name": "FragmentMono Regular",
                "font_family": "FragmentMono",
                "font_postscript": "FragmentMono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FrancoisOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FrancoisOne-Regular.ttf"
                },
                "font_name": "FrancoisOne Regular",
                "font_family": "FrancoisOne",
                "font_postscript": "FrancoisOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FrankRuhlLibre",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FrankRuhlLibre-Regular.ttf"
                },
                "font_name": "FrankRuhlLibre Regular",
                "font_family": "FrankRuhlLibre",
                "font_postscript": "FrankRuhlLibre-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Fraunces",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fraunces-Regular.ttf"
                },
                "font_name": "Fraunces Regular",
                "font_family": "Fraunces",
                "font_postscript": "Fraunces-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fraunces-Italic.ttf"
                },
                "font_name": "Fraunces Italic",
                "font_family": "Fraunces",
                "font_postscript": "Fraunces-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FreckleFace",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FreckleFace-Regular.ttf"
                },
                "font_name": "FreckleFace Regular",
                "font_family": "FreckleFace",
                "font_postscript": "FreckleFace-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FrederickatheGreat",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FrederickatheGreat-Regular.ttf"
                },
                "font_name": "FrederickatheGreat Regular",
                "font_family": "FrederickatheGreat",
                "font_postscript": "FrederickatheGreat-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Fredoka",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fredoka-Regular.ttf"
                },
                "font_name": "Fredoka Regular",
                "font_family": "Fredoka",
                "font_postscript": "Fredoka-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Freehand",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Freehand-Regular.ttf"
                },
                "font_name": "Freehand Regular",
                "font_family": "Freehand",
                "font_postscript": "Freehand-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Freeman",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Freeman-Regular.ttf"
                },
                "font_name": "Freeman Regular",
                "font_family": "Freeman",
                "font_postscript": "Freeman-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Fresca",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fresca-Regular.ttf"
                },
                "font_name": "Fresca Regular",
                "font_family": "Fresca",
                "font_postscript": "Fresca-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Frijole",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Frijole-Regular.ttf"
                },
                "font_name": "Frijole Regular",
                "font_family": "Frijole",
                "font_postscript": "Frijole-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Fruktur",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fruktur-Regular.ttf"
                },
                "font_name": "Fruktur Regular",
                "font_family": "Fruktur",
                "font_postscript": "Fruktur-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fruktur-Italic.ttf"
                },
                "font_name": "Fruktur Italic",
                "font_family": "Fruktur",
                "font_postscript": "Fruktur-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FugazOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FugazOne-Regular.ttf"
                },
                "font_name": "FugazOne Regular",
                "font_family": "FugazOne",
                "font_postscript": "FugazOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Fuggles",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Fuggles-Regular.ttf"
                },
                "font_name": "Fuggles Regular",
                "font_family": "Fuggles",
                "font_postscript": "Fuggles-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "FuzzyBubbles",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "FuzzyBubbles-Bold.ttf"
                },
                "font_name": "FuzzyBubbles Bold",
                "font_family": "FuzzyBubbles",
                "font_postscript": "FuzzyBubbles-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "FuzzyBubbles-Regular.ttf"
                },
                "font_name": "FuzzyBubbles Regular",
                "font_family": "FuzzyBubbles",
                "font_postscript": "FuzzyBubbles-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GFSDidot",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GFSDidot-Regular.ttf"
                },
                "font_name": "GFSDidot Regular",
                "font_family": "GFSDidot",
                "font_postscript": "GFSDidot-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GFSNeohellenic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GFSNeohellenic-Regular.ttf"
                },
                "font_name": "GFSNeohellenic Regular",
                "font_family": "GFSNeohellenic",
                "font_postscript": "GFSNeohellenic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GFSNeohellenicBold",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GFSNeohellenicBold-Regular.ttf"
                },
                "font_name": "GFSNeohellenicBold Regular",
                "font_family": "GFSNeohellenicBold",
                "font_postscript": "GFSNeohellenicBold-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GFSNeohellenicBoldItalic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GFSNeohellenicBoldItalic-Regular.ttf"
                },
                "font_name": "GFSNeohellenicBoldItalic Regular",
                "font_family": "GFSNeohellenicBoldItalic",
                "font_postscript": "GFSNeohellenicBoldItalic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GFSNeohellenicItalic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GFSNeohellenicItalic-Regular.ttf"
                },
                "font_name": "GFSNeohellenicItalic Regular",
                "font_family": "GFSNeohellenicItalic",
                "font_postscript": "GFSNeohellenicItalic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Gabarito",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gabarito-Regular.ttf"
                },
                "font_name": "Gabarito Regular",
                "font_family": "Gabarito",
                "font_postscript": "Gabarito-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Gabriela",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gabriela-Regular.ttf"
                },
                "font_name": "Gabriela Regular",
                "font_family": "Gabriela",
                "font_postscript": "Gabriela-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Gaegu",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gaegu-Light.ttf"
                },
                "font_name": "Gaegu Light",
                "font_family": "Gaegu",
                "font_postscript": "Gaegu-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gaegu-Regular.ttf"
                },
                "font_name": "Gaegu Regular",
                "font_family": "Gaegu",
                "font_postscript": "Gaegu-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gaegu-Bold.ttf"
                },
                "font_name": "Gaegu Bold",
                "font_family": "Gaegu",
                "font_postscript": "Gaegu-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Gafata",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gafata-Regular.ttf"
                },
                "font_name": "Gafata Regular",
                "font_family": "Gafata",
                "font_postscript": "Gafata-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GajrajOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GajrajOne-Regular.ttf"
                },
                "font_name": "GajrajOne Regular",
                "font_family": "GajrajOne",
                "font_postscript": "GajrajOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Galada",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Galada-Regular.ttf"
                },
                "font_name": "Galada Regular",
                "font_family": "Galada",
                "font_postscript": "Galada-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Galdeano",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Galdeano-Regular.ttf"
                },
                "font_name": "Galdeano Regular",
                "font_family": "Galdeano",
                "font_postscript": "Galdeano-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Galindo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Galindo-Regular.ttf"
                },
                "font_name": "Galindo Regular",
                "font_family": "Galindo",
                "font_postscript": "Galindo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GamjaFlower",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GamjaFlower-Regular.ttf"
                },
                "font_name": "GamjaFlower Regular",
                "font_family": "GamjaFlower",
                "font_postscript": "GamjaFlower-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Gantari",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gantari-Regular.ttf"
                },
                "font_name": "Gantari Regular",
                "font_family": "Gantari",
                "font_postscript": "Gantari-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gantari-Italic.ttf"
                },
                "font_name": "Gantari Italic",
                "font_family": "Gantari",
                "font_postscript": "Gantari-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GasoekOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GasoekOne-Regular.ttf"
                },
                "font_name": "GasoekOne Regular",
                "font_family": "GasoekOne",
                "font_postscript": "GasoekOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Gayathri",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gayathri-Regular.ttf"
                },
                "font_name": "Gayathri Regular",
                "font_family": "Gayathri",
                "font_postscript": "Gayathri-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gayathri-Thin.ttf"
                },
                "font_name": "Gayathri Thin",
                "font_family": "Gayathri",
                "font_postscript": "Gayathri-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gayathri-Bold.ttf"
                },
                "font_name": "Gayathri Bold",
                "font_family": "Gayathri",
                "font_postscript": "Gayathri-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Gelasio",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gelasio-Regular.ttf"
                },
                "font_name": "Gelasio Regular",
                "font_family": "Gelasio",
                "font_postscript": "Gelasio-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gelasio-Italic.ttf"
                },
                "font_name": "Gelasio Italic",
                "font_family": "Gelasio",
                "font_postscript": "Gelasio-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GemunuLibre",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GemunuLibre-Regular.ttf"
                },
                "font_name": "GemunuLibre Regular",
                "font_family": "GemunuLibre",
                "font_postscript": "GemunuLibre-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Genos",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Genos-Italic.ttf"
                },
                "font_name": "Genos Italic",
                "font_family": "Genos",
                "font_postscript": "Genos-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Genos-Regular.ttf"
                },
                "font_name": "Genos Regular",
                "font_family": "Genos",
                "font_postscript": "Genos-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GentiumBookPlus",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GentiumBookPlus-BoldItalic.ttf"
                },
                "font_name": "GentiumBookPlus BoldItalic",
                "font_family": "GentiumBookPlus",
                "font_postscript": "GentiumBookPlus-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "GentiumBookPlus-Italic.ttf"
                },
                "font_name": "GentiumBookPlus Italic",
                "font_family": "GentiumBookPlus",
                "font_postscript": "GentiumBookPlus-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "GentiumBookPlus-Bold.ttf"
                },
                "font_name": "GentiumBookPlus Bold",
                "font_family": "GentiumBookPlus",
                "font_postscript": "GentiumBookPlus-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "GentiumBookPlus-Regular.ttf"
                },
                "font_name": "GentiumBookPlus Regular",
                "font_family": "GentiumBookPlus",
                "font_postscript": "GentiumBookPlus-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GentiumPlus",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GentiumPlus-Italic.ttf"
                },
                "font_name": "GentiumPlus Italic",
                "font_family": "GentiumPlus",
                "font_postscript": "GentiumPlus-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "GentiumPlus-BoldItalic.ttf"
                },
                "font_name": "GentiumPlus BoldItalic",
                "font_family": "GentiumPlus",
                "font_postscript": "GentiumPlus-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "GentiumPlus-Regular.ttf"
                },
                "font_name": "GentiumPlus Regular",
                "font_family": "GentiumPlus",
                "font_postscript": "GentiumPlus-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "GentiumPlus-Bold.ttf"
                },
                "font_name": "GentiumPlus Bold",
                "font_family": "GentiumPlus",
                "font_postscript": "GentiumPlus-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Geo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Geo-Regular.ttf"
                },
                "font_name": "Geo Regular",
                "font_family": "Geo",
                "font_postscript": "Geo-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Geo-Oblique.ttf"
                },
                "font_name": "Geo Oblique",
                "font_family": "Geo",
                "font_postscript": "Geo-Oblique",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Geologica",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Geologica-Regular.ttf"
                },
                "font_name": "Geologica Regular",
                "font_family": "Geologica",
                "font_postscript": "Geologica-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Georama",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Georama-Regular.ttf"
                },
                "font_name": "Georama Regular",
                "font_family": "Georama",
                "font_postscript": "Georama-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Georama-Italic.ttf"
                },
                "font_name": "Georama Italic",
                "font_family": "Georama",
                "font_postscript": "Georama-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Geostar",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Geostar-Regular.ttf"
                },
                "font_name": "Geostar Regular",
                "font_family": "Geostar",
                "font_postscript": "Geostar-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GeostarFill",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GeostarFill-Regular.ttf"
                },
                "font_name": "GeostarFill Regular",
                "font_family": "GeostarFill",
                "font_postscript": "GeostarFill-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GermaniaOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GermaniaOne-Regular.ttf"
                },
                "font_name": "GermaniaOne Regular",
                "font_family": "GermaniaOne",
                "font_postscript": "GermaniaOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GideonRoman",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GideonRoman-Regular.ttf"
                },
                "font_name": "GideonRoman Regular",
                "font_family": "GideonRoman",
                "font_postscript": "GideonRoman-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Gidugu",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gidugu-Regular.ttf"
                },
                "font_name": "Gidugu Regular",
                "font_family": "Gidugu",
                "font_postscript": "Gidugu-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GildaDisplay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GildaDisplay-Regular.ttf"
                },
                "font_name": "GildaDisplay Regular",
                "font_family": "GildaDisplay",
                "font_postscript": "GildaDisplay-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Girassol",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Girassol-Regular.ttf"
                },
                "font_name": "Girassol Regular",
                "font_family": "Girassol",
                "font_postscript": "Girassol-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GiveYouGlory",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GiveYouGlory-Regular.ttf"
                },
                "font_name": "GiveYouGlory Regular",
                "font_family": "GiveYouGlory",
                "font_postscript": "GiveYouGlory-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GlassAntiqua",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GlassAntiqua-Regular.ttf"
                },
                "font_name": "GlassAntiqua Regular",
                "font_family": "GlassAntiqua",
                "font_postscript": "GlassAntiqua-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Glegoo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Glegoo-Bold.ttf"
                },
                "font_name": "Glegoo Bold",
                "font_family": "Glegoo",
                "font_postscript": "Glegoo-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Glegoo-Regular.ttf"
                },
                "font_name": "Glegoo Regular",
                "font_family": "Glegoo",
                "font_postscript": "Glegoo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Gloock",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gloock-Regular.ttf"
                },
                "font_name": "Gloock Regular",
                "font_family": "Gloock",
                "font_postscript": "Gloock-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GloriaHallelujah",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GloriaHallelujah-Regular.ttf"
                },
                "font_name": "GloriaHallelujah Regular",
                "font_family": "GloriaHallelujah",
                "font_postscript": "GloriaHallelujah-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Glory",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Glory-Regular.ttf"
                },
                "font_name": "Glory Regular",
                "font_family": "Glory",
                "font_postscript": "Glory-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Glory-Italic.ttf"
                },
                "font_name": "Glory Italic",
                "font_family": "Glory",
                "font_postscript": "Glory-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Gluten",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gluten-Regular.ttf"
                },
                "font_name": "Gluten Regular",
                "font_family": "Gluten",
                "font_postscript": "Gluten-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GoblinOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GoblinOne-Regular.ttf"
                },
                "font_name": "GoblinOne Regular",
                "font_family": "GoblinOne",
                "font_postscript": "GoblinOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GochiHand",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GochiHand-Regular.ttf"
                },
                "font_name": "GochiHand Regular",
                "font_family": "GochiHand",
                "font_postscript": "GochiHand-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Goldman",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Goldman-Regular.ttf"
                },
                "font_name": "Goldman Regular",
                "font_family": "Goldman",
                "font_postscript": "Goldman-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Goldman-Bold.ttf"
                },
                "font_name": "Goldman Bold",
                "font_family": "Goldman",
                "font_postscript": "Goldman-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GolosText",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GolosText-Regular.ttf"
                },
                "font_name": "GolosText Regular",
                "font_family": "GolosText",
                "font_postscript": "GolosText-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Gorditas",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gorditas-Regular.ttf"
                },
                "font_name": "Gorditas Regular",
                "font_family": "Gorditas",
                "font_postscript": "Gorditas-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gorditas-Bold.ttf"
                },
                "font_name": "Gorditas Bold",
                "font_family": "Gorditas",
                "font_postscript": "Gorditas-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GothicA1",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GothicA1-ExtraLight.ttf"
                },
                "font_name": "GothicA1 ExtraLight",
                "font_family": "GothicA1",
                "font_postscript": "GothicA1-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "GothicA1-Regular.ttf"
                },
                "font_name": "GothicA1 Regular",
                "font_family": "GothicA1",
                "font_postscript": "GothicA1-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "GothicA1-Light.ttf"
                },
                "font_name": "GothicA1 Light",
                "font_family": "GothicA1",
                "font_postscript": "GothicA1-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "GothicA1-Thin.ttf"
                },
                "font_name": "GothicA1 Thin",
                "font_family": "GothicA1",
                "font_postscript": "GothicA1-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "GothicA1-Bold.ttf"
                },
                "font_name": "GothicA1 Bold",
                "font_family": "GothicA1",
                "font_postscript": "GothicA1-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "GothicA1-ExtraBold.ttf"
                },
                "font_name": "GothicA1 ExtraBold",
                "font_family": "GothicA1",
                "font_postscript": "GothicA1-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "GothicA1-Medium.ttf"
                },
                "font_name": "GothicA1 Medium",
                "font_family": "GothicA1",
                "font_postscript": "GothicA1-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "GothicA1-Black.ttf"
                },
                "font_name": "GothicA1 Black",
                "font_family": "GothicA1",
                "font_postscript": "GothicA1-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "GothicA1-SemiBold.ttf"
                },
                "font_name": "GothicA1 SemiBold",
                "font_family": "GothicA1",
                "font_postscript": "GothicA1-SemiBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Gotu",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gotu-Regular.ttf"
                },
                "font_name": "Gotu Regular",
                "font_family": "Gotu",
                "font_postscript": "Gotu-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GoudyBookletter1911",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GoudyBookletter1911-Regular.ttf"
                },
                "font_name": "GoudyBookletter1911 Regular",
                "font_family": "GoudyBookletter1911",
                "font_postscript": "GoudyBookletter1911-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GowunBatang",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GowunBatang-Bold.ttf"
                },
                "font_name": "GowunBatang Bold",
                "font_family": "GowunBatang",
                "font_postscript": "GowunBatang-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "GowunBatang-Regular.ttf"
                },
                "font_name": "GowunBatang Regular",
                "font_family": "GowunBatang",
                "font_postscript": "GowunBatang-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GowunDodum",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GowunDodum-Regular.ttf"
                },
                "font_name": "GowunDodum Regular",
                "font_family": "GowunDodum",
                "font_postscript": "GowunDodum-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Graduate",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Graduate-Regular.ttf"
                },
                "font_name": "Graduate Regular",
                "font_family": "Graduate",
                "font_postscript": "Graduate-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GrandHotel",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GrandHotel-Regular.ttf"
                },
                "font_name": "GrandHotel Regular",
                "font_family": "GrandHotel",
                "font_postscript": "GrandHotel-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GrandifloraOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GrandifloraOne-Regular.ttf"
                },
                "font_name": "GrandifloraOne Regular",
                "font_family": "GrandifloraOne",
                "font_postscript": "GrandifloraOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Grandstander",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Grandstander-Regular.ttf"
                },
                "font_name": "Grandstander Regular",
                "font_family": "Grandstander",
                "font_postscript": "Grandstander-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Grandstander-Italic.ttf"
                },
                "font_name": "Grandstander Italic",
                "font_family": "Grandstander",
                "font_postscript": "Grandstander-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GrapeNuts",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GrapeNuts-Regular.ttf"
                },
                "font_name": "GrapeNuts Regular",
                "font_family": "GrapeNuts",
                "font_postscript": "GrapeNuts-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GravitasOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GravitasOne-Regular.ttf"
                },
                "font_name": "GravitasOne Regular",
                "font_family": "GravitasOne",
                "font_postscript": "GravitasOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GreatVibes",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GreatVibes-Regular.ttf"
                },
                "font_name": "GreatVibes Regular",
                "font_family": "GreatVibes",
                "font_postscript": "GreatVibes-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GrechenFuemen",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GrechenFuemen-Regular.ttf"
                },
                "font_name": "GrechenFuemen Regular",
                "font_family": "GrechenFuemen",
                "font_postscript": "GrechenFuemen-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Grenze",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Grenze-ExtraLight.ttf"
                },
                "font_name": "Grenze ExtraLight",
                "font_family": "Grenze",
                "font_postscript": "Grenze-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Grenze-ThinItalic.ttf"
                },
                "font_name": "Grenze ThinItalic",
                "font_family": "Grenze",
                "font_postscript": "Grenze-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Grenze-BoldItalic.ttf"
                },
                "font_name": "Grenze BoldItalic",
                "font_family": "Grenze",
                "font_postscript": "Grenze-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Grenze-Light.ttf"
                },
                "font_name": "Grenze Light",
                "font_family": "Grenze",
                "font_postscript": "Grenze-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Grenze-ExtraLightItalic.ttf"
                },
                "font_name": "Grenze ExtraLightItalic",
                "font_family": "Grenze",
                "font_postscript": "Grenze-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Grenze-Medium.ttf"
                },
                "font_name": "Grenze Medium",
                "font_family": "Grenze",
                "font_postscript": "Grenze-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Grenze-Regular.ttf"
                },
                "font_name": "Grenze Regular",
                "font_family": "Grenze",
                "font_postscript": "Grenze-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Grenze-Italic.ttf"
                },
                "font_name": "Grenze Italic",
                "font_family": "Grenze",
                "font_postscript": "Grenze-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Grenze-LightItalic.ttf"
                },
                "font_name": "Grenze LightItalic",
                "font_family": "Grenze",
                "font_postscript": "Grenze-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Grenze-Thin.ttf"
                },
                "font_name": "Grenze Thin",
                "font_family": "Grenze",
                "font_postscript": "Grenze-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Grenze-SemiBoldItalic.ttf"
                },
                "font_name": "Grenze SemiBoldItalic",
                "font_family": "Grenze",
                "font_postscript": "Grenze-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Grenze-Bold.ttf"
                },
                "font_name": "Grenze Bold",
                "font_family": "Grenze",
                "font_postscript": "Grenze-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Grenze-Black.ttf"
                },
                "font_name": "Grenze Black",
                "font_family": "Grenze",
                "font_postscript": "Grenze-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Grenze-ExtraBold.ttf"
                },
                "font_name": "Grenze ExtraBold",
                "font_family": "Grenze",
                "font_postscript": "Grenze-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Grenze-BlackItalic.ttf"
                },
                "font_name": "Grenze BlackItalic",
                "font_family": "Grenze",
                "font_postscript": "Grenze-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Grenze-SemiBold.ttf"
                },
                "font_name": "Grenze SemiBold",
                "font_family": "Grenze",
                "font_postscript": "Grenze-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Grenze-ExtraBoldItalic.ttf"
                },
                "font_name": "Grenze ExtraBoldItalic",
                "font_family": "Grenze",
                "font_postscript": "Grenze-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Grenze-MediumItalic.ttf"
                },
                "font_name": "Grenze MediumItalic",
                "font_family": "Grenze",
                "font_postscript": "Grenze-MediumItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GrenzeGotisch",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GrenzeGotisch-Regular.ttf"
                },
                "font_name": "GrenzeGotisch Regular",
                "font_family": "GrenzeGotisch",
                "font_postscript": "GrenzeGotisch-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "GreyQo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "GreyQo-Regular.ttf"
                },
                "font_name": "GreyQo Regular",
                "font_family": "GreyQo",
                "font_postscript": "GreyQo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Griffy",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Griffy-Regular.ttf"
                },
                "font_name": "Griffy Regular",
                "font_family": "Griffy",
                "font_postscript": "Griffy-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Gruppo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gruppo-Regular.ttf"
                },
                "font_name": "Gruppo Regular",
                "font_family": "Gruppo",
                "font_postscript": "Gruppo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Gudea",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gudea-Italic.ttf"
                },
                "font_name": "Gudea Italic",
                "font_family": "Gudea",
                "font_postscript": "Gudea-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gudea-Regular.ttf"
                },
                "font_name": "Gudea Regular",
                "font_family": "Gudea",
                "font_postscript": "Gudea-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gudea-Bold.ttf"
                },
                "font_name": "Gudea Bold",
                "font_family": "Gudea",
                "font_postscript": "Gudea-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Gugi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gugi-Regular.ttf"
                },
                "font_name": "Gugi Regular",
                "font_family": "Gugi",
                "font_postscript": "Gugi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Gulzar",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gulzar-Regular.ttf"
                },
                "font_name": "Gulzar Regular",
                "font_family": "Gulzar",
                "font_postscript": "Gulzar-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Gupter",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gupter-Medium.ttf"
                },
                "font_name": "Gupter Medium",
                "font_family": "Gupter",
                "font_postscript": "Gupter-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gupter-Bold.ttf"
                },
                "font_name": "Gupter Bold",
                "font_family": "Gupter",
                "font_postscript": "Gupter-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gupter-Regular.ttf"
                },
                "font_name": "Gupter Regular",
                "font_family": "Gupter",
                "font_postscript": "Gupter-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Gurajada",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gurajada-Regular.ttf"
                },
                "font_name": "Gurajada Regular",
                "font_family": "Gurajada",
                "font_postscript": "Gurajada-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Gwendolyn",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gwendolyn-Bold.ttf"
                },
                "font_name": "Gwendolyn Bold",
                "font_family": "Gwendolyn",
                "font_postscript": "Gwendolyn-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Gwendolyn-Regular.ttf"
                },
                "font_name": "Gwendolyn Regular",
                "font_family": "Gwendolyn",
                "font_postscript": "Gwendolyn-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Habibi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Habibi-Regular.ttf"
                },
                "font_name": "Habibi Regular",
                "font_family": "Habibi",
                "font_postscript": "Habibi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HachiMaruPop",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HachiMaruPop-Regular.ttf"
                },
                "font_name": "HachiMaruPop Regular",
                "font_family": "HachiMaruPop",
                "font_postscript": "HachiMaruPop-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Hahmlet",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Hahmlet-Regular.ttf"
                },
                "font_name": "Hahmlet Regular",
                "font_family": "Hahmlet",
                "font_postscript": "Hahmlet-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Halant",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Halant-Regular.ttf"
                },
                "font_name": "Halant Regular",
                "font_family": "Halant",
                "font_postscript": "Halant-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Halant-Bold.ttf"
                },
                "font_name": "Halant Bold",
                "font_family": "Halant",
                "font_postscript": "Halant-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Halant-SemiBold.ttf"
                },
                "font_name": "Halant SemiBold",
                "font_family": "Halant",
                "font_postscript": "Halant-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Halant-Light.ttf"
                },
                "font_name": "Halant Light",
                "font_family": "Halant",
                "font_postscript": "Halant-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Halant-Medium.ttf"
                },
                "font_name": "Halant Medium",
                "font_family": "Halant",
                "font_postscript": "Halant-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HammersmithOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HammersmithOne-Regular.ttf"
                },
                "font_name": "HammersmithOne Regular",
                "font_family": "HammersmithOne",
                "font_postscript": "HammersmithOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Hanalei",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Hanalei-Regular.ttf"
                },
                "font_name": "Hanalei Regular",
                "font_family": "Hanalei",
                "font_postscript": "Hanalei-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HanaleiFill",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HanaleiFill-Regular.ttf"
                },
                "font_name": "HanaleiFill Regular",
                "font_family": "HanaleiFill",
                "font_postscript": "HanaleiFill-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Handjet",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Handjet-Regular.ttf"
                },
                "font_name": "Handjet Regular",
                "font_family": "Handjet",
                "font_postscript": "Handjet-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Handlee",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Handlee-Regular.ttf"
                },
                "font_name": "Handlee Regular",
                "font_family": "Handlee",
                "font_postscript": "Handlee-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HankenGrotesk",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HankenGrotesk-Italic.ttf"
                },
                "font_name": "HankenGrotesk Italic",
                "font_family": "HankenGrotesk",
                "font_postscript": "HankenGrotesk-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HankenGrotesk-Regular.ttf"
                },
                "font_name": "HankenGrotesk Regular",
                "font_family": "HankenGrotesk",
                "font_postscript": "HankenGrotesk-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Hannari",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Hannari-Regular.ttf"
                },
                "font_name": "Hannari Regular",
                "font_family": "Hannari",
                "font_postscript": "Hannari-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Hanuman",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Hanuman-Regular.ttf"
                },
                "font_name": "Hanuman Regular",
                "font_family": "Hanuman",
                "font_postscript": "Hanuman-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Hanuman-Black.ttf"
                },
                "font_name": "Hanuman Black",
                "font_family": "Hanuman",
                "font_postscript": "Hanuman-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Hanuman-Light.ttf"
                },
                "font_name": "Hanuman Light",
                "font_family": "Hanuman",
                "font_postscript": "Hanuman-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Hanuman-Bold.ttf"
                },
                "font_name": "Hanuman Bold",
                "font_family": "Hanuman",
                "font_postscript": "Hanuman-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Hanuman-Thin.ttf"
                },
                "font_name": "Hanuman Thin",
                "font_family": "Hanuman",
                "font_postscript": "Hanuman-Thin",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HappyMonkey",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HappyMonkey-Regular.ttf"
                },
                "font_name": "HappyMonkey Regular",
                "font_family": "HappyMonkey",
                "font_postscript": "HappyMonkey-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Harmattan",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Harmattan-SemiBold.ttf"
                },
                "font_name": "Harmattan SemiBold",
                "font_family": "Harmattan",
                "font_postscript": "Harmattan-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Harmattan-Regular.ttf"
                },
                "font_name": "Harmattan Regular",
                "font_family": "Harmattan",
                "font_postscript": "Harmattan-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Harmattan-Bold.ttf"
                },
                "font_name": "Harmattan Bold",
                "font_family": "Harmattan",
                "font_postscript": "Harmattan-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Harmattan-Medium.ttf"
                },
                "font_name": "Harmattan Medium",
                "font_family": "Harmattan",
                "font_postscript": "Harmattan-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HeadlandOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HeadlandOne-Regular.ttf"
                },
                "font_name": "HeadlandOne Regular",
                "font_family": "HeadlandOne",
                "font_postscript": "HeadlandOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HedvigLettersSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HedvigLettersSans-Regular.ttf"
                },
                "font_name": "HedvigLettersSans Regular",
                "font_family": "HedvigLettersSans",
                "font_postscript": "HedvigLettersSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HedvigLettersSerif",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HedvigLettersSerif-Regular.ttf"
                },
                "font_name": "HedvigLettersSerif Regular",
                "font_family": "HedvigLettersSerif",
                "font_postscript": "HedvigLettersSerif-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Heebo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Heebo-Regular.ttf"
                },
                "font_name": "Heebo Regular",
                "font_family": "Heebo",
                "font_postscript": "Heebo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HennyPenny",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HennyPenny-Regular.ttf"
                },
                "font_name": "HennyPenny Regular",
                "font_family": "HennyPenny",
                "font_postscript": "HennyPenny-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HeptaSlab",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HeptaSlab-Regular.ttf"
                },
                "font_name": "HeptaSlab Regular",
                "font_family": "HeptaSlab",
                "font_postscript": "HeptaSlab-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HermeneusOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HermeneusOne-Regular.ttf"
                },
                "font_name": "HermeneusOne Regular",
                "font_family": "HermeneusOne",
                "font_postscript": "HermeneusOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HerrVonMuellerhoff",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HerrVonMuellerhoff-Regular.ttf"
                },
                "font_name": "HerrVonMuellerhoff Regular",
                "font_family": "HerrVonMuellerhoff",
                "font_postscript": "HerrVonMuellerhoff-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HiMelody",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HiMelody-Regular.ttf"
                },
                "font_name": "HiMelody Regular",
                "font_family": "HiMelody",
                "font_postscript": "HiMelody-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HinaMincho",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HinaMincho-Regular.ttf"
                },
                "font_name": "HinaMincho Regular",
                "font_family": "HinaMincho",
                "font_postscript": "HinaMincho-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Hind",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Hind-Light.ttf"
                },
                "font_name": "Hind Light",
                "font_family": "Hind",
                "font_postscript": "Hind-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Hind-Bold.ttf"
                },
                "font_name": "Hind Bold",
                "font_family": "Hind",
                "font_postscript": "Hind-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Hind-SemiBold.ttf"
                },
                "font_name": "Hind SemiBold",
                "font_family": "Hind",
                "font_postscript": "Hind-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Hind-Regular.ttf"
                },
                "font_name": "Hind Regular",
                "font_family": "Hind",
                "font_postscript": "Hind-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Hind-Medium.ttf"
                },
                "font_name": "Hind Medium",
                "font_family": "Hind",
                "font_postscript": "Hind-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HindColombo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindColombo-Bold.ttf"
                },
                "font_name": "HindColombo Bold",
                "font_family": "HindColombo",
                "font_postscript": "HindColombo-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindColombo-Light.ttf"
                },
                "font_name": "HindColombo Light",
                "font_family": "HindColombo",
                "font_postscript": "HindColombo-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindColombo-SemiBold.ttf"
                },
                "font_name": "HindColombo SemiBold",
                "font_family": "HindColombo",
                "font_postscript": "HindColombo-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindColombo-Medium.ttf"
                },
                "font_name": "HindColombo Medium",
                "font_family": "HindColombo",
                "font_postscript": "HindColombo-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindColombo-Regular.ttf"
                },
                "font_name": "HindColombo Regular",
                "font_family": "HindColombo",
                "font_postscript": "HindColombo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HindGuntur",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindGuntur-SemiBold.ttf"
                },
                "font_name": "HindGuntur SemiBold",
                "font_family": "HindGuntur",
                "font_postscript": "HindGuntur-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindGuntur-Light.ttf"
                },
                "font_name": "HindGuntur Light",
                "font_family": "HindGuntur",
                "font_postscript": "HindGuntur-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindGuntur-Bold.ttf"
                },
                "font_name": "HindGuntur Bold",
                "font_family": "HindGuntur",
                "font_postscript": "HindGuntur-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindGuntur-Regular.ttf"
                },
                "font_name": "HindGuntur Regular",
                "font_family": "HindGuntur",
                "font_postscript": "HindGuntur-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindGuntur-Medium.ttf"
                },
                "font_name": "HindGuntur Medium",
                "font_family": "HindGuntur",
                "font_postscript": "HindGuntur-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HindJalandhar",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindJalandhar-Bold.ttf"
                },
                "font_name": "HindJalandhar Bold",
                "font_family": "HindJalandhar",
                "font_postscript": "HindJalandhar-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindJalandhar-Medium.ttf"
                },
                "font_name": "HindJalandhar Medium",
                "font_family": "HindJalandhar",
                "font_postscript": "HindJalandhar-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindJalandhar-SemiBold.ttf"
                },
                "font_name": "HindJalandhar SemiBold",
                "font_family": "HindJalandhar",
                "font_postscript": "HindJalandhar-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindJalandhar-Light.ttf"
                },
                "font_name": "HindJalandhar Light",
                "font_family": "HindJalandhar",
                "font_postscript": "HindJalandhar-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindJalandhar-Regular.ttf"
                },
                "font_name": "HindJalandhar Regular",
                "font_family": "HindJalandhar",
                "font_postscript": "HindJalandhar-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HindKochi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindKochi-SemiBold.ttf"
                },
                "font_name": "HindKochi SemiBold",
                "font_family": "HindKochi",
                "font_postscript": "HindKochi-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindKochi-Medium.ttf"
                },
                "font_name": "HindKochi Medium",
                "font_family": "HindKochi",
                "font_postscript": "HindKochi-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindKochi-Regular.ttf"
                },
                "font_name": "HindKochi Regular",
                "font_family": "HindKochi",
                "font_postscript": "HindKochi-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindKochi-Bold.ttf"
                },
                "font_name": "HindKochi Bold",
                "font_family": "HindKochi",
                "font_postscript": "HindKochi-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindKochi-Light.ttf"
                },
                "font_name": "HindKochi Light",
                "font_family": "HindKochi",
                "font_postscript": "HindKochi-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HindMadurai",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindMadurai-Regular.ttf"
                },
                "font_name": "HindMadurai Regular",
                "font_family": "HindMadurai",
                "font_postscript": "HindMadurai-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindMadurai-SemiBold.ttf"
                },
                "font_name": "HindMadurai SemiBold",
                "font_family": "HindMadurai",
                "font_postscript": "HindMadurai-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindMadurai-Light.ttf"
                },
                "font_name": "HindMadurai Light",
                "font_family": "HindMadurai",
                "font_postscript": "HindMadurai-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindMadurai-Bold.ttf"
                },
                "font_name": "HindMadurai Bold",
                "font_family": "HindMadurai",
                "font_postscript": "HindMadurai-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindMadurai-Medium.ttf"
                },
                "font_name": "HindMadurai Medium",
                "font_family": "HindMadurai",
                "font_postscript": "HindMadurai-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HindMysuru",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindMysuru-SemiBold.ttf"
                },
                "font_name": "HindMysuru SemiBold",
                "font_family": "HindMysuru",
                "font_postscript": "HindMysuru-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindMysuru-Medium.ttf"
                },
                "font_name": "HindMysuru Medium",
                "font_family": "HindMysuru",
                "font_postscript": "HindMysuru-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindMysuru-Regular.ttf"
                },
                "font_name": "HindMysuru Regular",
                "font_family": "HindMysuru",
                "font_postscript": "HindMysuru-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindMysuru-Light.ttf"
                },
                "font_name": "HindMysuru Light",
                "font_family": "HindMysuru",
                "font_postscript": "HindMysuru-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindMysuru-Bold.ttf"
                },
                "font_name": "HindMysuru Bold",
                "font_family": "HindMysuru",
                "font_postscript": "HindMysuru-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HindSiliguri",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindSiliguri-Medium.ttf"
                },
                "font_name": "HindSiliguri Medium",
                "font_family": "HindSiliguri",
                "font_postscript": "HindSiliguri-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindSiliguri-Regular.ttf"
                },
                "font_name": "HindSiliguri Regular",
                "font_family": "HindSiliguri",
                "font_postscript": "HindSiliguri-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindSiliguri-SemiBold.ttf"
                },
                "font_name": "HindSiliguri SemiBold",
                "font_family": "HindSiliguri",
                "font_postscript": "HindSiliguri-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindSiliguri-Light.ttf"
                },
                "font_name": "HindSiliguri Light",
                "font_family": "HindSiliguri",
                "font_postscript": "HindSiliguri-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindSiliguri-Bold.ttf"
                },
                "font_name": "HindSiliguri Bold",
                "font_family": "HindSiliguri",
                "font_postscript": "HindSiliguri-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HindVadodara",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindVadodara-Light.ttf"
                },
                "font_name": "HindVadodara Light",
                "font_family": "HindVadodara",
                "font_postscript": "HindVadodara-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindVadodara-Bold.ttf"
                },
                "font_name": "HindVadodara Bold",
                "font_family": "HindVadodara",
                "font_postscript": "HindVadodara-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindVadodara-Regular.ttf"
                },
                "font_name": "HindVadodara Regular",
                "font_family": "HindVadodara",
                "font_postscript": "HindVadodara-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindVadodara-SemiBold.ttf"
                },
                "font_name": "HindVadodara SemiBold",
                "font_family": "HindVadodara",
                "font_postscript": "HindVadodara-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "HindVadodara-Medium.ttf"
                },
                "font_name": "HindVadodara Medium",
                "font_family": "HindVadodara",
                "font_postscript": "HindVadodara-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HoltwoodOneSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HoltwoodOneSC-Regular.ttf"
                },
                "font_name": "HoltwoodOneSC Regular",
                "font_family": "HoltwoodOneSC",
                "font_postscript": "HoltwoodOneSC-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "HomemadeApple",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "HomemadeApple-Regular.ttf"
                },
                "font_name": "HomemadeApple Regular",
                "font_family": "HomemadeApple",
                "font_postscript": "HomemadeApple-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Homenaje",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Homenaje-Regular.ttf"
                },
                "font_name": "Homenaje Regular",
                "font_family": "Homenaje",
                "font_postscript": "Homenaje-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Honk",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Honk-Regular.ttf"
                },
                "font_name": "Honk Regular",
                "font_family": "Honk",
                "font_postscript": "Honk-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Hubballi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Hubballi-Regular.ttf"
                },
                "font_name": "Hubballi Regular",
                "font_family": "Hubballi",
                "font_postscript": "Hubballi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Hurricane",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Hurricane-Regular.ttf"
                },
                "font_name": "Hurricane Regular",
                "font_family": "Hurricane",
                "font_postscript": "Hurricane-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IBMPlexMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexMono-Thin.ttf"
                },
                "font_name": "IBMPlexMono Thin",
                "font_family": "IBMPlexMono",
                "font_postscript": "IBMPlexMono-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexMono-Bold.ttf"
                },
                "font_name": "IBMPlexMono Bold",
                "font_family": "IBMPlexMono",
                "font_postscript": "IBMPlexMono-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexMono-MediumItalic.ttf"
                },
                "font_name": "IBMPlexMono MediumItalic",
                "font_family": "IBMPlexMono",
                "font_postscript": "IBMPlexMono-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexMono-BoldItalic.ttf"
                },
                "font_name": "IBMPlexMono BoldItalic",
                "font_family": "IBMPlexMono",
                "font_postscript": "IBMPlexMono-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexMono-Medium.ttf"
                },
                "font_name": "IBMPlexMono Medium",
                "font_family": "IBMPlexMono",
                "font_postscript": "IBMPlexMono-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexMono-ExtraLight.ttf"
                },
                "font_name": "IBMPlexMono ExtraLight",
                "font_family": "IBMPlexMono",
                "font_postscript": "IBMPlexMono-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexMono-ExtraLightItalic.ttf"
                },
                "font_name": "IBMPlexMono ExtraLightItalic",
                "font_family": "IBMPlexMono",
                "font_postscript": "IBMPlexMono-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexMono-ThinItalic.ttf"
                },
                "font_name": "IBMPlexMono ThinItalic",
                "font_family": "IBMPlexMono",
                "font_postscript": "IBMPlexMono-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexMono-Regular.ttf"
                },
                "font_name": "IBMPlexMono Regular",
                "font_family": "IBMPlexMono",
                "font_postscript": "IBMPlexMono-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexMono-Light.ttf"
                },
                "font_name": "IBMPlexMono Light",
                "font_family": "IBMPlexMono",
                "font_postscript": "IBMPlexMono-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexMono-Italic.ttf"
                },
                "font_name": "IBMPlexMono Italic",
                "font_family": "IBMPlexMono",
                "font_postscript": "IBMPlexMono-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexMono-SemiBold.ttf"
                },
                "font_name": "IBMPlexMono SemiBold",
                "font_family": "IBMPlexMono",
                "font_postscript": "IBMPlexMono-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexMono-SemiBoldItalic.ttf"
                },
                "font_name": "IBMPlexMono SemiBoldItalic",
                "font_family": "IBMPlexMono",
                "font_postscript": "IBMPlexMono-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexMono-LightItalic.ttf"
                },
                "font_name": "IBMPlexMono LightItalic",
                "font_family": "IBMPlexMono",
                "font_postscript": "IBMPlexMono-LightItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IBMPlexSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSans-SemiBoldItalic.ttf"
                },
                "font_name": "IBMPlexSans SemiBoldItalic",
                "font_family": "IBMPlexSans",
                "font_postscript": "IBMPlexSans-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSans-Medium.ttf"
                },
                "font_name": "IBMPlexSans Medium",
                "font_family": "IBMPlexSans",
                "font_postscript": "IBMPlexSans-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSans-SemiBold.ttf"
                },
                "font_name": "IBMPlexSans SemiBold",
                "font_family": "IBMPlexSans",
                "font_postscript": "IBMPlexSans-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSans-MediumItalic.ttf"
                },
                "font_name": "IBMPlexSans MediumItalic",
                "font_family": "IBMPlexSans",
                "font_postscript": "IBMPlexSans-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSans-LightItalic.ttf"
                },
                "font_name": "IBMPlexSans LightItalic",
                "font_family": "IBMPlexSans",
                "font_postscript": "IBMPlexSans-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSans-ExtraLight.ttf"
                },
                "font_name": "IBMPlexSans ExtraLight",
                "font_family": "IBMPlexSans",
                "font_postscript": "IBMPlexSans-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSans-Thin.ttf"
                },
                "font_name": "IBMPlexSans Thin",
                "font_family": "IBMPlexSans",
                "font_postscript": "IBMPlexSans-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSans-ThinItalic.ttf"
                },
                "font_name": "IBMPlexSans ThinItalic",
                "font_family": "IBMPlexSans",
                "font_postscript": "IBMPlexSans-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSans-BoldItalic.ttf"
                },
                "font_name": "IBMPlexSans BoldItalic",
                "font_family": "IBMPlexSans",
                "font_postscript": "IBMPlexSans-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSans-Light.ttf"
                },
                "font_name": "IBMPlexSans Light",
                "font_family": "IBMPlexSans",
                "font_postscript": "IBMPlexSans-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSans-Italic.ttf"
                },
                "font_name": "IBMPlexSans Italic",
                "font_family": "IBMPlexSans",
                "font_postscript": "IBMPlexSans-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSans-Regular.ttf"
                },
                "font_name": "IBMPlexSans Regular",
                "font_family": "IBMPlexSans",
                "font_postscript": "IBMPlexSans-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSans-ExtraLightItalic.ttf"
                },
                "font_name": "IBMPlexSans ExtraLightItalic",
                "font_family": "IBMPlexSans",
                "font_postscript": "IBMPlexSans-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSans-Bold.ttf"
                },
                "font_name": "IBMPlexSans Bold",
                "font_family": "IBMPlexSans",
                "font_postscript": "IBMPlexSans-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IBMPlexSansArabic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansArabic-SemiBold.ttf"
                },
                "font_name": "IBMPlexSansArabic SemiBold",
                "font_family": "IBMPlexSansArabic",
                "font_postscript": "IBMPlexSansArabic-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansArabic-Regular.ttf"
                },
                "font_name": "IBMPlexSansArabic Regular",
                "font_family": "IBMPlexSansArabic",
                "font_postscript": "IBMPlexSansArabic-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansArabic-Light.ttf"
                },
                "font_name": "IBMPlexSansArabic Light",
                "font_family": "IBMPlexSansArabic",
                "font_postscript": "IBMPlexSansArabic-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansArabic-ExtraLight.ttf"
                },
                "font_name": "IBMPlexSansArabic ExtraLight",
                "font_family": "IBMPlexSansArabic",
                "font_postscript": "IBMPlexSansArabic-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansArabic-Bold.ttf"
                },
                "font_name": "IBMPlexSansArabic Bold",
                "font_family": "IBMPlexSansArabic",
                "font_postscript": "IBMPlexSansArabic-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansArabic-Thin.ttf"
                },
                "font_name": "IBMPlexSansArabic Thin",
                "font_family": "IBMPlexSansArabic",
                "font_postscript": "IBMPlexSansArabic-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansArabic-Medium.ttf"
                },
                "font_name": "IBMPlexSansArabic Medium",
                "font_family": "IBMPlexSansArabic",
                "font_postscript": "IBMPlexSansArabic-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IBMPlexSansCondensed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansCondensed-MediumItalic.ttf"
                },
                "font_name": "IBMPlexSansCondensed MediumItalic",
                "font_family": "IBMPlexSansCondensed",
                "font_postscript": "IBMPlexSansCondensed-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansCondensed-Italic.ttf"
                },
                "font_name": "IBMPlexSansCondensed Italic",
                "font_family": "IBMPlexSansCondensed",
                "font_postscript": "IBMPlexSansCondensed-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansCondensed-Bold.ttf"
                },
                "font_name": "IBMPlexSansCondensed Bold",
                "font_family": "IBMPlexSansCondensed",
                "font_postscript": "IBMPlexSansCondensed-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansCondensed-Thin.ttf"
                },
                "font_name": "IBMPlexSansCondensed Thin",
                "font_family": "IBMPlexSansCondensed",
                "font_postscript": "IBMPlexSansCondensed-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansCondensed-Light.ttf"
                },
                "font_name": "IBMPlexSansCondensed Light",
                "font_family": "IBMPlexSansCondensed",
                "font_postscript": "IBMPlexSansCondensed-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansCondensed-LightItalic.ttf"
                },
                "font_name": "IBMPlexSansCondensed LightItalic",
                "font_family": "IBMPlexSansCondensed",
                "font_postscript": "IBMPlexSansCondensed-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansCondensed-ExtraLight.ttf"
                },
                "font_name": "IBMPlexSansCondensed ExtraLight",
                "font_family": "IBMPlexSansCondensed",
                "font_postscript": "IBMPlexSansCondensed-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansCondensed-ThinItalic.ttf"
                },
                "font_name": "IBMPlexSansCondensed ThinItalic",
                "font_family": "IBMPlexSansCondensed",
                "font_postscript": "IBMPlexSansCondensed-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansCondensed-BoldItalic.ttf"
                },
                "font_name": "IBMPlexSansCondensed BoldItalic",
                "font_family": "IBMPlexSansCondensed",
                "font_postscript": "IBMPlexSansCondensed-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansCondensed-SemiBoldItalic.ttf"
                },
                "font_name": "IBMPlexSansCondensed SemiBoldItalic",
                "font_family": "IBMPlexSansCondensed",
                "font_postscript": "IBMPlexSansCondensed-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansCondensed-ExtraLightItalic.ttf"
                },
                "font_name": "IBMPlexSansCondensed ExtraLightItalic",
                "font_family": "IBMPlexSansCondensed",
                "font_postscript": "IBMPlexSansCondensed-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansCondensed-Regular.ttf"
                },
                "font_name": "IBMPlexSansCondensed Regular",
                "font_family": "IBMPlexSansCondensed",
                "font_postscript": "IBMPlexSansCondensed-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansCondensed-SemiBold.ttf"
                },
                "font_name": "IBMPlexSansCondensed SemiBold",
                "font_family": "IBMPlexSansCondensed",
                "font_postscript": "IBMPlexSansCondensed-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansCondensed-Medium.ttf"
                },
                "font_name": "IBMPlexSansCondensed Medium",
                "font_family": "IBMPlexSansCondensed",
                "font_postscript": "IBMPlexSansCondensed-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IBMPlexSansDevanagari",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansDevanagari-Medium.ttf"
                },
                "font_name": "IBMPlexSansDevanagari Medium",
                "font_family": "IBMPlexSansDevanagari",
                "font_postscript": "IBMPlexSansDevanagari-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansDevanagari-ExtraLight.ttf"
                },
                "font_name": "IBMPlexSansDevanagari ExtraLight",
                "font_family": "IBMPlexSansDevanagari",
                "font_postscript": "IBMPlexSansDevanagari-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansDevanagari-Regular.ttf"
                },
                "font_name": "IBMPlexSansDevanagari Regular",
                "font_family": "IBMPlexSansDevanagari",
                "font_postscript": "IBMPlexSansDevanagari-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansDevanagari-Bold.ttf"
                },
                "font_name": "IBMPlexSansDevanagari Bold",
                "font_family": "IBMPlexSansDevanagari",
                "font_postscript": "IBMPlexSansDevanagari-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansDevanagari-Thin.ttf"
                },
                "font_name": "IBMPlexSansDevanagari Thin",
                "font_family": "IBMPlexSansDevanagari",
                "font_postscript": "IBMPlexSansDevanagari-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansDevanagari-SemiBold.ttf"
                },
                "font_name": "IBMPlexSansDevanagari SemiBold",
                "font_family": "IBMPlexSansDevanagari",
                "font_postscript": "IBMPlexSansDevanagari-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansDevanagari-Light.ttf"
                },
                "font_name": "IBMPlexSansDevanagari Light",
                "font_family": "IBMPlexSansDevanagari",
                "font_postscript": "IBMPlexSansDevanagari-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IBMPlexSansHebrew",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansHebrew-Regular.ttf"
                },
                "font_name": "IBMPlexSansHebrew Regular",
                "font_family": "IBMPlexSansHebrew",
                "font_postscript": "IBMPlexSansHebrew-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansHebrew-Light.ttf"
                },
                "font_name": "IBMPlexSansHebrew Light",
                "font_family": "IBMPlexSansHebrew",
                "font_postscript": "IBMPlexSansHebrew-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansHebrew-SemiBold.ttf"
                },
                "font_name": "IBMPlexSansHebrew SemiBold",
                "font_family": "IBMPlexSansHebrew",
                "font_postscript": "IBMPlexSansHebrew-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansHebrew-Thin.ttf"
                },
                "font_name": "IBMPlexSansHebrew Thin",
                "font_family": "IBMPlexSansHebrew",
                "font_postscript": "IBMPlexSansHebrew-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansHebrew-Bold.ttf"
                },
                "font_name": "IBMPlexSansHebrew Bold",
                "font_family": "IBMPlexSansHebrew",
                "font_postscript": "IBMPlexSansHebrew-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansHebrew-Medium.ttf"
                },
                "font_name": "IBMPlexSansHebrew Medium",
                "font_family": "IBMPlexSansHebrew",
                "font_postscript": "IBMPlexSansHebrew-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansHebrew-ExtraLight.ttf"
                },
                "font_name": "IBMPlexSansHebrew ExtraLight",
                "font_family": "IBMPlexSansHebrew",
                "font_postscript": "IBMPlexSansHebrew-ExtraLight",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IBMPlexSansJP",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansJP-Bold.ttf"
                },
                "font_name": "IBMPlexSansJP Bold",
                "font_family": "IBMPlexSansJP",
                "font_postscript": "IBMPlexSansJP-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansJP-Thin.ttf"
                },
                "font_name": "IBMPlexSansJP Thin",
                "font_family": "IBMPlexSansJP",
                "font_postscript": "IBMPlexSansJP-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansJP-ExtraLight.ttf"
                },
                "font_name": "IBMPlexSansJP ExtraLight",
                "font_family": "IBMPlexSansJP",
                "font_postscript": "IBMPlexSansJP-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansJP-Medium.ttf"
                },
                "font_name": "IBMPlexSansJP Medium",
                "font_family": "IBMPlexSansJP",
                "font_postscript": "IBMPlexSansJP-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansJP-SemiBold.ttf"
                },
                "font_name": "IBMPlexSansJP SemiBold",
                "font_family": "IBMPlexSansJP",
                "font_postscript": "IBMPlexSansJP-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansJP-Light.ttf"
                },
                "font_name": "IBMPlexSansJP Light",
                "font_family": "IBMPlexSansJP",
                "font_postscript": "IBMPlexSansJP-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansJP-Regular.ttf"
                },
                "font_name": "IBMPlexSansJP Regular",
                "font_family": "IBMPlexSansJP",
                "font_postscript": "IBMPlexSansJP-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IBMPlexSansKR",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansKR-ExtraLight.ttf"
                },
                "font_name": "IBMPlexSansKR ExtraLight",
                "font_family": "IBMPlexSansKR",
                "font_postscript": "IBMPlexSansKR-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansKR-Regular.ttf"
                },
                "font_name": "IBMPlexSansKR Regular",
                "font_family": "IBMPlexSansKR",
                "font_postscript": "IBMPlexSansKR-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansKR-SemiBold.ttf"
                },
                "font_name": "IBMPlexSansKR SemiBold",
                "font_family": "IBMPlexSansKR",
                "font_postscript": "IBMPlexSansKR-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansKR-Medium.ttf"
                },
                "font_name": "IBMPlexSansKR Medium",
                "font_family": "IBMPlexSansKR",
                "font_postscript": "IBMPlexSansKR-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansKR-Light.ttf"
                },
                "font_name": "IBMPlexSansKR Light",
                "font_family": "IBMPlexSansKR",
                "font_postscript": "IBMPlexSansKR-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansKR-Thin.ttf"
                },
                "font_name": "IBMPlexSansKR Thin",
                "font_family": "IBMPlexSansKR",
                "font_postscript": "IBMPlexSansKR-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansKR-Bold.ttf"
                },
                "font_name": "IBMPlexSansKR Bold",
                "font_family": "IBMPlexSansKR",
                "font_postscript": "IBMPlexSansKR-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IBMPlexSansThai",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansThai-Regular.ttf"
                },
                "font_name": "IBMPlexSansThai Regular",
                "font_family": "IBMPlexSansThai",
                "font_postscript": "IBMPlexSansThai-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansThai-Bold.ttf"
                },
                "font_name": "IBMPlexSansThai Bold",
                "font_family": "IBMPlexSansThai",
                "font_postscript": "IBMPlexSansThai-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansThai-Medium.ttf"
                },
                "font_name": "IBMPlexSansThai Medium",
                "font_family": "IBMPlexSansThai",
                "font_postscript": "IBMPlexSansThai-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansThai-Thin.ttf"
                },
                "font_name": "IBMPlexSansThai Thin",
                "font_family": "IBMPlexSansThai",
                "font_postscript": "IBMPlexSansThai-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansThai-Light.ttf"
                },
                "font_name": "IBMPlexSansThai Light",
                "font_family": "IBMPlexSansThai",
                "font_postscript": "IBMPlexSansThai-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansThai-ExtraLight.ttf"
                },
                "font_name": "IBMPlexSansThai ExtraLight",
                "font_family": "IBMPlexSansThai",
                "font_postscript": "IBMPlexSansThai-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansThai-SemiBold.ttf"
                },
                "font_name": "IBMPlexSansThai SemiBold",
                "font_family": "IBMPlexSansThai",
                "font_postscript": "IBMPlexSansThai-SemiBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IBMPlexSansThaiLooped",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansThaiLooped-Thin.ttf"
                },
                "font_name": "IBMPlexSansThaiLooped Thin",
                "font_family": "IBMPlexSansThaiLooped",
                "font_postscript": "IBMPlexSansThaiLooped-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansThaiLooped-Medium.ttf"
                },
                "font_name": "IBMPlexSansThaiLooped Medium",
                "font_family": "IBMPlexSansThaiLooped",
                "font_postscript": "IBMPlexSansThaiLooped-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansThaiLooped-Bold.ttf"
                },
                "font_name": "IBMPlexSansThaiLooped Bold",
                "font_family": "IBMPlexSansThaiLooped",
                "font_postscript": "IBMPlexSansThaiLooped-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansThaiLooped-SemiBold.ttf"
                },
                "font_name": "IBMPlexSansThaiLooped SemiBold",
                "font_family": "IBMPlexSansThaiLooped",
                "font_postscript": "IBMPlexSansThaiLooped-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansThaiLooped-Regular.ttf"
                },
                "font_name": "IBMPlexSansThaiLooped Regular",
                "font_family": "IBMPlexSansThaiLooped",
                "font_postscript": "IBMPlexSansThaiLooped-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansThaiLooped-Light.ttf"
                },
                "font_name": "IBMPlexSansThaiLooped Light",
                "font_family": "IBMPlexSansThaiLooped",
                "font_postscript": "IBMPlexSansThaiLooped-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSansThaiLooped-ExtraLight.ttf"
                },
                "font_name": "IBMPlexSansThaiLooped ExtraLight",
                "font_family": "IBMPlexSansThaiLooped",
                "font_postscript": "IBMPlexSansThaiLooped-ExtraLight",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IBMPlexSerif",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSerif-ExtraLight.ttf"
                },
                "font_name": "IBMPlexSerif ExtraLight",
                "font_family": "IBMPlexSerif",
                "font_postscript": "IBMPlexSerif-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSerif-Regular.ttf"
                },
                "font_name": "IBMPlexSerif Regular",
                "font_family": "IBMPlexSerif",
                "font_postscript": "IBMPlexSerif-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSerif-ExtraLightItalic.ttf"
                },
                "font_name": "IBMPlexSerif ExtraLightItalic",
                "font_family": "IBMPlexSerif",
                "font_postscript": "IBMPlexSerif-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSerif-ThinItalic.ttf"
                },
                "font_name": "IBMPlexSerif ThinItalic",
                "font_family": "IBMPlexSerif",
                "font_postscript": "IBMPlexSerif-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSerif-BoldItalic.ttf"
                },
                "font_name": "IBMPlexSerif BoldItalic",
                "font_family": "IBMPlexSerif",
                "font_postscript": "IBMPlexSerif-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSerif-SemiBold.ttf"
                },
                "font_name": "IBMPlexSerif SemiBold",
                "font_family": "IBMPlexSerif",
                "font_postscript": "IBMPlexSerif-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSerif-Italic.ttf"
                },
                "font_name": "IBMPlexSerif Italic",
                "font_family": "IBMPlexSerif",
                "font_postscript": "IBMPlexSerif-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSerif-Medium.ttf"
                },
                "font_name": "IBMPlexSerif Medium",
                "font_family": "IBMPlexSerif",
                "font_postscript": "IBMPlexSerif-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSerif-Light.ttf"
                },
                "font_name": "IBMPlexSerif Light",
                "font_family": "IBMPlexSerif",
                "font_postscript": "IBMPlexSerif-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSerif-Thin.ttf"
                },
                "font_name": "IBMPlexSerif Thin",
                "font_family": "IBMPlexSerif",
                "font_postscript": "IBMPlexSerif-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSerif-SemiBoldItalic.ttf"
                },
                "font_name": "IBMPlexSerif SemiBoldItalic",
                "font_family": "IBMPlexSerif",
                "font_postscript": "IBMPlexSerif-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSerif-MediumItalic.ttf"
                },
                "font_name": "IBMPlexSerif MediumItalic",
                "font_family": "IBMPlexSerif",
                "font_postscript": "IBMPlexSerif-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSerif-LightItalic.ttf"
                },
                "font_name": "IBMPlexSerif LightItalic",
                "font_family": "IBMPlexSerif",
                "font_postscript": "IBMPlexSerif-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IBMPlexSerif-Bold.ttf"
                },
                "font_name": "IBMPlexSerif Bold",
                "font_family": "IBMPlexSerif",
                "font_postscript": "IBMPlexSerif-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IMFELLDoublePica",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IMFELLDoublePica-Italic.ttf"
                },
                "font_name": "IMFELLDoublePica Italic",
                "font_family": "IMFELLDoublePica",
                "font_postscript": "IMFELLDoublePica-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IMFELLDoublePica-Regular.ttf"
                },
                "font_name": "IMFELLDoublePica Regular",
                "font_family": "IMFELLDoublePica",
                "font_postscript": "IMFELLDoublePica-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IMFeDPsc28P",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IMFeDPsc28P-Regular.ttf"
                },
                "font_name": "IMFeDPsc28P Regular",
                "font_family": "IMFeDPsc28P",
                "font_postscript": "IMFeDPsc28P-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IMFeENit28P",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IMFeENit28P-Regular.ttf"
                },
                "font_name": "IMFeENit28P Regular",
                "font_family": "IMFeENit28P",
                "font_postscript": "IMFeENit28P-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IMFeENrm28P",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IMFeENrm28P-Regular.ttf"
                },
                "font_name": "IMFeENrm28P Regular",
                "font_family": "IMFeENrm28P",
                "font_postscript": "IMFeENrm28P-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IMFeENsc28P",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IMFeENsc28P-Regular.ttf"
                },
                "font_name": "IMFeENsc28P Regular",
                "font_family": "IMFeENsc28P",
                "font_postscript": "IMFeENsc28P-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IMFeFCit28P",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IMFeFCit28P-Regular.ttf"
                },
                "font_name": "IMFeFCit28P Regular",
                "font_family": "IMFeFCit28P",
                "font_postscript": "IMFeFCit28P-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IMFeFCrm28P",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IMFeFCrm28P-Regular.ttf"
                },
                "font_name": "IMFeFCrm28P Regular",
                "font_family": "IMFeFCrm28P",
                "font_postscript": "IMFeFCrm28P-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IMFeFCsc28P",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IMFeFCsc28P-Regular.ttf"
                },
                "font_name": "IMFeFCsc28P Regular",
                "font_family": "IMFeFCsc28P",
                "font_postscript": "IMFeFCsc28P-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IMFeGPit28P",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IMFeGPit28P-Regular.ttf"
                },
                "font_name": "IMFeGPit28P Regular",
                "font_family": "IMFeGPit28P",
                "font_postscript": "IMFeGPit28P-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IMFeGPrm28P",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IMFeGPrm28P-Regular.ttf"
                },
                "font_name": "IMFeGPrm28P Regular",
                "font_family": "IMFeGPrm28P",
                "font_postscript": "IMFeGPrm28P-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IMFeGPsc28P",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IMFeGPsc28P-Regular.ttf"
                },
                "font_name": "IMFeGPsc28P Regular",
                "font_family": "IMFeGPsc28P",
                "font_postscript": "IMFeGPsc28P-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IMFePIit28P",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IMFePIit28P-Regular.ttf"
                },
                "font_name": "IMFePIit28P Regular",
                "font_family": "IMFePIit28P",
                "font_postscript": "IMFePIit28P-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IMFePIrm28P",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IMFePIrm28P-Regular.ttf"
                },
                "font_name": "IMFePIrm28P Regular",
                "font_family": "IMFePIrm28P",
                "font_postscript": "IMFePIrm28P-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IMFePIsc28P",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IMFePIsc28P-Regular.ttf"
                },
                "font_name": "IMFePIsc28P Regular",
                "font_family": "IMFePIsc28P",
                "font_postscript": "IMFePIsc28P-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IbarraRealNova",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IbarraRealNova-Regular.ttf"
                },
                "font_name": "IbarraRealNova Regular",
                "font_family": "IbarraRealNova",
                "font_postscript": "IbarraRealNova-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IbarraRealNova-Italic.ttf"
                },
                "font_name": "IbarraRealNova Italic",
                "font_family": "IbarraRealNova",
                "font_postscript": "IbarraRealNova-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Iceberg",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Iceberg-Regular.ttf"
                },
                "font_name": "Iceberg Regular",
                "font_family": "Iceberg",
                "font_postscript": "Iceberg-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Iceland",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Iceland-Regular.ttf"
                },
                "font_name": "Iceland Regular",
                "font_family": "Iceland",
                "font_postscript": "Iceland-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Imbue",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Imbue-Regular.ttf"
                },
                "font_name": "Imbue Regular",
                "font_family": "Imbue",
                "font_postscript": "Imbue-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ImperialScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ImperialScript-Regular.ttf"
                },
                "font_name": "ImperialScript Regular",
                "font_family": "ImperialScript",
                "font_postscript": "ImperialScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Imprima",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Imprima-Regular.ttf"
                },
                "font_name": "Imprima Regular",
                "font_family": "Imprima",
                "font_postscript": "Imprima-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "InclusiveSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "InclusiveSans-Italic.ttf"
                },
                "font_name": "InclusiveSans Italic",
                "font_family": "InclusiveSans",
                "font_postscript": "InclusiveSans-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "InclusiveSans-Regular.ttf"
                },
                "font_name": "InclusiveSans Regular",
                "font_family": "InclusiveSans",
                "font_postscript": "InclusiveSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Inconsolata",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-UltraExpandedMedium.ttf"
                },
                "font_name": "Inconsolata UltraExpandedMedium",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-UltraExpandedMedium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-SemiExpanded.ttf"
                },
                "font_name": "Inconsolata SemiExpanded",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-SemiExpanded",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-UltraExpandedLight.ttf"
                },
                "font_name": "Inconsolata UltraExpandedLight",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-UltraExpandedLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-UltraCondensedBlack.ttf"
                },
                "font_name": "Inconsolata UltraCondensedBlack",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-UltraCondensedBlack",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-UltraCondensedSemiBold.ttf"
                },
                "font_name": "Inconsolata UltraCondensedSemiBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-UltraCondensedSemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-UltraCondensedRegular.ttf"
                },
                "font_name": "Inconsolata UltraCondensedRegular",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-UltraCondensedRegular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-CondensedLight.ttf"
                },
                "font_name": "Inconsolata CondensedLight",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-CondensedLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-SemiExpandedMedium.ttf"
                },
                "font_name": "Inconsolata SemiExpandedMedium",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-SemiExpandedMedium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExpandedExtraLight.ttf"
                },
                "font_name": "Inconsolata ExpandedExtraLight",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExpandedExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-SemiBold.ttf"
                },
                "font_name": "Inconsolata SemiBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExtraCondensedBold.ttf"
                },
                "font_name": "Inconsolata ExtraCondensedBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExtraCondensedBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-Medium.ttf"
                },
                "font_name": "Inconsolata Medium",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExtraCondensedRegular.ttf"
                },
                "font_name": "Inconsolata ExtraCondensedRegular",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExtraCondensedRegular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-SemiExpandedBold.ttf"
                },
                "font_name": "Inconsolata SemiExpandedBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-SemiExpandedBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-Black.ttf"
                },
                "font_name": "Inconsolata Black",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-SemiCondensedSemiBold.ttf"
                },
                "font_name": "Inconsolata SemiCondensedSemiBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-SemiCondensedSemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-SemiExpandedSemiBold.ttf"
                },
                "font_name": "Inconsolata SemiExpandedSemiBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-SemiExpandedSemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-SemiCondensedBlack.ttf"
                },
                "font_name": "Inconsolata SemiCondensedBlack",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-SemiCondensedBlack",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-SemiExpandedExtraBold.ttf"
                },
                "font_name": "Inconsolata SemiExpandedExtraBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-SemiExpandedExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExtraExpandedLight.ttf"
                },
                "font_name": "Inconsolata ExtraExpandedLight",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExtraExpandedLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExtraCondensedLight.ttf"
                },
                "font_name": "Inconsolata ExtraCondensedLight",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExtraCondensedLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExtraCondensedExtraBold.ttf"
                },
                "font_name": "Inconsolata ExtraCondensedExtraBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExtraCondensedExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-CondensedRegular.ttf"
                },
                "font_name": "Inconsolata CondensedRegular",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-CondensedRegular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-SemiExpandedExtraLight.ttf"
                },
                "font_name": "Inconsolata SemiExpandedExtraLight",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-SemiExpandedExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExpandedBlack.ttf"
                },
                "font_name": "Inconsolata ExpandedBlack",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExpandedBlack",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-UltraExpandedExtraBold.ttf"
                },
                "font_name": "Inconsolata UltraExpandedExtraBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-UltraExpandedExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-Expanded.ttf"
                },
                "font_name": "Inconsolata Expanded",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-Expanded",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-UltraExpandedExtraLight.ttf"
                },
                "font_name": "Inconsolata UltraExpandedExtraLight",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-UltraExpandedExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-SemiExpandedBlack.ttf"
                },
                "font_name": "Inconsolata SemiExpandedBlack",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-SemiExpandedBlack",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-UltraCondensedBold.ttf"
                },
                "font_name": "Inconsolata UltraCondensedBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-UltraCondensedBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-Condensed.ttf"
                },
                "font_name": "Inconsolata Condensed",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-Condensed",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExpandedLight.ttf"
                },
                "font_name": "Inconsolata ExpandedLight",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExpandedLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-UltraExpandedRegular.ttf"
                },
                "font_name": "Inconsolata UltraExpandedRegular",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-UltraExpandedRegular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExpandedSemiBold.ttf"
                },
                "font_name": "Inconsolata ExpandedSemiBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExpandedSemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExtraLight.ttf"
                },
                "font_name": "Inconsolata ExtraLight",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-CondensedExtraBold.ttf"
                },
                "font_name": "Inconsolata CondensedExtraBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-CondensedExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-SemiExpandedLight.ttf"
                },
                "font_name": "Inconsolata SemiExpandedLight",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-SemiExpandedLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-Bold.ttf"
                },
                "font_name": "Inconsolata Bold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-UltraCondensed.ttf"
                },
                "font_name": "Inconsolata UltraCondensed",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-UltraCondensed",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-CondensedSemiBold.ttf"
                },
                "font_name": "Inconsolata CondensedSemiBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-CondensedSemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-CondensedMedium.ttf"
                },
                "font_name": "Inconsolata CondensedMedium",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-CondensedMedium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExtraExpandedBlack.ttf"
                },
                "font_name": "Inconsolata ExtraExpandedBlack",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExtraExpandedBlack",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExtraCondensedBlack.ttf"
                },
                "font_name": "Inconsolata ExtraCondensedBlack",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExtraCondensedBlack",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExtraCondensedMedium.ttf"
                },
                "font_name": "Inconsolata ExtraCondensedMedium",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExtraCondensedMedium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExtraExpandedMedium.ttf"
                },
                "font_name": "Inconsolata ExtraExpandedMedium",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExtraExpandedMedium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExtraExpanded.ttf"
                },
                "font_name": "Inconsolata ExtraExpanded",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExtraExpanded",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-SemiExpandedRegular.ttf"
                },
                "font_name": "Inconsolata SemiExpandedRegular",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-SemiExpandedRegular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExtraBold.ttf"
                },
                "font_name": "Inconsolata ExtraBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-SemiCondensedExtraLight.ttf"
                },
                "font_name": "Inconsolata SemiCondensedExtraLight",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-SemiCondensedExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExtraCondensedSemiBold.ttf"
                },
                "font_name": "Inconsolata ExtraCondensedSemiBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExtraCondensedSemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-UltraExpanded.ttf"
                },
                "font_name": "Inconsolata UltraExpanded",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-UltraExpanded",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-UltraExpandedBold.ttf"
                },
                "font_name": "Inconsolata UltraExpandedBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-UltraExpandedBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-SemiCondensedMedium.ttf"
                },
                "font_name": "Inconsolata SemiCondensedMedium",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-SemiCondensedMedium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExtraExpandedExtraBold.ttf"
                },
                "font_name": "Inconsolata ExtraExpandedExtraBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExtraExpandedExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-UltraExpandedSemiBold.ttf"
                },
                "font_name": "Inconsolata UltraExpandedSemiBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-UltraExpandedSemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-SemiCondensed.ttf"
                },
                "font_name": "Inconsolata SemiCondensed",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-SemiCondensed",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-CondensedBlack.ttf"
                },
                "font_name": "Inconsolata CondensedBlack",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-CondensedBlack",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExtraExpandedRegular.ttf"
                },
                "font_name": "Inconsolata ExtraExpandedRegular",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExtraExpandedRegular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-SemiCondensedLight.ttf"
                },
                "font_name": "Inconsolata SemiCondensedLight",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-SemiCondensedLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExpandedExtraBold.ttf"
                },
                "font_name": "Inconsolata ExpandedExtraBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExpandedExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-SemiCondensedRegular.ttf"
                },
                "font_name": "Inconsolata SemiCondensedRegular",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-SemiCondensedRegular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-UltraCondensedExtraBold.ttf"
                },
                "font_name": "Inconsolata UltraCondensedExtraBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-UltraCondensedExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-SemiCondensedExtraBold.ttf"
                },
                "font_name": "Inconsolata SemiCondensedExtraBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-SemiCondensedExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-UltraCondensedExtraLight.ttf"
                },
                "font_name": "Inconsolata UltraCondensedExtraLight",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-UltraCondensedExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExpandedRegular.ttf"
                },
                "font_name": "Inconsolata ExpandedRegular",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExpandedRegular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExtraCondensedExtraLight.ttf"
                },
                "font_name": "Inconsolata ExtraCondensedExtraLight",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExtraCondensedExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExpandedMedium.ttf"
                },
                "font_name": "Inconsolata ExpandedMedium",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExpandedMedium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-Light.ttf"
                },
                "font_name": "Inconsolata Light",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExtraExpandedSemiBold.ttf"
                },
                "font_name": "Inconsolata ExtraExpandedSemiBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExtraExpandedSemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-CondensedBold.ttf"
                },
                "font_name": "Inconsolata CondensedBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-CondensedBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExtraExpandedExtraLight.ttf"
                },
                "font_name": "Inconsolata ExtraExpandedExtraLight",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExtraExpandedExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-Regular.ttf"
                },
                "font_name": "Inconsolata Regular",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-Regular.ttf"
                },
                "font_name": "Inconsolata Regular",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExtraCondensed.ttf"
                },
                "font_name": "Inconsolata ExtraCondensed",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExtraCondensed",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExpandedBold.ttf"
                },
                "font_name": "Inconsolata ExpandedBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExpandedBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-SemiCondensedBold.ttf"
                },
                "font_name": "Inconsolata SemiCondensedBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-SemiCondensedBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-UltraCondensedMedium.ttf"
                },
                "font_name": "Inconsolata UltraCondensedMedium",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-UltraCondensedMedium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-ExtraExpandedBold.ttf"
                },
                "font_name": "Inconsolata ExtraExpandedBold",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-ExtraExpandedBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-UltraExpandedBlack.ttf"
                },
                "font_name": "Inconsolata UltraExpandedBlack",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-UltraExpandedBlack",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-CondensedExtraLight.ttf"
                },
                "font_name": "Inconsolata CondensedExtraLight",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-CondensedExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inconsolata-UltraCondensedLight.ttf"
                },
                "font_name": "Inconsolata UltraCondensedLight",
                "font_family": "Inconsolata",
                "font_postscript": "Inconsolata-UltraCondensedLight",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Inder",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inder-Regular.ttf"
                },
                "font_name": "Inder Regular",
                "font_family": "Inder",
                "font_postscript": "Inder-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IndieFlower",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IndieFlower-Regular.ttf"
                },
                "font_name": "IndieFlower Regular",
                "font_family": "IndieFlower",
                "font_postscript": "IndieFlower-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IngridDarling",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IngridDarling-Regular.ttf"
                },
                "font_name": "IngridDarling Regular",
                "font_family": "IngridDarling",
                "font_postscript": "IngridDarling-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Inika",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inika-Bold.ttf"
                },
                "font_name": "Inika Bold",
                "font_family": "Inika",
                "font_postscript": "Inika-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inika-Regular.ttf"
                },
                "font_name": "Inika Regular",
                "font_family": "Inika",
                "font_postscript": "Inika-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "InknutAntiqua",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "InknutAntiqua-Bold.ttf"
                },
                "font_name": "InknutAntiqua Bold",
                "font_family": "InknutAntiqua",
                "font_postscript": "InknutAntiqua-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "InknutAntiqua-Regular.ttf"
                },
                "font_name": "InknutAntiqua Regular",
                "font_family": "InknutAntiqua",
                "font_postscript": "InknutAntiqua-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "InknutAntiqua-SemiBold.ttf"
                },
                "font_name": "InknutAntiqua SemiBold",
                "font_family": "InknutAntiqua",
                "font_postscript": "InknutAntiqua-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "InknutAntiqua-Light.ttf"
                },
                "font_name": "InknutAntiqua Light",
                "font_family": "InknutAntiqua",
                "font_postscript": "InknutAntiqua-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "InknutAntiqua-Black.ttf"
                },
                "font_name": "InknutAntiqua Black",
                "font_family": "InknutAntiqua",
                "font_postscript": "InknutAntiqua-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "InknutAntiqua-Medium.ttf"
                },
                "font_name": "InknutAntiqua Medium",
                "font_family": "InknutAntiqua",
                "font_postscript": "InknutAntiqua-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "InknutAntiqua-ExtraBold.ttf"
                },
                "font_name": "InknutAntiqua ExtraBold",
                "font_family": "InknutAntiqua",
                "font_postscript": "InknutAntiqua-ExtraBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "InriaSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "InriaSans-Light.ttf"
                },
                "font_name": "InriaSans Light",
                "font_family": "InriaSans",
                "font_postscript": "InriaSans-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "InriaSans-Regular.ttf"
                },
                "font_name": "InriaSans Regular",
                "font_family": "InriaSans",
                "font_postscript": "InriaSans-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "InriaSans-Italic.ttf"
                },
                "font_name": "InriaSans Italic",
                "font_family": "InriaSans",
                "font_postscript": "InriaSans-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "InriaSans-LightItalic.ttf"
                },
                "font_name": "InriaSans LightItalic",
                "font_family": "InriaSans",
                "font_postscript": "InriaSans-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "InriaSans-BoldItalic.ttf"
                },
                "font_name": "InriaSans BoldItalic",
                "font_family": "InriaSans",
                "font_postscript": "InriaSans-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "InriaSans-Bold.ttf"
                },
                "font_name": "InriaSans Bold",
                "font_family": "InriaSans",
                "font_postscript": "InriaSans-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "InriaSerif",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "InriaSerif-Light.ttf"
                },
                "font_name": "InriaSerif Light",
                "font_family": "InriaSerif",
                "font_postscript": "InriaSerif-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "InriaSerif-Bold.ttf"
                },
                "font_name": "InriaSerif Bold",
                "font_family": "InriaSerif",
                "font_postscript": "InriaSerif-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "InriaSerif-LightItalic.ttf"
                },
                "font_name": "InriaSerif LightItalic",
                "font_family": "InriaSerif",
                "font_postscript": "InriaSerif-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "InriaSerif-Regular.ttf"
                },
                "font_name": "InriaSerif Regular",
                "font_family": "InriaSerif",
                "font_postscript": "InriaSerif-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "InriaSerif-Italic.ttf"
                },
                "font_name": "InriaSerif Italic",
                "font_family": "InriaSerif",
                "font_postscript": "InriaSerif-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "InriaSerif-BoldItalic.ttf"
                },
                "font_name": "InriaSerif BoldItalic",
                "font_family": "InriaSerif",
                "font_postscript": "InriaSerif-BoldItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Inspiration",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inspiration-Regular.ttf"
                },
                "font_name": "Inspiration Regular",
                "font_family": "Inspiration",
                "font_postscript": "Inspiration-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "InstrumentSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "InstrumentSans-Regular.ttf"
                },
                "font_name": "InstrumentSans Regular",
                "font_family": "InstrumentSans",
                "font_postscript": "InstrumentSans-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "InstrumentSans-Italic.ttf"
                },
                "font_name": "InstrumentSans Italic",
                "font_family": "InstrumentSans",
                "font_postscript": "InstrumentSans-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "InstrumentSerif",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "InstrumentSerif-Italic.ttf"
                },
                "font_name": "InstrumentSerif Italic",
                "font_family": "InstrumentSerif",
                "font_postscript": "InstrumentSerif-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "InstrumentSerif-Regular.ttf"
                },
                "font_name": "InstrumentSerif Regular",
                "font_family": "InstrumentSerif",
                "font_postscript": "InstrumentSerif-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Inter",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Inter-Regular.ttf"
                },
                "font_name": "Inter Regular",
                "font_family": "Inter",
                "font_postscript": "Inter-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "InterTight",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "InterTight-Italic.ttf"
                },
                "font_name": "InterTight Italic",
                "font_family": "InterTight",
                "font_postscript": "InterTight-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "InterTight-Regular.ttf"
                },
                "font_name": "InterTight Regular",
                "font_family": "InterTight",
                "font_postscript": "InterTight-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IrishGrover",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IrishGrover-Regular.ttf"
                },
                "font_name": "IrishGrover Regular",
                "font_family": "IrishGrover",
                "font_postscript": "IrishGrover-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IslandMoments",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IslandMoments-Regular.ttf"
                },
                "font_name": "IslandMoments Regular",
                "font_family": "IslandMoments",
                "font_postscript": "IslandMoments-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "IstokWeb",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "IstokWeb-Italic.ttf"
                },
                "font_name": "IstokWeb Italic",
                "font_family": "IstokWeb",
                "font_postscript": "IstokWeb-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IstokWeb-BoldItalic.ttf"
                },
                "font_name": "IstokWeb BoldItalic",
                "font_family": "IstokWeb",
                "font_postscript": "IstokWeb-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IstokWeb-Bold.ttf"
                },
                "font_name": "IstokWeb Bold",
                "font_family": "IstokWeb",
                "font_postscript": "IstokWeb-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "IstokWeb-Regular.ttf"
                },
                "font_name": "IstokWeb Regular",
                "font_family": "IstokWeb",
                "font_postscript": "IstokWeb-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Italiana",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Italiana-Regular.ttf"
                },
                "font_name": "Italiana Regular",
                "font_family": "Italiana",
                "font_postscript": "Italiana-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Italianno",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Italianno-Regular.ttf"
                },
                "font_name": "Italianno Regular",
                "font_family": "Italianno",
                "font_postscript": "Italianno-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Itim",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Itim-Regular.ttf"
                },
                "font_name": "Itim Regular",
                "font_family": "Itim",
                "font_postscript": "Itim-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Jacquard12",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jacquard12-Regular.ttf"
                },
                "font_name": "Jacquard12 Regular",
                "font_family": "Jacquard12",
                "font_postscript": "Jacquard12-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Jacquard12Charted",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jacquard12Charted-Regular.ttf"
                },
                "font_name": "Jacquard12Charted Regular",
                "font_family": "Jacquard12Charted",
                "font_postscript": "Jacquard12Charted-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Jacquard24",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jacquard24-Regular.ttf"
                },
                "font_name": "Jacquard24 Regular",
                "font_family": "Jacquard24",
                "font_postscript": "Jacquard24-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Jacquard24Charted",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jacquard24Charted-Regular.ttf"
                },
                "font_name": "Jacquard24Charted Regular",
                "font_family": "Jacquard24Charted",
                "font_postscript": "Jacquard24Charted-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "JacquardaBastarda9",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "JacquardaBastarda9-Regular.ttf"
                },
                "font_name": "JacquardaBastarda9 Regular",
                "font_family": "JacquardaBastarda9",
                "font_postscript": "JacquardaBastarda9-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "JacquardaBastarda9Charted",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "JacquardaBastarda9Charted-Regular.ttf"
                },
                "font_name": "JacquardaBastarda9Charted Regular",
                "font_family": "JacquardaBastarda9Charted",
                "font_postscript": "JacquardaBastarda9Charted-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "JacquesFrancois",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "JacquesFrancois-Regular.ttf"
                },
                "font_name": "JacquesFrancois Regular",
                "font_family": "JacquesFrancois",
                "font_postscript": "JacquesFrancois-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "JacquesFrancoisShadow",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "JacquesFrancoisShadow-Regular.ttf"
                },
                "font_name": "JacquesFrancoisShadow Regular",
                "font_family": "JacquesFrancoisShadow",
                "font_postscript": "JacquesFrancoisShadow-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Jaini",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jaini-Regular.ttf"
                },
                "font_name": "Jaini Regular",
                "font_family": "Jaini",
                "font_postscript": "Jaini-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "JainiPurva",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "JainiPurva-Regular.ttf"
                },
                "font_name": "JainiPurva Regular",
                "font_family": "JainiPurva",
                "font_postscript": "JainiPurva-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Jaldi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jaldi-Regular.ttf"
                },
                "font_name": "Jaldi Regular",
                "font_family": "Jaldi",
                "font_postscript": "Jaldi-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jaldi-Bold.ttf"
                },
                "font_name": "Jaldi Bold",
                "font_family": "Jaldi",
                "font_postscript": "Jaldi-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Jaro",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jaro-Regular.ttf"
                },
                "font_name": "Jaro Regular",
                "font_family": "Jaro",
                "font_postscript": "Jaro-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "JejuGothic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "JejuGothic-Regular.ttf"
                },
                "font_name": "JejuGothic Regular",
                "font_family": "JejuGothic",
                "font_postscript": "JejuGothic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "JejuHallasan",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "JejuHallasan-Regular.ttf"
                },
                "font_name": "JejuHallasan Regular",
                "font_family": "JejuHallasan",
                "font_postscript": "JejuHallasan-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "JejuMyeongjo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "JejuMyeongjo-Regular.ttf"
                },
                "font_name": "JejuMyeongjo Regular",
                "font_family": "JejuMyeongjo",
                "font_postscript": "JejuMyeongjo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Jersey10",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jersey10-Regular.ttf"
                },
                "font_name": "Jersey10 Regular",
                "font_family": "Jersey10",
                "font_postscript": "Jersey10-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Jersey10Charted",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jersey10Charted-Regular.ttf"
                },
                "font_name": "Jersey10Charted Regular",
                "font_family": "Jersey10Charted",
                "font_postscript": "Jersey10Charted-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Jersey15",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jersey15-Regular.ttf"
                },
                "font_name": "Jersey15 Regular",
                "font_family": "Jersey15",
                "font_postscript": "Jersey15-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Jersey15Charted",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jersey15Charted-Regular.ttf"
                },
                "font_name": "Jersey15Charted Regular",
                "font_family": "Jersey15Charted",
                "font_postscript": "Jersey15Charted-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Jersey20",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jersey20-Regular.ttf"
                },
                "font_name": "Jersey20 Regular",
                "font_family": "Jersey20",
                "font_postscript": "Jersey20-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Jersey20Charted",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jersey20Charted-Regular.ttf"
                },
                "font_name": "Jersey20Charted Regular",
                "font_family": "Jersey20Charted",
                "font_postscript": "Jersey20Charted-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Jersey25",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jersey25-Regular.ttf"
                },
                "font_name": "Jersey25 Regular",
                "font_family": "Jersey25",
                "font_postscript": "Jersey25-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Jersey25Charted",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jersey25Charted-Regular.ttf"
                },
                "font_name": "Jersey25Charted Regular",
                "font_family": "Jersey25Charted",
                "font_postscript": "Jersey25Charted-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "JetBrainsMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "JetBrainsMono-Regular.ttf"
                },
                "font_name": "JetBrainsMono Regular",
                "font_family": "JetBrainsMono",
                "font_postscript": "JetBrainsMono-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "JetBrainsMono-Italic.ttf"
                },
                "font_name": "JetBrainsMono Italic",
                "font_family": "JetBrainsMono",
                "font_postscript": "JetBrainsMono-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "JimNightshade",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "JimNightshade-Regular.ttf"
                },
                "font_name": "JimNightshade Regular",
                "font_family": "JimNightshade",
                "font_postscript": "JimNightshade-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Joan",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Joan-Regular.ttf"
                },
                "font_name": "Joan Regular",
                "font_family": "Joan",
                "font_postscript": "Joan-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "JockeyOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "JockeyOne-Regular.ttf"
                },
                "font_name": "JockeyOne Regular",
                "font_family": "JockeyOne",
                "font_postscript": "JockeyOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "JollyLodger",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "JollyLodger-Regular.ttf"
                },
                "font_name": "JollyLodger Regular",
                "font_family": "JollyLodger",
                "font_postscript": "JollyLodger-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Jomhuria",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jomhuria-Regular.ttf"
                },
                "font_name": "Jomhuria Regular",
                "font_family": "Jomhuria",
                "font_postscript": "Jomhuria-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Jomolhari",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jomolhari-Regular.ttf"
                },
                "font_name": "Jomolhari Regular",
                "font_family": "Jomolhari",
                "font_postscript": "Jomolhari-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "JosefinSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "JosefinSans-Italic.ttf"
                },
                "font_name": "JosefinSans Italic",
                "font_family": "JosefinSans",
                "font_postscript": "JosefinSans-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "JosefinSans-Regular.ttf"
                },
                "font_name": "JosefinSans Regular",
                "font_family": "JosefinSans",
                "font_postscript": "JosefinSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "JosefinSlab",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "JosefinSlab-Regular.ttf"
                },
                "font_name": "JosefinSlab Regular",
                "font_family": "JosefinSlab",
                "font_postscript": "JosefinSlab-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "JosefinSlab-Italic.ttf"
                },
                "font_name": "JosefinSlab Italic",
                "font_family": "JosefinSlab",
                "font_postscript": "JosefinSlab-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Jost",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jost-Regular.ttf"
                },
                "font_name": "Jost Regular",
                "font_family": "Jost",
                "font_postscript": "Jost-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jost-Italic.ttf"
                },
                "font_name": "Jost Italic",
                "font_family": "Jost",
                "font_postscript": "Jost-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "JotiOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "JotiOne-Regular.ttf"
                },
                "font_name": "JotiOne Regular",
                "font_family": "JotiOne",
                "font_postscript": "JotiOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Jua",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jua-Regular.ttf"
                },
                "font_name": "Jua Regular",
                "font_family": "Jua",
                "font_postscript": "Jua-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Judson",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Judson-Bold.ttf"
                },
                "font_name": "Judson Bold",
                "font_family": "Judson",
                "font_postscript": "Judson-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Judson-Regular.ttf"
                },
                "font_name": "Judson Regular",
                "font_family": "Judson",
                "font_postscript": "Judson-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Judson-Italic.ttf"
                },
                "font_name": "Judson Italic",
                "font_family": "Judson",
                "font_postscript": "Judson-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Julee",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Julee-Regular.ttf"
                },
                "font_name": "Julee Regular",
                "font_family": "Julee",
                "font_postscript": "Julee-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "JuliusSansOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "JuliusSansOne-Regular.ttf"
                },
                "font_name": "JuliusSansOne Regular",
                "font_family": "JuliusSansOne",
                "font_postscript": "JuliusSansOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Junge",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Junge-Regular.ttf"
                },
                "font_name": "Junge Regular",
                "font_family": "Junge",
                "font_postscript": "Junge-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Jura",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Jura-Regular.ttf"
                },
                "font_name": "Jura Regular",
                "font_family": "Jura",
                "font_postscript": "Jura-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "JustAnotherHand",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "JustAnotherHand-Regular.ttf"
                },
                "font_name": "JustAnotherHand Regular",
                "font_family": "JustAnotherHand",
                "font_postscript": "JustAnotherHand-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "JustMeAgainDownHere",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "JustMeAgainDownHere-Regular.ttf"
                },
                "font_name": "JustMeAgainDownHere Regular",
                "font_family": "JustMeAgainDownHere",
                "font_postscript": "JustMeAgainDownHere-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "K2D",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "K2D-SemiBold.ttf"
                },
                "font_name": "K2D SemiBold",
                "font_family": "K2D",
                "font_postscript": "K2D-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "K2D-Bold.ttf"
                },
                "font_name": "K2D Bold",
                "font_family": "K2D",
                "font_postscript": "K2D-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "K2D-Regular.ttf"
                },
                "font_name": "K2D Regular",
                "font_family": "K2D",
                "font_postscript": "K2D-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "K2D-Thin.ttf"
                },
                "font_name": "K2D Thin",
                "font_family": "K2D",
                "font_postscript": "K2D-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "K2D-Italic.ttf"
                },
                "font_name": "K2D Italic",
                "font_family": "K2D",
                "font_postscript": "K2D-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "K2D-LightItalic.ttf"
                },
                "font_name": "K2D LightItalic",
                "font_family": "K2D",
                "font_postscript": "K2D-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "K2D-ExtraBold.ttf"
                },
                "font_name": "K2D ExtraBold",
                "font_family": "K2D",
                "font_postscript": "K2D-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "K2D-ExtraLightItalic.ttf"
                },
                "font_name": "K2D ExtraLightItalic",
                "font_family": "K2D",
                "font_postscript": "K2D-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "K2D-ExtraLight.ttf"
                },
                "font_name": "K2D ExtraLight",
                "font_family": "K2D",
                "font_postscript": "K2D-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "K2D-MediumItalic.ttf"
                },
                "font_name": "K2D MediumItalic",
                "font_family": "K2D",
                "font_postscript": "K2D-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "K2D-ThinItalic.ttf"
                },
                "font_name": "K2D ThinItalic",
                "font_family": "K2D",
                "font_postscript": "K2D-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "K2D-BoldItalic.ttf"
                },
                "font_name": "K2D BoldItalic",
                "font_family": "K2D",
                "font_postscript": "K2D-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "K2D-SemiBoldItalic.ttf"
                },
                "font_name": "K2D SemiBoldItalic",
                "font_family": "K2D",
                "font_postscript": "K2D-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "K2D-Light.ttf"
                },
                "font_name": "K2D Light",
                "font_family": "K2D",
                "font_postscript": "K2D-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "K2D-ExtraBoldItalic.ttf"
                },
                "font_name": "K2D ExtraBoldItalic",
                "font_family": "K2D",
                "font_postscript": "K2D-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "K2D-Medium.ttf"
                },
                "font_name": "K2D Medium",
                "font_family": "K2D",
                "font_postscript": "K2D-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Kablammo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kablammo-Regular.ttf"
                },
                "font_name": "Kablammo Regular",
                "font_family": "Kablammo",
                "font_postscript": "Kablammo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Kadwa",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kadwa-Bold.ttf"
                },
                "font_name": "Kadwa Bold",
                "font_family": "Kadwa",
                "font_postscript": "Kadwa-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kadwa-Regular.ttf"
                },
                "font_name": "Kadwa Regular",
                "font_family": "Kadwa",
                "font_postscript": "Kadwa-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KaiseiDecol",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KaiseiDecol-Regular.ttf"
                },
                "font_name": "KaiseiDecol Regular",
                "font_family": "KaiseiDecol",
                "font_postscript": "KaiseiDecol-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KaiseiDecol-Medium.ttf"
                },
                "font_name": "KaiseiDecol Medium",
                "font_family": "KaiseiDecol",
                "font_postscript": "KaiseiDecol-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KaiseiDecol-Bold.ttf"
                },
                "font_name": "KaiseiDecol Bold",
                "font_family": "KaiseiDecol",
                "font_postscript": "KaiseiDecol-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KaiseiHarunoUmi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KaiseiHarunoUmi-Regular.ttf"
                },
                "font_name": "KaiseiHarunoUmi Regular",
                "font_family": "KaiseiHarunoUmi",
                "font_postscript": "KaiseiHarunoUmi-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KaiseiHarunoUmi-Medium.ttf"
                },
                "font_name": "KaiseiHarunoUmi Medium",
                "font_family": "KaiseiHarunoUmi",
                "font_postscript": "KaiseiHarunoUmi-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KaiseiHarunoUmi-Bold.ttf"
                },
                "font_name": "KaiseiHarunoUmi Bold",
                "font_family": "KaiseiHarunoUmi",
                "font_postscript": "KaiseiHarunoUmi-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KaiseiOpti",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KaiseiOpti-Regular.ttf"
                },
                "font_name": "KaiseiOpti Regular",
                "font_family": "KaiseiOpti",
                "font_postscript": "KaiseiOpti-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KaiseiOpti-Bold.ttf"
                },
                "font_name": "KaiseiOpti Bold",
                "font_family": "KaiseiOpti",
                "font_postscript": "KaiseiOpti-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KaiseiOpti-Medium.ttf"
                },
                "font_name": "KaiseiOpti Medium",
                "font_family": "KaiseiOpti",
                "font_postscript": "KaiseiOpti-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KaiseiTokumin",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KaiseiTokumin-ExtraBold.ttf"
                },
                "font_name": "KaiseiTokumin ExtraBold",
                "font_family": "KaiseiTokumin",
                "font_postscript": "KaiseiTokumin-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KaiseiTokumin-Bold.ttf"
                },
                "font_name": "KaiseiTokumin Bold",
                "font_family": "KaiseiTokumin",
                "font_postscript": "KaiseiTokumin-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KaiseiTokumin-Medium.ttf"
                },
                "font_name": "KaiseiTokumin Medium",
                "font_family": "KaiseiTokumin",
                "font_postscript": "KaiseiTokumin-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KaiseiTokumin-Regular.ttf"
                },
                "font_name": "KaiseiTokumin Regular",
                "font_family": "KaiseiTokumin",
                "font_postscript": "KaiseiTokumin-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Kalam",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kalam-Regular.ttf"
                },
                "font_name": "Kalam Regular",
                "font_family": "Kalam",
                "font_postscript": "Kalam-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kalam-Light.ttf"
                },
                "font_name": "Kalam Light",
                "font_family": "Kalam",
                "font_postscript": "Kalam-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kalam-Bold.ttf"
                },
                "font_name": "Kalam Bold",
                "font_family": "Kalam",
                "font_postscript": "Kalam-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Kalnia",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kalnia-Regular.ttf"
                },
                "font_name": "Kalnia Regular",
                "font_family": "Kalnia",
                "font_postscript": "Kalnia-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KalniaGlaze",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KalniaGlaze-Regular.ttf"
                },
                "font_name": "KalniaGlaze Regular",
                "font_family": "KalniaGlaze",
                "font_postscript": "KalniaGlaze-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Kameron",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kameron-Regular.ttf"
                },
                "font_name": "Kameron Regular",
                "font_family": "Kameron",
                "font_postscript": "Kameron-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Kanit",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kanit-ExtraLight.ttf"
                },
                "font_name": "Kanit ExtraLight",
                "font_family": "Kanit",
                "font_postscript": "Kanit-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kanit-ThinItalic.ttf"
                },
                "font_name": "Kanit ThinItalic",
                "font_family": "Kanit",
                "font_postscript": "Kanit-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kanit-BoldItalic.ttf"
                },
                "font_name": "Kanit BoldItalic",
                "font_family": "Kanit",
                "font_postscript": "Kanit-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kanit-SemiBold.ttf"
                },
                "font_name": "Kanit SemiBold",
                "font_family": "Kanit",
                "font_postscript": "Kanit-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kanit-Black.ttf"
                },
                "font_name": "Kanit Black",
                "font_family": "Kanit",
                "font_postscript": "Kanit-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kanit-Regular.ttf"
                },
                "font_name": "Kanit Regular",
                "font_family": "Kanit",
                "font_postscript": "Kanit-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kanit-Italic.ttf"
                },
                "font_name": "Kanit Italic",
                "font_family": "Kanit",
                "font_postscript": "Kanit-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kanit-MediumItalic.ttf"
                },
                "font_name": "Kanit MediumItalic",
                "font_family": "Kanit",
                "font_postscript": "Kanit-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kanit-Thin.ttf"
                },
                "font_name": "Kanit Thin",
                "font_family": "Kanit",
                "font_postscript": "Kanit-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kanit-ExtraBoldItalic.ttf"
                },
                "font_name": "Kanit ExtraBoldItalic",
                "font_family": "Kanit",
                "font_postscript": "Kanit-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kanit-ExtraBold.ttf"
                },
                "font_name": "Kanit ExtraBold",
                "font_family": "Kanit",
                "font_postscript": "Kanit-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kanit-Bold.ttf"
                },
                "font_name": "Kanit Bold",
                "font_family": "Kanit",
                "font_postscript": "Kanit-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kanit-SemiBoldItalic.ttf"
                },
                "font_name": "Kanit SemiBoldItalic",
                "font_family": "Kanit",
                "font_postscript": "Kanit-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kanit-BlackItalic.ttf"
                },
                "font_name": "Kanit BlackItalic",
                "font_family": "Kanit",
                "font_postscript": "Kanit-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kanit-Light.ttf"
                },
                "font_name": "Kanit Light",
                "font_family": "Kanit",
                "font_postscript": "Kanit-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kanit-LightItalic.ttf"
                },
                "font_name": "Kanit LightItalic",
                "font_family": "Kanit",
                "font_postscript": "Kanit-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kanit-ExtraLightItalic.ttf"
                },
                "font_name": "Kanit ExtraLightItalic",
                "font_family": "Kanit",
                "font_postscript": "Kanit-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kanit-Medium.ttf"
                },
                "font_name": "Kanit Medium",
                "font_family": "Kanit",
                "font_postscript": "Kanit-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KantumruyPro",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KantumruyPro-Regular.ttf"
                },
                "font_name": "KantumruyPro Regular",
                "font_family": "KantumruyPro",
                "font_postscript": "KantumruyPro-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KantumruyPro-Italic.ttf"
                },
                "font_name": "KantumruyPro Italic",
                "font_family": "KantumruyPro",
                "font_postscript": "KantumruyPro-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Kapakana",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kapakana-Regular.ttf"
                },
                "font_name": "Kapakana Regular",
                "font_family": "Kapakana",
                "font_postscript": "Kapakana-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Karantina",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Karantina-Regular.ttf"
                },
                "font_name": "Karantina Regular",
                "font_family": "Karantina",
                "font_postscript": "Karantina-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Karantina-Light.ttf"
                },
                "font_name": "Karantina Light",
                "font_family": "Karantina",
                "font_postscript": "Karantina-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Karantina-Bold.ttf"
                },
                "font_name": "Karantina Bold",
                "font_family": "Karantina",
                "font_postscript": "Karantina-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Karla",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Karla-Italic.ttf"
                },
                "font_name": "Karla Italic",
                "font_family": "Karla",
                "font_postscript": "Karla-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Karla-Regular.ttf"
                },
                "font_name": "Karla Regular",
                "font_family": "Karla",
                "font_postscript": "Karla-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KarlaTamilInclined",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KarlaTamilInclined-Bold.ttf"
                },
                "font_name": "KarlaTamilInclined Bold",
                "font_family": "KarlaTamilInclined",
                "font_postscript": "KarlaTamilInclined-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KarlaTamilInclined-Regular.ttf"
                },
                "font_name": "KarlaTamilInclined Regular",
                "font_family": "KarlaTamilInclined",
                "font_postscript": "KarlaTamilInclined-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KarlaTamilUpright",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KarlaTamilUpright-Regular.ttf"
                },
                "font_name": "KarlaTamilUpright Regular",
                "font_family": "KarlaTamilUpright",
                "font_postscript": "KarlaTamilUpright-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KarlaTamilUpright-Bold.ttf"
                },
                "font_name": "KarlaTamilUpright Bold",
                "font_family": "KarlaTamilUpright",
                "font_postscript": "KarlaTamilUpright-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Karma",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Karma-Light.ttf"
                },
                "font_name": "Karma Light",
                "font_family": "Karma",
                "font_postscript": "Karma-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Karma-Medium.ttf"
                },
                "font_name": "Karma Medium",
                "font_family": "Karma",
                "font_postscript": "Karma-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Karma-SemiBold.ttf"
                },
                "font_name": "Karma SemiBold",
                "font_family": "Karma",
                "font_postscript": "Karma-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Karma-Bold.ttf"
                },
                "font_name": "Karma Bold",
                "font_family": "Karma",
                "font_postscript": "Karma-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Karma-Regular.ttf"
                },
                "font_name": "Karma Regular",
                "font_family": "Karma",
                "font_postscript": "Karma-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Katibeh",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Katibeh-Regular.ttf"
                },
                "font_name": "Katibeh Regular",
                "font_family": "Katibeh",
                "font_postscript": "Katibeh-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KaushanScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KaushanScript-Regular.ttf"
                },
                "font_name": "KaushanScript Regular",
                "font_family": "KaushanScript",
                "font_postscript": "KaushanScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Kavivanar",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kavivanar-Regular.ttf"
                },
                "font_name": "Kavivanar Regular",
                "font_family": "Kavivanar",
                "font_postscript": "Kavivanar-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Kavoon",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kavoon-Regular.ttf"
                },
                "font_name": "Kavoon Regular",
                "font_family": "Kavoon",
                "font_postscript": "Kavoon-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KayPhoDu",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KayPhoDu-Regular.ttf"
                },
                "font_name": "KayPhoDu Regular",
                "font_family": "KayPhoDu",
                "font_postscript": "KayPhoDu-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KayPhoDu-Medium.ttf"
                },
                "font_name": "KayPhoDu Medium",
                "font_family": "KayPhoDu",
                "font_postscript": "KayPhoDu-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KayPhoDu-SemiBold.ttf"
                },
                "font_name": "KayPhoDu SemiBold",
                "font_family": "KayPhoDu",
                "font_postscript": "KayPhoDu-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KayPhoDu-Bold.ttf"
                },
                "font_name": "KayPhoDu Bold",
                "font_family": "KayPhoDu",
                "font_postscript": "KayPhoDu-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KdamThmorPro",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KdamThmorPro-Regular.ttf"
                },
                "font_name": "KdamThmorPro Regular",
                "font_family": "KdamThmorPro",
                "font_postscript": "KdamThmorPro-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KeaniaOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KeaniaOne-Regular.ttf"
                },
                "font_name": "KeaniaOne Regular",
                "font_family": "KeaniaOne",
                "font_postscript": "KeaniaOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KellySlab",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KellySlab-Regular.ttf"
                },
                "font_name": "KellySlab Regular",
                "font_family": "KellySlab",
                "font_postscript": "KellySlab-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Kenia",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kenia-Regular.ttf"
                },
                "font_name": "Kenia Regular",
                "font_family": "Kenia",
                "font_postscript": "Kenia-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Khand",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Khand-Medium.ttf"
                },
                "font_name": "Khand Medium",
                "font_family": "Khand",
                "font_postscript": "Khand-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Khand-Bold.ttf"
                },
                "font_name": "Khand Bold",
                "font_family": "Khand",
                "font_postscript": "Khand-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Khand-Light.ttf"
                },
                "font_name": "Khand Light",
                "font_family": "Khand",
                "font_postscript": "Khand-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Khand-Regular.ttf"
                },
                "font_name": "Khand Regular",
                "font_family": "Khand",
                "font_postscript": "Khand-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Khand-SemiBold.ttf"
                },
                "font_name": "Khand SemiBold",
                "font_family": "Khand",
                "font_postscript": "Khand-SemiBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Khmer",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Khmer-Regular.ttf"
                },
                "font_name": "Khmer Regular",
                "font_family": "Khmer",
                "font_postscript": "Khmer-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Khula",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Khula-SemiBold.ttf"
                },
                "font_name": "Khula SemiBold",
                "font_family": "Khula",
                "font_postscript": "Khula-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Khula-Bold.ttf"
                },
                "font_name": "Khula Bold",
                "font_family": "Khula",
                "font_postscript": "Khula-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Khula-Light.ttf"
                },
                "font_name": "Khula Light",
                "font_family": "Khula",
                "font_postscript": "Khula-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Khula-ExtraBold.ttf"
                },
                "font_name": "Khula ExtraBold",
                "font_family": "Khula",
                "font_postscript": "Khula-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Khula-Regular.ttf"
                },
                "font_name": "Khula Regular",
                "font_family": "Khula",
                "font_postscript": "Khula-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Khyay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Khyay-Regular.ttf"
                },
                "font_name": "Khyay Regular",
                "font_family": "Khyay",
                "font_postscript": "Khyay-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Kings",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kings-Regular.ttf"
                },
                "font_name": "Kings Regular",
                "font_family": "Kings",
                "font_postscript": "Kings-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KirangHaerang",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KirangHaerang-Regular.ttf"
                },
                "font_name": "KirangHaerang Regular",
                "font_family": "KirangHaerang",
                "font_postscript": "KirangHaerang-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KiteOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KiteOne-Regular.ttf"
                },
                "font_name": "KiteOne Regular",
                "font_family": "KiteOne",
                "font_postscript": "KiteOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KiwiMaru",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KiwiMaru-Medium.ttf"
                },
                "font_name": "KiwiMaru Medium",
                "font_family": "KiwiMaru",
                "font_postscript": "KiwiMaru-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KiwiMaru-Light.ttf"
                },
                "font_name": "KiwiMaru Light",
                "font_family": "KiwiMaru",
                "font_postscript": "KiwiMaru-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KiwiMaru-Regular.ttf"
                },
                "font_name": "KiwiMaru Regular",
                "font_family": "KiwiMaru",
                "font_postscript": "KiwiMaru-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KleeOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KleeOne-Regular.ttf"
                },
                "font_name": "KleeOne Regular",
                "font_family": "KleeOne",
                "font_postscript": "KleeOne-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KleeOne-SemiBold.ttf"
                },
                "font_name": "KleeOne SemiBold",
                "font_family": "KleeOne",
                "font_postscript": "KleeOne-SemiBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Knewave",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Knewave-Regular.ttf"
                },
                "font_name": "Knewave Regular",
                "font_family": "Knewave",
                "font_postscript": "Knewave-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KoHo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KoHo-Medium.ttf"
                },
                "font_name": "KoHo Medium",
                "font_family": "KoHo",
                "font_postscript": "KoHo-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KoHo-SemiBold.ttf"
                },
                "font_name": "KoHo SemiBold",
                "font_family": "KoHo",
                "font_postscript": "KoHo-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KoHo-Light.ttf"
                },
                "font_name": "KoHo Light",
                "font_family": "KoHo",
                "font_postscript": "KoHo-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KoHo-Bold.ttf"
                },
                "font_name": "KoHo Bold",
                "font_family": "KoHo",
                "font_postscript": "KoHo-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KoHo-ExtraLight.ttf"
                },
                "font_name": "KoHo ExtraLight",
                "font_family": "KoHo",
                "font_postscript": "KoHo-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KoHo-BoldItalic.ttf"
                },
                "font_name": "KoHo BoldItalic",
                "font_family": "KoHo",
                "font_postscript": "KoHo-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KoHo-ExtraLightItalic.ttf"
                },
                "font_name": "KoHo ExtraLightItalic",
                "font_family": "KoHo",
                "font_postscript": "KoHo-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KoHo-MediumItalic.ttf"
                },
                "font_name": "KoHo MediumItalic",
                "font_family": "KoHo",
                "font_postscript": "KoHo-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KoHo-Regular.ttf"
                },
                "font_name": "KoHo Regular",
                "font_family": "KoHo",
                "font_postscript": "KoHo-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KoHo-SemiBoldItalic.ttf"
                },
                "font_name": "KoHo SemiBoldItalic",
                "font_family": "KoHo",
                "font_postscript": "KoHo-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KoHo-LightItalic.ttf"
                },
                "font_name": "KoHo LightItalic",
                "font_family": "KoHo",
                "font_postscript": "KoHo-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KoHo-Italic.ttf"
                },
                "font_name": "KoHo Italic",
                "font_family": "KoHo",
                "font_postscript": "KoHo-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KoPubBatang",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KoPubBatang-Bold.ttf"
                },
                "font_name": "KoPubBatang Bold",
                "font_family": "KoPubBatang",
                "font_postscript": "KoPubBatang-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KoPubBatang-Regular.ttf"
                },
                "font_name": "KoPubBatang Regular",
                "font_family": "KoPubBatang",
                "font_postscript": "KoPubBatang-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KoPubBatang-Light.ttf"
                },
                "font_name": "KoPubBatang Light",
                "font_family": "KoPubBatang",
                "font_postscript": "KoPubBatang-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Kodchasan",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kodchasan-Italic.ttf"
                },
                "font_name": "Kodchasan Italic",
                "font_family": "Kodchasan",
                "font_postscript": "Kodchasan-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kodchasan-MediumItalic.ttf"
                },
                "font_name": "Kodchasan MediumItalic",
                "font_family": "Kodchasan",
                "font_postscript": "Kodchasan-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kodchasan-SemiBoldItalic.ttf"
                },
                "font_name": "Kodchasan SemiBoldItalic",
                "font_family": "Kodchasan",
                "font_postscript": "Kodchasan-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kodchasan-SemiBold.ttf"
                },
                "font_name": "Kodchasan SemiBold",
                "font_family": "Kodchasan",
                "font_postscript": "Kodchasan-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kodchasan-Bold.ttf"
                },
                "font_name": "Kodchasan Bold",
                "font_family": "Kodchasan",
                "font_postscript": "Kodchasan-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kodchasan-ExtraLightItalic.ttf"
                },
                "font_name": "Kodchasan ExtraLightItalic",
                "font_family": "Kodchasan",
                "font_postscript": "Kodchasan-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kodchasan-Regular.ttf"
                },
                "font_name": "Kodchasan Regular",
                "font_family": "Kodchasan",
                "font_postscript": "Kodchasan-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kodchasan-BoldItalic.ttf"
                },
                "font_name": "Kodchasan BoldItalic",
                "font_family": "Kodchasan",
                "font_postscript": "Kodchasan-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kodchasan-Medium.ttf"
                },
                "font_name": "Kodchasan Medium",
                "font_family": "Kodchasan",
                "font_postscript": "Kodchasan-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kodchasan-LightItalic.ttf"
                },
                "font_name": "Kodchasan LightItalic",
                "font_family": "Kodchasan",
                "font_postscript": "Kodchasan-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kodchasan-Light.ttf"
                },
                "font_name": "Kodchasan Light",
                "font_family": "Kodchasan",
                "font_postscript": "Kodchasan-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kodchasan-ExtraLight.ttf"
                },
                "font_name": "Kodchasan ExtraLight",
                "font_family": "Kodchasan",
                "font_postscript": "Kodchasan-ExtraLight",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KodeMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KodeMono-Regular.ttf"
                },
                "font_name": "KodeMono Regular",
                "font_family": "KodeMono",
                "font_postscript": "KodeMono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KohSantepheap",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KohSantepheap-Light.ttf"
                },
                "font_name": "KohSantepheap Light",
                "font_family": "KohSantepheap",
                "font_postscript": "KohSantepheap-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KohSantepheap-Bold.ttf"
                },
                "font_name": "KohSantepheap Bold",
                "font_family": "KohSantepheap",
                "font_postscript": "KohSantepheap-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KohSantepheap-Thin.ttf"
                },
                "font_name": "KohSantepheap Thin",
                "font_family": "KohSantepheap",
                "font_postscript": "KohSantepheap-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KohSantepheap-Regular.ttf"
                },
                "font_name": "KohSantepheap Regular",
                "font_family": "KohSantepheap",
                "font_postscript": "KohSantepheap-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KohSantepheap-Black.ttf"
                },
                "font_name": "KohSantepheap Black",
                "font_family": "KohSantepheap",
                "font_postscript": "KohSantepheap-Black",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Kokoro",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kokoro-Regular.ttf"
                },
                "font_name": "Kokoro Regular",
                "font_family": "Kokoro",
                "font_postscript": "Kokoro-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KolkerBrush",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KolkerBrush-Regular.ttf"
                },
                "font_name": "KolkerBrush Regular",
                "font_family": "KolkerBrush",
                "font_postscript": "KolkerBrush-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KonkhmerSleokchher",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KonkhmerSleokchher-Regular.ttf"
                },
                "font_name": "KonkhmerSleokchher Regular",
                "font_family": "KonkhmerSleokchher",
                "font_postscript": "KonkhmerSleokchher-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Kosugi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kosugi-Regular.ttf"
                },
                "font_name": "Kosugi Regular",
                "font_family": "Kosugi",
                "font_postscript": "Kosugi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KosugiMaru",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KosugiMaru-Regular.ttf"
                },
                "font_name": "KosugiMaru Regular",
                "font_family": "KosugiMaru",
                "font_postscript": "KosugiMaru-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KottaOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KottaOne-Regular.ttf"
                },
                "font_name": "KottaOne Regular",
                "font_family": "KottaOne",
                "font_postscript": "KottaOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Koulen",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Koulen-Regular.ttf"
                },
                "font_name": "Koulen Regular",
                "font_family": "Koulen",
                "font_postscript": "Koulen-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Kranky",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kranky-Regular.ttf"
                },
                "font_name": "Kranky Regular",
                "font_family": "Kranky",
                "font_postscript": "Kranky-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Kreon",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kreon-Regular.ttf"
                },
                "font_name": "Kreon Regular",
                "font_family": "Kreon",
                "font_postscript": "Kreon-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Kristi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kristi-Regular.ttf"
                },
                "font_name": "Kristi Regular",
                "font_family": "Kristi",
                "font_postscript": "Kristi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KronaOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KronaOne-Regular.ttf"
                },
                "font_name": "KronaOne Regular",
                "font_family": "KronaOne",
                "font_postscript": "KronaOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Krub",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Krub-Medium.ttf"
                },
                "font_name": "Krub Medium",
                "font_family": "Krub",
                "font_postscript": "Krub-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Krub-Regular.ttf"
                },
                "font_name": "Krub Regular",
                "font_family": "Krub",
                "font_postscript": "Krub-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Krub-SemiBold.ttf"
                },
                "font_name": "Krub SemiBold",
                "font_family": "Krub",
                "font_postscript": "Krub-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Krub-SemiBoldItalic.ttf"
                },
                "font_name": "Krub SemiBoldItalic",
                "font_family": "Krub",
                "font_postscript": "Krub-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Krub-Light.ttf"
                },
                "font_name": "Krub Light",
                "font_family": "Krub",
                "font_postscript": "Krub-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Krub-ExtraLight.ttf"
                },
                "font_name": "Krub ExtraLight",
                "font_family": "Krub",
                "font_postscript": "Krub-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Krub-BoldItalic.ttf"
                },
                "font_name": "Krub BoldItalic",
                "font_family": "Krub",
                "font_postscript": "Krub-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Krub-MediumItalic.ttf"
                },
                "font_name": "Krub MediumItalic",
                "font_family": "Krub",
                "font_postscript": "Krub-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Krub-ExtraLightItalic.ttf"
                },
                "font_name": "Krub ExtraLightItalic",
                "font_family": "Krub",
                "font_postscript": "Krub-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Krub-LightItalic.ttf"
                },
                "font_name": "Krub LightItalic",
                "font_family": "Krub",
                "font_postscript": "Krub-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Krub-Italic.ttf"
                },
                "font_name": "Krub Italic",
                "font_family": "Krub",
                "font_postscript": "Krub-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Krub-Bold.ttf"
                },
                "font_name": "Krub Bold",
                "font_family": "Krub",
                "font_postscript": "Krub-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Kufam",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kufam-Italic.ttf"
                },
                "font_name": "Kufam Italic",
                "font_family": "Kufam",
                "font_postscript": "Kufam-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kufam-Regular.ttf"
                },
                "font_name": "Kufam Regular",
                "font_family": "Kufam",
                "font_postscript": "Kufam-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KulimPark",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KulimPark-Italic.ttf"
                },
                "font_name": "KulimPark Italic",
                "font_family": "KulimPark",
                "font_postscript": "KulimPark-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KulimPark-Regular.ttf"
                },
                "font_name": "KulimPark Regular",
                "font_family": "KulimPark",
                "font_postscript": "KulimPark-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KulimPark-Bold.ttf"
                },
                "font_name": "KulimPark Bold",
                "font_family": "KulimPark",
                "font_postscript": "KulimPark-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KulimPark-ExtraLightItalic.ttf"
                },
                "font_name": "KulimPark ExtraLightItalic",
                "font_family": "KulimPark",
                "font_postscript": "KulimPark-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KulimPark-Light.ttf"
                },
                "font_name": "KulimPark Light",
                "font_family": "KulimPark",
                "font_postscript": "KulimPark-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KulimPark-BoldItalic.ttf"
                },
                "font_name": "KulimPark BoldItalic",
                "font_family": "KulimPark",
                "font_postscript": "KulimPark-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KulimPark-SemiBold.ttf"
                },
                "font_name": "KulimPark SemiBold",
                "font_family": "KulimPark",
                "font_postscript": "KulimPark-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KulimPark-ExtraLight.ttf"
                },
                "font_name": "KulimPark ExtraLight",
                "font_family": "KulimPark",
                "font_postscript": "KulimPark-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KulimPark-SemiBoldItalic.ttf"
                },
                "font_name": "KulimPark SemiBoldItalic",
                "font_family": "KulimPark",
                "font_postscript": "KulimPark-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "KulimPark-LightItalic.ttf"
                },
                "font_name": "KulimPark LightItalic",
                "font_family": "KulimPark",
                "font_postscript": "KulimPark-LightItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KumarOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KumarOne-Regular.ttf"
                },
                "font_name": "KumarOne Regular",
                "font_family": "KumarOne",
                "font_postscript": "KumarOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "KumbhSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "KumbhSans-Regular.ttf"
                },
                "font_name": "KumbhSans Regular",
                "font_family": "KumbhSans",
                "font_postscript": "KumbhSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Kurale",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Kurale-Regular.ttf"
                },
                "font_name": "Kurale Regular",
                "font_family": "Kurale",
                "font_postscript": "Kurale-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LXGWWenKaiMonoTC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LXGWWenKaiMonoTC-Regular.ttf"
                },
                "font_name": "LXGWWenKaiMonoTC Regular",
                "font_family": "LXGWWenKaiMonoTC",
                "font_postscript": "LXGWWenKaiMonoTC-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LXGWWenKaiMonoTC-Bold.ttf"
                },
                "font_name": "LXGWWenKaiMonoTC Bold",
                "font_family": "LXGWWenKaiMonoTC",
                "font_postscript": "LXGWWenKaiMonoTC-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LXGWWenKaiMonoTC-Light.ttf"
                },
                "font_name": "LXGWWenKaiMonoTC Light",
                "font_family": "LXGWWenKaiMonoTC",
                "font_postscript": "LXGWWenKaiMonoTC-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LXGWWenKaiTC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LXGWWenKaiTC-Bold.ttf"
                },
                "font_name": "LXGWWenKaiTC Bold",
                "font_family": "LXGWWenKaiTC",
                "font_postscript": "LXGWWenKaiTC-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LXGWWenKaiTC-Light.ttf"
                },
                "font_name": "LXGWWenKaiTC Light",
                "font_family": "LXGWWenKaiTC",
                "font_postscript": "LXGWWenKaiTC-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LXGWWenKaiTC-Regular.ttf"
                },
                "font_name": "LXGWWenKaiTC Regular",
                "font_family": "LXGWWenKaiTC",
                "font_postscript": "LXGWWenKaiTC-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LaBelleAurore",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LaBelleAurore-Regular.ttf"
                },
                "font_name": "LaBelleAurore Regular",
                "font_family": "LaBelleAurore",
                "font_postscript": "LaBelleAurore-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Labrada",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Labrada-Regular.ttf"
                },
                "font_name": "Labrada Regular",
                "font_family": "Labrada",
                "font_postscript": "Labrada-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Labrada-Italic.ttf"
                },
                "font_name": "Labrada Italic",
                "font_family": "Labrada",
                "font_postscript": "Labrada-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Lacquer",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lacquer-Regular.ttf"
                },
                "font_name": "Lacquer Regular",
                "font_family": "Lacquer",
                "font_postscript": "Lacquer-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Laila",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Laila-Medium.ttf"
                },
                "font_name": "Laila Medium",
                "font_family": "Laila",
                "font_postscript": "Laila-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Laila-Light.ttf"
                },
                "font_name": "Laila Light",
                "font_family": "Laila",
                "font_postscript": "Laila-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Laila-SemiBold.ttf"
                },
                "font_name": "Laila SemiBold",
                "font_family": "Laila",
                "font_postscript": "Laila-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Laila-Regular.ttf"
                },
                "font_name": "Laila Regular",
                "font_family": "Laila",
                "font_postscript": "Laila-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Laila-Bold.ttf"
                },
                "font_name": "Laila Bold",
                "font_family": "Laila",
                "font_postscript": "Laila-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LakkiReddy",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LakkiReddy-Regular.ttf"
                },
                "font_name": "LakkiReddy Regular",
                "font_family": "LakkiReddy",
                "font_postscript": "LakkiReddy-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Lalezar",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lalezar-Regular.ttf"
                },
                "font_name": "Lalezar Regular",
                "font_family": "Lalezar",
                "font_postscript": "Lalezar-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Lancelot",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lancelot-Regular.ttf"
                },
                "font_name": "Lancelot Regular",
                "font_family": "Lancelot",
                "font_postscript": "Lancelot-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Langar",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Langar-Regular.ttf"
                },
                "font_name": "Langar Regular",
                "font_family": "Langar",
                "font_postscript": "Langar-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Lateef",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lateef-ExtraBold.ttf"
                },
                "font_name": "Lateef ExtraBold",
                "font_family": "Lateef",
                "font_postscript": "Lateef-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lateef-Regular.ttf"
                },
                "font_name": "Lateef Regular",
                "font_family": "Lateef",
                "font_postscript": "Lateef-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lateef-Bold.ttf"
                },
                "font_name": "Lateef Bold",
                "font_family": "Lateef",
                "font_postscript": "Lateef-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lateef-Medium.ttf"
                },
                "font_name": "Lateef Medium",
                "font_family": "Lateef",
                "font_postscript": "Lateef-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lateef-ExtraLight.ttf"
                },
                "font_name": "Lateef ExtraLight",
                "font_family": "Lateef",
                "font_postscript": "Lateef-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lateef-Light.ttf"
                },
                "font_name": "Lateef Light",
                "font_family": "Lateef",
                "font_postscript": "Lateef-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lateef-SemiBold.ttf"
                },
                "font_name": "Lateef SemiBold",
                "font_family": "Lateef",
                "font_postscript": "Lateef-SemiBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Lato",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lato-Italic.ttf"
                },
                "font_name": "Lato Italic",
                "font_family": "Lato",
                "font_postscript": "Lato-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lato-LightItalic.ttf"
                },
                "font_name": "Lato LightItalic",
                "font_family": "Lato",
                "font_postscript": "Lato-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lato-Thin.ttf"
                },
                "font_name": "Lato Thin",
                "font_family": "Lato",
                "font_postscript": "Lato-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lato-Bold.ttf"
                },
                "font_name": "Lato Bold",
                "font_family": "Lato",
                "font_postscript": "Lato-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lato-Black.ttf"
                },
                "font_name": "Lato Black",
                "font_family": "Lato",
                "font_postscript": "Lato-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lato-ExtraBold.ttf"
                },
                "font_name": "Lato ExtraBold",
                "font_family": "Lato",
                "font_postscript": "Lato-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lato-SemiBoldItalic.ttf"
                },
                "font_name": "Lato SemiBoldItalic",
                "font_family": "Lato",
                "font_postscript": "Lato-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lato-Regular.ttf"
                },
                "font_name": "Lato Regular",
                "font_family": "Lato",
                "font_postscript": "Lato-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lato-BlackItalic.ttf"
                },
                "font_name": "Lato BlackItalic",
                "font_family": "Lato",
                "font_postscript": "Lato-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lato-ExtraLightItalic.ttf"
                },
                "font_name": "Lato ExtraLightItalic",
                "font_family": "Lato",
                "font_postscript": "Lato-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lato-SemiBold.ttf"
                },
                "font_name": "Lato SemiBold",
                "font_family": "Lato",
                "font_postscript": "Lato-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lato-MediumItalic.ttf"
                },
                "font_name": "Lato MediumItalic",
                "font_family": "Lato",
                "font_postscript": "Lato-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lato-BoldItalic.ttf"
                },
                "font_name": "Lato BoldItalic",
                "font_family": "Lato",
                "font_postscript": "Lato-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lato-ExtraBoldItalic.ttf"
                },
                "font_name": "Lato ExtraBoldItalic",
                "font_family": "Lato",
                "font_postscript": "Lato-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lato-Light.ttf"
                },
                "font_name": "Lato Light",
                "font_family": "Lato",
                "font_postscript": "Lato-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lato-Medium.ttf"
                },
                "font_name": "Lato Medium",
                "font_family": "Lato",
                "font_postscript": "Lato-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lato-ThinItalic.ttf"
                },
                "font_name": "Lato ThinItalic",
                "font_family": "Lato",
                "font_postscript": "Lato-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lato-ExtraLight.ttf"
                },
                "font_name": "Lato ExtraLight",
                "font_family": "Lato",
                "font_postscript": "Lato-ExtraLight",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LavishlyYours",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LavishlyYours-Regular.ttf"
                },
                "font_name": "LavishlyYours Regular",
                "font_family": "LavishlyYours",
                "font_postscript": "LavishlyYours-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LeagueGothic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LeagueGothic-Regular.ttf"
                },
                "font_name": "LeagueGothic Regular",
                "font_family": "LeagueGothic",
                "font_postscript": "LeagueGothic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LeagueScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LeagueScript-Regular.ttf"
                },
                "font_name": "LeagueScript Regular",
                "font_family": "LeagueScript",
                "font_postscript": "LeagueScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LeagueSpartan",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LeagueSpartan-Regular.ttf"
                },
                "font_name": "LeagueSpartan Regular",
                "font_family": "LeagueSpartan",
                "font_postscript": "LeagueSpartan-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LeckerliOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LeckerliOne-Regular.ttf"
                },
                "font_name": "LeckerliOne Regular",
                "font_family": "LeckerliOne",
                "font_postscript": "LeckerliOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Ledger",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ledger-Regular.ttf"
                },
                "font_name": "Ledger Regular",
                "font_family": "Ledger",
                "font_postscript": "Ledger-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Lekton",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lekton-Italic.ttf"
                },
                "font_name": "Lekton Italic",
                "font_family": "Lekton",
                "font_postscript": "Lekton-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lekton-Regular.ttf"
                },
                "font_name": "Lekton Regular",
                "font_family": "Lekton",
                "font_postscript": "Lekton-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lekton-Bold.ttf"
                },
                "font_name": "Lekton Bold",
                "font_family": "Lekton",
                "font_postscript": "Lekton-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Lemon",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lemon-Regular.ttf"
                },
                "font_name": "Lemon Regular",
                "font_family": "Lemon",
                "font_postscript": "Lemon-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Lemonada",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lemonada-Regular.ttf"
                },
                "font_name": "Lemonada Regular",
                "font_family": "Lemonada",
                "font_postscript": "Lemonada-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LemonadaVFBeta",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LemonadaVFBeta-Regular.ttf"
                },
                "font_name": "LemonadaVFBeta Regular",
                "font_family": "LemonadaVFBeta",
                "font_postscript": "LemonadaVFBeta-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Lexend",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lexend-Regular.ttf"
                },
                "font_name": "Lexend Regular",
                "font_family": "Lexend",
                "font_postscript": "Lexend-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LexendDeca",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LexendDeca-Regular.ttf"
                },
                "font_name": "LexendDeca Regular",
                "font_family": "LexendDeca",
                "font_postscript": "LexendDeca-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LexendExa",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LexendExa-Regular.ttf"
                },
                "font_name": "LexendExa Regular",
                "font_family": "LexendExa",
                "font_postscript": "LexendExa-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LexendGiga",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LexendGiga-Regular.ttf"
                },
                "font_name": "LexendGiga Regular",
                "font_family": "LexendGiga",
                "font_postscript": "LexendGiga-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LexendMega",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LexendMega-Regular.ttf"
                },
                "font_name": "LexendMega Regular",
                "font_family": "LexendMega",
                "font_postscript": "LexendMega-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LexendPeta",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LexendPeta-Regular.ttf"
                },
                "font_name": "LexendPeta Regular",
                "font_family": "LexendPeta",
                "font_postscript": "LexendPeta-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LexendTera",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LexendTera-Regular.ttf"
                },
                "font_name": "LexendTera Regular",
                "font_family": "LexendTera",
                "font_postscript": "LexendTera-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LexendZetta",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LexendZetta-Regular.ttf"
                },
                "font_name": "LexendZetta Regular",
                "font_family": "LexendZetta",
                "font_postscript": "LexendZetta-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LibreBarcode128",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LibreBarcode128-Regular.ttf"
                },
                "font_name": "LibreBarcode128 Regular",
                "font_family": "LibreBarcode128",
                "font_postscript": "LibreBarcode128-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LibreBarcode128Text",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LibreBarcode128Text-Regular.ttf"
                },
                "font_name": "LibreBarcode128Text Regular",
                "font_family": "LibreBarcode128Text",
                "font_postscript": "LibreBarcode128Text-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LibreBarcode39",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LibreBarcode39-Regular.ttf"
                },
                "font_name": "LibreBarcode39 Regular",
                "font_family": "LibreBarcode39",
                "font_postscript": "LibreBarcode39-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LibreBarcode39Extended",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LibreBarcode39Extended-Regular.ttf"
                },
                "font_name": "LibreBarcode39Extended Regular",
                "font_family": "LibreBarcode39Extended",
                "font_postscript": "LibreBarcode39Extended-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LibreBarcode39ExtendedText",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LibreBarcode39ExtendedText-Regular.ttf"
                },
                "font_name": "LibreBarcode39ExtendedText Regular",
                "font_family": "LibreBarcode39ExtendedText",
                "font_postscript": "LibreBarcode39ExtendedText-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LibreBarcode39Text",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LibreBarcode39Text-Regular.ttf"
                },
                "font_name": "LibreBarcode39Text Regular",
                "font_family": "LibreBarcode39Text",
                "font_postscript": "LibreBarcode39Text-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LibreBarcodeEAN13Text",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LibreBarcodeEAN13Text-Regular.ttf"
                },
                "font_name": "LibreBarcodeEAN13Text Regular",
                "font_family": "LibreBarcodeEAN13Text",
                "font_postscript": "LibreBarcodeEAN13Text-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LibreBaskerville",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LibreBaskerville-Italic.ttf"
                },
                "font_name": "LibreBaskerville Italic",
                "font_family": "LibreBaskerville",
                "font_postscript": "LibreBaskerville-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LibreBaskerville-Regular.ttf"
                },
                "font_name": "LibreBaskerville Regular",
                "font_family": "LibreBaskerville",
                "font_postscript": "LibreBaskerville-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LibreBaskerville-Bold.ttf"
                },
                "font_name": "LibreBaskerville Bold",
                "font_family": "LibreBaskerville",
                "font_postscript": "LibreBaskerville-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LibreBodoni",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LibreBodoni-Regular.ttf"
                },
                "font_name": "LibreBodoni Regular",
                "font_family": "LibreBodoni",
                "font_postscript": "LibreBodoni-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LibreBodoni-Italic.ttf"
                },
                "font_name": "LibreBodoni Italic",
                "font_family": "LibreBodoni",
                "font_postscript": "LibreBodoni-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LibreCaslonDisplay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LibreCaslonDisplay-Regular.ttf"
                },
                "font_name": "LibreCaslonDisplay Regular",
                "font_family": "LibreCaslonDisplay",
                "font_postscript": "LibreCaslonDisplay-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LibreCaslonText",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LibreCaslonText-Regular.ttf"
                },
                "font_name": "LibreCaslonText Regular",
                "font_family": "LibreCaslonText",
                "font_postscript": "LibreCaslonText-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LibreCaslonText-Italic.ttf"
                },
                "font_name": "LibreCaslonText Italic",
                "font_family": "LibreCaslonText",
                "font_postscript": "LibreCaslonText-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LibreFranklin",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LibreFranklin-Italic.ttf"
                },
                "font_name": "LibreFranklin Italic",
                "font_family": "LibreFranklin",
                "font_postscript": "LibreFranklin-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LibreFranklin-Regular.ttf"
                },
                "font_name": "LibreFranklin Regular",
                "font_family": "LibreFranklin",
                "font_postscript": "LibreFranklin-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Licorice",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Licorice-Regular.ttf"
                },
                "font_name": "Licorice Regular",
                "font_family": "Licorice",
                "font_postscript": "Licorice-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LifeSavers",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LifeSavers-ExtraBold.ttf"
                },
                "font_name": "LifeSavers ExtraBold",
                "font_family": "LifeSavers",
                "font_postscript": "LifeSavers-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LifeSavers-Regular.ttf"
                },
                "font_name": "LifeSavers Regular",
                "font_family": "LifeSavers",
                "font_postscript": "LifeSavers-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LifeSavers-Bold.ttf"
                },
                "font_name": "LifeSavers Bold",
                "font_family": "LifeSavers",
                "font_postscript": "LifeSavers-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Ligconsolata",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ligconsolata-Bold.ttf"
                },
                "font_name": "Ligconsolata Bold",
                "font_family": "Ligconsolata",
                "font_postscript": "Ligconsolata-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ligconsolata-Regular.ttf"
                },
                "font_name": "Ligconsolata Regular",
                "font_family": "Ligconsolata",
                "font_postscript": "Ligconsolata-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LilitaOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LilitaOne-Regular.ttf"
                },
                "font_name": "LilitaOne Regular",
                "font_family": "LilitaOne",
                "font_postscript": "LilitaOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LilyScriptOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LilyScriptOne-Regular.ttf"
                },
                "font_name": "LilyScriptOne Regular",
                "font_family": "LilyScriptOne",
                "font_postscript": "LilyScriptOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Limelight",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Limelight-Regular.ttf"
                },
                "font_name": "Limelight Regular",
                "font_family": "Limelight",
                "font_postscript": "Limelight-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LindenHill",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LindenHill-Regular.ttf"
                },
                "font_name": "LindenHill Regular",
                "font_family": "LindenHill",
                "font_postscript": "LindenHill-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LindenHill-Italic.ttf"
                },
                "font_name": "LindenHill Italic",
                "font_family": "LindenHill",
                "font_postscript": "LindenHill-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Linefont",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Linefont-Regular.ttf"
                },
                "font_name": "Linefont Regular",
                "font_family": "Linefont",
                "font_postscript": "Linefont-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LisuBosa",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LisuBosa-ExtraBoldItalic.ttf"
                },
                "font_name": "LisuBosa ExtraBoldItalic",
                "font_family": "LisuBosa",
                "font_postscript": "LisuBosa-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LisuBosa-LightItalic.ttf"
                },
                "font_name": "LisuBosa LightItalic",
                "font_family": "LisuBosa",
                "font_postscript": "LisuBosa-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LisuBosa-ExtraBold.ttf"
                },
                "font_name": "LisuBosa ExtraBold",
                "font_family": "LisuBosa",
                "font_postscript": "LisuBosa-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LisuBosa-Light.ttf"
                },
                "font_name": "LisuBosa Light",
                "font_family": "LisuBosa",
                "font_postscript": "LisuBosa-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LisuBosa-ExtraLight.ttf"
                },
                "font_name": "LisuBosa ExtraLight",
                "font_family": "LisuBosa",
                "font_postscript": "LisuBosa-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LisuBosa-Italic.ttf"
                },
                "font_name": "LisuBosa Italic",
                "font_family": "LisuBosa",
                "font_postscript": "LisuBosa-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LisuBosa-BoldItalic.ttf"
                },
                "font_name": "LisuBosa BoldItalic",
                "font_family": "LisuBosa",
                "font_postscript": "LisuBosa-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LisuBosa-ExtraLightItalic.ttf"
                },
                "font_name": "LisuBosa ExtraLightItalic",
                "font_family": "LisuBosa",
                "font_postscript": "LisuBosa-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LisuBosa-BlackItalic.ttf"
                },
                "font_name": "LisuBosa BlackItalic",
                "font_family": "LisuBosa",
                "font_postscript": "LisuBosa-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LisuBosa-SemiBoldItalic.ttf"
                },
                "font_name": "LisuBosa SemiBoldItalic",
                "font_family": "LisuBosa",
                "font_postscript": "LisuBosa-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LisuBosa-MediumItalic.ttf"
                },
                "font_name": "LisuBosa MediumItalic",
                "font_family": "LisuBosa",
                "font_postscript": "LisuBosa-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LisuBosa-SemiBold.ttf"
                },
                "font_name": "LisuBosa SemiBold",
                "font_family": "LisuBosa",
                "font_postscript": "LisuBosa-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LisuBosa-Black.ttf"
                },
                "font_name": "LisuBosa Black",
                "font_family": "LisuBosa",
                "font_postscript": "LisuBosa-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LisuBosa-Bold.ttf"
                },
                "font_name": "LisuBosa Bold",
                "font_family": "LisuBosa",
                "font_postscript": "LisuBosa-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LisuBosa-Regular.ttf"
                },
                "font_name": "LisuBosa Regular",
                "font_family": "LisuBosa",
                "font_postscript": "LisuBosa-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LisuBosa-Medium.ttf"
                },
                "font_name": "LisuBosa Medium",
                "font_family": "LisuBosa",
                "font_postscript": "LisuBosa-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Literata",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Literata-Italic.ttf"
                },
                "font_name": "Literata Italic",
                "font_family": "Literata",
                "font_postscript": "Literata-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Literata-Regular.ttf"
                },
                "font_name": "Literata Regular",
                "font_family": "Literata",
                "font_postscript": "Literata-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LiuJianMaoCao",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LiuJianMaoCao-Regular.ttf"
                },
                "font_name": "LiuJianMaoCao Regular",
                "font_family": "LiuJianMaoCao",
                "font_postscript": "LiuJianMaoCao-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Livvic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Livvic-Black.ttf"
                },
                "font_name": "Livvic Black",
                "font_family": "Livvic",
                "font_postscript": "Livvic-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Livvic-Medium.ttf"
                },
                "font_name": "Livvic Medium",
                "font_family": "Livvic",
                "font_postscript": "Livvic-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Livvic-Bold.ttf"
                },
                "font_name": "Livvic Bold",
                "font_family": "Livvic",
                "font_postscript": "Livvic-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Livvic-Thin.ttf"
                },
                "font_name": "Livvic Thin",
                "font_family": "Livvic",
                "font_postscript": "Livvic-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Livvic-SemiBold.ttf"
                },
                "font_name": "Livvic SemiBold",
                "font_family": "Livvic",
                "font_postscript": "Livvic-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Livvic-BlackItalic.ttf"
                },
                "font_name": "Livvic BlackItalic",
                "font_family": "Livvic",
                "font_postscript": "Livvic-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Livvic-Regular.ttf"
                },
                "font_name": "Livvic Regular",
                "font_family": "Livvic",
                "font_postscript": "Livvic-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Livvic-ExtraLightItalic.ttf"
                },
                "font_name": "Livvic ExtraLightItalic",
                "font_family": "Livvic",
                "font_postscript": "Livvic-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Livvic-MediumItalic.ttf"
                },
                "font_name": "Livvic MediumItalic",
                "font_family": "Livvic",
                "font_postscript": "Livvic-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Livvic-Italic.ttf"
                },
                "font_name": "Livvic Italic",
                "font_family": "Livvic",
                "font_postscript": "Livvic-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Livvic-SemiBoldItalic.ttf"
                },
                "font_name": "Livvic SemiBoldItalic",
                "font_family": "Livvic",
                "font_postscript": "Livvic-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Livvic-Light.ttf"
                },
                "font_name": "Livvic Light",
                "font_family": "Livvic",
                "font_postscript": "Livvic-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Livvic-ExtraLight.ttf"
                },
                "font_name": "Livvic ExtraLight",
                "font_family": "Livvic",
                "font_postscript": "Livvic-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Livvic-LightItalic.ttf"
                },
                "font_name": "Livvic LightItalic",
                "font_family": "Livvic",
                "font_postscript": "Livvic-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Livvic-ThinItalic.ttf"
                },
                "font_name": "Livvic ThinItalic",
                "font_family": "Livvic",
                "font_postscript": "Livvic-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Livvic-BoldItalic.ttf"
                },
                "font_name": "Livvic BoldItalic",
                "font_family": "Livvic",
                "font_postscript": "Livvic-BoldItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Lobster",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lobster-Regular.ttf"
                },
                "font_name": "Lobster Regular",
                "font_family": "Lobster",
                "font_postscript": "Lobster-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LobsterTwo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LobsterTwo-Bold.ttf"
                },
                "font_name": "LobsterTwo Bold",
                "font_family": "LobsterTwo",
                "font_postscript": "LobsterTwo-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LobsterTwo-Regular.ttf"
                },
                "font_name": "LobsterTwo Regular",
                "font_family": "LobsterTwo",
                "font_postscript": "LobsterTwo-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LobsterTwo-BoldItalic.ttf"
                },
                "font_name": "LobsterTwo BoldItalic",
                "font_family": "LobsterTwo",
                "font_postscript": "LobsterTwo-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LobsterTwo-Italic.ttf"
                },
                "font_name": "LobsterTwo Italic",
                "font_family": "LobsterTwo",
                "font_postscript": "LobsterTwo-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Lohit",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lohit-Bengali.ttf"
                },
                "font_name": "Lohit Bengali",
                "font_family": "Lohit",
                "font_postscript": "Lohit-Bengali",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lohit-Devanagari.ttf"
                },
                "font_name": "Lohit Devanagari",
                "font_family": "Lohit",
                "font_postscript": "Lohit-Devanagari",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lohit-Tamil.ttf"
                },
                "font_name": "Lohit Tamil",
                "font_family": "Lohit",
                "font_postscript": "Lohit-Tamil",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LondrinaOutline",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LondrinaOutline-Regular.ttf"
                },
                "font_name": "LondrinaOutline Regular",
                "font_family": "LondrinaOutline",
                "font_postscript": "LondrinaOutline-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LondrinaShadow",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LondrinaShadow-Regular.ttf"
                },
                "font_name": "LondrinaShadow Regular",
                "font_family": "LondrinaShadow",
                "font_postscript": "LondrinaShadow-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LondrinaSketch",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LondrinaSketch-Regular.ttf"
                },
                "font_name": "LondrinaSketch Regular",
                "font_family": "LondrinaSketch",
                "font_postscript": "LondrinaSketch-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LondrinaSolid",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LondrinaSolid-Black.ttf"
                },
                "font_name": "LondrinaSolid Black",
                "font_family": "LondrinaSolid",
                "font_postscript": "LondrinaSolid-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LondrinaSolid-Regular.ttf"
                },
                "font_name": "LondrinaSolid Regular",
                "font_family": "LondrinaSolid",
                "font_postscript": "LondrinaSolid-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LondrinaSolid-Thin.ttf"
                },
                "font_name": "LondrinaSolid Thin",
                "font_family": "LondrinaSolid",
                "font_postscript": "LondrinaSolid-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "LondrinaSolid-Light.ttf"
                },
                "font_name": "LondrinaSolid Light",
                "font_family": "LondrinaSolid",
                "font_postscript": "LondrinaSolid-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LongCang",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LongCang-Regular.ttf"
                },
                "font_name": "LongCang Regular",
                "font_family": "LongCang",
                "font_postscript": "LongCang-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Lora",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lora-Italic.ttf"
                },
                "font_name": "Lora Italic",
                "font_family": "Lora",
                "font_postscript": "Lora-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lora-Regular.ttf"
                },
                "font_name": "Lora Regular",
                "font_family": "Lora",
                "font_postscript": "Lora-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LoveLight",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LoveLight-Regular.ttf"
                },
                "font_name": "LoveLight Regular",
                "font_family": "LoveLight",
                "font_postscript": "LoveLight-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LoveYaLikeASister",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LoveYaLikeASister-Regular.ttf"
                },
                "font_name": "LoveYaLikeASister Regular",
                "font_family": "LoveYaLikeASister",
                "font_postscript": "LoveYaLikeASister-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LovedbytheKing",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LovedbytheKing-Regular.ttf"
                },
                "font_name": "LovedbytheKing Regular",
                "font_family": "LovedbytheKing",
                "font_postscript": "LovedbytheKing-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LoversQuarrel",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LoversQuarrel-Regular.ttf"
                },
                "font_name": "LoversQuarrel Regular",
                "font_family": "LoversQuarrel",
                "font_postscript": "LoversQuarrel-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LuckiestGuy",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LuckiestGuy-Regular.ttf"
                },
                "font_name": "LuckiestGuy Regular",
                "font_family": "LuckiestGuy",
                "font_postscript": "LuckiestGuy-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Lugrasimo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lugrasimo-Regular.ttf"
                },
                "font_name": "Lugrasimo Regular",
                "font_family": "Lugrasimo",
                "font_postscript": "Lugrasimo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Lumanosimo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lumanosimo-Regular.ttf"
                },
                "font_name": "Lumanosimo Regular",
                "font_family": "Lumanosimo",
                "font_postscript": "Lumanosimo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Lunasima",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lunasima-Regular.ttf"
                },
                "font_name": "Lunasima Regular",
                "font_family": "Lunasima",
                "font_postscript": "Lunasima-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lunasima-Bold.ttf"
                },
                "font_name": "Lunasima Bold",
                "font_family": "Lunasima",
                "font_postscript": "Lunasima-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Lusitana",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lusitana-Regular.ttf"
                },
                "font_name": "Lusitana Regular",
                "font_family": "Lusitana",
                "font_postscript": "Lusitana-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lusitana-Bold.ttf"
                },
                "font_name": "Lusitana Bold",
                "font_family": "Lusitana",
                "font_postscript": "Lusitana-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Lustria",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Lustria-Regular.ttf"
                },
                "font_name": "Lustria Regular",
                "font_family": "Lustria",
                "font_postscript": "Lustria-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LuxuriousRoman",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LuxuriousRoman-Regular.ttf"
                },
                "font_name": "LuxuriousRoman Regular",
                "font_family": "LuxuriousRoman",
                "font_postscript": "LuxuriousRoman-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "LuxuriousScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "LuxuriousScript-Regular.ttf"
                },
                "font_name": "LuxuriousScript Regular",
                "font_family": "LuxuriousScript",
                "font_postscript": "LuxuriousScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MPLUS1",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MPLUS1-Regular.ttf"
                },
                "font_name": "MPLUS1 Regular",
                "font_family": "MPLUS1",
                "font_postscript": "MPLUS1-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MPLUS1Code",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MPLUS1Code-Regular.ttf"
                },
                "font_name": "MPLUS1Code Regular",
                "font_family": "MPLUS1Code",
                "font_postscript": "MPLUS1Code-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MPLUS1p",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MPLUS1p-ExtraBold.ttf"
                },
                "font_name": "MPLUS1p ExtraBold",
                "font_family": "MPLUS1p",
                "font_postscript": "MPLUS1p-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MPLUS1p-Light.ttf"
                },
                "font_name": "MPLUS1p Light",
                "font_family": "MPLUS1p",
                "font_postscript": "MPLUS1p-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MPLUS1p-Thin.ttf"
                },
                "font_name": "MPLUS1p Thin",
                "font_family": "MPLUS1p",
                "font_postscript": "MPLUS1p-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MPLUS1p-Bold.ttf"
                },
                "font_name": "MPLUS1p Bold",
                "font_family": "MPLUS1p",
                "font_postscript": "MPLUS1p-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MPLUS1p-Black.ttf"
                },
                "font_name": "MPLUS1p Black",
                "font_family": "MPLUS1p",
                "font_postscript": "MPLUS1p-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MPLUS1p-Medium.ttf"
                },
                "font_name": "MPLUS1p Medium",
                "font_family": "MPLUS1p",
                "font_postscript": "MPLUS1p-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MPLUS1p-Regular.ttf"
                },
                "font_name": "MPLUS1p Regular",
                "font_family": "MPLUS1p",
                "font_postscript": "MPLUS1p-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MPLUS2",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MPLUS2-Regular.ttf"
                },
                "font_name": "MPLUS2 Regular",
                "font_family": "MPLUS2",
                "font_postscript": "MPLUS2-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MPLUSCodeLatin",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MPLUSCodeLatin-Regular.ttf"
                },
                "font_name": "MPLUSCodeLatin Regular",
                "font_family": "MPLUSCodeLatin",
                "font_postscript": "MPLUSCodeLatin-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MaShanZheng",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MaShanZheng-Regular.ttf"
                },
                "font_name": "MaShanZheng Regular",
                "font_family": "MaShanZheng",
                "font_postscript": "MaShanZheng-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Macondo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Macondo-Regular.ttf"
                },
                "font_name": "Macondo Regular",
                "font_family": "Macondo",
                "font_postscript": "Macondo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MacondoSwashCaps",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MacondoSwashCaps-Regular.ttf"
                },
                "font_name": "MacondoSwashCaps Regular",
                "font_family": "MacondoSwashCaps",
                "font_postscript": "MacondoSwashCaps-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Mada",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mada-Regular.ttf"
                },
                "font_name": "Mada Regular",
                "font_family": "Mada",
                "font_postscript": "Mada-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MadimiOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MadimiOne-Regular.ttf"
                },
                "font_name": "MadimiOne Regular",
                "font_family": "MadimiOne",
                "font_postscript": "MadimiOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Magra",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Magra-Bold.ttf"
                },
                "font_name": "Magra Bold",
                "font_family": "Magra",
                "font_postscript": "Magra-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Magra-Regular.ttf"
                },
                "font_name": "Magra Regular",
                "font_family": "Magra",
                "font_postscript": "Magra-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MaidenOrange",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MaidenOrange-Regular.ttf"
                },
                "font_name": "MaidenOrange Regular",
                "font_family": "MaidenOrange",
                "font_postscript": "MaidenOrange-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Maitree",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Maitree-SemiBold.ttf"
                },
                "font_name": "Maitree SemiBold",
                "font_family": "Maitree",
                "font_postscript": "Maitree-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Maitree-Bold.ttf"
                },
                "font_name": "Maitree Bold",
                "font_family": "Maitree",
                "font_postscript": "Maitree-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Maitree-Regular.ttf"
                },
                "font_name": "Maitree Regular",
                "font_family": "Maitree",
                "font_postscript": "Maitree-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Maitree-Medium.ttf"
                },
                "font_name": "Maitree Medium",
                "font_family": "Maitree",
                "font_postscript": "Maitree-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Maitree-ExtraLight.ttf"
                },
                "font_name": "Maitree ExtraLight",
                "font_family": "Maitree",
                "font_postscript": "Maitree-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Maitree-Light.ttf"
                },
                "font_name": "Maitree Light",
                "font_family": "Maitree",
                "font_postscript": "Maitree-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MajorMonoDisplay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MajorMonoDisplay-Regular.ttf"
                },
                "font_name": "MajorMonoDisplay Regular",
                "font_family": "MajorMonoDisplay",
                "font_postscript": "MajorMonoDisplay-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Mako",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mako-Regular.ttf"
                },
                "font_name": "Mako Regular",
                "font_family": "Mako",
                "font_postscript": "Mako-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Mali",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mali-ExtraLight.ttf"
                },
                "font_name": "Mali ExtraLight",
                "font_family": "Mali",
                "font_postscript": "Mali-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mali-Light.ttf"
                },
                "font_name": "Mali Light",
                "font_family": "Mali",
                "font_postscript": "Mali-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mali-BoldItalic.ttf"
                },
                "font_name": "Mali BoldItalic",
                "font_family": "Mali",
                "font_postscript": "Mali-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mali-Medium.ttf"
                },
                "font_name": "Mali Medium",
                "font_family": "Mali",
                "font_postscript": "Mali-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mali-Regular.ttf"
                },
                "font_name": "Mali Regular",
                "font_family": "Mali",
                "font_postscript": "Mali-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mali-LightItalic.ttf"
                },
                "font_name": "Mali LightItalic",
                "font_family": "Mali",
                "font_postscript": "Mali-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mali-MediumItalic.ttf"
                },
                "font_name": "Mali MediumItalic",
                "font_family": "Mali",
                "font_postscript": "Mali-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mali-SemiBold.ttf"
                },
                "font_name": "Mali SemiBold",
                "font_family": "Mali",
                "font_postscript": "Mali-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mali-SemiBoldItalic.ttf"
                },
                "font_name": "Mali SemiBoldItalic",
                "font_family": "Mali",
                "font_postscript": "Mali-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mali-ExtraLightItalic.ttf"
                },
                "font_name": "Mali ExtraLightItalic",
                "font_family": "Mali",
                "font_postscript": "Mali-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mali-Italic.ttf"
                },
                "font_name": "Mali Italic",
                "font_family": "Mali",
                "font_postscript": "Mali-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mali-Bold.ttf"
                },
                "font_name": "Mali Bold",
                "font_family": "Mali",
                "font_postscript": "Mali-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Mallanna",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mallanna-Regular.ttf"
                },
                "font_name": "Mallanna Regular",
                "font_family": "Mallanna",
                "font_postscript": "Mallanna-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Mandali",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mandali-Regular.ttf"
                },
                "font_name": "Mandali Regular",
                "font_family": "Mandali",
                "font_postscript": "Mandali-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Manjari",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Manjari-Bold.ttf"
                },
                "font_name": "Manjari Bold",
                "font_family": "Manjari",
                "font_postscript": "Manjari-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Manjari-Thin.ttf"
                },
                "font_name": "Manjari Thin",
                "font_family": "Manjari",
                "font_postscript": "Manjari-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Manjari-Regular.ttf"
                },
                "font_name": "Manjari Regular",
                "font_family": "Manjari",
                "font_postscript": "Manjari-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Manrope",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Manrope-Regular.ttf"
                },
                "font_name": "Manrope Regular",
                "font_family": "Manrope",
                "font_postscript": "Manrope-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Mansalva",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mansalva-Regular.ttf"
                },
                "font_name": "Mansalva Regular",
                "font_family": "Mansalva",
                "font_postscript": "Mansalva-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Manuale",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Manuale-Regular.ttf"
                },
                "font_name": "Manuale Regular",
                "font_family": "Manuale",
                "font_postscript": "Manuale-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Manuale-Italic.ttf"
                },
                "font_name": "Manuale Italic",
                "font_family": "Manuale",
                "font_postscript": "Manuale-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Marcellus",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Marcellus-Regular.ttf"
                },
                "font_name": "Marcellus Regular",
                "font_family": "Marcellus",
                "font_postscript": "Marcellus-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MarcellusSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MarcellusSC-Regular.ttf"
                },
                "font_name": "MarcellusSC Regular",
                "font_family": "MarcellusSC",
                "font_postscript": "MarcellusSC-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MarckScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MarckScript-Regular.ttf"
                },
                "font_name": "MarckScript Regular",
                "font_family": "MarckScript",
                "font_postscript": "MarckScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Margarine",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Margarine-Regular.ttf"
                },
                "font_name": "Margarine Regular",
                "font_family": "Margarine",
                "font_postscript": "Margarine-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Marhey",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Marhey-Regular.ttf"
                },
                "font_name": "Marhey Regular",
                "font_family": "Marhey",
                "font_postscript": "Marhey-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MarkaziText",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MarkaziText-VF.ttf"
                },
                "font_name": "MarkaziText VF",
                "font_family": "MarkaziText",
                "font_postscript": "MarkaziText-VF",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MarkaziText-Regular.ttf"
                },
                "font_name": "MarkaziText Regular",
                "font_family": "MarkaziText",
                "font_postscript": "MarkaziText-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MarkoOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MarkoOne-Regular.ttf"
                },
                "font_name": "MarkoOne Regular",
                "font_family": "MarkoOne",
                "font_postscript": "MarkoOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Marmelad",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Marmelad-Regular.ttf"
                },
                "font_name": "Marmelad Regular",
                "font_family": "Marmelad",
                "font_postscript": "Marmelad-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Martel",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Martel-Heavy.ttf"
                },
                "font_name": "Martel Heavy",
                "font_family": "Martel",
                "font_postscript": "Martel-Heavy",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Martel-Regular.ttf"
                },
                "font_name": "Martel Regular",
                "font_family": "Martel",
                "font_postscript": "Martel-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Martel-Light.ttf"
                },
                "font_name": "Martel Light",
                "font_family": "Martel",
                "font_postscript": "Martel-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Martel-Bold.ttf"
                },
                "font_name": "Martel Bold",
                "font_family": "Martel",
                "font_postscript": "Martel-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Martel-ExtraBold.ttf"
                },
                "font_name": "Martel ExtraBold",
                "font_family": "Martel",
                "font_postscript": "Martel-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Martel-DemiBold.ttf"
                },
                "font_name": "Martel DemiBold",
                "font_family": "Martel",
                "font_postscript": "Martel-DemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Martel-UltraLight.ttf"
                },
                "font_name": "Martel UltraLight",
                "font_family": "Martel",
                "font_postscript": "Martel-UltraLight",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MartelSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MartelSans-Black.ttf"
                },
                "font_name": "MartelSans Black",
                "font_family": "MartelSans",
                "font_postscript": "MartelSans-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MartelSans-ExtraBold.ttf"
                },
                "font_name": "MartelSans ExtraBold",
                "font_family": "MartelSans",
                "font_postscript": "MartelSans-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MartelSans-Bold.ttf"
                },
                "font_name": "MartelSans Bold",
                "font_family": "MartelSans",
                "font_postscript": "MartelSans-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MartelSans-Regular.ttf"
                },
                "font_name": "MartelSans Regular",
                "font_family": "MartelSans",
                "font_postscript": "MartelSans-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MartelSans-Light.ttf"
                },
                "font_name": "MartelSans Light",
                "font_family": "MartelSans",
                "font_postscript": "MartelSans-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MartelSans-SemiBold.ttf"
                },
                "font_name": "MartelSans SemiBold",
                "font_family": "MartelSans",
                "font_postscript": "MartelSans-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MartelSans-ExtraLight.ttf"
                },
                "font_name": "MartelSans ExtraLight",
                "font_family": "MartelSans",
                "font_postscript": "MartelSans-ExtraLight",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MartianMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MartianMono-Regular.ttf"
                },
                "font_name": "MartianMono Regular",
                "font_family": "MartianMono",
                "font_postscript": "MartianMono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Marvel",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Marvel-BoldItalic.ttf"
                },
                "font_name": "Marvel BoldItalic",
                "font_family": "Marvel",
                "font_postscript": "Marvel-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Marvel-Bold.ttf"
                },
                "font_name": "Marvel Bold",
                "font_family": "Marvel",
                "font_postscript": "Marvel-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Marvel-Regular.ttf"
                },
                "font_name": "Marvel Regular",
                "font_family": "Marvel",
                "font_postscript": "Marvel-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Marvel-Italic.ttf"
                },
                "font_name": "Marvel Italic",
                "font_family": "Marvel",
                "font_postscript": "Marvel-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Mate",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mate-Regular.ttf"
                },
                "font_name": "Mate Regular",
                "font_family": "Mate",
                "font_postscript": "Mate-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mate-Italic.ttf"
                },
                "font_name": "Mate Italic",
                "font_family": "Mate",
                "font_postscript": "Mate-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MateSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MateSC-Regular.ttf"
                },
                "font_name": "MateSC Regular",
                "font_family": "MateSC",
                "font_postscript": "MateSC-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MavenPro",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MavenPro-Regular.ttf"
                },
                "font_name": "MavenPro Regular",
                "font_family": "MavenPro",
                "font_postscript": "MavenPro-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MavenPro-Regular.ttf"
                },
                "font_name": "MavenPro Regular",
                "font_family": "MavenPro",
                "font_postscript": "MavenPro-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MavenProVFBeta",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MavenProVFBeta-Regular.ttf"
                },
                "font_name": "MavenProVFBeta Regular",
                "font_family": "MavenProVFBeta",
                "font_postscript": "MavenProVFBeta-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "McLaren",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "McLaren-Regular.ttf"
                },
                "font_name": "McLaren Regular",
                "font_family": "McLaren",
                "font_postscript": "McLaren-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MeaCulpa",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MeaCulpa-Regular.ttf"
                },
                "font_name": "MeaCulpa Regular",
                "font_family": "MeaCulpa",
                "font_postscript": "MeaCulpa-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Meddon",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Meddon-Regular.ttf"
                },
                "font_name": "Meddon Regular",
                "font_family": "Meddon",
                "font_postscript": "Meddon-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MedievalSharp",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MedievalSharp-Regular.ttf"
                },
                "font_name": "MedievalSharp Regular",
                "font_family": "MedievalSharp",
                "font_postscript": "MedievalSharp-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MedulaOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MedulaOne-Regular.ttf"
                },
                "font_name": "MedulaOne Regular",
                "font_family": "MedulaOne",
                "font_postscript": "MedulaOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MeeraInimai",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MeeraInimai-Regular.ttf"
                },
                "font_name": "MeeraInimai Regular",
                "font_family": "MeeraInimai",
                "font_postscript": "MeeraInimai-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Megrim",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Megrim-Regular.ttf"
                },
                "font_name": "Megrim Regular",
                "font_family": "Megrim",
                "font_postscript": "Megrim-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MeieScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MeieScript-Regular.ttf"
                },
                "font_name": "MeieScript Regular",
                "font_family": "MeieScript",
                "font_postscript": "MeieScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MeowScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MeowScript-Regular.ttf"
                },
                "font_name": "MeowScript Regular",
                "font_family": "MeowScript",
                "font_postscript": "MeowScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MergeOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MergeOne-Regular.ttf"
                },
                "font_name": "MergeOne Regular",
                "font_family": "MergeOne",
                "font_postscript": "MergeOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Merienda",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Merienda-Regular.ttf"
                },
                "font_name": "Merienda Regular",
                "font_family": "Merienda",
                "font_postscript": "Merienda-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Merriweather",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Merriweather-Bold.ttf"
                },
                "font_name": "Merriweather Bold",
                "font_family": "Merriweather",
                "font_postscript": "Merriweather-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Merriweather-Regular.ttf"
                },
                "font_name": "Merriweather Regular",
                "font_family": "Merriweather",
                "font_postscript": "Merriweather-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Merriweather-Light.ttf"
                },
                "font_name": "Merriweather Light",
                "font_family": "Merriweather",
                "font_postscript": "Merriweather-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Merriweather-BoldItalic.ttf"
                },
                "font_name": "Merriweather BoldItalic",
                "font_family": "Merriweather",
                "font_postscript": "Merriweather-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Merriweather-BlackItalic.ttf"
                },
                "font_name": "Merriweather BlackItalic",
                "font_family": "Merriweather",
                "font_postscript": "Merriweather-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Merriweather-Italic.ttf"
                },
                "font_name": "Merriweather Italic",
                "font_family": "Merriweather",
                "font_postscript": "Merriweather-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Merriweather-Black.ttf"
                },
                "font_name": "Merriweather Black",
                "font_family": "Merriweather",
                "font_postscript": "Merriweather-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Merriweather-LightItalic.ttf"
                },
                "font_name": "Merriweather LightItalic",
                "font_family": "Merriweather",
                "font_postscript": "Merriweather-LightItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MerriweatherSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MerriweatherSans-Regular.ttf"
                },
                "font_name": "MerriweatherSans Regular",
                "font_family": "MerriweatherSans",
                "font_postscript": "MerriweatherSans-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MerriweatherSans-Italic.ttf"
                },
                "font_name": "MerriweatherSans Italic",
                "font_family": "MerriweatherSans",
                "font_postscript": "MerriweatherSans-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MervaleScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MervaleScript-Regular.ttf"
                },
                "font_name": "MervaleScript Regular",
                "font_family": "MervaleScript",
                "font_postscript": "MervaleScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Metal",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Metal-Regular.ttf"
                },
                "font_name": "Metal Regular",
                "font_family": "Metal",
                "font_postscript": "Metal-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MetalMania",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MetalMania-Regular.ttf"
                },
                "font_name": "MetalMania Regular",
                "font_family": "MetalMania",
                "font_postscript": "MetalMania-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Metamorphous",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Metamorphous-Regular.ttf"
                },
                "font_name": "Metamorphous Regular",
                "font_family": "Metamorphous",
                "font_postscript": "Metamorphous-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Metrophobic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Metrophobic-Regular.ttf"
                },
                "font_name": "Metrophobic Regular",
                "font_family": "Metrophobic",
                "font_postscript": "Metrophobic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Miama",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Miama-Regular.ttf"
                },
                "font_name": "Miama Regular",
                "font_family": "Miama",
                "font_postscript": "Miama-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Michroma",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Michroma-Regular.ttf"
                },
                "font_name": "Michroma Regular",
                "font_family": "Michroma",
                "font_postscript": "Michroma-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Micro5",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Micro5-Regular.ttf"
                },
                "font_name": "Micro5 Regular",
                "font_family": "Micro5",
                "font_postscript": "Micro5-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Micro5Charted",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Micro5Charted-Regular.ttf"
                },
                "font_name": "Micro5Charted Regular",
                "font_family": "Micro5Charted",
                "font_postscript": "Micro5Charted-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Milonga",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Milonga-Regular.ttf"
                },
                "font_name": "Milonga Regular",
                "font_family": "Milonga",
                "font_postscript": "Milonga-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Miltonian",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Miltonian-Regular.ttf"
                },
                "font_name": "Miltonian Regular",
                "font_family": "Miltonian",
                "font_postscript": "Miltonian-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MiltonianTattoo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MiltonianTattoo-Regular.ttf"
                },
                "font_name": "MiltonianTattoo Regular",
                "font_family": "MiltonianTattoo",
                "font_postscript": "MiltonianTattoo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Mina",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mina-Regular.ttf"
                },
                "font_name": "Mina Regular",
                "font_family": "Mina",
                "font_postscript": "Mina-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mina-Bold.ttf"
                },
                "font_name": "Mina Bold",
                "font_family": "Mina",
                "font_postscript": "Mina-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Mingzat",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mingzat-Regular.ttf"
                },
                "font_name": "Mingzat Regular",
                "font_family": "Mingzat",
                "font_postscript": "Mingzat-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Miniver",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Miniver-Regular.ttf"
                },
                "font_name": "Miniver Regular",
                "font_family": "Miniver",
                "font_postscript": "Miniver-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MiriamLibre",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MiriamLibre-Bold.ttf"
                },
                "font_name": "MiriamLibre Bold",
                "font_family": "MiriamLibre",
                "font_postscript": "MiriamLibre-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MiriamLibre-Regular.ttf"
                },
                "font_name": "MiriamLibre Regular",
                "font_family": "MiriamLibre",
                "font_postscript": "MiriamLibre-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Mirza",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mirza-Regular.ttf"
                },
                "font_name": "Mirza Regular",
                "font_family": "Mirza",
                "font_postscript": "Mirza-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mirza-Medium.ttf"
                },
                "font_name": "Mirza Medium",
                "font_family": "Mirza",
                "font_postscript": "Mirza-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mirza-Bold.ttf"
                },
                "font_name": "Mirza Bold",
                "font_family": "Mirza",
                "font_postscript": "Mirza-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mirza-SemiBold.ttf"
                },
                "font_name": "Mirza SemiBold",
                "font_family": "Mirza",
                "font_postscript": "Mirza-SemiBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MissFajardose",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MissFajardose-Regular.ttf"
                },
                "font_name": "MissFajardose Regular",
                "font_family": "MissFajardose",
                "font_postscript": "MissFajardose-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Mitr",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mitr-Regular.ttf"
                },
                "font_name": "Mitr Regular",
                "font_family": "Mitr",
                "font_postscript": "Mitr-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mitr-Bold.ttf"
                },
                "font_name": "Mitr Bold",
                "font_family": "Mitr",
                "font_postscript": "Mitr-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mitr-Medium.ttf"
                },
                "font_name": "Mitr Medium",
                "font_family": "Mitr",
                "font_postscript": "Mitr-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mitr-Light.ttf"
                },
                "font_name": "Mitr Light",
                "font_family": "Mitr",
                "font_postscript": "Mitr-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mitr-SemiBold.ttf"
                },
                "font_name": "Mitr SemiBold",
                "font_family": "Mitr",
                "font_postscript": "Mitr-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mitr-ExtraLight.ttf"
                },
                "font_name": "Mitr ExtraLight",
                "font_family": "Mitr",
                "font_postscript": "Mitr-ExtraLight",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MochiyPopOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MochiyPopOne-Regular.ttf"
                },
                "font_name": "MochiyPopOne Regular",
                "font_family": "MochiyPopOne",
                "font_postscript": "MochiyPopOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MochiyPopPOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MochiyPopPOne-Regular.ttf"
                },
                "font_name": "MochiyPopPOne Regular",
                "font_family": "MochiyPopPOne",
                "font_postscript": "MochiyPopPOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Modak",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Modak-Regular.ttf"
                },
                "font_name": "Modak Regular",
                "font_family": "Modak",
                "font_postscript": "Modak-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ModernAntiqua",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ModernAntiqua-Regular.ttf"
                },
                "font_name": "ModernAntiqua Regular",
                "font_family": "ModernAntiqua",
                "font_postscript": "ModernAntiqua-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Mogra",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mogra-Regular.ttf"
                },
                "font_name": "Mogra Regular",
                "font_family": "Mogra",
                "font_postscript": "Mogra-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Mohave",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mohave-Italic.ttf"
                },
                "font_name": "Mohave Italic",
                "font_family": "Mohave",
                "font_postscript": "Mohave-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mohave-Regular.ttf"
                },
                "font_name": "Mohave Regular",
                "font_family": "Mohave",
                "font_postscript": "Mohave-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MoiraiOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MoiraiOne-Regular.ttf"
                },
                "font_name": "MoiraiOne Regular",
                "font_family": "MoiraiOne",
                "font_postscript": "MoiraiOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Molengo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Molengo-Regular.ttf"
                },
                "font_name": "Molengo Regular",
                "font_family": "Molengo",
                "font_postscript": "Molengo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Molle",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Molle-Regular.ttf"
                },
                "font_name": "Molle Regular",
                "font_family": "Molle",
                "font_postscript": "Molle-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Monda",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Monda-Regular.ttf"
                },
                "font_name": "Monda Regular",
                "font_family": "Monda",
                "font_postscript": "Monda-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Monofett",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Monofett-Regular.ttf"
                },
                "font_name": "Monofett Regular",
                "font_family": "Monofett",
                "font_postscript": "Monofett-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MonomaniacOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MonomaniacOne-Regular.ttf"
                },
                "font_name": "MonomaniacOne Regular",
                "font_family": "MonomaniacOne",
                "font_postscript": "MonomaniacOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Monoton",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Monoton-Regular.ttf"
                },
                "font_name": "Monoton Regular",
                "font_family": "Monoton",
                "font_postscript": "Monoton-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MonsieurLaDoulaise",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MonsieurLaDoulaise-Regular.ttf"
                },
                "font_name": "MonsieurLaDoulaise Regular",
                "font_family": "MonsieurLaDoulaise",
                "font_postscript": "MonsieurLaDoulaise-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Montaga",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Montaga-Regular.ttf"
                },
                "font_name": "Montaga Regular",
                "font_family": "Montaga",
                "font_postscript": "Montaga-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MontaguSlab",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MontaguSlab-Regular.ttf"
                },
                "font_name": "MontaguSlab Regular",
                "font_family": "MontaguSlab",
                "font_postscript": "MontaguSlab-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MonteCarlo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MonteCarlo-Regular.ttf"
                },
                "font_name": "MonteCarlo Regular",
                "font_family": "MonteCarlo",
                "font_postscript": "MonteCarlo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Montez",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Montez-Regular.ttf"
                },
                "font_name": "Montez Regular",
                "font_family": "Montez",
                "font_postscript": "Montez-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Montserrat",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Montserrat-Italic.ttf"
                },
                "font_name": "Montserrat Italic",
                "font_family": "Montserrat",
                "font_postscript": "Montserrat-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Montserrat-Regular.ttf"
                },
                "font_name": "Montserrat Regular",
                "font_family": "Montserrat",
                "font_postscript": "Montserrat-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MontserratAlternates",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MontserratAlternates-Thin.ttf"
                },
                "font_name": "MontserratAlternates Thin",
                "font_family": "MontserratAlternates",
                "font_postscript": "MontserratAlternates-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MontserratAlternates-BlackItalic.ttf"
                },
                "font_name": "MontserratAlternates BlackItalic",
                "font_family": "MontserratAlternates",
                "font_postscript": "MontserratAlternates-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MontserratAlternates-Bold.ttf"
                },
                "font_name": "MontserratAlternates Bold",
                "font_family": "MontserratAlternates",
                "font_postscript": "MontserratAlternates-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MontserratAlternates-ExtraLightItalic.ttf"
                },
                "font_name": "MontserratAlternates ExtraLightItalic",
                "font_family": "MontserratAlternates",
                "font_postscript": "MontserratAlternates-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MontserratAlternates-Black.ttf"
                },
                "font_name": "MontserratAlternates Black",
                "font_family": "MontserratAlternates",
                "font_postscript": "MontserratAlternates-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MontserratAlternates-LightItalic.ttf"
                },
                "font_name": "MontserratAlternates LightItalic",
                "font_family": "MontserratAlternates",
                "font_postscript": "MontserratAlternates-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MontserratAlternates-Medium.ttf"
                },
                "font_name": "MontserratAlternates Medium",
                "font_family": "MontserratAlternates",
                "font_postscript": "MontserratAlternates-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MontserratAlternates-MediumItalic.ttf"
                },
                "font_name": "MontserratAlternates MediumItalic",
                "font_family": "MontserratAlternates",
                "font_postscript": "MontserratAlternates-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MontserratAlternates-Italic.ttf"
                },
                "font_name": "MontserratAlternates Italic",
                "font_family": "MontserratAlternates",
                "font_postscript": "MontserratAlternates-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MontserratAlternates-ExtraBoldItalic.ttf"
                },
                "font_name": "MontserratAlternates ExtraBoldItalic",
                "font_family": "MontserratAlternates",
                "font_postscript": "MontserratAlternates-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MontserratAlternates-BoldItalic.ttf"
                },
                "font_name": "MontserratAlternates BoldItalic",
                "font_family": "MontserratAlternates",
                "font_postscript": "MontserratAlternates-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MontserratAlternates-Light.ttf"
                },
                "font_name": "MontserratAlternates Light",
                "font_family": "MontserratAlternates",
                "font_postscript": "MontserratAlternates-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MontserratAlternates-ExtraLight.ttf"
                },
                "font_name": "MontserratAlternates ExtraLight",
                "font_family": "MontserratAlternates",
                "font_postscript": "MontserratAlternates-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MontserratAlternates-Regular.ttf"
                },
                "font_name": "MontserratAlternates Regular",
                "font_family": "MontserratAlternates",
                "font_postscript": "MontserratAlternates-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MontserratAlternates-ThinItalic.ttf"
                },
                "font_name": "MontserratAlternates ThinItalic",
                "font_family": "MontserratAlternates",
                "font_postscript": "MontserratAlternates-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MontserratAlternates-SemiBold.ttf"
                },
                "font_name": "MontserratAlternates SemiBold",
                "font_family": "MontserratAlternates",
                "font_postscript": "MontserratAlternates-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MontserratAlternates-ExtraBold.ttf"
                },
                "font_name": "MontserratAlternates ExtraBold",
                "font_family": "MontserratAlternates",
                "font_postscript": "MontserratAlternates-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MontserratAlternates-SemiBoldItalic.ttf"
                },
                "font_name": "MontserratAlternates SemiBoldItalic",
                "font_family": "MontserratAlternates",
                "font_postscript": "MontserratAlternates-SemiBoldItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MontserratSubrayada",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MontserratSubrayada-Bold.ttf"
                },
                "font_name": "MontserratSubrayada Bold",
                "font_family": "MontserratSubrayada",
                "font_postscript": "MontserratSubrayada-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MontserratSubrayada-Regular.ttf"
                },
                "font_name": "MontserratSubrayada Regular",
                "font_family": "MontserratSubrayada",
                "font_postscript": "MontserratSubrayada-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MooLahLah",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MooLahLah-Regular.ttf"
                },
                "font_name": "MooLahLah Regular",
                "font_family": "MooLahLah",
                "font_postscript": "MooLahLah-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Mooli",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mooli-Regular.ttf"
                },
                "font_name": "Mooli Regular",
                "font_family": "Mooli",
                "font_postscript": "Mooli-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MoonDance",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MoonDance-Regular.ttf"
                },
                "font_name": "MoonDance Regular",
                "font_family": "MoonDance",
                "font_postscript": "MoonDance-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Moul",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Moul-Regular.ttf"
                },
                "font_name": "Moul Regular",
                "font_family": "Moul",
                "font_postscript": "Moul-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Moulpali",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Moulpali-Regular.ttf"
                },
                "font_name": "Moulpali Regular",
                "font_family": "Moulpali",
                "font_postscript": "Moulpali-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MountainsofChristmas",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MountainsofChristmas-Regular.ttf"
                },
                "font_name": "MountainsofChristmas Regular",
                "font_family": "MountainsofChristmas",
                "font_postscript": "MountainsofChristmas-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MountainsofChristmas-Bold.ttf"
                },
                "font_name": "MountainsofChristmas Bold",
                "font_family": "MountainsofChristmas",
                "font_postscript": "MountainsofChristmas-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MouseMemoirs",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MouseMemoirs-Regular.ttf"
                },
                "font_name": "MouseMemoirs Regular",
                "font_family": "MouseMemoirs",
                "font_postscript": "MouseMemoirs-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MrBedfort",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MrBedfort-Regular.ttf"
                },
                "font_name": "MrBedfort Regular",
                "font_family": "MrBedfort",
                "font_postscript": "MrBedfort-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MrDafoe",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MrDafoe-Regular.ttf"
                },
                "font_name": "MrDafoe Regular",
                "font_family": "MrDafoe",
                "font_postscript": "MrDafoe-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MrDeHaviland",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MrDeHaviland-Regular.ttf"
                },
                "font_name": "MrDeHaviland Regular",
                "font_family": "MrDeHaviland",
                "font_postscript": "MrDeHaviland-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MrsSaintDelafield",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MrsSaintDelafield-Regular.ttf"
                },
                "font_name": "MrsSaintDelafield Regular",
                "font_family": "MrsSaintDelafield",
                "font_postscript": "MrsSaintDelafield-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MrsSheppards",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MrsSheppards-Regular.ttf"
                },
                "font_name": "MrsSheppards Regular",
                "font_family": "MrsSheppards",
                "font_postscript": "MrsSheppards-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MsMadi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MsMadi-Regular.ttf"
                },
                "font_name": "MsMadi Regular",
                "font_family": "MsMadi",
                "font_postscript": "MsMadi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Mukta",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mukta-ExtraBold.ttf"
                },
                "font_name": "Mukta ExtraBold",
                "font_family": "Mukta",
                "font_postscript": "Mukta-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mukta-SemiBold.ttf"
                },
                "font_name": "Mukta SemiBold",
                "font_family": "Mukta",
                "font_postscript": "Mukta-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mukta-Bold.ttf"
                },
                "font_name": "Mukta Bold",
                "font_family": "Mukta",
                "font_postscript": "Mukta-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mukta-ExtraLight.ttf"
                },
                "font_name": "Mukta ExtraLight",
                "font_family": "Mukta",
                "font_postscript": "Mukta-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mukta-Light.ttf"
                },
                "font_name": "Mukta Light",
                "font_family": "Mukta",
                "font_postscript": "Mukta-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mukta-Medium.ttf"
                },
                "font_name": "Mukta Medium",
                "font_family": "Mukta",
                "font_postscript": "Mukta-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mukta-Regular.ttf"
                },
                "font_name": "Mukta Regular",
                "font_family": "Mukta",
                "font_postscript": "Mukta-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MuktaMahee",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuktaMahee-Regular.ttf"
                },
                "font_name": "MuktaMahee Regular",
                "font_family": "MuktaMahee",
                "font_postscript": "MuktaMahee-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuktaMahee-ExtraBold.ttf"
                },
                "font_name": "MuktaMahee ExtraBold",
                "font_family": "MuktaMahee",
                "font_postscript": "MuktaMahee-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuktaMahee-Light.ttf"
                },
                "font_name": "MuktaMahee Light",
                "font_family": "MuktaMahee",
                "font_postscript": "MuktaMahee-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuktaMahee-Bold.ttf"
                },
                "font_name": "MuktaMahee Bold",
                "font_family": "MuktaMahee",
                "font_postscript": "MuktaMahee-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuktaMahee-Medium.ttf"
                },
                "font_name": "MuktaMahee Medium",
                "font_family": "MuktaMahee",
                "font_postscript": "MuktaMahee-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuktaMahee-SemiBold.ttf"
                },
                "font_name": "MuktaMahee SemiBold",
                "font_family": "MuktaMahee",
                "font_postscript": "MuktaMahee-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuktaMahee-ExtraLight.ttf"
                },
                "font_name": "MuktaMahee ExtraLight",
                "font_family": "MuktaMahee",
                "font_postscript": "MuktaMahee-ExtraLight",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MuktaMalar",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuktaMalar-SemiBold.ttf"
                },
                "font_name": "MuktaMalar SemiBold",
                "font_family": "MuktaMalar",
                "font_postscript": "MuktaMalar-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuktaMalar-Bold.ttf"
                },
                "font_name": "MuktaMalar Bold",
                "font_family": "MuktaMalar",
                "font_postscript": "MuktaMalar-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuktaMalar-Medium.ttf"
                },
                "font_name": "MuktaMalar Medium",
                "font_family": "MuktaMalar",
                "font_postscript": "MuktaMalar-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuktaMalar-ExtraBold.ttf"
                },
                "font_name": "MuktaMalar ExtraBold",
                "font_family": "MuktaMalar",
                "font_postscript": "MuktaMalar-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuktaMalar-Regular.ttf"
                },
                "font_name": "MuktaMalar Regular",
                "font_family": "MuktaMalar",
                "font_postscript": "MuktaMalar-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuktaMalar-Light.ttf"
                },
                "font_name": "MuktaMalar Light",
                "font_family": "MuktaMalar",
                "font_postscript": "MuktaMalar-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuktaMalar-ExtraLight.ttf"
                },
                "font_name": "MuktaMalar ExtraLight",
                "font_family": "MuktaMalar",
                "font_postscript": "MuktaMalar-ExtraLight",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MuktaVaani",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuktaVaani-SemiBold.ttf"
                },
                "font_name": "MuktaVaani SemiBold",
                "font_family": "MuktaVaani",
                "font_postscript": "MuktaVaani-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuktaVaani-Regular.ttf"
                },
                "font_name": "MuktaVaani Regular",
                "font_family": "MuktaVaani",
                "font_postscript": "MuktaVaani-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuktaVaani-ExtraLight.ttf"
                },
                "font_name": "MuktaVaani ExtraLight",
                "font_family": "MuktaVaani",
                "font_postscript": "MuktaVaani-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuktaVaani-ExtraBold.ttf"
                },
                "font_name": "MuktaVaani ExtraBold",
                "font_family": "MuktaVaani",
                "font_postscript": "MuktaVaani-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuktaVaani-Bold.ttf"
                },
                "font_name": "MuktaVaani Bold",
                "font_family": "MuktaVaani",
                "font_postscript": "MuktaVaani-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuktaVaani-Light.ttf"
                },
                "font_name": "MuktaVaani Light",
                "font_family": "MuktaVaani",
                "font_postscript": "MuktaVaani-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuktaVaani-Medium.ttf"
                },
                "font_name": "MuktaVaani Medium",
                "font_family": "MuktaVaani",
                "font_postscript": "MuktaVaani-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Mulish",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mulish-Regular.ttf"
                },
                "font_name": "Mulish Regular",
                "font_family": "Mulish",
                "font_postscript": "Mulish-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mulish-Italic.ttf"
                },
                "font_name": "Mulish Italic",
                "font_family": "Mulish",
                "font_postscript": "Mulish-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Murecho",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Murecho-Regular.ttf"
                },
                "font_name": "Murecho Regular",
                "font_family": "Murecho",
                "font_postscript": "Murecho-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MuseoModerno",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuseoModerno-Regular.ttf"
                },
                "font_name": "MuseoModerno Regular",
                "font_family": "MuseoModerno",
                "font_postscript": "MuseoModerno-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "MuseoModerno-Italic.ttf"
                },
                "font_name": "MuseoModerno Italic",
                "font_family": "MuseoModerno",
                "font_postscript": "MuseoModerno-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MySoul",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MySoul-Regular.ttf"
                },
                "font_name": "MySoul Regular",
                "font_family": "MySoul",
                "font_postscript": "MySoul-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MyanmarSansPro",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MyanmarSansPro-Regular.ttf"
                },
                "font_name": "MyanmarSansPro Regular",
                "font_family": "MyanmarSansPro",
                "font_postscript": "MyanmarSansPro-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Mynerve",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Mynerve-Regular.ttf"
                },
                "font_name": "Mynerve Regular",
                "font_family": "Mynerve",
                "font_postscript": "Mynerve-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "MysteryQuest",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "MysteryQuest-Regular.ttf"
                },
                "font_name": "MysteryQuest Regular",
                "font_family": "MysteryQuest",
                "font_postscript": "MysteryQuest-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NATS",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NATS-Regular.ttf"
                },
                "font_name": "NATS Regular",
                "font_family": "NATS",
                "font_postscript": "NATS-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NTR",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NTR-Regular.ttf"
                },
                "font_name": "NTR Regular",
                "font_family": "NTR",
                "font_postscript": "NTR-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Nabla",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Nabla-Regular.ttf"
                },
                "font_name": "Nabla Regular",
                "font_family": "Nabla",
                "font_postscript": "Nabla-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Namdhinggo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Namdhinggo-Medium.ttf"
                },
                "font_name": "Namdhinggo Medium",
                "font_family": "Namdhinggo",
                "font_postscript": "Namdhinggo-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Namdhinggo-Regular.ttf"
                },
                "font_name": "Namdhinggo Regular",
                "font_family": "Namdhinggo",
                "font_postscript": "Namdhinggo-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Namdhinggo-Bold.ttf"
                },
                "font_name": "Namdhinggo Bold",
                "font_family": "Namdhinggo",
                "font_postscript": "Namdhinggo-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Namdhinggo-SemiBold.ttf"
                },
                "font_name": "Namdhinggo SemiBold",
                "font_family": "Namdhinggo",
                "font_postscript": "Namdhinggo-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Namdhinggo-ExtraBold.ttf"
                },
                "font_name": "Namdhinggo ExtraBold",
                "font_family": "Namdhinggo",
                "font_postscript": "Namdhinggo-ExtraBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NanumBrushScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NanumBrushScript-Regular.ttf"
                },
                "font_name": "NanumBrushScript Regular",
                "font_family": "NanumBrushScript",
                "font_postscript": "NanumBrushScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NanumGothic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NanumGothic-ExtraBold.ttf"
                },
                "font_name": "NanumGothic ExtraBold",
                "font_family": "NanumGothic",
                "font_postscript": "NanumGothic-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NanumGothic-Bold.ttf"
                },
                "font_name": "NanumGothic Bold",
                "font_family": "NanumGothic",
                "font_postscript": "NanumGothic-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NanumGothic-Regular.ttf"
                },
                "font_name": "NanumGothic Regular",
                "font_family": "NanumGothic",
                "font_postscript": "NanumGothic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NanumGothicCoding",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NanumGothicCoding-Regular.ttf"
                },
                "font_name": "NanumGothicCoding Regular",
                "font_family": "NanumGothicCoding",
                "font_postscript": "NanumGothicCoding-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NanumGothicCoding-Bold.ttf"
                },
                "font_name": "NanumGothicCoding Bold",
                "font_family": "NanumGothicCoding",
                "font_postscript": "NanumGothicCoding-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NanumMyeongjo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NanumMyeongjo-Bold.ttf"
                },
                "font_name": "NanumMyeongjo Bold",
                "font_family": "NanumMyeongjo",
                "font_postscript": "NanumMyeongjo-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NanumMyeongjo-Regular.ttf"
                },
                "font_name": "NanumMyeongjo Regular",
                "font_family": "NanumMyeongjo",
                "font_postscript": "NanumMyeongjo-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NanumMyeongjo-ExtraBold.ttf"
                },
                "font_name": "NanumMyeongjo ExtraBold",
                "font_family": "NanumMyeongjo",
                "font_postscript": "NanumMyeongjo-ExtraBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NanumPenScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NanumPenScript-Regular.ttf"
                },
                "font_name": "NanumPenScript Regular",
                "font_family": "NanumPenScript",
                "font_postscript": "NanumPenScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Narnoor",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Narnoor-SemiBold.ttf"
                },
                "font_name": "Narnoor SemiBold",
                "font_family": "Narnoor",
                "font_postscript": "Narnoor-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Narnoor-ExtraBold.ttf"
                },
                "font_name": "Narnoor ExtraBold",
                "font_family": "Narnoor",
                "font_postscript": "Narnoor-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Narnoor-Regular.ttf"
                },
                "font_name": "Narnoor Regular",
                "font_family": "Narnoor",
                "font_postscript": "Narnoor-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Narnoor-Medium.ttf"
                },
                "font_name": "Narnoor Medium",
                "font_family": "Narnoor",
                "font_postscript": "Narnoor-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Narnoor-Bold.ttf"
                },
                "font_name": "Narnoor Bold",
                "font_family": "Narnoor",
                "font_postscript": "Narnoor-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Neonderthaw",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Neonderthaw-Regular.ttf"
                },
                "font_name": "Neonderthaw Regular",
                "font_family": "Neonderthaw",
                "font_postscript": "Neonderthaw-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NerkoOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NerkoOne-Regular.ttf"
                },
                "font_name": "NerkoOne Regular",
                "font_family": "NerkoOne",
                "font_postscript": "NerkoOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Neucha",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Neucha-Regular.ttf"
                },
                "font_name": "Neucha Regular",
                "font_family": "Neucha",
                "font_postscript": "Neucha-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Neuton",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Neuton-Regular.ttf"
                },
                "font_name": "Neuton Regular",
                "font_family": "Neuton",
                "font_postscript": "Neuton-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Neuton-Bold.ttf"
                },
                "font_name": "Neuton Bold",
                "font_family": "Neuton",
                "font_postscript": "Neuton-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Neuton-ExtraLight.ttf"
                },
                "font_name": "Neuton ExtraLight",
                "font_family": "Neuton",
                "font_postscript": "Neuton-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Neuton-Italic.ttf"
                },
                "font_name": "Neuton Italic",
                "font_family": "Neuton",
                "font_postscript": "Neuton-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Neuton-Light.ttf"
                },
                "font_name": "Neuton Light",
                "font_family": "Neuton",
                "font_postscript": "Neuton-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Neuton-ExtraBold.ttf"
                },
                "font_name": "Neuton ExtraBold",
                "font_family": "Neuton",
                "font_postscript": "Neuton-ExtraBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NewRocker",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NewRocker-Regular.ttf"
                },
                "font_name": "NewRocker Regular",
                "font_family": "NewRocker",
                "font_postscript": "NewRocker-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NewTegomin",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NewTegomin-Regular.ttf"
                },
                "font_name": "NewTegomin Regular",
                "font_family": "NewTegomin",
                "font_postscript": "NewTegomin-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NewsCycle",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NewsCycle-Regular.ttf"
                },
                "font_name": "NewsCycle Regular",
                "font_family": "NewsCycle",
                "font_postscript": "NewsCycle-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NewsCycle-Bold.ttf"
                },
                "font_name": "NewsCycle Bold",
                "font_family": "NewsCycle",
                "font_postscript": "NewsCycle-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Newsreader",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Newsreader-Italic.ttf"
                },
                "font_name": "Newsreader Italic",
                "font_family": "Newsreader",
                "font_postscript": "Newsreader-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Newsreader-Regular.ttf"
                },
                "font_name": "Newsreader Regular",
                "font_family": "Newsreader",
                "font_postscript": "Newsreader-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NicoMoji",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NicoMoji-Regular.ttf"
                },
                "font_name": "NicoMoji Regular",
                "font_family": "NicoMoji",
                "font_postscript": "NicoMoji-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Niconne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Niconne-Regular.ttf"
                },
                "font_name": "Niconne Regular",
                "font_family": "Niconne",
                "font_postscript": "Niconne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Nikukyu",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Nikukyu-Regular.ttf"
                },
                "font_name": "Nikukyu Regular",
                "font_family": "Nikukyu",
                "font_postscript": "Nikukyu-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Niramit",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Niramit-Medium.ttf"
                },
                "font_name": "Niramit Medium",
                "font_family": "Niramit",
                "font_postscript": "Niramit-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Niramit-Bold.ttf"
                },
                "font_name": "Niramit Bold",
                "font_family": "Niramit",
                "font_postscript": "Niramit-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Niramit-LightItalic.ttf"
                },
                "font_name": "Niramit LightItalic",
                "font_family": "Niramit",
                "font_postscript": "Niramit-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Niramit-SemiBold.ttf"
                },
                "font_name": "Niramit SemiBold",
                "font_family": "Niramit",
                "font_postscript": "Niramit-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Niramit-ExtraLight.ttf"
                },
                "font_name": "Niramit ExtraLight",
                "font_family": "Niramit",
                "font_postscript": "Niramit-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Niramit-Light.ttf"
                },
                "font_name": "Niramit Light",
                "font_family": "Niramit",
                "font_postscript": "Niramit-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Niramit-BoldItalic.ttf"
                },
                "font_name": "Niramit BoldItalic",
                "font_family": "Niramit",
                "font_postscript": "Niramit-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Niramit-MediumItalic.ttf"
                },
                "font_name": "Niramit MediumItalic",
                "font_family": "Niramit",
                "font_postscript": "Niramit-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Niramit-Regular.ttf"
                },
                "font_name": "Niramit Regular",
                "font_family": "Niramit",
                "font_postscript": "Niramit-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Niramit-ExtraLightItalic.ttf"
                },
                "font_name": "Niramit ExtraLightItalic",
                "font_family": "Niramit",
                "font_postscript": "Niramit-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Niramit-Italic.ttf"
                },
                "font_name": "Niramit Italic",
                "font_family": "Niramit",
                "font_postscript": "Niramit-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Niramit-SemiBoldItalic.ttf"
                },
                "font_name": "Niramit SemiBoldItalic",
                "font_family": "Niramit",
                "font_postscript": "Niramit-SemiBoldItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NixieOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NixieOne-Regular.ttf"
                },
                "font_name": "NixieOne Regular",
                "font_family": "NixieOne",
                "font_postscript": "NixieOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Nobile",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Nobile-Italic.ttf"
                },
                "font_name": "Nobile Italic",
                "font_family": "Nobile",
                "font_postscript": "Nobile-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Nobile-Regular.ttf"
                },
                "font_name": "Nobile Regular",
                "font_family": "Nobile",
                "font_postscript": "Nobile-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Nobile-Bold.ttf"
                },
                "font_name": "Nobile Bold",
                "font_family": "Nobile",
                "font_postscript": "Nobile-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Nobile-BoldItalic.ttf"
                },
                "font_name": "Nobile BoldItalic",
                "font_family": "Nobile",
                "font_postscript": "Nobile-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Nobile-MediumItalic.ttf"
                },
                "font_name": "Nobile MediumItalic",
                "font_family": "Nobile",
                "font_postscript": "Nobile-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Nobile-Medium.ttf"
                },
                "font_name": "Nobile Medium",
                "font_family": "Nobile",
                "font_postscript": "Nobile-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Nokora",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Nokora-Regular.ttf"
                },
                "font_name": "Nokora Regular",
                "font_family": "Nokora",
                "font_postscript": "Nokora-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Nokora-Light.ttf"
                },
                "font_name": "Nokora Light",
                "font_family": "Nokora",
                "font_postscript": "Nokora-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Nokora-Thin.ttf"
                },
                "font_name": "Nokora Thin",
                "font_family": "Nokora",
                "font_postscript": "Nokora-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Nokora-Bold.ttf"
                },
                "font_name": "Nokora Bold",
                "font_family": "Nokora",
                "font_postscript": "Nokora-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Nokora-Black.ttf"
                },
                "font_name": "Nokora Black",
                "font_family": "Nokora",
                "font_postscript": "Nokora-Black",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Norican",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Norican-Regular.ttf"
                },
                "font_name": "Norican Regular",
                "font_family": "Norican",
                "font_postscript": "Norican-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Nosifer",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Nosifer-Regular.ttf"
                },
                "font_name": "Nosifer Regular",
                "font_family": "Nosifer",
                "font_postscript": "Nosifer-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NosiferCaps",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NosiferCaps-Regular.ttf"
                },
                "font_name": "NosiferCaps Regular",
                "font_family": "NosiferCaps",
                "font_postscript": "NosiferCaps-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Notable",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Notable-Regular.ttf"
                },
                "font_name": "Notable Regular",
                "font_family": "Notable",
                "font_postscript": "Notable-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NothingYouCouldDo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NothingYouCouldDo-Regular.ttf"
                },
                "font_name": "NothingYouCouldDo Regular",
                "font_family": "NothingYouCouldDo",
                "font_postscript": "NothingYouCouldDo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NoticiaText",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NoticiaText-Regular.ttf"
                },
                "font_name": "NoticiaText Regular",
                "font_family": "NoticiaText",
                "font_postscript": "NoticiaText-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NoticiaText-BoldItalic.ttf"
                },
                "font_name": "NoticiaText BoldItalic",
                "font_family": "NoticiaText",
                "font_postscript": "NoticiaText-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NoticiaText-Italic.ttf"
                },
                "font_name": "NoticiaText Italic",
                "font_family": "NoticiaText",
                "font_postscript": "NoticiaText-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NoticiaText-Bold.ttf"
                },
                "font_name": "NoticiaText Bold",
                "font_family": "NoticiaText",
                "font_postscript": "NoticiaText-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoColorEmoji",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoColorEmoji-Regular.ttf"
                },
                "font_name": "NotoColorEmoji Regular",
                "font_family": "NotoColorEmoji",
                "font_postscript": "NotoColorEmoji-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoColorEmojiCompatTest",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoColorEmojiCompatTest-Regular.ttf"
                },
                "font_name": "NotoColorEmojiCompatTest Regular",
                "font_family": "NotoColorEmojiCompatTest",
                "font_postscript": "NotoColorEmojiCompatTest-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoEmoji",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoEmoji-Regular.ttf"
                },
                "font_name": "NotoEmoji Regular",
                "font_family": "NotoEmoji",
                "font_postscript": "NotoEmoji-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoKufiArabic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoKufiArabic-Regular.ttf"
                },
                "font_name": "NotoKufiArabic Regular",
                "font_family": "NotoKufiArabic",
                "font_postscript": "NotoKufiArabic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoMusic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoMusic-Regular.ttf"
                },
                "font_name": "NotoMusic Regular",
                "font_family": "NotoMusic",
                "font_postscript": "NotoMusic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoNaskhArabic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoNaskhArabic-Regular.ttf"
                },
                "font_name": "NotoNaskhArabic Regular",
                "font_family": "NotoNaskhArabic",
                "font_postscript": "NotoNaskhArabic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoNaskhArabicUI",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoNaskhArabicUI-Regular.ttf"
                },
                "font_name": "NotoNaskhArabicUI Regular",
                "font_family": "NotoNaskhArabicUI",
                "font_postscript": "NotoNaskhArabicUI-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoNastaliqUrdu",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoNastaliqUrdu-Regular.ttf"
                },
                "font_name": "NotoNastaliqUrdu Regular",
                "font_family": "NotoNastaliqUrdu",
                "font_postscript": "NotoNastaliqUrdu-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoRashiHebrew",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoRashiHebrew-Regular.ttf"
                },
                "font_name": "NotoRashiHebrew Regular",
                "font_family": "NotoRashiHebrew",
                "font_postscript": "NotoRashiHebrew-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSans-Italic.ttf"
                },
                "font_name": "NotoSans Italic",
                "font_family": "NotoSans",
                "font_postscript": "NotoSans-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSans-Regular.ttf"
                },
                "font_name": "NotoSans Regular",
                "font_family": "NotoSans",
                "font_postscript": "NotoSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansAdlam",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansAdlam-Regular.ttf"
                },
                "font_name": "NotoSansAdlam Regular",
                "font_family": "NotoSansAdlam",
                "font_postscript": "NotoSansAdlam-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansAdlamUnjoined",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansAdlamUnjoined-Regular.ttf"
                },
                "font_name": "NotoSansAdlamUnjoined Regular",
                "font_family": "NotoSansAdlamUnjoined",
                "font_postscript": "NotoSansAdlamUnjoined-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansAnatolianHieroglyphs",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansAnatolianHieroglyphs-Regular.ttf"
                },
                "font_name": "NotoSansAnatolianHieroglyphs Regular",
                "font_family": "NotoSansAnatolianHieroglyphs",
                "font_postscript": "NotoSansAnatolianHieroglyphs-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansArabic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansArabic-Regular.ttf"
                },
                "font_name": "NotoSansArabic Regular",
                "font_family": "NotoSansArabic",
                "font_postscript": "NotoSansArabic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansArabicUI",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansArabicUI-Regular.ttf"
                },
                "font_name": "NotoSansArabicUI Regular",
                "font_family": "NotoSansArabicUI",
                "font_postscript": "NotoSansArabicUI-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansArmenian",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansArmenian-Regular.ttf"
                },
                "font_name": "NotoSansArmenian Regular",
                "font_family": "NotoSansArmenian",
                "font_postscript": "NotoSansArmenian-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansAvestan",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansAvestan-Regular.ttf"
                },
                "font_name": "NotoSansAvestan Regular",
                "font_family": "NotoSansAvestan",
                "font_postscript": "NotoSansAvestan-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansBalinese",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansBalinese-Regular.ttf"
                },
                "font_name": "NotoSansBalinese Regular",
                "font_family": "NotoSansBalinese",
                "font_postscript": "NotoSansBalinese-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansBamum",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansBamum-Regular.ttf"
                },
                "font_name": "NotoSansBamum Regular",
                "font_family": "NotoSansBamum",
                "font_postscript": "NotoSansBamum-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansBassaVah",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansBassaVah-Regular.ttf"
                },
                "font_name": "NotoSansBassaVah Regular",
                "font_family": "NotoSansBassaVah",
                "font_postscript": "NotoSansBassaVah-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansBatak",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansBatak-Regular.ttf"
                },
                "font_name": "NotoSansBatak Regular",
                "font_family": "NotoSansBatak",
                "font_postscript": "NotoSansBatak-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansBengali",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansBengali-Regular.ttf"
                },
                "font_name": "NotoSansBengali Regular",
                "font_family": "NotoSansBengali",
                "font_postscript": "NotoSansBengali-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansBengaliUI",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansBengaliUI-Regular.ttf"
                },
                "font_name": "NotoSansBengaliUI Regular",
                "font_family": "NotoSansBengaliUI",
                "font_postscript": "NotoSansBengaliUI-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansBhaiksuki",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansBhaiksuki-Regular.ttf"
                },
                "font_name": "NotoSansBhaiksuki Regular",
                "font_family": "NotoSansBhaiksuki",
                "font_postscript": "NotoSansBhaiksuki-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansBrahmi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansBrahmi-Regular.ttf"
                },
                "font_name": "NotoSansBrahmi Regular",
                "font_family": "NotoSansBrahmi",
                "font_postscript": "NotoSansBrahmi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansBuginese",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansBuginese-Regular.ttf"
                },
                "font_name": "NotoSansBuginese Regular",
                "font_family": "NotoSansBuginese",
                "font_postscript": "NotoSansBuginese-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansBuhid",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansBuhid-Regular.ttf"
                },
                "font_name": "NotoSansBuhid Regular",
                "font_family": "NotoSansBuhid",
                "font_postscript": "NotoSansBuhid-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansCanadianAboriginal",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansCanadianAboriginal-Regular.ttf"
                },
                "font_name": "NotoSansCanadianAboriginal Regular",
                "font_family": "NotoSansCanadianAboriginal",
                "font_postscript": "NotoSansCanadianAboriginal-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansCarian",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansCarian-Regular.ttf"
                },
                "font_name": "NotoSansCarian Regular",
                "font_family": "NotoSansCarian",
                "font_postscript": "NotoSansCarian-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansCaucasianAlbanian",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansCaucasianAlbanian-Regular.ttf"
                },
                "font_name": "NotoSansCaucasianAlbanian Regular",
                "font_family": "NotoSansCaucasianAlbanian",
                "font_postscript": "NotoSansCaucasianAlbanian-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansChakma",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansChakma-Regular.ttf"
                },
                "font_name": "NotoSansChakma Regular",
                "font_family": "NotoSansChakma",
                "font_postscript": "NotoSansChakma-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansCham",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansCham-Regular.ttf"
                },
                "font_name": "NotoSansCham Regular",
                "font_family": "NotoSansCham",
                "font_postscript": "NotoSansCham-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansCherokee",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansCherokee-Regular.ttf"
                },
                "font_name": "NotoSansCherokee Regular",
                "font_family": "NotoSansCherokee",
                "font_postscript": "NotoSansCherokee-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansChorasmian",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansChorasmian-Regular.ttf"
                },
                "font_name": "NotoSansChorasmian Regular",
                "font_family": "NotoSansChorasmian",
                "font_postscript": "NotoSansChorasmian-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansCoptic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansCoptic-Regular.ttf"
                },
                "font_name": "NotoSansCoptic Regular",
                "font_family": "NotoSansCoptic",
                "font_postscript": "NotoSansCoptic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansCuneiform",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansCuneiform-Regular.ttf"
                },
                "font_name": "NotoSansCuneiform Regular",
                "font_family": "NotoSansCuneiform",
                "font_postscript": "NotoSansCuneiform-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansCypriot",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansCypriot-Regular.ttf"
                },
                "font_name": "NotoSansCypriot Regular",
                "font_family": "NotoSansCypriot",
                "font_postscript": "NotoSansCypriot-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansCyproMinoan",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansCyproMinoan-Regular.ttf"
                },
                "font_name": "NotoSansCyproMinoan Regular",
                "font_family": "NotoSansCyproMinoan",
                "font_postscript": "NotoSansCyproMinoan-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansDeseret",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansDeseret-Regular.ttf"
                },
                "font_name": "NotoSansDeseret Regular",
                "font_family": "NotoSansDeseret",
                "font_postscript": "NotoSansDeseret-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansDevanagari",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansDevanagari-Regular.ttf"
                },
                "font_name": "NotoSansDevanagari Regular",
                "font_family": "NotoSansDevanagari",
                "font_postscript": "NotoSansDevanagari-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansDevanagariUI",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansDevanagariUI-Bold.ttf"
                },
                "font_name": "NotoSansDevanagariUI Bold",
                "font_family": "NotoSansDevanagariUI",
                "font_postscript": "NotoSansDevanagariUI-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansDevanagariUI-Thin.ttf"
                },
                "font_name": "NotoSansDevanagariUI Thin",
                "font_family": "NotoSansDevanagariUI",
                "font_postscript": "NotoSansDevanagariUI-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansDevanagariUI-Light.ttf"
                },
                "font_name": "NotoSansDevanagariUI Light",
                "font_family": "NotoSansDevanagariUI",
                "font_postscript": "NotoSansDevanagariUI-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansDevanagariUI-ExtraLight.ttf"
                },
                "font_name": "NotoSansDevanagariUI ExtraLight",
                "font_family": "NotoSansDevanagariUI",
                "font_postscript": "NotoSansDevanagariUI-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansDevanagariUI-Medium.ttf"
                },
                "font_name": "NotoSansDevanagariUI Medium",
                "font_family": "NotoSansDevanagariUI",
                "font_postscript": "NotoSansDevanagariUI-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansDevanagariUI-Regular.ttf"
                },
                "font_name": "NotoSansDevanagariUI Regular",
                "font_family": "NotoSansDevanagariUI",
                "font_postscript": "NotoSansDevanagariUI-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansDevanagariUI-Black.ttf"
                },
                "font_name": "NotoSansDevanagariUI Black",
                "font_family": "NotoSansDevanagariUI",
                "font_postscript": "NotoSansDevanagariUI-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansDevanagariUI-SemiBold.ttf"
                },
                "font_name": "NotoSansDevanagariUI SemiBold",
                "font_family": "NotoSansDevanagariUI",
                "font_postscript": "NotoSansDevanagariUI-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansDevanagariUI-ExtraBold.ttf"
                },
                "font_name": "NotoSansDevanagariUI ExtraBold",
                "font_family": "NotoSansDevanagariUI",
                "font_postscript": "NotoSansDevanagariUI-ExtraBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansDisplay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansDisplay-Italic.ttf"
                },
                "font_name": "NotoSansDisplay Italic",
                "font_family": "NotoSansDisplay",
                "font_postscript": "NotoSansDisplay-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansDisplay-Regular.ttf"
                },
                "font_name": "NotoSansDisplay Regular",
                "font_family": "NotoSansDisplay",
                "font_postscript": "NotoSansDisplay-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansDuployan",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansDuployan-Regular.ttf"
                },
                "font_name": "NotoSansDuployan Regular",
                "font_family": "NotoSansDuployan",
                "font_postscript": "NotoSansDuployan-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansDuployan-Bold.ttf"
                },
                "font_name": "NotoSansDuployan Bold",
                "font_family": "NotoSansDuployan",
                "font_postscript": "NotoSansDuployan-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansEgyptianHieroglyphs",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansEgyptianHieroglyphs-Regular.ttf"
                },
                "font_name": "NotoSansEgyptianHieroglyphs Regular",
                "font_family": "NotoSansEgyptianHieroglyphs",
                "font_postscript": "NotoSansEgyptianHieroglyphs-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansElbasan",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansElbasan-Regular.ttf"
                },
                "font_name": "NotoSansElbasan Regular",
                "font_family": "NotoSansElbasan",
                "font_postscript": "NotoSansElbasan-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansElymaic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansElymaic-Regular.ttf"
                },
                "font_name": "NotoSansElymaic Regular",
                "font_family": "NotoSansElymaic",
                "font_postscript": "NotoSansElymaic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansEthiopic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansEthiopic-Regular.ttf"
                },
                "font_name": "NotoSansEthiopic Regular",
                "font_family": "NotoSansEthiopic",
                "font_postscript": "NotoSansEthiopic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansGeorgian",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansGeorgian-Regular.ttf"
                },
                "font_name": "NotoSansGeorgian Regular",
                "font_family": "NotoSansGeorgian",
                "font_postscript": "NotoSansGeorgian-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansGlagolitic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansGlagolitic-Regular.ttf"
                },
                "font_name": "NotoSansGlagolitic Regular",
                "font_family": "NotoSansGlagolitic",
                "font_postscript": "NotoSansGlagolitic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansGothic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansGothic-Regular.ttf"
                },
                "font_name": "NotoSansGothic Regular",
                "font_family": "NotoSansGothic",
                "font_postscript": "NotoSansGothic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansGrantha",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansGrantha-Regular.ttf"
                },
                "font_name": "NotoSansGrantha Regular",
                "font_family": "NotoSansGrantha",
                "font_postscript": "NotoSansGrantha-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansGujarati",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansGujarati-Regular.ttf"
                },
                "font_name": "NotoSansGujarati Regular",
                "font_family": "NotoSansGujarati",
                "font_postscript": "NotoSansGujarati-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansGujaratiUI",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansGujaratiUI-Medium.ttf"
                },
                "font_name": "NotoSansGujaratiUI Medium",
                "font_family": "NotoSansGujaratiUI",
                "font_postscript": "NotoSansGujaratiUI-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansGujaratiUI-Light.ttf"
                },
                "font_name": "NotoSansGujaratiUI Light",
                "font_family": "NotoSansGujaratiUI",
                "font_postscript": "NotoSansGujaratiUI-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansGujaratiUI-Thin.ttf"
                },
                "font_name": "NotoSansGujaratiUI Thin",
                "font_family": "NotoSansGujaratiUI",
                "font_postscript": "NotoSansGujaratiUI-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansGujaratiUI-Bold.ttf"
                },
                "font_name": "NotoSansGujaratiUI Bold",
                "font_family": "NotoSansGujaratiUI",
                "font_postscript": "NotoSansGujaratiUI-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansGujaratiUI-ExtraLight.ttf"
                },
                "font_name": "NotoSansGujaratiUI ExtraLight",
                "font_family": "NotoSansGujaratiUI",
                "font_postscript": "NotoSansGujaratiUI-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansGujaratiUI-SemiBold.ttf"
                },
                "font_name": "NotoSansGujaratiUI SemiBold",
                "font_family": "NotoSansGujaratiUI",
                "font_postscript": "NotoSansGujaratiUI-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansGujaratiUI-Regular.ttf"
                },
                "font_name": "NotoSansGujaratiUI Regular",
                "font_family": "NotoSansGujaratiUI",
                "font_postscript": "NotoSansGujaratiUI-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansGujaratiUI-Black.ttf"
                },
                "font_name": "NotoSansGujaratiUI Black",
                "font_family": "NotoSansGujaratiUI",
                "font_postscript": "NotoSansGujaratiUI-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansGujaratiUI-ExtraBold.ttf"
                },
                "font_name": "NotoSansGujaratiUI ExtraBold",
                "font_family": "NotoSansGujaratiUI",
                "font_postscript": "NotoSansGujaratiUI-ExtraBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansGunjalaGondi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansGunjalaGondi-Regular.ttf"
                },
                "font_name": "NotoSansGunjalaGondi Regular",
                "font_family": "NotoSansGunjalaGondi",
                "font_postscript": "NotoSansGunjalaGondi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansGurmukhi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansGurmukhi-Regular.ttf"
                },
                "font_name": "NotoSansGurmukhi Regular",
                "font_family": "NotoSansGurmukhi",
                "font_postscript": "NotoSansGurmukhi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansGurmukhiUI",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansGurmukhiUI-Regular.ttf"
                },
                "font_name": "NotoSansGurmukhiUI Regular",
                "font_family": "NotoSansGurmukhiUI",
                "font_postscript": "NotoSansGurmukhiUI-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansHK",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansHK-Regular.ttf"
                },
                "font_name": "NotoSansHK Regular",
                "font_family": "NotoSansHK",
                "font_postscript": "NotoSansHK-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansHanifiRohingya",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansHanifiRohingya-Regular.ttf"
                },
                "font_name": "NotoSansHanifiRohingya Regular",
                "font_family": "NotoSansHanifiRohingya",
                "font_postscript": "NotoSansHanifiRohingya-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansHanunoo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansHanunoo-Regular.ttf"
                },
                "font_name": "NotoSansHanunoo Regular",
                "font_family": "NotoSansHanunoo",
                "font_postscript": "NotoSansHanunoo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansHatran",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansHatran-Regular.ttf"
                },
                "font_name": "NotoSansHatran Regular",
                "font_family": "NotoSansHatran",
                "font_postscript": "NotoSansHatran-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansHebrew",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansHebrew-Regular.ttf"
                },
                "font_name": "NotoSansHebrew Regular",
                "font_family": "NotoSansHebrew",
                "font_postscript": "NotoSansHebrew-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansImperialAramaic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansImperialAramaic-Regular.ttf"
                },
                "font_name": "NotoSansImperialAramaic Regular",
                "font_family": "NotoSansImperialAramaic",
                "font_postscript": "NotoSansImperialAramaic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansIndicSiyaqNumbers",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansIndicSiyaqNumbers-Regular.ttf"
                },
                "font_name": "NotoSansIndicSiyaqNumbers Regular",
                "font_family": "NotoSansIndicSiyaqNumbers",
                "font_postscript": "NotoSansIndicSiyaqNumbers-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansInscriptionalPahlavi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansInscriptionalPahlavi-Regular.ttf"
                },
                "font_name": "NotoSansInscriptionalPahlavi Regular",
                "font_family": "NotoSansInscriptionalPahlavi",
                "font_postscript": "NotoSansInscriptionalPahlavi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansInscriptionalParthian",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansInscriptionalParthian-Regular.ttf"
                },
                "font_name": "NotoSansInscriptionalParthian Regular",
                "font_family": "NotoSansInscriptionalParthian",
                "font_postscript": "NotoSansInscriptionalParthian-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansJP",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansJP-Regular.ttf"
                },
                "font_name": "NotoSansJP Regular",
                "font_family": "NotoSansJP",
                "font_postscript": "NotoSansJP-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansJavanese",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansJavanese-Regular.ttf"
                },
                "font_name": "NotoSansJavanese Regular",
                "font_family": "NotoSansJavanese",
                "font_postscript": "NotoSansJavanese-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansKR",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansKR-Regular.ttf"
                },
                "font_name": "NotoSansKR Regular",
                "font_family": "NotoSansKR",
                "font_postscript": "NotoSansKR-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansKaithi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansKaithi-Regular.ttf"
                },
                "font_name": "NotoSansKaithi Regular",
                "font_family": "NotoSansKaithi",
                "font_postscript": "NotoSansKaithi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansKannada",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansKannada-Regular.ttf"
                },
                "font_name": "NotoSansKannada Regular",
                "font_family": "NotoSansKannada",
                "font_postscript": "NotoSansKannada-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansKannadaUI",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansKannadaUI-Regular.ttf"
                },
                "font_name": "NotoSansKannadaUI Regular",
                "font_family": "NotoSansKannadaUI",
                "font_postscript": "NotoSansKannadaUI-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansKawi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansKawi-Regular.ttf"
                },
                "font_name": "NotoSansKawi Regular",
                "font_family": "NotoSansKawi",
                "font_postscript": "NotoSansKawi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansKayahLi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansKayahLi-Regular.ttf"
                },
                "font_name": "NotoSansKayahLi Regular",
                "font_family": "NotoSansKayahLi",
                "font_postscript": "NotoSansKayahLi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansKharoshthi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansKharoshthi-Regular.ttf"
                },
                "font_name": "NotoSansKharoshthi Regular",
                "font_family": "NotoSansKharoshthi",
                "font_postscript": "NotoSansKharoshthi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansKhmer",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansKhmer-Regular.ttf"
                },
                "font_name": "NotoSansKhmer Regular",
                "font_family": "NotoSansKhmer",
                "font_postscript": "NotoSansKhmer-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansKhmerUI",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansKhmerUI-Regular.ttf"
                },
                "font_name": "NotoSansKhmerUI Regular",
                "font_family": "NotoSansKhmerUI",
                "font_postscript": "NotoSansKhmerUI-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansKhojki",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansKhojki-Regular.ttf"
                },
                "font_name": "NotoSansKhojki Regular",
                "font_family": "NotoSansKhojki",
                "font_postscript": "NotoSansKhojki-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansKhudawadi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansKhudawadi-Regular.ttf"
                },
                "font_name": "NotoSansKhudawadi Regular",
                "font_family": "NotoSansKhudawadi",
                "font_postscript": "NotoSansKhudawadi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansLao",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansLao-Regular.ttf"
                },
                "font_name": "NotoSansLao Regular",
                "font_family": "NotoSansLao",
                "font_postscript": "NotoSansLao-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansLaoLooped",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansLaoLooped-Regular.ttf"
                },
                "font_name": "NotoSansLaoLooped Regular",
                "font_family": "NotoSansLaoLooped",
                "font_postscript": "NotoSansLaoLooped-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansLaoUI",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansLaoUI-Regular.ttf"
                },
                "font_name": "NotoSansLaoUI Regular",
                "font_family": "NotoSansLaoUI",
                "font_postscript": "NotoSansLaoUI-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansLepcha",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansLepcha-Regular.ttf"
                },
                "font_name": "NotoSansLepcha Regular",
                "font_family": "NotoSansLepcha",
                "font_postscript": "NotoSansLepcha-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansLimbu",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansLimbu-Regular.ttf"
                },
                "font_name": "NotoSansLimbu Regular",
                "font_family": "NotoSansLimbu",
                "font_postscript": "NotoSansLimbu-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansLinearA",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansLinearA-Regular.ttf"
                },
                "font_name": "NotoSansLinearA Regular",
                "font_family": "NotoSansLinearA",
                "font_postscript": "NotoSansLinearA-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansLinearB",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansLinearB-Regular.ttf"
                },
                "font_name": "NotoSansLinearB Regular",
                "font_family": "NotoSansLinearB",
                "font_postscript": "NotoSansLinearB-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansLisu",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansLisu-Regular.ttf"
                },
                "font_name": "NotoSansLisu Regular",
                "font_family": "NotoSansLisu",
                "font_postscript": "NotoSansLisu-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansLycian",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansLycian-Regular.ttf"
                },
                "font_name": "NotoSansLycian Regular",
                "font_family": "NotoSansLycian",
                "font_postscript": "NotoSansLycian-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansLydian",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansLydian-Regular.ttf"
                },
                "font_name": "NotoSansLydian Regular",
                "font_family": "NotoSansLydian",
                "font_postscript": "NotoSansLydian-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansMahajani",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMahajani-Regular.ttf"
                },
                "font_name": "NotoSansMahajani Regular",
                "font_family": "NotoSansMahajani",
                "font_postscript": "NotoSansMahajani-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansMalayalam",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMalayalam-Regular.ttf"
                },
                "font_name": "NotoSansMalayalam Regular",
                "font_family": "NotoSansMalayalam",
                "font_postscript": "NotoSansMalayalam-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansMalayalamUI",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMalayalamUI-Regular.ttf"
                },
                "font_name": "NotoSansMalayalamUI Regular",
                "font_family": "NotoSansMalayalamUI",
                "font_postscript": "NotoSansMalayalamUI-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansMandaic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMandaic-Regular.ttf"
                },
                "font_name": "NotoSansMandaic Regular",
                "font_family": "NotoSansMandaic",
                "font_postscript": "NotoSansMandaic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansManichaean",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansManichaean-Regular.ttf"
                },
                "font_name": "NotoSansManichaean Regular",
                "font_family": "NotoSansManichaean",
                "font_postscript": "NotoSansManichaean-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansMarchen",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMarchen-Regular.ttf"
                },
                "font_name": "NotoSansMarchen Regular",
                "font_family": "NotoSansMarchen",
                "font_postscript": "NotoSansMarchen-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansMasaramGondi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMasaramGondi-Regular.ttf"
                },
                "font_name": "NotoSansMasaramGondi Regular",
                "font_family": "NotoSansMasaramGondi",
                "font_postscript": "NotoSansMasaramGondi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansMath",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMath-Regular.ttf"
                },
                "font_name": "NotoSansMath Regular",
                "font_family": "NotoSansMath",
                "font_postscript": "NotoSansMath-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansMayanNumerals",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMayanNumerals-Regular.ttf"
                },
                "font_name": "NotoSansMayanNumerals Regular",
                "font_family": "NotoSansMayanNumerals",
                "font_postscript": "NotoSansMayanNumerals-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansMedefaidrin",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMedefaidrin-Regular.ttf"
                },
                "font_name": "NotoSansMedefaidrin Regular",
                "font_family": "NotoSansMedefaidrin",
                "font_postscript": "NotoSansMedefaidrin-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansMeeteiMayek",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMeeteiMayek-Regular.ttf"
                },
                "font_name": "NotoSansMeeteiMayek Regular",
                "font_family": "NotoSansMeeteiMayek",
                "font_postscript": "NotoSansMeeteiMayek-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansMendeKikakui",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMendeKikakui-Regular.ttf"
                },
                "font_name": "NotoSansMendeKikakui Regular",
                "font_family": "NotoSansMendeKikakui",
                "font_postscript": "NotoSansMendeKikakui-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansMeroitic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMeroitic-Regular.ttf"
                },
                "font_name": "NotoSansMeroitic Regular",
                "font_family": "NotoSansMeroitic",
                "font_postscript": "NotoSansMeroitic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansMiao",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMiao-Regular.ttf"
                },
                "font_name": "NotoSansMiao Regular",
                "font_family": "NotoSansMiao",
                "font_postscript": "NotoSansMiao-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansModi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansModi-Regular.ttf"
                },
                "font_name": "NotoSansModi Regular",
                "font_family": "NotoSansModi",
                "font_postscript": "NotoSansModi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansMongolian",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMongolian-Regular.ttf"
                },
                "font_name": "NotoSansMongolian Regular",
                "font_family": "NotoSansMongolian",
                "font_postscript": "NotoSansMongolian-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMono-Regular.ttf"
                },
                "font_name": "NotoSansMono Regular",
                "font_family": "NotoSansMono",
                "font_postscript": "NotoSansMono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansMro",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMro-Regular.ttf"
                },
                "font_name": "NotoSansMro Regular",
                "font_family": "NotoSansMro",
                "font_postscript": "NotoSansMro-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansMultani",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMultani-Regular.ttf"
                },
                "font_name": "NotoSansMultani Regular",
                "font_family": "NotoSansMultani",
                "font_postscript": "NotoSansMultani-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansMyanmar",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMyanmar-Regular.ttf"
                },
                "font_name": "NotoSansMyanmar Regular",
                "font_family": "NotoSansMyanmar",
                "font_postscript": "NotoSansMyanmar-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansMyanmarUI",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMyanmarUI-Black.ttf"
                },
                "font_name": "NotoSansMyanmarUI Black",
                "font_family": "NotoSansMyanmarUI",
                "font_postscript": "NotoSansMyanmarUI-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMyanmarUI-ExtraBold.ttf"
                },
                "font_name": "NotoSansMyanmarUI ExtraBold",
                "font_family": "NotoSansMyanmarUI",
                "font_postscript": "NotoSansMyanmarUI-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMyanmarUI-Regular.ttf"
                },
                "font_name": "NotoSansMyanmarUI Regular",
                "font_family": "NotoSansMyanmarUI",
                "font_postscript": "NotoSansMyanmarUI-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMyanmarUI-SemiBold.ttf"
                },
                "font_name": "NotoSansMyanmarUI SemiBold",
                "font_family": "NotoSansMyanmarUI",
                "font_postscript": "NotoSansMyanmarUI-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMyanmarUI-ExtraLight.ttf"
                },
                "font_name": "NotoSansMyanmarUI ExtraLight",
                "font_family": "NotoSansMyanmarUI",
                "font_postscript": "NotoSansMyanmarUI-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMyanmarUI-Light.ttf"
                },
                "font_name": "NotoSansMyanmarUI Light",
                "font_family": "NotoSansMyanmarUI",
                "font_postscript": "NotoSansMyanmarUI-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMyanmarUI-Thin.ttf"
                },
                "font_name": "NotoSansMyanmarUI Thin",
                "font_family": "NotoSansMyanmarUI",
                "font_postscript": "NotoSansMyanmarUI-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMyanmarUI-Medium.ttf"
                },
                "font_name": "NotoSansMyanmarUI Medium",
                "font_family": "NotoSansMyanmarUI",
                "font_postscript": "NotoSansMyanmarUI-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansMyanmarUI-Bold.ttf"
                },
                "font_name": "NotoSansMyanmarUI Bold",
                "font_family": "NotoSansMyanmarUI",
                "font_postscript": "NotoSansMyanmarUI-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansNKo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansNKo-Regular.ttf"
                },
                "font_name": "NotoSansNKo Regular",
                "font_family": "NotoSansNKo",
                "font_postscript": "NotoSansNKo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansNKoUnjoined",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansNKoUnjoined-Regular.ttf"
                },
                "font_name": "NotoSansNKoUnjoined Regular",
                "font_family": "NotoSansNKoUnjoined",
                "font_postscript": "NotoSansNKoUnjoined-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansNabataean",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansNabataean-Regular.ttf"
                },
                "font_name": "NotoSansNabataean Regular",
                "font_family": "NotoSansNabataean",
                "font_postscript": "NotoSansNabataean-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansNagMundari",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansNagMundari-Regular.ttf"
                },
                "font_name": "NotoSansNagMundari Regular",
                "font_family": "NotoSansNagMundari",
                "font_postscript": "NotoSansNagMundari-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansNandinagari",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansNandinagari-Regular.ttf"
                },
                "font_name": "NotoSansNandinagari Regular",
                "font_family": "NotoSansNandinagari",
                "font_postscript": "NotoSansNandinagari-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansNewTaiLue",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansNewTaiLue-Regular.ttf"
                },
                "font_name": "NotoSansNewTaiLue Regular",
                "font_family": "NotoSansNewTaiLue",
                "font_postscript": "NotoSansNewTaiLue-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansNewa",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansNewa-Regular.ttf"
                },
                "font_name": "NotoSansNewa Regular",
                "font_family": "NotoSansNewa",
                "font_postscript": "NotoSansNewa-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansNushu",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansNushu-Regular.ttf"
                },
                "font_name": "NotoSansNushu Regular",
                "font_family": "NotoSansNushu",
                "font_postscript": "NotoSansNushu-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansOgham",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansOgham-Regular.ttf"
                },
                "font_name": "NotoSansOgham Regular",
                "font_family": "NotoSansOgham",
                "font_postscript": "NotoSansOgham-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansOlChiki",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansOlChiki-Regular.ttf"
                },
                "font_name": "NotoSansOlChiki Regular",
                "font_family": "NotoSansOlChiki",
                "font_postscript": "NotoSansOlChiki-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansOldHungarian",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansOldHungarian-Regular.ttf"
                },
                "font_name": "NotoSansOldHungarian Regular",
                "font_family": "NotoSansOldHungarian",
                "font_postscript": "NotoSansOldHungarian-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansOldItalic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansOldItalic-Regular.ttf"
                },
                "font_name": "NotoSansOldItalic Regular",
                "font_family": "NotoSansOldItalic",
                "font_postscript": "NotoSansOldItalic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansOldNorthArabian",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansOldNorthArabian-Regular.ttf"
                },
                "font_name": "NotoSansOldNorthArabian Regular",
                "font_family": "NotoSansOldNorthArabian",
                "font_postscript": "NotoSansOldNorthArabian-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansOldPermic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansOldPermic-Regular.ttf"
                },
                "font_name": "NotoSansOldPermic Regular",
                "font_family": "NotoSansOldPermic",
                "font_postscript": "NotoSansOldPermic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansOldPersian",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansOldPersian-Regular.ttf"
                },
                "font_name": "NotoSansOldPersian Regular",
                "font_family": "NotoSansOldPersian",
                "font_postscript": "NotoSansOldPersian-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansOldSogdian",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansOldSogdian-Regular.ttf"
                },
                "font_name": "NotoSansOldSogdian Regular",
                "font_family": "NotoSansOldSogdian",
                "font_postscript": "NotoSansOldSogdian-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansOldSouthArabian",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansOldSouthArabian-Regular.ttf"
                },
                "font_name": "NotoSansOldSouthArabian Regular",
                "font_family": "NotoSansOldSouthArabian",
                "font_postscript": "NotoSansOldSouthArabian-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansOldTurkic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansOldTurkic-Regular.ttf"
                },
                "font_name": "NotoSansOldTurkic Regular",
                "font_family": "NotoSansOldTurkic",
                "font_postscript": "NotoSansOldTurkic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansOriya",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansOriya-Regular.ttf"
                },
                "font_name": "NotoSansOriya Regular",
                "font_family": "NotoSansOriya",
                "font_postscript": "NotoSansOriya-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansOriyaUI",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansOriyaUI-Black.ttf"
                },
                "font_name": "NotoSansOriyaUI Black",
                "font_family": "NotoSansOriyaUI",
                "font_postscript": "NotoSansOriyaUI-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansOriyaUI-Bold.ttf"
                },
                "font_name": "NotoSansOriyaUI Bold",
                "font_family": "NotoSansOriyaUI",
                "font_postscript": "NotoSansOriyaUI-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansOriyaUI-Thin.ttf"
                },
                "font_name": "NotoSansOriyaUI Thin",
                "font_family": "NotoSansOriyaUI",
                "font_postscript": "NotoSansOriyaUI-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansOriyaUI-Regular.ttf"
                },
                "font_name": "NotoSansOriyaUI Regular",
                "font_family": "NotoSansOriyaUI",
                "font_postscript": "NotoSansOriyaUI-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansOsage",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansOsage-Regular.ttf"
                },
                "font_name": "NotoSansOsage Regular",
                "font_family": "NotoSansOsage",
                "font_postscript": "NotoSansOsage-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansOsmanya",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansOsmanya-Regular.ttf"
                },
                "font_name": "NotoSansOsmanya Regular",
                "font_family": "NotoSansOsmanya",
                "font_postscript": "NotoSansOsmanya-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansPahawhHmong",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansPahawhHmong-Regular.ttf"
                },
                "font_name": "NotoSansPahawhHmong Regular",
                "font_family": "NotoSansPahawhHmong",
                "font_postscript": "NotoSansPahawhHmong-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansPalmyrene",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansPalmyrene-Regular.ttf"
                },
                "font_name": "NotoSansPalmyrene Regular",
                "font_family": "NotoSansPalmyrene",
                "font_postscript": "NotoSansPalmyrene-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansPauCinHau",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansPauCinHau-Regular.ttf"
                },
                "font_name": "NotoSansPauCinHau Regular",
                "font_family": "NotoSansPauCinHau",
                "font_postscript": "NotoSansPauCinHau-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansPhagsPa",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansPhagsPa-Regular.ttf"
                },
                "font_name": "NotoSansPhagsPa Regular",
                "font_family": "NotoSansPhagsPa",
                "font_postscript": "NotoSansPhagsPa-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansPhoenician",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansPhoenician-Regular.ttf"
                },
                "font_name": "NotoSansPhoenician Regular",
                "font_family": "NotoSansPhoenician",
                "font_postscript": "NotoSansPhoenician-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansPsalterPahlavi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansPsalterPahlavi-Regular.ttf"
                },
                "font_name": "NotoSansPsalterPahlavi Regular",
                "font_family": "NotoSansPsalterPahlavi",
                "font_postscript": "NotoSansPsalterPahlavi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansRejang",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansRejang-Regular.ttf"
                },
                "font_name": "NotoSansRejang Regular",
                "font_family": "NotoSansRejang",
                "font_postscript": "NotoSansRejang-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansRunic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansRunic-Regular.ttf"
                },
                "font_name": "NotoSansRunic Regular",
                "font_family": "NotoSansRunic",
                "font_postscript": "NotoSansRunic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansSC-Regular.ttf"
                },
                "font_name": "NotoSansSC Regular",
                "font_family": "NotoSansSC",
                "font_postscript": "NotoSansSC-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansSamaritan",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansSamaritan-Regular.ttf"
                },
                "font_name": "NotoSansSamaritan Regular",
                "font_family": "NotoSansSamaritan",
                "font_postscript": "NotoSansSamaritan-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansSaurashtra",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansSaurashtra-Regular.ttf"
                },
                "font_name": "NotoSansSaurashtra Regular",
                "font_family": "NotoSansSaurashtra",
                "font_postscript": "NotoSansSaurashtra-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansSharada",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansSharada-Regular.ttf"
                },
                "font_name": "NotoSansSharada Regular",
                "font_family": "NotoSansSharada",
                "font_postscript": "NotoSansSharada-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansShavian",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansShavian-Regular.ttf"
                },
                "font_name": "NotoSansShavian Regular",
                "font_family": "NotoSansShavian",
                "font_postscript": "NotoSansShavian-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansSiddham",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansSiddham-Regular.ttf"
                },
                "font_name": "NotoSansSiddham Regular",
                "font_family": "NotoSansSiddham",
                "font_postscript": "NotoSansSiddham-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansSignWriting",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansSignWriting-Regular.ttf"
                },
                "font_name": "NotoSansSignWriting Regular",
                "font_family": "NotoSansSignWriting",
                "font_postscript": "NotoSansSignWriting-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansSinhala",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansSinhala-Regular.ttf"
                },
                "font_name": "NotoSansSinhala Regular",
                "font_family": "NotoSansSinhala",
                "font_postscript": "NotoSansSinhala-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansSinhalaUI",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansSinhalaUI-Regular.ttf"
                },
                "font_name": "NotoSansSinhalaUI Regular",
                "font_family": "NotoSansSinhalaUI",
                "font_postscript": "NotoSansSinhalaUI-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansSogdian",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansSogdian-Regular.ttf"
                },
                "font_name": "NotoSansSogdian Regular",
                "font_family": "NotoSansSogdian",
                "font_postscript": "NotoSansSogdian-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansSoraSompeng",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansSoraSompeng-Regular.ttf"
                },
                "font_name": "NotoSansSoraSompeng Regular",
                "font_family": "NotoSansSoraSompeng",
                "font_postscript": "NotoSansSoraSompeng-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansSoyombo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansSoyombo-Regular.ttf"
                },
                "font_name": "NotoSansSoyombo Regular",
                "font_family": "NotoSansSoyombo",
                "font_postscript": "NotoSansSoyombo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansSundanese",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansSundanese-Regular.ttf"
                },
                "font_name": "NotoSansSundanese Regular",
                "font_family": "NotoSansSundanese",
                "font_postscript": "NotoSansSundanese-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansSylotiNagri",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansSylotiNagri-Regular.ttf"
                },
                "font_name": "NotoSansSylotiNagri Regular",
                "font_family": "NotoSansSylotiNagri",
                "font_postscript": "NotoSansSylotiNagri-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansSymbols",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansSymbols-Regular.ttf"
                },
                "font_name": "NotoSansSymbols Regular",
                "font_family": "NotoSansSymbols",
                "font_postscript": "NotoSansSymbols-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansSymbols2",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansSymbols2-Regular.ttf"
                },
                "font_name": "NotoSansSymbols2 Regular",
                "font_family": "NotoSansSymbols2",
                "font_postscript": "NotoSansSymbols2-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansSyriac",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansSyriac-Regular.ttf"
                },
                "font_name": "NotoSansSyriac Regular",
                "font_family": "NotoSansSyriac",
                "font_postscript": "NotoSansSyriac-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansSyriacEastern",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansSyriacEastern-Regular.ttf"
                },
                "font_name": "NotoSansSyriacEastern Regular",
                "font_family": "NotoSansSyriacEastern",
                "font_postscript": "NotoSansSyriacEastern-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansTC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansTC-Regular.ttf"
                },
                "font_name": "NotoSansTC Regular",
                "font_family": "NotoSansTC",
                "font_postscript": "NotoSansTC-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansTagalog",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansTagalog-Regular.ttf"
                },
                "font_name": "NotoSansTagalog Regular",
                "font_family": "NotoSansTagalog",
                "font_postscript": "NotoSansTagalog-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansTagbanwa",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansTagbanwa-Regular.ttf"
                },
                "font_name": "NotoSansTagbanwa Regular",
                "font_family": "NotoSansTagbanwa",
                "font_postscript": "NotoSansTagbanwa-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansTaiLe",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansTaiLe-Regular.ttf"
                },
                "font_name": "NotoSansTaiLe Regular",
                "font_family": "NotoSansTaiLe",
                "font_postscript": "NotoSansTaiLe-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansTaiTham",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansTaiTham-Regular.ttf"
                },
                "font_name": "NotoSansTaiTham Regular",
                "font_family": "NotoSansTaiTham",
                "font_postscript": "NotoSansTaiTham-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansTaiViet",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansTaiViet-Regular.ttf"
                },
                "font_name": "NotoSansTaiViet Regular",
                "font_family": "NotoSansTaiViet",
                "font_postscript": "NotoSansTaiViet-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansTakri",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansTakri-Regular.ttf"
                },
                "font_name": "NotoSansTakri Regular",
                "font_family": "NotoSansTakri",
                "font_postscript": "NotoSansTakri-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansTamil",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansTamil-Regular.ttf"
                },
                "font_name": "NotoSansTamil Regular",
                "font_family": "NotoSansTamil",
                "font_postscript": "NotoSansTamil-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansTamilSupplement",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansTamilSupplement-Regular.ttf"
                },
                "font_name": "NotoSansTamilSupplement Regular",
                "font_family": "NotoSansTamilSupplement",
                "font_postscript": "NotoSansTamilSupplement-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansTamilUI",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansTamilUI-Regular.ttf"
                },
                "font_name": "NotoSansTamilUI Regular",
                "font_family": "NotoSansTamilUI",
                "font_postscript": "NotoSansTamilUI-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansTangsa",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansTangsa-Regular.ttf"
                },
                "font_name": "NotoSansTangsa Regular",
                "font_family": "NotoSansTangsa",
                "font_postscript": "NotoSansTangsa-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansTelugu",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansTelugu-Regular.ttf"
                },
                "font_name": "NotoSansTelugu Regular",
                "font_family": "NotoSansTelugu",
                "font_postscript": "NotoSansTelugu-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansTeluguUI",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansTeluguUI-Regular.ttf"
                },
                "font_name": "NotoSansTeluguUI Regular",
                "font_family": "NotoSansTeluguUI",
                "font_postscript": "NotoSansTeluguUI-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansThaana",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansThaana-Regular.ttf"
                },
                "font_name": "NotoSansThaana Regular",
                "font_family": "NotoSansThaana",
                "font_postscript": "NotoSansThaana-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansThai",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansThai-Regular.ttf"
                },
                "font_name": "NotoSansThai Regular",
                "font_family": "NotoSansThai",
                "font_postscript": "NotoSansThai-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansThaiLooped",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansThaiLooped-Light.ttf"
                },
                "font_name": "NotoSansThaiLooped Light",
                "font_family": "NotoSansThaiLooped",
                "font_postscript": "NotoSansThaiLooped-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansThaiLooped-SemiBold.ttf"
                },
                "font_name": "NotoSansThaiLooped SemiBold",
                "font_family": "NotoSansThaiLooped",
                "font_postscript": "NotoSansThaiLooped-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansThaiLooped-ExtraBold.ttf"
                },
                "font_name": "NotoSansThaiLooped ExtraBold",
                "font_family": "NotoSansThaiLooped",
                "font_postscript": "NotoSansThaiLooped-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansThaiLooped-Medium.ttf"
                },
                "font_name": "NotoSansThaiLooped Medium",
                "font_family": "NotoSansThaiLooped",
                "font_postscript": "NotoSansThaiLooped-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansThaiLooped-Regular.ttf"
                },
                "font_name": "NotoSansThaiLooped Regular",
                "font_family": "NotoSansThaiLooped",
                "font_postscript": "NotoSansThaiLooped-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansThaiLooped-Black.ttf"
                },
                "font_name": "NotoSansThaiLooped Black",
                "font_family": "NotoSansThaiLooped",
                "font_postscript": "NotoSansThaiLooped-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansThaiLooped-ExtraLight.ttf"
                },
                "font_name": "NotoSansThaiLooped ExtraLight",
                "font_family": "NotoSansThaiLooped",
                "font_postscript": "NotoSansThaiLooped-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansThaiLooped-Thin.ttf"
                },
                "font_name": "NotoSansThaiLooped Thin",
                "font_family": "NotoSansThaiLooped",
                "font_postscript": "NotoSansThaiLooped-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansThaiLooped-Bold.ttf"
                },
                "font_name": "NotoSansThaiLooped Bold",
                "font_family": "NotoSansThaiLooped",
                "font_postscript": "NotoSansThaiLooped-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansThaiUI",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansThaiUI-Regular.ttf"
                },
                "font_name": "NotoSansThaiUI Regular",
                "font_family": "NotoSansThaiUI",
                "font_postscript": "NotoSansThaiUI-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansTifinagh",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansTifinagh-Regular.ttf"
                },
                "font_name": "NotoSansTifinagh Regular",
                "font_family": "NotoSansTifinagh",
                "font_postscript": "NotoSansTifinagh-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansTirhuta",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansTirhuta-Regular.ttf"
                },
                "font_name": "NotoSansTirhuta Regular",
                "font_family": "NotoSansTirhuta",
                "font_postscript": "NotoSansTirhuta-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansUgaritic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansUgaritic-Regular.ttf"
                },
                "font_name": "NotoSansUgaritic Regular",
                "font_family": "NotoSansUgaritic",
                "font_postscript": "NotoSansUgaritic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansVai",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansVai-Regular.ttf"
                },
                "font_name": "NotoSansVai Regular",
                "font_family": "NotoSansVai",
                "font_postscript": "NotoSansVai-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansVithkuqi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansVithkuqi-Regular.ttf"
                },
                "font_name": "NotoSansVithkuqi Regular",
                "font_family": "NotoSansVithkuqi",
                "font_postscript": "NotoSansVithkuqi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansWancho",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansWancho-Regular.ttf"
                },
                "font_name": "NotoSansWancho Regular",
                "font_family": "NotoSansWancho",
                "font_postscript": "NotoSansWancho-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansWarangCiti",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansWarangCiti-Regular.ttf"
                },
                "font_name": "NotoSansWarangCiti Regular",
                "font_family": "NotoSansWarangCiti",
                "font_postscript": "NotoSansWarangCiti-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansYi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansYi-Regular.ttf"
                },
                "font_name": "NotoSansYi Regular",
                "font_family": "NotoSansYi",
                "font_postscript": "NotoSansYi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSansZanabazarSquare",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSansZanabazarSquare-Regular.ttf"
                },
                "font_name": "NotoSansZanabazarSquare Regular",
                "font_family": "NotoSansZanabazarSquare",
                "font_postscript": "NotoSansZanabazarSquare-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerif",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerif-Regular.ttf"
                },
                "font_name": "NotoSerif Regular",
                "font_family": "NotoSerif",
                "font_postscript": "NotoSerif-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerif-Italic.ttf"
                },
                "font_name": "NotoSerif Italic",
                "font_family": "NotoSerif",
                "font_postscript": "NotoSerif-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifAhom",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifAhom-Regular.ttf"
                },
                "font_name": "NotoSerifAhom Regular",
                "font_family": "NotoSerifAhom",
                "font_postscript": "NotoSerifAhom-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifArmenian",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifArmenian-Regular.ttf"
                },
                "font_name": "NotoSerifArmenian Regular",
                "font_family": "NotoSerifArmenian",
                "font_postscript": "NotoSerifArmenian-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifBalinese",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifBalinese-Regular.ttf"
                },
                "font_name": "NotoSerifBalinese Regular",
                "font_family": "NotoSerifBalinese",
                "font_postscript": "NotoSerifBalinese-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifBengali",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifBengali-Regular.ttf"
                },
                "font_name": "NotoSerifBengali Regular",
                "font_family": "NotoSerifBengali",
                "font_postscript": "NotoSerifBengali-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifDevanagari",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifDevanagari-Regular.ttf"
                },
                "font_name": "NotoSerifDevanagari Regular",
                "font_family": "NotoSerifDevanagari",
                "font_postscript": "NotoSerifDevanagari-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifDisplay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifDisplay-Regular.ttf"
                },
                "font_name": "NotoSerifDisplay Regular",
                "font_family": "NotoSerifDisplay",
                "font_postscript": "NotoSerifDisplay-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifDisplay-Italic.ttf"
                },
                "font_name": "NotoSerifDisplay Italic",
                "font_family": "NotoSerifDisplay",
                "font_postscript": "NotoSerifDisplay-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifDogra",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifDogra-Regular.ttf"
                },
                "font_name": "NotoSerifDogra Regular",
                "font_family": "NotoSerifDogra",
                "font_postscript": "NotoSerifDogra-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifEthiopic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifEthiopic-Regular.ttf"
                },
                "font_name": "NotoSerifEthiopic Regular",
                "font_family": "NotoSerifEthiopic",
                "font_postscript": "NotoSerifEthiopic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifGeorgian",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifGeorgian-Regular.ttf"
                },
                "font_name": "NotoSerifGeorgian Regular",
                "font_family": "NotoSerifGeorgian",
                "font_postscript": "NotoSerifGeorgian-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifGrantha",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifGrantha-Regular.ttf"
                },
                "font_name": "NotoSerifGrantha Regular",
                "font_family": "NotoSerifGrantha",
                "font_postscript": "NotoSerifGrantha-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifGujarati",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifGujarati-Regular.ttf"
                },
                "font_name": "NotoSerifGujarati Regular",
                "font_family": "NotoSerifGujarati",
                "font_postscript": "NotoSerifGujarati-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifGurmukhi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifGurmukhi-Regular.ttf"
                },
                "font_name": "NotoSerifGurmukhi Regular",
                "font_family": "NotoSerifGurmukhi",
                "font_postscript": "NotoSerifGurmukhi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifHK",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifHK-Regular.ttf"
                },
                "font_name": "NotoSerifHK Regular",
                "font_family": "NotoSerifHK",
                "font_postscript": "NotoSerifHK-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifHebrew",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifHebrew-Regular.ttf"
                },
                "font_name": "NotoSerifHebrew Regular",
                "font_family": "NotoSerifHebrew",
                "font_postscript": "NotoSerifHebrew-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifHentaigana",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifHentaigana-Regular.ttf"
                },
                "font_name": "NotoSerifHentaigana Regular",
                "font_family": "NotoSerifHentaigana",
                "font_postscript": "NotoSerifHentaigana-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifJP",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifJP-Regular.ttf"
                },
                "font_name": "NotoSerifJP Regular",
                "font_family": "NotoSerifJP",
                "font_postscript": "NotoSerifJP-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifKR",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifKR-Regular.ttf"
                },
                "font_name": "NotoSerifKR Regular",
                "font_family": "NotoSerifKR",
                "font_postscript": "NotoSerifKR-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifKannada",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifKannada-Regular.ttf"
                },
                "font_name": "NotoSerifKannada Regular",
                "font_family": "NotoSerifKannada",
                "font_postscript": "NotoSerifKannada-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifKhitanSmallScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifKhitanSmallScript-Regular.ttf"
                },
                "font_name": "NotoSerifKhitanSmallScript Regular",
                "font_family": "NotoSerifKhitanSmallScript",
                "font_postscript": "NotoSerifKhitanSmallScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifKhmer",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifKhmer-Regular.ttf"
                },
                "font_name": "NotoSerifKhmer Regular",
                "font_family": "NotoSerifKhmer",
                "font_postscript": "NotoSerifKhmer-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifKhojki",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifKhojki-Regular.ttf"
                },
                "font_name": "NotoSerifKhojki Regular",
                "font_family": "NotoSerifKhojki",
                "font_postscript": "NotoSerifKhojki-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifLao",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifLao-Regular.ttf"
                },
                "font_name": "NotoSerifLao Regular",
                "font_family": "NotoSerifLao",
                "font_postscript": "NotoSerifLao-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifMakasar",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifMakasar-Regular.ttf"
                },
                "font_name": "NotoSerifMakasar Regular",
                "font_family": "NotoSerifMakasar",
                "font_postscript": "NotoSerifMakasar-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifMalayalam",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifMalayalam-Regular.ttf"
                },
                "font_name": "NotoSerifMalayalam Regular",
                "font_family": "NotoSerifMalayalam",
                "font_postscript": "NotoSerifMalayalam-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifMyanmar",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifMyanmar-Thin.ttf"
                },
                "font_name": "NotoSerifMyanmar Thin",
                "font_family": "NotoSerifMyanmar",
                "font_postscript": "NotoSerifMyanmar-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifMyanmar-Black.ttf"
                },
                "font_name": "NotoSerifMyanmar Black",
                "font_family": "NotoSerifMyanmar",
                "font_postscript": "NotoSerifMyanmar-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifMyanmar-Bold.ttf"
                },
                "font_name": "NotoSerifMyanmar Bold",
                "font_family": "NotoSerifMyanmar",
                "font_postscript": "NotoSerifMyanmar-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifMyanmar-ExtraBold.ttf"
                },
                "font_name": "NotoSerifMyanmar ExtraBold",
                "font_family": "NotoSerifMyanmar",
                "font_postscript": "NotoSerifMyanmar-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifMyanmar-Medium.ttf"
                },
                "font_name": "NotoSerifMyanmar Medium",
                "font_family": "NotoSerifMyanmar",
                "font_postscript": "NotoSerifMyanmar-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifMyanmar-Light.ttf"
                },
                "font_name": "NotoSerifMyanmar Light",
                "font_family": "NotoSerifMyanmar",
                "font_postscript": "NotoSerifMyanmar-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifMyanmar-ExtraLight.ttf"
                },
                "font_name": "NotoSerifMyanmar ExtraLight",
                "font_family": "NotoSerifMyanmar",
                "font_postscript": "NotoSerifMyanmar-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifMyanmar-Regular.ttf"
                },
                "font_name": "NotoSerifMyanmar Regular",
                "font_family": "NotoSerifMyanmar",
                "font_postscript": "NotoSerifMyanmar-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifMyanmar-SemiBold.ttf"
                },
                "font_name": "NotoSerifMyanmar SemiBold",
                "font_family": "NotoSerifMyanmar",
                "font_postscript": "NotoSerifMyanmar-SemiBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifNPHmong",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifNPHmong-Regular.ttf"
                },
                "font_name": "NotoSerifNPHmong Regular",
                "font_family": "NotoSerifNPHmong",
                "font_postscript": "NotoSerifNPHmong-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifNyiakengPuachueHmong",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifNyiakengPuachueHmong-Regular.ttf"
                },
                "font_name": "NotoSerifNyiakengPuachueHmong Regular",
                "font_family": "NotoSerifNyiakengPuachueHmong",
                "font_postscript": "NotoSerifNyiakengPuachueHmong-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifOldUyghur",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifOldUyghur-Regular.ttf"
                },
                "font_name": "NotoSerifOldUyghur Regular",
                "font_family": "NotoSerifOldUyghur",
                "font_postscript": "NotoSerifOldUyghur-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifOriya",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifOriya-Regular.ttf"
                },
                "font_name": "NotoSerifOriya Regular",
                "font_family": "NotoSerifOriya",
                "font_postscript": "NotoSerifOriya-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifOttomanSiyaq",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifOttomanSiyaq-Regular.ttf"
                },
                "font_name": "NotoSerifOttomanSiyaq Regular",
                "font_family": "NotoSerifOttomanSiyaq",
                "font_postscript": "NotoSerifOttomanSiyaq-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifSC-Regular.ttf"
                },
                "font_name": "NotoSerifSC Regular",
                "font_family": "NotoSerifSC",
                "font_postscript": "NotoSerifSC-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifSinhala",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifSinhala-Regular.ttf"
                },
                "font_name": "NotoSerifSinhala Regular",
                "font_family": "NotoSerifSinhala",
                "font_postscript": "NotoSerifSinhala-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifTC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifTC-Regular.ttf"
                },
                "font_name": "NotoSerifTC Regular",
                "font_family": "NotoSerifTC",
                "font_postscript": "NotoSerifTC-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifTamil",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifTamil-Regular.ttf"
                },
                "font_name": "NotoSerifTamil Regular",
                "font_family": "NotoSerifTamil",
                "font_postscript": "NotoSerifTamil-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifTamil-Italic.ttf"
                },
                "font_name": "NotoSerifTamil Italic",
                "font_family": "NotoSerifTamil",
                "font_postscript": "NotoSerifTamil-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifTangut",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifTangut-Regular.ttf"
                },
                "font_name": "NotoSerifTangut Regular",
                "font_family": "NotoSerifTangut",
                "font_postscript": "NotoSerifTangut-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifTelugu",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifTelugu-Regular.ttf"
                },
                "font_name": "NotoSerifTelugu Regular",
                "font_family": "NotoSerifTelugu",
                "font_postscript": "NotoSerifTelugu-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifThai",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifThai-Regular.ttf"
                },
                "font_name": "NotoSerifThai Regular",
                "font_family": "NotoSerifThai",
                "font_postscript": "NotoSerifThai-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifTibetan",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifTibetan-Regular.ttf"
                },
                "font_name": "NotoSerifTibetan Regular",
                "font_family": "NotoSerifTibetan",
                "font_postscript": "NotoSerifTibetan-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifToto",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifToto-Regular.ttf"
                },
                "font_name": "NotoSerifToto Regular",
                "font_family": "NotoSerifToto",
                "font_postscript": "NotoSerifToto-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifVithkuqi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifVithkuqi-Regular.ttf"
                },
                "font_name": "NotoSerifVithkuqi Regular",
                "font_family": "NotoSerifVithkuqi",
                "font_postscript": "NotoSerifVithkuqi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoSerifYezidi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoSerifYezidi-Regular.ttf"
                },
                "font_name": "NotoSerifYezidi Regular",
                "font_family": "NotoSerifYezidi",
                "font_postscript": "NotoSerifYezidi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoTraditionalNushu",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoTraditionalNushu-Regular.ttf"
                },
                "font_name": "NotoTraditionalNushu Regular",
                "font_family": "NotoTraditionalNushu",
                "font_postscript": "NotoTraditionalNushu-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NotoZnamennyMusicalNotation",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NotoZnamennyMusicalNotation-Regular.ttf"
                },
                "font_name": "NotoZnamennyMusicalNotation Regular",
                "font_family": "NotoZnamennyMusicalNotation",
                "font_postscript": "NotoZnamennyMusicalNotation-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NovaCut",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NovaCut-Regular.ttf"
                },
                "font_name": "NovaCut Regular",
                "font_family": "NovaCut",
                "font_postscript": "NovaCut-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NovaFlat",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NovaFlat-Regular.ttf"
                },
                "font_name": "NovaFlat Regular",
                "font_family": "NovaFlat",
                "font_postscript": "NovaFlat-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NovaMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NovaMono-Regular.ttf"
                },
                "font_name": "NovaMono Regular",
                "font_family": "NovaMono",
                "font_postscript": "NovaMono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NovaOval",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NovaOval-Regular.ttf"
                },
                "font_name": "NovaOval Regular",
                "font_family": "NovaOval",
                "font_postscript": "NovaOval-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NovaRound",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NovaRound-Regular.ttf"
                },
                "font_name": "NovaRound Regular",
                "font_family": "NovaRound",
                "font_postscript": "NovaRound-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NovaScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NovaScript-Regular.ttf"
                },
                "font_name": "NovaScript Regular",
                "font_family": "NovaScript",
                "font_postscript": "NovaScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NovaSlim",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NovaSlim-Regular.ttf"
                },
                "font_name": "NovaSlim Regular",
                "font_family": "NovaSlim",
                "font_postscript": "NovaSlim-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NovaSquare",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NovaSquare-Regular.ttf"
                },
                "font_name": "NovaSquare Regular",
                "font_family": "NovaSquare",
                "font_postscript": "NovaSquare-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Numans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Numans-Regular.ttf"
                },
                "font_name": "Numans Regular",
                "font_family": "Numans",
                "font_postscript": "Numans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Nunito",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Nunito-Regular.ttf"
                },
                "font_name": "Nunito Regular",
                "font_family": "Nunito",
                "font_postscript": "Nunito-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Nunito-Regular.ttf"
                },
                "font_name": "Nunito Regular",
                "font_family": "Nunito",
                "font_postscript": "Nunito-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Nunito-Italic.ttf"
                },
                "font_name": "Nunito Italic",
                "font_family": "Nunito",
                "font_postscript": "Nunito-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NunitoSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NunitoSans-Italic.ttf"
                },
                "font_name": "NunitoSans Italic",
                "font_family": "NunitoSans",
                "font_postscript": "NunitoSans-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "NunitoSans-Regular.ttf"
                },
                "font_name": "NunitoSans Regular",
                "font_family": "NunitoSans",
                "font_postscript": "NunitoSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "NuosuSIL",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "NuosuSIL-Regular.ttf"
                },
                "font_name": "NuosuSIL Regular",
                "font_family": "NuosuSIL",
                "font_postscript": "NuosuSIL-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "OFLGoudyStMTT",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "OFLGoudyStMTT-Italic.ttf"
                },
                "font_name": "OFLGoudyStMTT Italic",
                "font_family": "OFLGoudyStMTT",
                "font_postscript": "OFLGoudyStMTT-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "OFLGoudyStMTT-Regular.ttf"
                },
                "font_name": "OFLGoudyStMTT Regular",
                "font_family": "OFLGoudyStMTT",
                "font_postscript": "OFLGoudyStMTT-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "OdibeeSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "OdibeeSans-Regular.ttf"
                },
                "font_name": "OdibeeSans Regular",
                "font_family": "OdibeeSans",
                "font_postscript": "OdibeeSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "OdorMeanChey",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "OdorMeanChey-Regular.ttf"
                },
                "font_name": "OdorMeanChey Regular",
                "font_family": "OdorMeanChey",
                "font_postscript": "OdorMeanChey-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Offside",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Offside-Regular.ttf"
                },
                "font_name": "Offside Regular",
                "font_family": "Offside",
                "font_postscript": "Offside-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Oi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Oi-Regular.ttf"
                },
                "font_name": "Oi Regular",
                "font_family": "Oi",
                "font_postscript": "Oi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Ojuju",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ojuju-Regular.ttf"
                },
                "font_name": "Ojuju Regular",
                "font_family": "Ojuju",
                "font_postscript": "Ojuju-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "OldStandard",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "OldStandard-Italic.ttf"
                },
                "font_name": "OldStandard Italic",
                "font_family": "OldStandard",
                "font_postscript": "OldStandard-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "OldStandard-Bold.ttf"
                },
                "font_name": "OldStandard Bold",
                "font_family": "OldStandard",
                "font_postscript": "OldStandard-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "OldStandard-Regular.ttf"
                },
                "font_name": "OldStandard Regular",
                "font_family": "OldStandard",
                "font_postscript": "OldStandard-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Oldenburg",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Oldenburg-Regular.ttf"
                },
                "font_name": "Oldenburg Regular",
                "font_family": "Oldenburg",
                "font_postscript": "Oldenburg-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Ole",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ole-Regular.ttf"
                },
                "font_name": "Ole Regular",
                "font_family": "Ole",
                "font_postscript": "Ole-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "OleoScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "OleoScript-Bold.ttf"
                },
                "font_name": "OleoScript Bold",
                "font_family": "OleoScript",
                "font_postscript": "OleoScript-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "OleoScript-Regular.ttf"
                },
                "font_name": "OleoScript Regular",
                "font_family": "OleoScript",
                "font_postscript": "OleoScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "OleoScriptSwashCaps",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "OleoScriptSwashCaps-Bold.ttf"
                },
                "font_name": "OleoScriptSwashCaps Bold",
                "font_family": "OleoScriptSwashCaps",
                "font_postscript": "OleoScriptSwashCaps-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "OleoScriptSwashCaps-Regular.ttf"
                },
                "font_name": "OleoScriptSwashCaps Regular",
                "font_family": "OleoScriptSwashCaps",
                "font_postscript": "OleoScriptSwashCaps-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Onest",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Onest-Regular.ttf"
                },
                "font_name": "Onest Regular",
                "font_family": "Onest",
                "font_postscript": "Onest-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "OoohBaby",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "OoohBaby-Regular.ttf"
                },
                "font_name": "OoohBaby Regular",
                "font_family": "OoohBaby",
                "font_postscript": "OoohBaby-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "OpenSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "OpenSans-Regular.ttf"
                },
                "font_name": "OpenSans Regular",
                "font_family": "OpenSans",
                "font_postscript": "OpenSans-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "OpenSans-Italic.ttf"
                },
                "font_name": "OpenSans Italic",
                "font_family": "OpenSans",
                "font_postscript": "OpenSans-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "OpenSansCondensed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "OpenSansCondensed-Italic.ttf"
                },
                "font_name": "OpenSansCondensed Italic",
                "font_family": "OpenSansCondensed",
                "font_postscript": "OpenSansCondensed-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "OpenSansCondensed-Regular.ttf"
                },
                "font_name": "OpenSansCondensed Regular",
                "font_family": "OpenSansCondensed",
                "font_postscript": "OpenSansCondensed-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "OpenSansHebrew",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "OpenSansHebrew-ExtraBoldItalic.ttf"
                },
                "font_name": "OpenSansHebrew ExtraBoldItalic",
                "font_family": "OpenSansHebrew",
                "font_postscript": "OpenSansHebrew-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "OpenSansHebrew-BoldItalic.ttf"
                },
                "font_name": "OpenSansHebrew BoldItalic",
                "font_family": "OpenSansHebrew",
                "font_postscript": "OpenSansHebrew-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "OpenSansHebrew-Regular.ttf"
                },
                "font_name": "OpenSansHebrew Regular",
                "font_family": "OpenSansHebrew",
                "font_postscript": "OpenSansHebrew-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "OpenSansHebrew-ExtraBold.ttf"
                },
                "font_name": "OpenSansHebrew ExtraBold",
                "font_family": "OpenSansHebrew",
                "font_postscript": "OpenSansHebrew-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "OpenSansHebrew-Light.ttf"
                },
                "font_name": "OpenSansHebrew Light",
                "font_family": "OpenSansHebrew",
                "font_postscript": "OpenSansHebrew-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "OpenSansHebrew-LightItalic.ttf"
                },
                "font_name": "OpenSansHebrew LightItalic",
                "font_family": "OpenSansHebrew",
                "font_postscript": "OpenSansHebrew-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "OpenSansHebrew-Bold.ttf"
                },
                "font_name": "OpenSansHebrew Bold",
                "font_family": "OpenSansHebrew",
                "font_postscript": "OpenSansHebrew-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "OpenSansHebrew-Italic.ttf"
                },
                "font_name": "OpenSansHebrew Italic",
                "font_family": "OpenSansHebrew",
                "font_postscript": "OpenSansHebrew-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "OpenSansHebrewCondensed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "OpenSansHebrewCondensed-Italic.ttf"
                },
                "font_name": "OpenSansHebrewCondensed Italic",
                "font_family": "OpenSansHebrewCondensed",
                "font_postscript": "OpenSansHebrewCondensed-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "OpenSansHebrewCondensed-ExtraBoldItalic.ttf"
                },
                "font_name": "OpenSansHebrewCondensed ExtraBoldItalic",
                "font_family": "OpenSansHebrewCondensed",
                "font_postscript": "OpenSansHebrewCondensed-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "OpenSansHebrewCondensed-LightItalic.ttf"
                },
                "font_name": "OpenSansHebrewCondensed LightItalic",
                "font_family": "OpenSansHebrewCondensed",
                "font_postscript": "OpenSansHebrewCondensed-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "OpenSansHebrewCondensed-Regular.ttf"
                },
                "font_name": "OpenSansHebrewCondensed Regular",
                "font_family": "OpenSansHebrewCondensed",
                "font_postscript": "OpenSansHebrewCondensed-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "OpenSansHebrewCondensed-ExtraBold.ttf"
                },
                "font_name": "OpenSansHebrewCondensed ExtraBold",
                "font_family": "OpenSansHebrewCondensed",
                "font_postscript": "OpenSansHebrewCondensed-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "OpenSansHebrewCondensed-BoldItalic.ttf"
                },
                "font_name": "OpenSansHebrewCondensed BoldItalic",
                "font_family": "OpenSansHebrewCondensed",
                "font_postscript": "OpenSansHebrewCondensed-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "OpenSansHebrewCondensed-Light.ttf"
                },
                "font_name": "OpenSansHebrewCondensed Light",
                "font_family": "OpenSansHebrewCondensed",
                "font_postscript": "OpenSansHebrewCondensed-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "OpenSansHebrewCondensed-Bold.ttf"
                },
                "font_name": "OpenSansHebrewCondensed Bold",
                "font_family": "OpenSansHebrewCondensed",
                "font_postscript": "OpenSansHebrewCondensed-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Oranienbaum",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Oranienbaum-Regular.ttf"
                },
                "font_name": "Oranienbaum Regular",
                "font_family": "Oranienbaum",
                "font_postscript": "Oranienbaum-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Orbit",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Orbit-Regular.ttf"
                },
                "font_name": "Orbit Regular",
                "font_family": "Orbit",
                "font_postscript": "Orbit-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Orbitron",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Orbitron-Regular.ttf"
                },
                "font_name": "Orbitron Regular",
                "font_family": "Orbitron",
                "font_postscript": "Orbitron-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Oregano",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Oregano-Italic.ttf"
                },
                "font_name": "Oregano Italic",
                "font_family": "Oregano",
                "font_postscript": "Oregano-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Oregano-Regular.ttf"
                },
                "font_name": "Oregano Regular",
                "font_family": "Oregano",
                "font_postscript": "Oregano-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "OrelegaOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "OrelegaOne-Regular.ttf"
                },
                "font_name": "OrelegaOne Regular",
                "font_family": "OrelegaOne",
                "font_postscript": "OrelegaOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Orienta",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Orienta-Regular.ttf"
                },
                "font_name": "Orienta Regular",
                "font_family": "Orienta",
                "font_postscript": "Orienta-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "OriginalSurfer",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "OriginalSurfer-Regular.ttf"
                },
                "font_name": "OriginalSurfer Regular",
                "font_family": "OriginalSurfer",
                "font_postscript": "OriginalSurfer-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Oswald",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Oswald-Regular.ttf"
                },
                "font_name": "Oswald Regular",
                "font_family": "Oswald",
                "font_postscript": "Oswald-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "OtomanopeeOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "OtomanopeeOne-Regular.ttf"
                },
                "font_name": "OtomanopeeOne Regular",
                "font_family": "OtomanopeeOne",
                "font_postscript": "OtomanopeeOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Outfit",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Outfit-Regular.ttf"
                },
                "font_name": "Outfit Regular",
                "font_family": "Outfit",
                "font_postscript": "Outfit-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Overlock",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Overlock-BoldItalic.ttf"
                },
                "font_name": "Overlock BoldItalic",
                "font_family": "Overlock",
                "font_postscript": "Overlock-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Overlock-BlackItalic.ttf"
                },
                "font_name": "Overlock BlackItalic",
                "font_family": "Overlock",
                "font_postscript": "Overlock-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Overlock-Black.ttf"
                },
                "font_name": "Overlock Black",
                "font_family": "Overlock",
                "font_postscript": "Overlock-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Overlock-Regular.ttf"
                },
                "font_name": "Overlock Regular",
                "font_family": "Overlock",
                "font_postscript": "Overlock-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Overlock-Italic.ttf"
                },
                "font_name": "Overlock Italic",
                "font_family": "Overlock",
                "font_postscript": "Overlock-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Overlock-Bold.ttf"
                },
                "font_name": "Overlock Bold",
                "font_family": "Overlock",
                "font_postscript": "Overlock-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "OverlockSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "OverlockSC-Regular.ttf"
                },
                "font_name": "OverlockSC Regular",
                "font_family": "OverlockSC",
                "font_postscript": "OverlockSC-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Overpass",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Overpass-Regular.ttf"
                },
                "font_name": "Overpass Regular",
                "font_family": "Overpass",
                "font_postscript": "Overpass-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Overpass-Italic.ttf"
                },
                "font_name": "Overpass Italic",
                "font_family": "Overpass",
                "font_postscript": "Overpass-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "OverpassMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "OverpassMono-Regular.ttf"
                },
                "font_name": "OverpassMono Regular",
                "font_family": "OverpassMono",
                "font_postscript": "OverpassMono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "OvertheRainbow",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "OvertheRainbow-Regular.ttf"
                },
                "font_name": "OvertheRainbow Regular",
                "font_family": "OvertheRainbow",
                "font_postscript": "OvertheRainbow-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Ovo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ovo-Regular.ttf"
                },
                "font_name": "Ovo Regular",
                "font_family": "Ovo",
                "font_postscript": "Ovo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Oxanium",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Oxanium-Regular.ttf"
                },
                "font_name": "Oxanium Regular",
                "font_family": "Oxanium",
                "font_postscript": "Oxanium-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Oxygen",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Oxygen-Regular.ttf"
                },
                "font_name": "Oxygen Regular",
                "font_family": "Oxygen",
                "font_postscript": "Oxygen-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Oxygen-Light.ttf"
                },
                "font_name": "Oxygen Light",
                "font_family": "Oxygen",
                "font_postscript": "Oxygen-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Oxygen-Bold.ttf"
                },
                "font_name": "Oxygen Bold",
                "font_family": "Oxygen",
                "font_postscript": "Oxygen-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "OxygenMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "OxygenMono-Regular.ttf"
                },
                "font_name": "OxygenMono Regular",
                "font_family": "OxygenMono",
                "font_postscript": "OxygenMono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PTM55FT",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PTM55FT-Regular.ttf"
                },
                "font_name": "PTM55FT Regular",
                "font_family": "PTM55FT",
                "font_postscript": "PTM55FT-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PT_Sans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PT_Sans-Narrow-Web-Regular.ttf"
                },
                "font_name": "PT_Sans Narrow Web Regular",
                "font_family": "PT_Sans",
                "font_postscript": "PT_Sans-Narrow-Web-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PT_Sans-Narrow-Web-Bold.ttf"
                },
                "font_name": "PT_Sans Narrow Web Bold",
                "font_family": "PT_Sans",
                "font_postscript": "PT_Sans-Narrow-Web-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PT_Sans-Caption-Web-Regular.ttf"
                },
                "font_name": "PT_Sans Caption Web Regular",
                "font_family": "PT_Sans",
                "font_postscript": "PT_Sans-Caption-Web-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PT_Sans-Caption-Web-Bold.ttf"
                },
                "font_name": "PT_Sans Caption Web Bold",
                "font_family": "PT_Sans",
                "font_postscript": "PT_Sans-Caption-Web-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PT_Sans-Web-Italic.ttf"
                },
                "font_name": "PT_Sans Web Italic",
                "font_family": "PT_Sans",
                "font_postscript": "PT_Sans-Web-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PT_Sans-Web-Regular.ttf"
                },
                "font_name": "PT_Sans Web Regular",
                "font_family": "PT_Sans",
                "font_postscript": "PT_Sans-Web-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PT_Sans-Web-Bold.ttf"
                },
                "font_name": "PT_Sans Web Bold",
                "font_family": "PT_Sans",
                "font_postscript": "PT_Sans-Web-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PT_Sans-Web-BoldItalic.ttf"
                },
                "font_name": "PT_Sans Web BoldItalic",
                "font_family": "PT_Sans",
                "font_postscript": "PT_Sans-Web-BoldItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PT_Serif",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PT_Serif-Caption-Web-Italic.ttf"
                },
                "font_name": "PT_Serif Caption Web Italic",
                "font_family": "PT_Serif",
                "font_postscript": "PT_Serif-Caption-Web-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PT_Serif-Web-Regular.ttf"
                },
                "font_name": "PT_Serif Web Regular",
                "font_family": "PT_Serif",
                "font_postscript": "PT_Serif-Web-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PT_Serif-Web-BoldItalic.ttf"
                },
                "font_name": "PT_Serif Web BoldItalic",
                "font_family": "PT_Serif",
                "font_postscript": "PT_Serif-Web-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PT_Serif-Web-Italic.ttf"
                },
                "font_name": "PT_Serif Web Italic",
                "font_family": "PT_Serif",
                "font_postscript": "PT_Serif-Web-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PT_Serif-Caption-Web-Regular.ttf"
                },
                "font_name": "PT_Serif Caption Web Regular",
                "font_family": "PT_Serif",
                "font_postscript": "PT_Serif-Caption-Web-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PT_Serif-Web-Bold.ttf"
                },
                "font_name": "PT_Serif Web Bold",
                "font_family": "PT_Serif",
                "font_postscript": "PT_Serif-Web-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Pacifico",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Pacifico-Regular.ttf"
                },
                "font_name": "Pacifico Regular",
                "font_family": "Pacifico",
                "font_postscript": "Pacifico-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Padauk",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Padauk-Regular.ttf"
                },
                "font_name": "Padauk Regular",
                "font_family": "Padauk",
                "font_postscript": "Padauk-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Padauk-Bold.ttf"
                },
                "font_name": "Padauk Bold",
                "font_family": "Padauk",
                "font_postscript": "Padauk-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PadyakkeExpandedOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PadyakkeExpandedOne-Regular.ttf"
                },
                "font_name": "PadyakkeExpandedOne Regular",
                "font_family": "PadyakkeExpandedOne",
                "font_postscript": "PadyakkeExpandedOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Palanquin",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Palanquin-Regular.ttf"
                },
                "font_name": "Palanquin Regular",
                "font_family": "Palanquin",
                "font_postscript": "Palanquin-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Palanquin-Thin.ttf"
                },
                "font_name": "Palanquin Thin",
                "font_family": "Palanquin",
                "font_postscript": "Palanquin-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Palanquin-ExtraLight.ttf"
                },
                "font_name": "Palanquin ExtraLight",
                "font_family": "Palanquin",
                "font_postscript": "Palanquin-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Palanquin-Bold.ttf"
                },
                "font_name": "Palanquin Bold",
                "font_family": "Palanquin",
                "font_postscript": "Palanquin-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Palanquin-Light.ttf"
                },
                "font_name": "Palanquin Light",
                "font_family": "Palanquin",
                "font_postscript": "Palanquin-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Palanquin-SemiBold.ttf"
                },
                "font_name": "Palanquin SemiBold",
                "font_family": "Palanquin",
                "font_postscript": "Palanquin-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Palanquin-Medium.ttf"
                },
                "font_name": "Palanquin Medium",
                "font_family": "Palanquin",
                "font_postscript": "Palanquin-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PalanquinDark",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PalanquinDark-Medium.ttf"
                },
                "font_name": "PalanquinDark Medium",
                "font_family": "PalanquinDark",
                "font_postscript": "PalanquinDark-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PalanquinDark-Regular.ttf"
                },
                "font_name": "PalanquinDark Regular",
                "font_family": "PalanquinDark",
                "font_postscript": "PalanquinDark-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PalanquinDark-Bold.ttf"
                },
                "font_name": "PalanquinDark Bold",
                "font_family": "PalanquinDark",
                "font_postscript": "PalanquinDark-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PalanquinDark-SemiBold.ttf"
                },
                "font_name": "PalanquinDark SemiBold",
                "font_family": "PalanquinDark",
                "font_postscript": "PalanquinDark-SemiBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PaletteMosaic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PaletteMosaic-Regular.ttf"
                },
                "font_name": "PaletteMosaic Regular",
                "font_family": "PaletteMosaic",
                "font_postscript": "PaletteMosaic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Pangolin",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Pangolin-Regular.ttf"
                },
                "font_name": "Pangolin Regular",
                "font_family": "Pangolin",
                "font_postscript": "Pangolin-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Paprika",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Paprika-Regular.ttf"
                },
                "font_name": "Paprika Regular",
                "font_family": "Paprika",
                "font_postscript": "Paprika-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Parisienne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Parisienne-Regular.ttf"
                },
                "font_name": "Parisienne Regular",
                "font_family": "Parisienne",
                "font_postscript": "Parisienne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PasseroOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PasseroOne-Regular.ttf"
                },
                "font_name": "PasseroOne Regular",
                "font_family": "PasseroOne",
                "font_postscript": "PasseroOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PassionOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PassionOne-Regular.ttf"
                },
                "font_name": "PassionOne Regular",
                "font_family": "PassionOne",
                "font_postscript": "PassionOne-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PassionOne-Bold.ttf"
                },
                "font_name": "PassionOne Bold",
                "font_family": "PassionOne",
                "font_postscript": "PassionOne-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PassionOne-Black.ttf"
                },
                "font_name": "PassionOne Black",
                "font_family": "PassionOne",
                "font_postscript": "PassionOne-Black",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PassionsConflict",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PassionsConflict-Regular.ttf"
                },
                "font_name": "PassionsConflict Regular",
                "font_family": "PassionsConflict",
                "font_postscript": "PassionsConflict-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PathwayExtreme",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PathwayExtreme-Regular.ttf"
                },
                "font_name": "PathwayExtreme Regular",
                "font_family": "PathwayExtreme",
                "font_postscript": "PathwayExtreme-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PathwayExtreme-Italic.ttf"
                },
                "font_name": "PathwayExtreme Italic",
                "font_family": "PathwayExtreme",
                "font_postscript": "PathwayExtreme-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PathwayGothicOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PathwayGothicOne-Regular.ttf"
                },
                "font_name": "PathwayGothicOne Regular",
                "font_family": "PathwayGothicOne",
                "font_postscript": "PathwayGothicOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PatrickHand",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PatrickHand-Regular.ttf"
                },
                "font_name": "PatrickHand Regular",
                "font_family": "PatrickHand",
                "font_postscript": "PatrickHand-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PatrickHandSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PatrickHandSC-Regular.ttf"
                },
                "font_name": "PatrickHandSC Regular",
                "font_family": "PatrickHandSC",
                "font_postscript": "PatrickHandSC-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Pattaya",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Pattaya-Regular.ttf"
                },
                "font_name": "Pattaya Regular",
                "font_family": "Pattaya",
                "font_postscript": "Pattaya-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PatuaOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PatuaOne-Regular.ttf"
                },
                "font_name": "PatuaOne Regular",
                "font_family": "PatuaOne",
                "font_postscript": "PatuaOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Pavanam",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Pavanam-Regular.ttf"
                },
                "font_name": "Pavanam Regular",
                "font_family": "Pavanam",
                "font_postscript": "Pavanam-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PaytoneOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PaytoneOne-Regular.ttf"
                },
                "font_name": "PaytoneOne Regular",
                "font_family": "PaytoneOne",
                "font_postscript": "PaytoneOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Peddana",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Peddana-Regular.ttf"
                },
                "font_name": "Peddana Regular",
                "font_family": "Peddana",
                "font_postscript": "Peddana-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Peralta",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Peralta-Regular.ttf"
                },
                "font_name": "Peralta Regular",
                "font_family": "Peralta",
                "font_postscript": "Peralta-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PermanentMarker",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PermanentMarker-Regular.ttf"
                },
                "font_name": "PermanentMarker Regular",
                "font_family": "PermanentMarker",
                "font_postscript": "PermanentMarker-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Petemoss",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Petemoss-Regular.ttf"
                },
                "font_name": "Petemoss Regular",
                "font_family": "Petemoss",
                "font_postscript": "Petemoss-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PetitFormalScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PetitFormalScript-Regular.ttf"
                },
                "font_name": "PetitFormalScript Regular",
                "font_family": "PetitFormalScript",
                "font_postscript": "PetitFormalScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Petrona",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Petrona-Regular.ttf"
                },
                "font_name": "Petrona Regular",
                "font_family": "Petrona",
                "font_postscript": "Petrona-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Petrona-Italic.ttf"
                },
                "font_name": "Petrona Italic",
                "font_family": "Petrona",
                "font_postscript": "Petrona-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Phetsarath",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Phetsarath-Regular.ttf"
                },
                "font_name": "Phetsarath Regular",
                "font_family": "Phetsarath",
                "font_postscript": "Phetsarath-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Phetsarath-Bold.ttf"
                },
                "font_name": "Phetsarath Bold",
                "font_family": "Phetsarath",
                "font_postscript": "Phetsarath-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Philosopher",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Philosopher-Bold.ttf"
                },
                "font_name": "Philosopher Bold",
                "font_family": "Philosopher",
                "font_postscript": "Philosopher-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Philosopher-Regular.ttf"
                },
                "font_name": "Philosopher Regular",
                "font_family": "Philosopher",
                "font_postscript": "Philosopher-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Philosopher-BoldItalic.ttf"
                },
                "font_name": "Philosopher BoldItalic",
                "font_family": "Philosopher",
                "font_postscript": "Philosopher-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Philosopher-Italic.ttf"
                },
                "font_name": "Philosopher Italic",
                "font_family": "Philosopher",
                "font_postscript": "Philosopher-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Phudu",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Phudu-Regular.ttf"
                },
                "font_name": "Phudu Regular",
                "font_family": "Phudu",
                "font_postscript": "Phudu-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Piazzolla",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Piazzolla-Italic.ttf"
                },
                "font_name": "Piazzolla Italic",
                "font_family": "Piazzolla",
                "font_postscript": "Piazzolla-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Piazzolla-Regular.ttf"
                },
                "font_name": "Piazzolla Regular",
                "font_family": "Piazzolla",
                "font_postscript": "Piazzolla-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Piedra",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Piedra-Regular.ttf"
                },
                "font_name": "Piedra Regular",
                "font_family": "Piedra",
                "font_postscript": "Piedra-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PinyonScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PinyonScript-Regular.ttf"
                },
                "font_name": "PinyonScript Regular",
                "font_family": "PinyonScript",
                "font_postscript": "PinyonScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PirataOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PirataOne-Regular.ttf"
                },
                "font_name": "PirataOne Regular",
                "font_family": "PirataOne",
                "font_postscript": "PirataOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PixelifySans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PixelifySans-Regular.ttf"
                },
                "font_name": "PixelifySans Regular",
                "font_family": "PixelifySans",
                "font_postscript": "PixelifySans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Plaster",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Plaster-Regular.ttf"
                },
                "font_name": "Plaster Regular",
                "font_family": "Plaster",
                "font_postscript": "Plaster-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Platypi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Platypi-Italic.ttf"
                },
                "font_name": "Platypi Italic",
                "font_family": "Platypi",
                "font_postscript": "Platypi-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Platypi-Regular.ttf"
                },
                "font_name": "Platypi Regular",
                "font_family": "Platypi",
                "font_postscript": "Platypi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Play",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Play-Regular.ttf"
                },
                "font_name": "Play Regular",
                "font_family": "Play",
                "font_postscript": "Play-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Play-Bold.ttf"
                },
                "font_name": "Play Bold",
                "font_family": "Play",
                "font_postscript": "Play-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Playball",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Playball-Regular.ttf"
                },
                "font_name": "Playball Regular",
                "font_family": "Playball",
                "font_postscript": "Playball-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Playfair",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Playfair-Regular.ttf"
                },
                "font_name": "Playfair Regular",
                "font_family": "Playfair",
                "font_postscript": "Playfair-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Playfair-Italic.ttf"
                },
                "font_name": "Playfair Italic",
                "font_family": "Playfair",
                "font_postscript": "Playfair-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PlayfairDisplay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlayfairDisplay-Italic.ttf"
                },
                "font_name": "PlayfairDisplay Italic",
                "font_family": "PlayfairDisplay",
                "font_postscript": "PlayfairDisplay-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlayfairDisplay-Regular.ttf"
                },
                "font_name": "PlayfairDisplay Regular",
                "font_family": "PlayfairDisplay",
                "font_postscript": "PlayfairDisplay-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PlayfairDisplaySC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlayfairDisplaySC-BoldItalic.ttf"
                },
                "font_name": "PlayfairDisplaySC BoldItalic",
                "font_family": "PlayfairDisplaySC",
                "font_postscript": "PlayfairDisplaySC-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlayfairDisplaySC-Black.ttf"
                },
                "font_name": "PlayfairDisplaySC Black",
                "font_family": "PlayfairDisplaySC",
                "font_postscript": "PlayfairDisplaySC-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlayfairDisplaySC-Italic.ttf"
                },
                "font_name": "PlayfairDisplaySC Italic",
                "font_family": "PlayfairDisplaySC",
                "font_postscript": "PlayfairDisplaySC-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlayfairDisplaySC-Regular.ttf"
                },
                "font_name": "PlayfairDisplaySC Regular",
                "font_family": "PlayfairDisplaySC",
                "font_postscript": "PlayfairDisplaySC-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlayfairDisplaySC-Bold.ttf"
                },
                "font_name": "PlayfairDisplaySC Bold",
                "font_family": "PlayfairDisplaySC",
                "font_postscript": "PlayfairDisplaySC-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlayfairDisplaySC-BlackItalic.ttf"
                },
                "font_name": "PlayfairDisplaySC BlackItalic",
                "font_family": "PlayfairDisplaySC",
                "font_postscript": "PlayfairDisplaySC-BlackItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PlaypenSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlaypenSans-Regular.ttf"
                },
                "font_name": "PlaypenSans Regular",
                "font_family": "PlaypenSans",
                "font_postscript": "PlaypenSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PlaywriteBR",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlaywriteBR-Regular.ttf"
                },
                "font_name": "PlaywriteBR Regular",
                "font_family": "PlaywriteBR",
                "font_postscript": "PlaywriteBR-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PlaywriteCO",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlaywriteCO-Regular.ttf"
                },
                "font_name": "PlaywriteCO Regular",
                "font_family": "PlaywriteCO",
                "font_postscript": "PlaywriteCO-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PlaywriteDEGrund",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlaywriteDEGrund-Regular.ttf"
                },
                "font_name": "PlaywriteDEGrund Regular",
                "font_family": "PlaywriteDEGrund",
                "font_postscript": "PlaywriteDEGrund-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PlaywriteDESAS",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlaywriteDESAS-Regular.ttf"
                },
                "font_name": "PlaywriteDESAS Regular",
                "font_family": "PlaywriteDESAS",
                "font_postscript": "PlaywriteDESAS-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PlaywriteDEVA",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlaywriteDEVA-Regular.ttf"
                },
                "font_name": "PlaywriteDEVA Regular",
                "font_family": "PlaywriteDEVA",
                "font_postscript": "PlaywriteDEVA-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PlaywriteFRTrad",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlaywriteFRTrad-Regular.ttf"
                },
                "font_name": "PlaywriteFRTrad Regular",
                "font_family": "PlaywriteFRTrad",
                "font_postscript": "PlaywriteFRTrad-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PlaywriteGBJ",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlaywriteGBJ-Regular.ttf"
                },
                "font_name": "PlaywriteGBJ Regular",
                "font_family": "PlaywriteGBJ",
                "font_postscript": "PlaywriteGBJ-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlaywriteGBJ-Italic.ttf"
                },
                "font_name": "PlaywriteGBJ Italic",
                "font_family": "PlaywriteGBJ",
                "font_postscript": "PlaywriteGBJ-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PlaywriteGBS",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlaywriteGBS-Italic.ttf"
                },
                "font_name": "PlaywriteGBS Italic",
                "font_family": "PlaywriteGBS",
                "font_postscript": "PlaywriteGBS-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlaywriteGBS-Regular.ttf"
                },
                "font_name": "PlaywriteGBS Regular",
                "font_family": "PlaywriteGBS",
                "font_postscript": "PlaywriteGBS-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PlaywriteVN",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlaywriteVN-Regular.ttf"
                },
                "font_name": "PlaywriteVN Regular",
                "font_family": "PlaywriteVN",
                "font_postscript": "PlaywriteVN-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PlusJakartaSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlusJakartaSans-Italic.ttf"
                },
                "font_name": "PlusJakartaSans Italic",
                "font_family": "PlusJakartaSans",
                "font_postscript": "PlusJakartaSans-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PlusJakartaSans-Regular.ttf"
                },
                "font_name": "PlusJakartaSans Regular",
                "font_family": "PlusJakartaSans",
                "font_postscript": "PlusJakartaSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Podkova",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Podkova-Regular.ttf"
                },
                "font_name": "Podkova Regular",
                "font_family": "Podkova",
                "font_postscript": "Podkova-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PodkovaVFBeta",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PodkovaVFBeta-Regular.ttf"
                },
                "font_name": "PodkovaVFBeta Regular",
                "font_family": "PodkovaVFBeta",
                "font_postscript": "PodkovaVFBeta-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PoetsenOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PoetsenOne-Regular.ttf"
                },
                "font_name": "PoetsenOne Regular",
                "font_family": "PoetsenOne",
                "font_postscript": "PoetsenOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PoiretOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PoiretOne-Regular.ttf"
                },
                "font_name": "PoiretOne Regular",
                "font_family": "PoiretOne",
                "font_postscript": "PoiretOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PollerOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PollerOne-Regular.ttf"
                },
                "font_name": "PollerOne Regular",
                "font_family": "PollerOne",
                "font_postscript": "PollerOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PoltawskiNowy",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PoltawskiNowy-Italic.ttf"
                },
                "font_name": "PoltawskiNowy Italic",
                "font_family": "PoltawskiNowy",
                "font_postscript": "PoltawskiNowy-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PoltawskiNowy-Regular.ttf"
                },
                "font_name": "PoltawskiNowy Regular",
                "font_family": "PoltawskiNowy",
                "font_postscript": "PoltawskiNowy-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Poly",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Poly-Italic.ttf"
                },
                "font_name": "Poly Italic",
                "font_family": "Poly",
                "font_postscript": "Poly-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Poly-Regular.ttf"
                },
                "font_name": "Poly Regular",
                "font_family": "Poly",
                "font_postscript": "Poly-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Pompiere",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Pompiere-Regular.ttf"
                },
                "font_name": "Pompiere Regular",
                "font_family": "Pompiere",
                "font_postscript": "Pompiere-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Ponnala",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ponnala-Regular.ttf"
                },
                "font_name": "Ponnala Regular",
                "font_family": "Ponnala",
                "font_postscript": "Ponnala-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PontanoSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PontanoSans-Regular.ttf"
                },
                "font_name": "PontanoSans Regular",
                "font_family": "PontanoSans",
                "font_postscript": "PontanoSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PoorStory",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PoorStory-Regular.ttf"
                },
                "font_name": "PoorStory Regular",
                "font_family": "PoorStory",
                "font_postscript": "PoorStory-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Poppins",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Poppins-ExtraLight.ttf"
                },
                "font_name": "Poppins ExtraLight",
                "font_family": "Poppins",
                "font_postscript": "Poppins-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Poppins-ThinItalic.ttf"
                },
                "font_name": "Poppins ThinItalic",
                "font_family": "Poppins",
                "font_postscript": "Poppins-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Poppins-ExtraLightItalic.ttf"
                },
                "font_name": "Poppins ExtraLightItalic",
                "font_family": "Poppins",
                "font_postscript": "Poppins-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Poppins-BoldItalic.ttf"
                },
                "font_name": "Poppins BoldItalic",
                "font_family": "Poppins",
                "font_postscript": "Poppins-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Poppins-Light.ttf"
                },
                "font_name": "Poppins Light",
                "font_family": "Poppins",
                "font_postscript": "Poppins-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Poppins-Medium.ttf"
                },
                "font_name": "Poppins Medium",
                "font_family": "Poppins",
                "font_postscript": "Poppins-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Poppins-SemiBoldItalic.ttf"
                },
                "font_name": "Poppins SemiBoldItalic",
                "font_family": "Poppins",
                "font_postscript": "Poppins-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Poppins-ExtraBoldItalic.ttf"
                },
                "font_name": "Poppins ExtraBoldItalic",
                "font_family": "Poppins",
                "font_postscript": "Poppins-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Poppins-ExtraBold.ttf"
                },
                "font_name": "Poppins ExtraBold",
                "font_family": "Poppins",
                "font_postscript": "Poppins-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Poppins-BlackItalic.ttf"
                },
                "font_name": "Poppins BlackItalic",
                "font_family": "Poppins",
                "font_postscript": "Poppins-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Poppins-Regular.ttf"
                },
                "font_name": "Poppins Regular",
                "font_family": "Poppins",
                "font_postscript": "Poppins-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Poppins-LightItalic.ttf"
                },
                "font_name": "Poppins LightItalic",
                "font_family": "Poppins",
                "font_postscript": "Poppins-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Poppins-Bold.ttf"
                },
                "font_name": "Poppins Bold",
                "font_family": "Poppins",
                "font_postscript": "Poppins-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Poppins-Black.ttf"
                },
                "font_name": "Poppins Black",
                "font_family": "Poppins",
                "font_postscript": "Poppins-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Poppins-Thin.ttf"
                },
                "font_name": "Poppins Thin",
                "font_family": "Poppins",
                "font_postscript": "Poppins-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Poppins-SemiBold.ttf"
                },
                "font_name": "Poppins SemiBold",
                "font_family": "Poppins",
                "font_postscript": "Poppins-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Poppins-Italic.ttf"
                },
                "font_name": "Poppins Italic",
                "font_family": "Poppins",
                "font_postscript": "Poppins-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Poppins-MediumItalic.ttf"
                },
                "font_name": "Poppins MediumItalic",
                "font_family": "Poppins",
                "font_postscript": "Poppins-MediumItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PortLligatSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PortLligatSans-Regular.ttf"
                },
                "font_name": "PortLligatSans Regular",
                "font_family": "PortLligatSans",
                "font_postscript": "PortLligatSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PortLligatSlab",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PortLligatSlab-Regular.ttf"
                },
                "font_name": "PortLligatSlab Regular",
                "font_family": "PortLligatSlab",
                "font_postscript": "PortLligatSlab-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PorterSansBlock",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PorterSansBlock-Regular.ttf"
                },
                "font_name": "PorterSansBlock Regular",
                "font_family": "PorterSansBlock",
                "font_postscript": "PorterSansBlock-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PottaOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PottaOne-Regular.ttf"
                },
                "font_name": "PottaOne Regular",
                "font_family": "PottaOne",
                "font_postscript": "PottaOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PragatiNarrow",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PragatiNarrow-Bold.ttf"
                },
                "font_name": "PragatiNarrow Bold",
                "font_family": "PragatiNarrow",
                "font_postscript": "PragatiNarrow-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PragatiNarrow-Regular.ttf"
                },
                "font_name": "PragatiNarrow Regular",
                "font_family": "PragatiNarrow",
                "font_postscript": "PragatiNarrow-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Praise",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Praise-Regular.ttf"
                },
                "font_name": "Praise Regular",
                "font_family": "Praise",
                "font_postscript": "Praise-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Prata",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Prata-Regular.ttf"
                },
                "font_name": "Prata Regular",
                "font_family": "Prata",
                "font_postscript": "Prata-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Preahvihear",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Preahvihear-Regular.ttf"
                },
                "font_name": "Preahvihear Regular",
                "font_family": "Preahvihear",
                "font_postscript": "Preahvihear-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PressStart2P",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PressStart2P-Regular.ttf"
                },
                "font_name": "PressStart2P Regular",
                "font_family": "PressStart2P",
                "font_postscript": "PressStart2P-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Pridi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Pridi-Regular.ttf"
                },
                "font_name": "Pridi Regular",
                "font_family": "Pridi",
                "font_postscript": "Pridi-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Pridi-Bold.ttf"
                },
                "font_name": "Pridi Bold",
                "font_family": "Pridi",
                "font_postscript": "Pridi-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Pridi-Medium.ttf"
                },
                "font_name": "Pridi Medium",
                "font_family": "Pridi",
                "font_postscript": "Pridi-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Pridi-ExtraLight.ttf"
                },
                "font_name": "Pridi ExtraLight",
                "font_family": "Pridi",
                "font_postscript": "Pridi-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Pridi-Light.ttf"
                },
                "font_name": "Pridi Light",
                "font_family": "Pridi",
                "font_postscript": "Pridi-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Pridi-SemiBold.ttf"
                },
                "font_name": "Pridi SemiBold",
                "font_family": "Pridi",
                "font_postscript": "Pridi-SemiBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PrincessSofia",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PrincessSofia-Regular.ttf"
                },
                "font_name": "PrincessSofia Regular",
                "font_family": "PrincessSofia",
                "font_postscript": "PrincessSofia-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Prociono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Prociono-Regular.ttf"
                },
                "font_name": "Prociono Regular",
                "font_family": "Prociono",
                "font_postscript": "Prociono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Prompt",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Prompt-ThinItalic.ttf"
                },
                "font_name": "Prompt ThinItalic",
                "font_family": "Prompt",
                "font_postscript": "Prompt-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Prompt-BlackItalic.ttf"
                },
                "font_name": "Prompt BlackItalic",
                "font_family": "Prompt",
                "font_postscript": "Prompt-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Prompt-ExtraLight.ttf"
                },
                "font_name": "Prompt ExtraLight",
                "font_family": "Prompt",
                "font_postscript": "Prompt-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Prompt-MediumItalic.ttf"
                },
                "font_name": "Prompt MediumItalic",
                "font_family": "Prompt",
                "font_postscript": "Prompt-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Prompt-BoldItalic.ttf"
                },
                "font_name": "Prompt BoldItalic",
                "font_family": "Prompt",
                "font_postscript": "Prompt-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Prompt-Medium.ttf"
                },
                "font_name": "Prompt Medium",
                "font_family": "Prompt",
                "font_postscript": "Prompt-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Prompt-Light.ttf"
                },
                "font_name": "Prompt Light",
                "font_family": "Prompt",
                "font_postscript": "Prompt-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Prompt-ExtraBold.ttf"
                },
                "font_name": "Prompt ExtraBold",
                "font_family": "Prompt",
                "font_postscript": "Prompt-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Prompt-Thin.ttf"
                },
                "font_name": "Prompt Thin",
                "font_family": "Prompt",
                "font_postscript": "Prompt-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Prompt-LightItalic.ttf"
                },
                "font_name": "Prompt LightItalic",
                "font_family": "Prompt",
                "font_postscript": "Prompt-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Prompt-Bold.ttf"
                },
                "font_name": "Prompt Bold",
                "font_family": "Prompt",
                "font_postscript": "Prompt-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Prompt-SemiBold.ttf"
                },
                "font_name": "Prompt SemiBold",
                "font_family": "Prompt",
                "font_postscript": "Prompt-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Prompt-Regular.ttf"
                },
                "font_name": "Prompt Regular",
                "font_family": "Prompt",
                "font_postscript": "Prompt-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Prompt-ExtraLightItalic.ttf"
                },
                "font_name": "Prompt ExtraLightItalic",
                "font_family": "Prompt",
                "font_postscript": "Prompt-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Prompt-SemiBoldItalic.ttf"
                },
                "font_name": "Prompt SemiBoldItalic",
                "font_family": "Prompt",
                "font_postscript": "Prompt-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Prompt-ExtraBoldItalic.ttf"
                },
                "font_name": "Prompt ExtraBoldItalic",
                "font_family": "Prompt",
                "font_postscript": "Prompt-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Prompt-Italic.ttf"
                },
                "font_name": "Prompt Italic",
                "font_family": "Prompt",
                "font_postscript": "Prompt-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Prompt-Black.ttf"
                },
                "font_name": "Prompt Black",
                "font_family": "Prompt",
                "font_postscript": "Prompt-Black",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ProstoOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ProstoOne-Regular.ttf"
                },
                "font_name": "ProstoOne Regular",
                "font_family": "ProstoOne",
                "font_postscript": "ProstoOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ProtestGuerrilla",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ProtestGuerrilla-Regular.ttf"
                },
                "font_name": "ProtestGuerrilla Regular",
                "font_family": "ProtestGuerrilla",
                "font_postscript": "ProtestGuerrilla-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ProtestRevolution",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ProtestRevolution-Regular.ttf"
                },
                "font_name": "ProtestRevolution Regular",
                "font_family": "ProtestRevolution",
                "font_postscript": "ProtestRevolution-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ProtestRiot",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ProtestRiot-Regular.ttf"
                },
                "font_name": "ProtestRiot Regular",
                "font_family": "ProtestRiot",
                "font_postscript": "ProtestRiot-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ProtestStrike",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ProtestStrike-Regular.ttf"
                },
                "font_name": "ProtestStrike Regular",
                "font_family": "ProtestStrike",
                "font_postscript": "ProtestStrike-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ProzaLibre",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ProzaLibre-Medium.ttf"
                },
                "font_name": "ProzaLibre Medium",
                "font_family": "ProzaLibre",
                "font_postscript": "ProzaLibre-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ProzaLibre-BoldItalic.ttf"
                },
                "font_name": "ProzaLibre BoldItalic",
                "font_family": "ProzaLibre",
                "font_postscript": "ProzaLibre-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ProzaLibre-MediumItalic.ttf"
                },
                "font_name": "ProzaLibre MediumItalic",
                "font_family": "ProzaLibre",
                "font_postscript": "ProzaLibre-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ProzaLibre-ExtraBoldItalic.ttf"
                },
                "font_name": "ProzaLibre ExtraBoldItalic",
                "font_family": "ProzaLibre",
                "font_postscript": "ProzaLibre-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ProzaLibre-Regular.ttf"
                },
                "font_name": "ProzaLibre Regular",
                "font_family": "ProzaLibre",
                "font_postscript": "ProzaLibre-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ProzaLibre-ExtraBold.ttf"
                },
                "font_name": "ProzaLibre ExtraBold",
                "font_family": "ProzaLibre",
                "font_postscript": "ProzaLibre-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ProzaLibre-SemiBoldItalic.ttf"
                },
                "font_name": "ProzaLibre SemiBoldItalic",
                "font_family": "ProzaLibre",
                "font_postscript": "ProzaLibre-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ProzaLibre-SemiBold.ttf"
                },
                "font_name": "ProzaLibre SemiBold",
                "font_family": "ProzaLibre",
                "font_postscript": "ProzaLibre-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ProzaLibre-Italic.ttf"
                },
                "font_name": "ProzaLibre Italic",
                "font_family": "ProzaLibre",
                "font_postscript": "ProzaLibre-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ProzaLibre-Bold.ttf"
                },
                "font_name": "ProzaLibre Bold",
                "font_family": "ProzaLibre",
                "font_postscript": "ProzaLibre-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PublicSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PublicSans-Regular.ttf"
                },
                "font_name": "PublicSans Regular",
                "font_family": "PublicSans",
                "font_postscript": "PublicSans-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "PublicSans-Italic.ttf"
                },
                "font_name": "PublicSans Italic",
                "font_family": "PublicSans",
                "font_postscript": "PublicSans-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PuppiesPlay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PuppiesPlay-Regular.ttf"
                },
                "font_name": "PuppiesPlay Regular",
                "font_family": "PuppiesPlay",
                "font_postscript": "PuppiesPlay-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Puritan",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Puritan-Bold.ttf"
                },
                "font_name": "Puritan Bold",
                "font_family": "Puritan",
                "font_postscript": "Puritan-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Puritan-Regular.ttf"
                },
                "font_name": "Puritan Regular",
                "font_family": "Puritan",
                "font_postscript": "Puritan-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Puritan-Italic.ttf"
                },
                "font_name": "Puritan Italic",
                "font_family": "Puritan",
                "font_postscript": "Puritan-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Puritan-BoldItalic.ttf"
                },
                "font_name": "Puritan BoldItalic",
                "font_family": "Puritan",
                "font_postscript": "Puritan-BoldItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "PurplePurse",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "PurplePurse-Regular.ttf"
                },
                "font_name": "PurplePurse Regular",
                "font_family": "PurplePurse",
                "font_postscript": "PurplePurse-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Pushster",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Pushster-Regular.ttf"
                },
                "font_name": "Pushster Regular",
                "font_family": "Pushster",
                "font_postscript": "Pushster-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Qahiri",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Qahiri-Regular.ttf"
                },
                "font_name": "Qahiri Regular",
                "font_family": "Qahiri",
                "font_postscript": "Qahiri-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Quando",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Quando-Regular.ttf"
                },
                "font_name": "Quando Regular",
                "font_family": "Quando",
                "font_postscript": "Quando-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Quantico",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Quantico-Bold.ttf"
                },
                "font_name": "Quantico Bold",
                "font_family": "Quantico",
                "font_postscript": "Quantico-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Quantico-BoldItalic.ttf"
                },
                "font_name": "Quantico BoldItalic",
                "font_family": "Quantico",
                "font_postscript": "Quantico-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Quantico-Regular.ttf"
                },
                "font_name": "Quantico Regular",
                "font_family": "Quantico",
                "font_postscript": "Quantico-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Quantico-Italic.ttf"
                },
                "font_name": "Quantico Italic",
                "font_family": "Quantico",
                "font_postscript": "Quantico-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Quattrocento",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Quattrocento-Bold.ttf"
                },
                "font_name": "Quattrocento Bold",
                "font_family": "Quattrocento",
                "font_postscript": "Quattrocento-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Quattrocento-Regular.ttf"
                },
                "font_name": "Quattrocento Regular",
                "font_family": "Quattrocento",
                "font_postscript": "Quattrocento-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "QuattrocentoSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "QuattrocentoSans-Italic.ttf"
                },
                "font_name": "QuattrocentoSans Italic",
                "font_family": "QuattrocentoSans",
                "font_postscript": "QuattrocentoSans-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "QuattrocentoSans-Regular.ttf"
                },
                "font_name": "QuattrocentoSans Regular",
                "font_family": "QuattrocentoSans",
                "font_postscript": "QuattrocentoSans-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "QuattrocentoSans-BoldItalic.ttf"
                },
                "font_name": "QuattrocentoSans BoldItalic",
                "font_family": "QuattrocentoSans",
                "font_postscript": "QuattrocentoSans-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "QuattrocentoSans-Bold.ttf"
                },
                "font_name": "QuattrocentoSans Bold",
                "font_family": "QuattrocentoSans",
                "font_postscript": "QuattrocentoSans-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Questrial",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Questrial-Regular.ttf"
                },
                "font_name": "Questrial Regular",
                "font_family": "Questrial",
                "font_postscript": "Questrial-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Quicksand",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Quicksand-Regular.ttf"
                },
                "font_name": "Quicksand Regular",
                "font_family": "Quicksand",
                "font_postscript": "Quicksand-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Quintessential",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Quintessential-Regular.ttf"
                },
                "font_name": "Quintessential Regular",
                "font_family": "Quintessential",
                "font_postscript": "Quintessential-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Qwigley",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Qwigley-Regular.ttf"
                },
                "font_name": "Qwigley Regular",
                "font_family": "Qwigley",
                "font_postscript": "Qwigley-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "QwitcherGrypen",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "QwitcherGrypen-Regular.ttf"
                },
                "font_name": "QwitcherGrypen Regular",
                "font_family": "QwitcherGrypen",
                "font_postscript": "QwitcherGrypen-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "QwitcherGrypen-Bold.ttf"
                },
                "font_name": "QwitcherGrypen Bold",
                "font_family": "QwitcherGrypen",
                "font_postscript": "QwitcherGrypen-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "REM",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "REM-Regular.ttf"
                },
                "font_name": "REM Regular",
                "font_family": "REM",
                "font_postscript": "REM-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "REM-Italic.ttf"
                },
                "font_name": "REM Italic",
                "font_family": "REM",
                "font_postscript": "REM-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RacingSansOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RacingSansOne-Regular.ttf"
                },
                "font_name": "RacingSansOne Regular",
                "font_family": "RacingSansOne",
                "font_postscript": "RacingSansOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RadioCanada",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RadioCanada-Regular.ttf"
                },
                "font_name": "RadioCanada Regular",
                "font_family": "RadioCanada",
                "font_postscript": "RadioCanada-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "RadioCanada-Italic.ttf"
                },
                "font_name": "RadioCanada Italic",
                "font_family": "RadioCanada",
                "font_postscript": "RadioCanada-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RadioCanadaBig",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RadioCanadaBig-Italic.ttf"
                },
                "font_name": "RadioCanadaBig Italic",
                "font_family": "RadioCanadaBig",
                "font_postscript": "RadioCanadaBig-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "RadioCanadaBig-Regular.ttf"
                },
                "font_name": "RadioCanadaBig Regular",
                "font_family": "RadioCanadaBig",
                "font_postscript": "RadioCanadaBig-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Radley",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Radley-Italic.ttf"
                },
                "font_name": "Radley Italic",
                "font_family": "Radley",
                "font_postscript": "Radley-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Radley-Regular.ttf"
                },
                "font_name": "Radley Regular",
                "font_family": "Radley",
                "font_postscript": "Radley-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Rajdhani",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rajdhani-Medium.ttf"
                },
                "font_name": "Rajdhani Medium",
                "font_family": "Rajdhani",
                "font_postscript": "Rajdhani-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rajdhani-SemiBold.ttf"
                },
                "font_name": "Rajdhani SemiBold",
                "font_family": "Rajdhani",
                "font_postscript": "Rajdhani-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rajdhani-Light.ttf"
                },
                "font_name": "Rajdhani Light",
                "font_family": "Rajdhani",
                "font_postscript": "Rajdhani-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rajdhani-Bold.ttf"
                },
                "font_name": "Rajdhani Bold",
                "font_family": "Rajdhani",
                "font_postscript": "Rajdhani-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rajdhani-Regular.ttf"
                },
                "font_name": "Rajdhani Regular",
                "font_family": "Rajdhani",
                "font_postscript": "Rajdhani-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Rakkas",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rakkas-Regular.ttf"
                },
                "font_name": "Rakkas Regular",
                "font_family": "Rakkas",
                "font_postscript": "Rakkas-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Raleway",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Raleway-Italic.ttf"
                },
                "font_name": "Raleway Italic",
                "font_family": "Raleway",
                "font_postscript": "Raleway-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Raleway-Regular.ttf"
                },
                "font_name": "Raleway Regular",
                "font_family": "Raleway",
                "font_postscript": "Raleway-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RalewayDots",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RalewayDots-Regular.ttf"
                },
                "font_name": "RalewayDots Regular",
                "font_family": "RalewayDots",
                "font_postscript": "RalewayDots-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Ramabhadra",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ramabhadra-Regular.ttf"
                },
                "font_name": "Ramabhadra Regular",
                "font_family": "Ramabhadra",
                "font_postscript": "Ramabhadra-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Ramaraja",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ramaraja-Regular.ttf"
                },
                "font_name": "Ramaraja Regular",
                "font_family": "Ramaraja",
                "font_postscript": "Ramaraja-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Rambla",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rambla-Italic.ttf"
                },
                "font_name": "Rambla Italic",
                "font_family": "Rambla",
                "font_postscript": "Rambla-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rambla-BoldItalic.ttf"
                },
                "font_name": "Rambla BoldItalic",
                "font_family": "Rambla",
                "font_postscript": "Rambla-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rambla-Regular.ttf"
                },
                "font_name": "Rambla Regular",
                "font_family": "Rambla",
                "font_postscript": "Rambla-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rambla-Bold.ttf"
                },
                "font_name": "Rambla Bold",
                "font_family": "Rambla",
                "font_postscript": "Rambla-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RammettoOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RammettoOne-Regular.ttf"
                },
                "font_name": "RammettoOne Regular",
                "font_family": "RammettoOne",
                "font_postscript": "RammettoOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RampartOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RampartOne-Regular.ttf"
                },
                "font_name": "RampartOne Regular",
                "font_family": "RampartOne",
                "font_postscript": "RampartOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Ranchers",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ranchers-Regular.ttf"
                },
                "font_name": "Ranchers Regular",
                "font_family": "Ranchers",
                "font_postscript": "Ranchers-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Rancho",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rancho-Regular.ttf"
                },
                "font_name": "Rancho Regular",
                "font_family": "Rancho",
                "font_postscript": "Rancho-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Ranga",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ranga-Regular.ttf"
                },
                "font_name": "Ranga Regular",
                "font_family": "Ranga",
                "font_postscript": "Ranga-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ranga-Bold.ttf"
                },
                "font_name": "Ranga Bold",
                "font_family": "Ranga",
                "font_postscript": "Ranga-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Rasa",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rasa-Regular.ttf"
                },
                "font_name": "Rasa Regular",
                "font_family": "Rasa",
                "font_postscript": "Rasa-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rasa-Italic.ttf"
                },
                "font_name": "Rasa Italic",
                "font_family": "Rasa",
                "font_postscript": "Rasa-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Rationale",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rationale-Regular.ttf"
                },
                "font_name": "Rationale Regular",
                "font_family": "Rationale",
                "font_postscript": "Rationale-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RaviPrakash",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RaviPrakash-Regular.ttf"
                },
                "font_name": "RaviPrakash Regular",
                "font_family": "RaviPrakash",
                "font_postscript": "RaviPrakash-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ReadexPro",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ReadexPro-Regular.ttf"
                },
                "font_name": "ReadexPro Regular",
                "font_family": "ReadexPro",
                "font_postscript": "ReadexPro-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Recursive",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Recursive-Regular.ttf"
                },
                "font_name": "Recursive Regular",
                "font_family": "Recursive",
                "font_postscript": "Recursive-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RedHatDisplay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RedHatDisplay-Regular.ttf"
                },
                "font_name": "RedHatDisplay Regular",
                "font_family": "RedHatDisplay",
                "font_postscript": "RedHatDisplay-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "RedHatDisplay-Italic.ttf"
                },
                "font_name": "RedHatDisplay Italic",
                "font_family": "RedHatDisplay",
                "font_postscript": "RedHatDisplay-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RedHatMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RedHatMono-Italic.ttf"
                },
                "font_name": "RedHatMono Italic",
                "font_family": "RedHatMono",
                "font_postscript": "RedHatMono-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "RedHatMono-Regular.ttf"
                },
                "font_name": "RedHatMono Regular",
                "font_family": "RedHatMono",
                "font_postscript": "RedHatMono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RedHatText",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RedHatText-Regular.ttf"
                },
                "font_name": "RedHatText Regular",
                "font_family": "RedHatText",
                "font_postscript": "RedHatText-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "RedHatText-Italic.ttf"
                },
                "font_name": "RedHatText Italic",
                "font_family": "RedHatText",
                "font_postscript": "RedHatText-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RedRose",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RedRose-Regular.ttf"
                },
                "font_name": "RedRose Regular",
                "font_family": "RedRose",
                "font_postscript": "RedRose-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Redacted",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Redacted-Regular.ttf"
                },
                "font_name": "Redacted Regular",
                "font_family": "Redacted",
                "font_postscript": "Redacted-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RedactedScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RedactedScript-Bold.ttf"
                },
                "font_name": "RedactedScript Bold",
                "font_family": "RedactedScript",
                "font_postscript": "RedactedScript-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "RedactedScript-Light.ttf"
                },
                "font_name": "RedactedScript Light",
                "font_family": "RedactedScript",
                "font_postscript": "RedactedScript-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "RedactedScript-Regular.ttf"
                },
                "font_name": "RedactedScript Regular",
                "font_family": "RedactedScript",
                "font_postscript": "RedactedScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RedditMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RedditMono-Regular.ttf"
                },
                "font_name": "RedditMono Regular",
                "font_family": "RedditMono",
                "font_postscript": "RedditMono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RedditSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RedditSans-Regular.ttf"
                },
                "font_name": "RedditSans Regular",
                "font_family": "RedditSans",
                "font_postscript": "RedditSans-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "RedditSans-Italic.ttf"
                },
                "font_name": "RedditSans Italic",
                "font_family": "RedditSans",
                "font_postscript": "RedditSans-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RedditSansCondensed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RedditSansCondensed-Regular.ttf"
                },
                "font_name": "RedditSansCondensed Regular",
                "font_family": "RedditSansCondensed",
                "font_postscript": "RedditSansCondensed-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Redressed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Redressed-Regular.ttf"
                },
                "font_name": "Redressed Regular",
                "font_family": "Redressed",
                "font_postscript": "Redressed-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ReemKufi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ReemKufi-Regular.ttf"
                },
                "font_name": "ReemKufi Regular",
                "font_family": "ReemKufi",
                "font_postscript": "ReemKufi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ReemKufiFun",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ReemKufiFun-Regular.ttf"
                },
                "font_name": "ReemKufiFun Regular",
                "font_family": "ReemKufiFun",
                "font_postscript": "ReemKufiFun-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ReemKufiInk",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ReemKufiInk-Regular.ttf"
                },
                "font_name": "ReemKufiInk Regular",
                "font_family": "ReemKufiInk",
                "font_postscript": "ReemKufiInk-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ReenieBeanie",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ReenieBeanie-Regular.ttf"
                },
                "font_name": "ReenieBeanie Regular",
                "font_family": "ReenieBeanie",
                "font_postscript": "ReenieBeanie-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ReggaeOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ReggaeOne-Regular.ttf"
                },
                "font_name": "ReggaeOne Regular",
                "font_family": "ReggaeOne",
                "font_postscript": "ReggaeOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RethinkSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RethinkSans-Regular.ttf"
                },
                "font_name": "RethinkSans Regular",
                "font_family": "RethinkSans",
                "font_postscript": "RethinkSans-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "RethinkSans-Italic.ttf"
                },
                "font_name": "RethinkSans Italic",
                "font_family": "RethinkSans",
                "font_postscript": "RethinkSans-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Revalia",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Revalia-Regular.ttf"
                },
                "font_name": "Revalia Regular",
                "font_family": "Revalia",
                "font_postscript": "Revalia-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RhodiumLibre",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RhodiumLibre-Regular.ttf"
                },
                "font_name": "RhodiumLibre Regular",
                "font_family": "RhodiumLibre",
                "font_postscript": "RhodiumLibre-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Ribeye",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ribeye-Regular.ttf"
                },
                "font_name": "Ribeye Regular",
                "font_family": "Ribeye",
                "font_postscript": "Ribeye-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RibeyeMarrow",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RibeyeMarrow-Regular.ttf"
                },
                "font_name": "RibeyeMarrow Regular",
                "font_family": "RibeyeMarrow",
                "font_postscript": "RibeyeMarrow-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Righteous",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Righteous-Regular.ttf"
                },
                "font_name": "Righteous Regular",
                "font_family": "Righteous",
                "font_postscript": "Righteous-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Risque",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Risque-Regular.ttf"
                },
                "font_name": "Risque Regular",
                "font_family": "Risque",
                "font_postscript": "Risque-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RoadRage",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RoadRage-Regular.ttf"
                },
                "font_name": "RoadRage Regular",
                "font_family": "RoadRage",
                "font_postscript": "RoadRage-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Roboto",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Roboto-Italic.ttf"
                },
                "font_name": "Roboto Italic",
                "font_family": "Roboto",
                "font_postscript": "Roboto-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Roboto-Regular.ttf"
                },
                "font_name": "Roboto Regular",
                "font_family": "Roboto",
                "font_postscript": "Roboto-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RobotoCondensed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RobotoCondensed-Italic.ttf"
                },
                "font_name": "RobotoCondensed Italic",
                "font_family": "RobotoCondensed",
                "font_postscript": "RobotoCondensed-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "RobotoCondensed-Regular.ttf"
                },
                "font_name": "RobotoCondensed Regular",
                "font_family": "RobotoCondensed",
                "font_postscript": "RobotoCondensed-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RobotoFlex",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RobotoFlex-Regular.ttf"
                },
                "font_name": "RobotoFlex Regular",
                "font_family": "RobotoFlex",
                "font_postscript": "RobotoFlex-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RobotoMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RobotoMono-Regular.ttf"
                },
                "font_name": "RobotoMono Regular",
                "font_family": "RobotoMono",
                "font_postscript": "RobotoMono-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "RobotoMono-Italic.ttf"
                },
                "font_name": "RobotoMono Italic",
                "font_family": "RobotoMono",
                "font_postscript": "RobotoMono-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RobotoSerif",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RobotoSerif-Italic.ttf"
                },
                "font_name": "RobotoSerif Italic",
                "font_family": "RobotoSerif",
                "font_postscript": "RobotoSerif-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "RobotoSerif-Regular.ttf"
                },
                "font_name": "RobotoSerif Regular",
                "font_family": "RobotoSerif",
                "font_postscript": "RobotoSerif-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RobotoSlab",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RobotoSlab-Regular.ttf"
                },
                "font_name": "RobotoSlab Regular",
                "font_family": "RobotoSlab",
                "font_postscript": "RobotoSlab-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Rochester",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rochester-Regular.ttf"
                },
                "font_name": "Rochester Regular",
                "font_family": "Rochester",
                "font_postscript": "Rochester-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Rock3D",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rock3D-Regular.ttf"
                },
                "font_name": "Rock3D Regular",
                "font_family": "Rock3D",
                "font_postscript": "Rock3D-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RockSalt",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RockSalt-Regular.ttf"
                },
                "font_name": "RockSalt Regular",
                "font_family": "RockSalt",
                "font_postscript": "RockSalt-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RocknRollOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RocknRollOne-Regular.ttf"
                },
                "font_name": "RocknRollOne Regular",
                "font_family": "RocknRollOne",
                "font_postscript": "RocknRollOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Rokkitt",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rokkitt-Italic.ttf"
                },
                "font_name": "Rokkitt Italic",
                "font_family": "Rokkitt",
                "font_postscript": "Rokkitt-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rokkitt-Regular.ttf"
                },
                "font_name": "Rokkitt Regular",
                "font_family": "Rokkitt",
                "font_postscript": "Rokkitt-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RokkittVFBeta",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RokkittVFBeta-Regular.ttf"
                },
                "font_name": "RokkittVFBeta Regular",
                "font_family": "RokkittVFBeta",
                "font_postscript": "RokkittVFBeta-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Romanesco",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Romanesco-Regular.ttf"
                },
                "font_name": "Romanesco Regular",
                "font_family": "Romanesco",
                "font_postscript": "Romanesco-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RopaSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RopaSans-Italic.ttf"
                },
                "font_name": "RopaSans Italic",
                "font_family": "RopaSans",
                "font_postscript": "RopaSans-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "RopaSans-Regular.ttf"
                },
                "font_name": "RopaSans Regular",
                "font_family": "RopaSans",
                "font_postscript": "RopaSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Rosario",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rosario-Italic.ttf"
                },
                "font_name": "Rosario Italic",
                "font_family": "Rosario",
                "font_postscript": "Rosario-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rosario-Regular.ttf"
                },
                "font_name": "Rosario Regular",
                "font_family": "Rosario",
                "font_postscript": "Rosario-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Rosarivo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rosarivo-Regular.ttf"
                },
                "font_name": "Rosarivo Regular",
                "font_family": "Rosarivo",
                "font_postscript": "Rosarivo-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rosarivo-Italic.ttf"
                },
                "font_name": "Rosarivo Italic",
                "font_family": "Rosarivo",
                "font_postscript": "Rosarivo-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RougeScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RougeScript-Regular.ttf"
                },
                "font_name": "RougeScript Regular",
                "font_family": "RougeScript",
                "font_postscript": "RougeScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RoundedMplus1c",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RoundedMplus1c-Medium.ttf"
                },
                "font_name": "RoundedMplus1c Medium",
                "font_family": "RoundedMplus1c",
                "font_postscript": "RoundedMplus1c-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "RoundedMplus1c-Light.ttf"
                },
                "font_name": "RoundedMplus1c Light",
                "font_family": "RoundedMplus1c",
                "font_postscript": "RoundedMplus1c-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "RoundedMplus1c-Bold.ttf"
                },
                "font_name": "RoundedMplus1c Bold",
                "font_family": "RoundedMplus1c",
                "font_postscript": "RoundedMplus1c-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "RoundedMplus1c-Thin.ttf"
                },
                "font_name": "RoundedMplus1c Thin",
                "font_family": "RoundedMplus1c",
                "font_postscript": "RoundedMplus1c-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "RoundedMplus1c-Regular.ttf"
                },
                "font_name": "RoundedMplus1c Regular",
                "font_family": "RoundedMplus1c",
                "font_postscript": "RoundedMplus1c-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "RoundedMplus1c-Black.ttf"
                },
                "font_name": "RoundedMplus1c Black",
                "font_family": "RoundedMplus1c",
                "font_postscript": "RoundedMplus1c-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "RoundedMplus1c-ExtraBold.ttf"
                },
                "font_name": "RoundedMplus1c ExtraBold",
                "font_family": "RoundedMplus1c",
                "font_postscript": "RoundedMplus1c-ExtraBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Rowdies",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rowdies-Bold.ttf"
                },
                "font_name": "Rowdies Bold",
                "font_family": "Rowdies",
                "font_postscript": "Rowdies-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rowdies-Light.ttf"
                },
                "font_name": "Rowdies Light",
                "font_family": "Rowdies",
                "font_postscript": "Rowdies-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rowdies-Regular.ttf"
                },
                "font_name": "Rowdies Regular",
                "font_family": "Rowdies",
                "font_postscript": "Rowdies-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RozhaOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RozhaOne-Regular.ttf"
                },
                "font_name": "RozhaOne Regular",
                "font_family": "RozhaOne",
                "font_postscript": "RozhaOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Rubik",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rubik-Italic.ttf"
                },
                "font_name": "Rubik Italic",
                "font_family": "Rubik",
                "font_postscript": "Rubik-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rubik-Regular.ttf"
                },
                "font_name": "Rubik Regular",
                "font_family": "Rubik",
                "font_postscript": "Rubik-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Rubik80sFade",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rubik80sFade-Regular.ttf"
                },
                "font_name": "Rubik80sFade Regular",
                "font_family": "Rubik80sFade",
                "font_postscript": "Rubik80sFade-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikBeastly",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikBeastly-Regular.ttf"
                },
                "font_name": "RubikBeastly Regular",
                "font_family": "RubikBeastly",
                "font_postscript": "RubikBeastly-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikBrokenFax",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikBrokenFax-Regular.ttf"
                },
                "font_name": "RubikBrokenFax Regular",
                "font_family": "RubikBrokenFax",
                "font_postscript": "RubikBrokenFax-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikBubbles",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikBubbles-Regular.ttf"
                },
                "font_name": "RubikBubbles Regular",
                "font_family": "RubikBubbles",
                "font_postscript": "RubikBubbles-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikBurned",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikBurned-Regular.ttf"
                },
                "font_name": "RubikBurned Regular",
                "font_family": "RubikBurned",
                "font_postscript": "RubikBurned-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikDirt",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikDirt-Regular.ttf"
                },
                "font_name": "RubikDirt Regular",
                "font_family": "RubikDirt",
                "font_postscript": "RubikDirt-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikDistressed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikDistressed-Regular.ttf"
                },
                "font_name": "RubikDistressed Regular",
                "font_family": "RubikDistressed",
                "font_postscript": "RubikDistressed-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikDoodleShadow",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikDoodleShadow-Regular.ttf"
                },
                "font_name": "RubikDoodleShadow Regular",
                "font_family": "RubikDoodleShadow",
                "font_postscript": "RubikDoodleShadow-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikDoodleTriangles",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikDoodleTriangles-Regular.ttf"
                },
                "font_name": "RubikDoodleTriangles Regular",
                "font_family": "RubikDoodleTriangles",
                "font_postscript": "RubikDoodleTriangles-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikGemstones",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikGemstones-Regular.ttf"
                },
                "font_name": "RubikGemstones Regular",
                "font_family": "RubikGemstones",
                "font_postscript": "RubikGemstones-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikGlitch",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikGlitch-Regular.ttf"
                },
                "font_name": "RubikGlitch Regular",
                "font_family": "RubikGlitch",
                "font_postscript": "RubikGlitch-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikGlitchPop",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikGlitchPop-Regular.ttf"
                },
                "font_name": "RubikGlitchPop Regular",
                "font_family": "RubikGlitchPop",
                "font_postscript": "RubikGlitchPop-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikIso",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikIso-Regular.ttf"
                },
                "font_name": "RubikIso Regular",
                "font_family": "RubikIso",
                "font_postscript": "RubikIso-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikLines",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikLines-Regular.ttf"
                },
                "font_name": "RubikLines Regular",
                "font_family": "RubikLines",
                "font_postscript": "RubikLines-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikMaps",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikMaps-Regular.ttf"
                },
                "font_name": "RubikMaps Regular",
                "font_family": "RubikMaps",
                "font_postscript": "RubikMaps-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikMarkerHatch",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikMarkerHatch-Regular.ttf"
                },
                "font_name": "RubikMarkerHatch Regular",
                "font_family": "RubikMarkerHatch",
                "font_postscript": "RubikMarkerHatch-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikMaze",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikMaze-Regular.ttf"
                },
                "font_name": "RubikMaze Regular",
                "font_family": "RubikMaze",
                "font_postscript": "RubikMaze-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikMicrobe",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikMicrobe-Regular.ttf"
                },
                "font_name": "RubikMicrobe Regular",
                "font_family": "RubikMicrobe",
                "font_postscript": "RubikMicrobe-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikMonoOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikMonoOne-Regular.ttf"
                },
                "font_name": "RubikMonoOne Regular",
                "font_family": "RubikMonoOne",
                "font_postscript": "RubikMonoOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikMoonrocks",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikMoonrocks-Regular.ttf"
                },
                "font_name": "RubikMoonrocks Regular",
                "font_family": "RubikMoonrocks",
                "font_postscript": "RubikMoonrocks-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikOne-Regular.ttf"
                },
                "font_name": "RubikOne Regular",
                "font_family": "RubikOne",
                "font_postscript": "RubikOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikPixels",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikPixels-Regular.ttf"
                },
                "font_name": "RubikPixels Regular",
                "font_family": "RubikPixels",
                "font_postscript": "RubikPixels-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikPuddles",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikPuddles-Regular.ttf"
                },
                "font_name": "RubikPuddles Regular",
                "font_family": "RubikPuddles",
                "font_postscript": "RubikPuddles-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikScribble",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikScribble-Regular.ttf"
                },
                "font_name": "RubikScribble Regular",
                "font_family": "RubikScribble",
                "font_postscript": "RubikScribble-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikSprayPaint",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikSprayPaint-Regular.ttf"
                },
                "font_name": "RubikSprayPaint Regular",
                "font_family": "RubikSprayPaint",
                "font_postscript": "RubikSprayPaint-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikStorm",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikStorm-Regular.ttf"
                },
                "font_name": "RubikStorm Regular",
                "font_family": "RubikStorm",
                "font_postscript": "RubikStorm-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikVinyl",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikVinyl-Regular.ttf"
                },
                "font_name": "RubikVinyl Regular",
                "font_family": "RubikVinyl",
                "font_postscript": "RubikVinyl-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RubikWetPaint",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RubikWetPaint-Regular.ttf"
                },
                "font_name": "RubikWetPaint Regular",
                "font_family": "RubikWetPaint",
                "font_postscript": "RubikWetPaint-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Ruda",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ruda-Regular.ttf"
                },
                "font_name": "Ruda Regular",
                "font_family": "Ruda",
                "font_postscript": "Ruda-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Rufina",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rufina-Bold.ttf"
                },
                "font_name": "Rufina Bold",
                "font_family": "Rufina",
                "font_postscript": "Rufina-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rufina-Regular.ttf"
                },
                "font_name": "Rufina Regular",
                "font_family": "Rufina",
                "font_postscript": "Rufina-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RugeBoogie",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RugeBoogie-Regular.ttf"
                },
                "font_name": "RugeBoogie Regular",
                "font_family": "RugeBoogie",
                "font_postscript": "RugeBoogie-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Ruluko",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ruluko-Regular.ttf"
                },
                "font_name": "Ruluko Regular",
                "font_family": "Ruluko",
                "font_postscript": "Ruluko-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RumRaisin",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RumRaisin-Regular.ttf"
                },
                "font_name": "RumRaisin Regular",
                "font_family": "RumRaisin",
                "font_postscript": "RumRaisin-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RuslanDisplay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RuslanDisplay-Regular.ttf"
                },
                "font_name": "RuslanDisplay Regular",
                "font_family": "RuslanDisplay",
                "font_postscript": "RuslanDisplay-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "RussoOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "RussoOne-Regular.ttf"
                },
                "font_name": "RussoOne Regular",
                "font_family": "RussoOne",
                "font_postscript": "RussoOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Ruthie",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ruthie-Regular.ttf"
                },
                "font_name": "Ruthie Regular",
                "font_family": "Ruthie",
                "font_postscript": "Ruthie-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Ruwudu",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ruwudu-SemiBold.ttf"
                },
                "font_name": "Ruwudu SemiBold",
                "font_family": "Ruwudu",
                "font_postscript": "Ruwudu-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ruwudu-Bold.ttf"
                },
                "font_name": "Ruwudu Bold",
                "font_family": "Ruwudu",
                "font_postscript": "Ruwudu-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ruwudu-Regular.ttf"
                },
                "font_name": "Ruwudu Regular",
                "font_family": "Ruwudu",
                "font_postscript": "Ruwudu-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ruwudu-Medium.ttf"
                },
                "font_name": "Ruwudu Medium",
                "font_family": "Ruwudu",
                "font_postscript": "Ruwudu-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Rye",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Rye-Regular.ttf"
                },
                "font_name": "Rye Regular",
                "font_family": "Rye",
                "font_postscript": "Rye-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "STIXTwoMath",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "STIXTwoMath-Regular.ttf"
                },
                "font_name": "STIXTwoMath Regular",
                "font_family": "STIXTwoMath",
                "font_postscript": "STIXTwoMath-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "STIXTwoText",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "STIXTwoText-Regular.ttf"
                },
                "font_name": "STIXTwoText Regular",
                "font_family": "STIXTwoText",
                "font_postscript": "STIXTwoText-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "STIXTwoText-Italic.ttf"
                },
                "font_name": "STIXTwoText Italic",
                "font_family": "STIXTwoText",
                "font_postscript": "STIXTwoText-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sacramento",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sacramento-Regular.ttf"
                },
                "font_name": "Sacramento Regular",
                "font_family": "Sacramento",
                "font_postscript": "Sacramento-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sahitya",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sahitya-Regular.ttf"
                },
                "font_name": "Sahitya Regular",
                "font_family": "Sahitya",
                "font_postscript": "Sahitya-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sahitya-Bold.ttf"
                },
                "font_name": "Sahitya Bold",
                "font_family": "Sahitya",
                "font_postscript": "Sahitya-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sail",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sail-Regular.ttf"
                },
                "font_name": "Sail Regular",
                "font_family": "Sail",
                "font_postscript": "Sail-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Saira",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Saira-Regular.ttf"
                },
                "font_name": "Saira Regular",
                "font_family": "Saira",
                "font_postscript": "Saira-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Saira-Italic.ttf"
                },
                "font_name": "Saira Italic",
                "font_family": "Saira",
                "font_postscript": "Saira-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SairaCondensed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaCondensed-SemiBold.ttf"
                },
                "font_name": "SairaCondensed SemiBold",
                "font_family": "SairaCondensed",
                "font_postscript": "SairaCondensed-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaCondensed-Light.ttf"
                },
                "font_name": "SairaCondensed Light",
                "font_family": "SairaCondensed",
                "font_postscript": "SairaCondensed-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaCondensed-Medium.ttf"
                },
                "font_name": "SairaCondensed Medium",
                "font_family": "SairaCondensed",
                "font_postscript": "SairaCondensed-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaCondensed-Regular.ttf"
                },
                "font_name": "SairaCondensed Regular",
                "font_family": "SairaCondensed",
                "font_postscript": "SairaCondensed-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaCondensed-Thin.ttf"
                },
                "font_name": "SairaCondensed Thin",
                "font_family": "SairaCondensed",
                "font_postscript": "SairaCondensed-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaCondensed-Bold.ttf"
                },
                "font_name": "SairaCondensed Bold",
                "font_family": "SairaCondensed",
                "font_postscript": "SairaCondensed-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaCondensed-Black.ttf"
                },
                "font_name": "SairaCondensed Black",
                "font_family": "SairaCondensed",
                "font_postscript": "SairaCondensed-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaCondensed-ExtraLight.ttf"
                },
                "font_name": "SairaCondensed ExtraLight",
                "font_family": "SairaCondensed",
                "font_postscript": "SairaCondensed-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaCondensed-ExtraBold.ttf"
                },
                "font_name": "SairaCondensed ExtraBold",
                "font_family": "SairaCondensed",
                "font_postscript": "SairaCondensed-ExtraBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SairaExtraCondensed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaExtraCondensed-Thin.ttf"
                },
                "font_name": "SairaExtraCondensed Thin",
                "font_family": "SairaExtraCondensed",
                "font_postscript": "SairaExtraCondensed-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaExtraCondensed-Light.ttf"
                },
                "font_name": "SairaExtraCondensed Light",
                "font_family": "SairaExtraCondensed",
                "font_postscript": "SairaExtraCondensed-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaExtraCondensed-Bold.ttf"
                },
                "font_name": "SairaExtraCondensed Bold",
                "font_family": "SairaExtraCondensed",
                "font_postscript": "SairaExtraCondensed-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaExtraCondensed-Medium.ttf"
                },
                "font_name": "SairaExtraCondensed Medium",
                "font_family": "SairaExtraCondensed",
                "font_postscript": "SairaExtraCondensed-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaExtraCondensed-ExtraLight.ttf"
                },
                "font_name": "SairaExtraCondensed ExtraLight",
                "font_family": "SairaExtraCondensed",
                "font_postscript": "SairaExtraCondensed-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaExtraCondensed-SemiBold.ttf"
                },
                "font_name": "SairaExtraCondensed SemiBold",
                "font_family": "SairaExtraCondensed",
                "font_postscript": "SairaExtraCondensed-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaExtraCondensed-Regular.ttf"
                },
                "font_name": "SairaExtraCondensed Regular",
                "font_family": "SairaExtraCondensed",
                "font_postscript": "SairaExtraCondensed-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaExtraCondensed-ExtraBold.ttf"
                },
                "font_name": "SairaExtraCondensed ExtraBold",
                "font_family": "SairaExtraCondensed",
                "font_postscript": "SairaExtraCondensed-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaExtraCondensed-Black.ttf"
                },
                "font_name": "SairaExtraCondensed Black",
                "font_family": "SairaExtraCondensed",
                "font_postscript": "SairaExtraCondensed-Black",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SairaSemiCondensed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaSemiCondensed-Black.ttf"
                },
                "font_name": "SairaSemiCondensed Black",
                "font_family": "SairaSemiCondensed",
                "font_postscript": "SairaSemiCondensed-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaSemiCondensed-Medium.ttf"
                },
                "font_name": "SairaSemiCondensed Medium",
                "font_family": "SairaSemiCondensed",
                "font_postscript": "SairaSemiCondensed-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaSemiCondensed-Bold.ttf"
                },
                "font_name": "SairaSemiCondensed Bold",
                "font_family": "SairaSemiCondensed",
                "font_postscript": "SairaSemiCondensed-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaSemiCondensed-Thin.ttf"
                },
                "font_name": "SairaSemiCondensed Thin",
                "font_family": "SairaSemiCondensed",
                "font_postscript": "SairaSemiCondensed-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaSemiCondensed-Regular.ttf"
                },
                "font_name": "SairaSemiCondensed Regular",
                "font_family": "SairaSemiCondensed",
                "font_postscript": "SairaSemiCondensed-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaSemiCondensed-ExtraBold.ttf"
                },
                "font_name": "SairaSemiCondensed ExtraBold",
                "font_family": "SairaSemiCondensed",
                "font_postscript": "SairaSemiCondensed-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaSemiCondensed-ExtraLight.ttf"
                },
                "font_name": "SairaSemiCondensed ExtraLight",
                "font_family": "SairaSemiCondensed",
                "font_postscript": "SairaSemiCondensed-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaSemiCondensed-SemiBold.ttf"
                },
                "font_name": "SairaSemiCondensed SemiBold",
                "font_family": "SairaSemiCondensed",
                "font_postscript": "SairaSemiCondensed-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaSemiCondensed-Light.ttf"
                },
                "font_name": "SairaSemiCondensed Light",
                "font_family": "SairaSemiCondensed",
                "font_postscript": "SairaSemiCondensed-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SairaStencilOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SairaStencilOne-Regular.ttf"
                },
                "font_name": "SairaStencilOne Regular",
                "font_family": "SairaStencilOne",
                "font_postscript": "SairaStencilOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Salsa",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Salsa-Regular.ttf"
                },
                "font_name": "Salsa Regular",
                "font_family": "Salsa",
                "font_postscript": "Salsa-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sanchez",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sanchez-Regular.ttf"
                },
                "font_name": "Sanchez Regular",
                "font_family": "Sanchez",
                "font_postscript": "Sanchez-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sanchez-Italic.ttf"
                },
                "font_name": "Sanchez Italic",
                "font_family": "Sanchez",
                "font_postscript": "Sanchez-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sancreek",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sancreek-Regular.ttf"
                },
                "font_name": "Sancreek Regular",
                "font_family": "Sancreek",
                "font_postscript": "Sancreek-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SankofaDisplay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SankofaDisplay-Regular.ttf"
                },
                "font_name": "SankofaDisplay Regular",
                "font_family": "SankofaDisplay",
                "font_postscript": "SankofaDisplay-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sansation",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sansation-BoldItalic.ttf"
                },
                "font_name": "Sansation BoldItalic",
                "font_family": "Sansation",
                "font_postscript": "Sansation-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sansation-Light.ttf"
                },
                "font_name": "Sansation Light",
                "font_family": "Sansation",
                "font_postscript": "Sansation-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sansation-LightItalic.ttf"
                },
                "font_name": "Sansation LightItalic",
                "font_family": "Sansation",
                "font_postscript": "Sansation-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sansation-Regular.ttf"
                },
                "font_name": "Sansation Regular",
                "font_family": "Sansation",
                "font_postscript": "Sansation-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sansation-Bold.ttf"
                },
                "font_name": "Sansation Bold",
                "font_family": "Sansation",
                "font_postscript": "Sansation-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sansation-Italic.ttf"
                },
                "font_name": "Sansation Italic",
                "font_family": "Sansation",
                "font_postscript": "Sansation-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sansita",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sansita-Regular.ttf"
                },
                "font_name": "Sansita Regular",
                "font_family": "Sansita",
                "font_postscript": "Sansita-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sansita-BlackItalic.ttf"
                },
                "font_name": "Sansita BlackItalic",
                "font_family": "Sansita",
                "font_postscript": "Sansita-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sansita-ExtraBoldItalic.ttf"
                },
                "font_name": "Sansita ExtraBoldItalic",
                "font_family": "Sansita",
                "font_postscript": "Sansita-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sansita-Bold.ttf"
                },
                "font_name": "Sansita Bold",
                "font_family": "Sansita",
                "font_postscript": "Sansita-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sansita-Italic.ttf"
                },
                "font_name": "Sansita Italic",
                "font_family": "Sansita",
                "font_postscript": "Sansita-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sansita-ExtraBold.ttf"
                },
                "font_name": "Sansita ExtraBold",
                "font_family": "Sansita",
                "font_postscript": "Sansita-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sansita-BoldItalic.ttf"
                },
                "font_name": "Sansita BoldItalic",
                "font_family": "Sansita",
                "font_postscript": "Sansita-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sansita-Black.ttf"
                },
                "font_name": "Sansita Black",
                "font_family": "Sansita",
                "font_postscript": "Sansita-Black",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SansitaOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SansitaOne-Regular.ttf"
                },
                "font_name": "SansitaOne Regular",
                "font_family": "SansitaOne",
                "font_postscript": "SansitaOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SansitaSwashed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SansitaSwashed-Regular.ttf"
                },
                "font_name": "SansitaSwashed Regular",
                "font_family": "SansitaSwashed",
                "font_postscript": "SansitaSwashed-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sarabun",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarabun-Italic.ttf"
                },
                "font_name": "Sarabun Italic",
                "font_family": "Sarabun",
                "font_postscript": "Sarabun-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarabun-ExtraLightItalic.ttf"
                },
                "font_name": "Sarabun ExtraLightItalic",
                "font_family": "Sarabun",
                "font_postscript": "Sarabun-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarabun-ExtraBold.ttf"
                },
                "font_name": "Sarabun ExtraBold",
                "font_family": "Sarabun",
                "font_postscript": "Sarabun-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarabun-ExtraLight.ttf"
                },
                "font_name": "Sarabun ExtraLight",
                "font_family": "Sarabun",
                "font_postscript": "Sarabun-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarabun-Thin.ttf"
                },
                "font_name": "Sarabun Thin",
                "font_family": "Sarabun",
                "font_postscript": "Sarabun-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarabun-ThinItalic.ttf"
                },
                "font_name": "Sarabun ThinItalic",
                "font_family": "Sarabun",
                "font_postscript": "Sarabun-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarabun-BoldItalic.ttf"
                },
                "font_name": "Sarabun BoldItalic",
                "font_family": "Sarabun",
                "font_postscript": "Sarabun-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarabun-Regular.ttf"
                },
                "font_name": "Sarabun Regular",
                "font_family": "Sarabun",
                "font_postscript": "Sarabun-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarabun-Bold.ttf"
                },
                "font_name": "Sarabun Bold",
                "font_family": "Sarabun",
                "font_postscript": "Sarabun-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarabun-SemiBold.ttf"
                },
                "font_name": "Sarabun SemiBold",
                "font_family": "Sarabun",
                "font_postscript": "Sarabun-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarabun-SemiBoldItalic.ttf"
                },
                "font_name": "Sarabun SemiBoldItalic",
                "font_family": "Sarabun",
                "font_postscript": "Sarabun-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarabun-Medium.ttf"
                },
                "font_name": "Sarabun Medium",
                "font_family": "Sarabun",
                "font_postscript": "Sarabun-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarabun-ExtraBoldItalic.ttf"
                },
                "font_name": "Sarabun ExtraBoldItalic",
                "font_family": "Sarabun",
                "font_postscript": "Sarabun-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarabun-Light.ttf"
                },
                "font_name": "Sarabun Light",
                "font_family": "Sarabun",
                "font_postscript": "Sarabun-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarabun-LightItalic.ttf"
                },
                "font_name": "Sarabun LightItalic",
                "font_family": "Sarabun",
                "font_postscript": "Sarabun-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarabun-MediumItalic.ttf"
                },
                "font_name": "Sarabun MediumItalic",
                "font_family": "Sarabun",
                "font_postscript": "Sarabun-MediumItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sarala",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarala-Regular.ttf"
                },
                "font_name": "Sarala Regular",
                "font_family": "Sarala",
                "font_postscript": "Sarala-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarala-Bold.ttf"
                },
                "font_name": "Sarala Bold",
                "font_family": "Sarala",
                "font_postscript": "Sarala-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sarina",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarina-Regular.ttf"
                },
                "font_name": "Sarina Regular",
                "font_family": "Sarina",
                "font_postscript": "Sarina-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sarpanch",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarpanch-SemiBold.ttf"
                },
                "font_name": "Sarpanch SemiBold",
                "font_family": "Sarpanch",
                "font_postscript": "Sarpanch-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarpanch-Black.ttf"
                },
                "font_name": "Sarpanch Black",
                "font_family": "Sarpanch",
                "font_postscript": "Sarpanch-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarpanch-Bold.ttf"
                },
                "font_name": "Sarpanch Bold",
                "font_family": "Sarpanch",
                "font_postscript": "Sarpanch-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarpanch-ExtraBold.ttf"
                },
                "font_name": "Sarpanch ExtraBold",
                "font_family": "Sarpanch",
                "font_postscript": "Sarpanch-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarpanch-Regular.ttf"
                },
                "font_name": "Sarpanch Regular",
                "font_family": "Sarpanch",
                "font_postscript": "Sarpanch-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sarpanch-Medium.ttf"
                },
                "font_name": "Sarpanch Medium",
                "font_family": "Sarpanch",
                "font_postscript": "Sarpanch-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SassyFrass",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SassyFrass-Regular.ttf"
                },
                "font_name": "SassyFrass Regular",
                "font_family": "SassyFrass",
                "font_postscript": "SassyFrass-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Satisfy",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Satisfy-Regular.ttf"
                },
                "font_name": "Satisfy Regular",
                "font_family": "Satisfy",
                "font_postscript": "Satisfy-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SawarabiGothic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SawarabiGothic-Regular.ttf"
                },
                "font_name": "SawarabiGothic Regular",
                "font_family": "SawarabiGothic",
                "font_postscript": "SawarabiGothic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SawarabiMincho",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SawarabiMincho-Regular.ttf"
                },
                "font_name": "SawarabiMincho Regular",
                "font_family": "SawarabiMincho",
                "font_postscript": "SawarabiMincho-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Scada",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Scada-Regular.ttf"
                },
                "font_name": "Scada Regular",
                "font_family": "Scada",
                "font_postscript": "Scada-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Scada-Italic.ttf"
                },
                "font_name": "Scada Italic",
                "font_family": "Scada",
                "font_postscript": "Scada-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Scada-BoldItalic.ttf"
                },
                "font_name": "Scada BoldItalic",
                "font_family": "Scada",
                "font_postscript": "Scada-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Scada-Bold.ttf"
                },
                "font_name": "Scada Bold",
                "font_family": "Scada",
                "font_postscript": "Scada-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ScheherazadeNew",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ScheherazadeNew-Medium.ttf"
                },
                "font_name": "ScheherazadeNew Medium",
                "font_family": "ScheherazadeNew",
                "font_postscript": "ScheherazadeNew-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ScheherazadeNew-SemiBold.ttf"
                },
                "font_name": "ScheherazadeNew SemiBold",
                "font_family": "ScheherazadeNew",
                "font_postscript": "ScheherazadeNew-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ScheherazadeNew-Regular.ttf"
                },
                "font_name": "ScheherazadeNew Regular",
                "font_family": "ScheherazadeNew",
                "font_postscript": "ScheherazadeNew-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ScheherazadeNew-Bold.ttf"
                },
                "font_name": "ScheherazadeNew Bold",
                "font_family": "ScheherazadeNew",
                "font_postscript": "ScheherazadeNew-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SchibstedGrotesk",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SchibstedGrotesk-Italic.ttf"
                },
                "font_name": "SchibstedGrotesk Italic",
                "font_family": "SchibstedGrotesk",
                "font_postscript": "SchibstedGrotesk-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SchibstedGrotesk-Regular.ttf"
                },
                "font_name": "SchibstedGrotesk Regular",
                "font_family": "SchibstedGrotesk",
                "font_postscript": "SchibstedGrotesk-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Schoolbell",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Schoolbell-Regular.ttf"
                },
                "font_name": "Schoolbell Regular",
                "font_family": "Schoolbell",
                "font_postscript": "Schoolbell-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ScopeOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ScopeOne-Regular.ttf"
                },
                "font_name": "ScopeOne Regular",
                "font_family": "ScopeOne",
                "font_postscript": "ScopeOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SeaweedScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SeaweedScript-Regular.ttf"
                },
                "font_name": "SeaweedScript Regular",
                "font_family": "SeaweedScript",
                "font_postscript": "SeaweedScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SecularOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SecularOne-Regular.ttf"
                },
                "font_name": "SecularOne Regular",
                "font_family": "SecularOne",
                "font_postscript": "SecularOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sedan",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sedan-Regular.ttf"
                },
                "font_name": "Sedan Regular",
                "font_family": "Sedan",
                "font_postscript": "Sedan-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sedan-Italic.ttf"
                },
                "font_name": "Sedan Italic",
                "font_family": "Sedan",
                "font_postscript": "Sedan-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SedanSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SedanSC-Regular.ttf"
                },
                "font_name": "SedanSC Regular",
                "font_family": "SedanSC",
                "font_postscript": "SedanSC-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SedgwickAve",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SedgwickAve-Regular.ttf"
                },
                "font_name": "SedgwickAve Regular",
                "font_family": "SedgwickAve",
                "font_postscript": "SedgwickAve-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SedgwickAveDisplay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SedgwickAveDisplay-Regular.ttf"
                },
                "font_name": "SedgwickAveDisplay Regular",
                "font_family": "SedgwickAveDisplay",
                "font_postscript": "SedgwickAveDisplay-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sen",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sen-Regular.ttf"
                },
                "font_name": "Sen Regular",
                "font_family": "Sen",
                "font_postscript": "Sen-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SendFlowers",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SendFlowers-Regular.ttf"
                },
                "font_name": "SendFlowers Regular",
                "font_family": "SendFlowers",
                "font_postscript": "SendFlowers-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sevillana",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sevillana-Regular.ttf"
                },
                "font_name": "Sevillana Regular",
                "font_family": "Sevillana",
                "font_postscript": "Sevillana-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SeymourOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SeymourOne-Regular.ttf"
                },
                "font_name": "SeymourOne Regular",
                "font_family": "SeymourOne",
                "font_postscript": "SeymourOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ShadowsIntoLight",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ShadowsIntoLight-Regular.ttf"
                },
                "font_name": "ShadowsIntoLight Regular",
                "font_family": "ShadowsIntoLight",
                "font_postscript": "ShadowsIntoLight-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ShadowsIntoLightTwo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ShadowsIntoLightTwo-Regular.ttf"
                },
                "font_name": "ShadowsIntoLightTwo Regular",
                "font_family": "ShadowsIntoLightTwo",
                "font_postscript": "ShadowsIntoLightTwo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Shalimar",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Shalimar-Regular.ttf"
                },
                "font_name": "Shalimar Regular",
                "font_family": "Shalimar",
                "font_postscript": "Shalimar-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ShantellSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ShantellSans-Regular.ttf"
                },
                "font_name": "ShantellSans Regular",
                "font_family": "ShantellSans",
                "font_postscript": "ShantellSans-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ShantellSans-Italic.ttf"
                },
                "font_name": "ShantellSans Italic",
                "font_family": "ShantellSans",
                "font_postscript": "ShantellSans-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Shanti",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Shanti-Regular.ttf"
                },
                "font_name": "Shanti Regular",
                "font_family": "Shanti",
                "font_postscript": "Shanti-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Share",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Share-Bold.ttf"
                },
                "font_name": "Share Bold",
                "font_family": "Share",
                "font_postscript": "Share-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Share-Regular.ttf"
                },
                "font_name": "Share Regular",
                "font_family": "Share",
                "font_postscript": "Share-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Share-Italic.ttf"
                },
                "font_name": "Share Italic",
                "font_family": "Share",
                "font_postscript": "Share-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Share-BoldItalic.ttf"
                },
                "font_name": "Share BoldItalic",
                "font_family": "Share",
                "font_postscript": "Share-BoldItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ShareTech",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ShareTech-Regular.ttf"
                },
                "font_name": "ShareTech Regular",
                "font_family": "ShareTech",
                "font_postscript": "ShareTech-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ShareTechMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ShareTechMono-Regular.ttf"
                },
                "font_name": "ShareTechMono Regular",
                "font_family": "ShareTechMono",
                "font_postscript": "ShareTechMono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ShipporiAntique",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ShipporiAntique-Regular.ttf"
                },
                "font_name": "ShipporiAntique Regular",
                "font_family": "ShipporiAntique",
                "font_postscript": "ShipporiAntique-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ShipporiAntiqueB1",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ShipporiAntiqueB1-Regular.ttf"
                },
                "font_name": "ShipporiAntiqueB1 Regular",
                "font_family": "ShipporiAntiqueB1",
                "font_postscript": "ShipporiAntiqueB1-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ShipporiMincho",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ShipporiMincho-Bold.ttf"
                },
                "font_name": "ShipporiMincho Bold",
                "font_family": "ShipporiMincho",
                "font_postscript": "ShipporiMincho-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ShipporiMincho-SemiBold.ttf"
                },
                "font_name": "ShipporiMincho SemiBold",
                "font_family": "ShipporiMincho",
                "font_postscript": "ShipporiMincho-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ShipporiMincho-Regular.ttf"
                },
                "font_name": "ShipporiMincho Regular",
                "font_family": "ShipporiMincho",
                "font_postscript": "ShipporiMincho-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ShipporiMincho-Medium.ttf"
                },
                "font_name": "ShipporiMincho Medium",
                "font_family": "ShipporiMincho",
                "font_postscript": "ShipporiMincho-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ShipporiMincho-ExtraBold.ttf"
                },
                "font_name": "ShipporiMincho ExtraBold",
                "font_family": "ShipporiMincho",
                "font_postscript": "ShipporiMincho-ExtraBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ShipporiMinchoB1",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ShipporiMinchoB1-Bold.ttf"
                },
                "font_name": "ShipporiMinchoB1 Bold",
                "font_family": "ShipporiMinchoB1",
                "font_postscript": "ShipporiMinchoB1-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ShipporiMinchoB1-Medium.ttf"
                },
                "font_name": "ShipporiMinchoB1 Medium",
                "font_family": "ShipporiMinchoB1",
                "font_postscript": "ShipporiMinchoB1-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ShipporiMinchoB1-SemiBold.ttf"
                },
                "font_name": "ShipporiMinchoB1 SemiBold",
                "font_family": "ShipporiMinchoB1",
                "font_postscript": "ShipporiMinchoB1-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ShipporiMinchoB1-Regular.ttf"
                },
                "font_name": "ShipporiMinchoB1 Regular",
                "font_family": "ShipporiMinchoB1",
                "font_postscript": "ShipporiMinchoB1-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ShipporiMinchoB1-ExtraBold.ttf"
                },
                "font_name": "ShipporiMinchoB1 ExtraBold",
                "font_family": "ShipporiMinchoB1",
                "font_postscript": "ShipporiMinchoB1-ExtraBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Shizuru",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Shizuru-Regular.ttf"
                },
                "font_name": "Shizuru Regular",
                "font_family": "Shizuru",
                "font_postscript": "Shizuru-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Shojumaru",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Shojumaru-Regular.ttf"
                },
                "font_name": "Shojumaru Regular",
                "font_family": "Shojumaru",
                "font_postscript": "Shojumaru-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ShortStack",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ShortStack-Regular.ttf"
                },
                "font_name": "ShortStack Regular",
                "font_family": "ShortStack",
                "font_postscript": "ShortStack-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Shrikhand",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Shrikhand-Regular.ttf"
                },
                "font_name": "Shrikhand Regular",
                "font_family": "Shrikhand",
                "font_postscript": "Shrikhand-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Siemreap",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Siemreap-Regular.ttf"
                },
                "font_name": "Siemreap Regular",
                "font_family": "Siemreap",
                "font_postscript": "Siemreap-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sigmar",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sigmar-Regular.ttf"
                },
                "font_name": "Sigmar Regular",
                "font_family": "Sigmar",
                "font_postscript": "Sigmar-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SigmarOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SigmarOne-Regular.ttf"
                },
                "font_name": "SigmarOne Regular",
                "font_family": "SigmarOne",
                "font_postscript": "SigmarOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Signika",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Signika-Regular.ttf"
                },
                "font_name": "Signika Regular",
                "font_family": "Signika",
                "font_postscript": "Signika-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SignikaNegative",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SignikaNegative-Regular.ttf"
                },
                "font_name": "SignikaNegative Regular",
                "font_family": "SignikaNegative",
                "font_postscript": "SignikaNegative-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SignikaNegativeSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SignikaNegativeSC-Regular.ttf"
                },
                "font_name": "SignikaNegativeSC Regular",
                "font_family": "SignikaNegativeSC",
                "font_postscript": "SignikaNegativeSC-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SignikaNegativeSC-SemiBold.ttf"
                },
                "font_name": "SignikaNegativeSC SemiBold",
                "font_family": "SignikaNegativeSC",
                "font_postscript": "SignikaNegativeSC-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SignikaNegativeSC-Bold.ttf"
                },
                "font_name": "SignikaNegativeSC Bold",
                "font_family": "SignikaNegativeSC",
                "font_postscript": "SignikaNegativeSC-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SignikaNegativeSC-Light.ttf"
                },
                "font_name": "SignikaNegativeSC Light",
                "font_family": "SignikaNegativeSC",
                "font_postscript": "SignikaNegativeSC-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SignikaSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SignikaSC-Regular.ttf"
                },
                "font_name": "SignikaSC Regular",
                "font_family": "SignikaSC",
                "font_postscript": "SignikaSC-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Silkscreen",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Silkscreen-Regular.ttf"
                },
                "font_name": "Silkscreen Regular",
                "font_family": "Silkscreen",
                "font_postscript": "Silkscreen-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Silkscreen-Bold.ttf"
                },
                "font_name": "Silkscreen Bold",
                "font_family": "Silkscreen",
                "font_postscript": "Silkscreen-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Simonetta",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Simonetta-Black.ttf"
                },
                "font_name": "Simonetta Black",
                "font_family": "Simonetta",
                "font_postscript": "Simonetta-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Simonetta-Regular.ttf"
                },
                "font_name": "Simonetta Regular",
                "font_family": "Simonetta",
                "font_postscript": "Simonetta-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Simonetta-Italic.ttf"
                },
                "font_name": "Simonetta Italic",
                "font_family": "Simonetta",
                "font_postscript": "Simonetta-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Simonetta-BlackItalic.ttf"
                },
                "font_name": "Simonetta BlackItalic",
                "font_family": "Simonetta",
                "font_postscript": "Simonetta-BlackItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SingleDay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SingleDay-Regular.ttf"
                },
                "font_name": "SingleDay Regular",
                "font_family": "SingleDay",
                "font_postscript": "SingleDay-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sintony",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sintony-Regular.ttf"
                },
                "font_name": "Sintony Regular",
                "font_family": "Sintony",
                "font_postscript": "Sintony-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sintony-Bold.ttf"
                },
                "font_name": "Sintony Bold",
                "font_family": "Sintony",
                "font_postscript": "Sintony-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SirinStencil",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SirinStencil-Regular.ttf"
                },
                "font_name": "SirinStencil Regular",
                "font_family": "SirinStencil",
                "font_postscript": "SirinStencil-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sitara",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sitara-BoldItalic.ttf"
                },
                "font_name": "Sitara BoldItalic",
                "font_family": "Sitara",
                "font_postscript": "Sitara-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sitara-Regular.ttf"
                },
                "font_name": "Sitara Regular",
                "font_family": "Sitara",
                "font_postscript": "Sitara-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sitara-Bold.ttf"
                },
                "font_name": "Sitara Bold",
                "font_family": "Sitara",
                "font_postscript": "Sitara-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sitara-Italic.ttf"
                },
                "font_name": "Sitara Italic",
                "font_family": "Sitara",
                "font_postscript": "Sitara-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SixCaps",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SixCaps-Regular.ttf"
                },
                "font_name": "SixCaps Regular",
                "font_family": "SixCaps",
                "font_postscript": "SixCaps-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sixtyfour",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sixtyfour-Regular.ttf"
                },
                "font_name": "Sixtyfour Regular",
                "font_family": "Sixtyfour",
                "font_postscript": "Sixtyfour-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Skranji",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Skranji-Regular.ttf"
                },
                "font_name": "Skranji Regular",
                "font_family": "Skranji",
                "font_postscript": "Skranji-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Skranji-Bold.ttf"
                },
                "font_name": "Skranji Bold",
                "font_family": "Skranji",
                "font_postscript": "Skranji-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Slabo13px",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Slabo13px-Regular.ttf"
                },
                "font_name": "Slabo13px Regular",
                "font_family": "Slabo13px",
                "font_postscript": "Slabo13px-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Slabo27px",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Slabo27px-Regular.ttf"
                },
                "font_name": "Slabo27px Regular",
                "font_family": "Slabo27px",
                "font_postscript": "Slabo27px-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Slackey",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Slackey-Regular.ttf"
                },
                "font_name": "Slackey Regular",
                "font_family": "Slackey",
                "font_postscript": "Slackey-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SlacksideOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SlacksideOne-Regular.ttf"
                },
                "font_name": "SlacksideOne Regular",
                "font_family": "SlacksideOne",
                "font_postscript": "SlacksideOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Smokum",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Smokum-Regular.ttf"
                },
                "font_name": "Smokum Regular",
                "font_family": "Smokum",
                "font_postscript": "Smokum-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Smooch",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Smooch-Regular.ttf"
                },
                "font_name": "Smooch Regular",
                "font_family": "Smooch",
                "font_postscript": "Smooch-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SmoochSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SmoochSans-Regular.ttf"
                },
                "font_name": "SmoochSans Regular",
                "font_family": "SmoochSans",
                "font_postscript": "SmoochSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Smythe",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Smythe-Regular.ttf"
                },
                "font_name": "Smythe Regular",
                "font_family": "Smythe",
                "font_postscript": "Smythe-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sniglet",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sniglet-ExtraBold.ttf"
                },
                "font_name": "Sniglet ExtraBold",
                "font_family": "Sniglet",
                "font_postscript": "Sniglet-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sniglet-Regular.ttf"
                },
                "font_name": "Sniglet Regular",
                "font_family": "Sniglet",
                "font_postscript": "Sniglet-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Snippet",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Snippet-Regular.ttf"
                },
                "font_name": "Snippet Regular",
                "font_family": "Snippet",
                "font_postscript": "Snippet-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SnowburstOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SnowburstOne-Regular.ttf"
                },
                "font_name": "SnowburstOne Regular",
                "font_family": "SnowburstOne",
                "font_postscript": "SnowburstOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SofadiOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SofadiOne-Regular.ttf"
                },
                "font_name": "SofadiOne Regular",
                "font_family": "SofadiOne",
                "font_postscript": "SofadiOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sofia",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sofia-Regular.ttf"
                },
                "font_name": "Sofia Regular",
                "font_family": "Sofia",
                "font_postscript": "Sofia-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SofiaSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SofiaSans-Italic.ttf"
                },
                "font_name": "SofiaSans Italic",
                "font_family": "SofiaSans",
                "font_postscript": "SofiaSans-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SofiaSans-Regular.ttf"
                },
                "font_name": "SofiaSans Regular",
                "font_family": "SofiaSans",
                "font_postscript": "SofiaSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SofiaSansCondensed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SofiaSansCondensed-Italic.ttf"
                },
                "font_name": "SofiaSansCondensed Italic",
                "font_family": "SofiaSansCondensed",
                "font_postscript": "SofiaSansCondensed-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SofiaSansCondensed-Regular.ttf"
                },
                "font_name": "SofiaSansCondensed Regular",
                "font_family": "SofiaSansCondensed",
                "font_postscript": "SofiaSansCondensed-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SofiaSansExtraCondensed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SofiaSansExtraCondensed-Regular.ttf"
                },
                "font_name": "SofiaSansExtraCondensed Regular",
                "font_family": "SofiaSansExtraCondensed",
                "font_postscript": "SofiaSansExtraCondensed-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SofiaSansExtraCondensed-Italic.ttf"
                },
                "font_name": "SofiaSansExtraCondensed Italic",
                "font_family": "SofiaSansExtraCondensed",
                "font_postscript": "SofiaSansExtraCondensed-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SofiaSansSemiCondensed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SofiaSansSemiCondensed-Regular.ttf"
                },
                "font_name": "SofiaSansSemiCondensed Regular",
                "font_family": "SofiaSansSemiCondensed",
                "font_postscript": "SofiaSansSemiCondensed-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SofiaSansSemiCondensed-Italic.ttf"
                },
                "font_name": "SofiaSansSemiCondensed Italic",
                "font_family": "SofiaSansSemiCondensed",
                "font_postscript": "SofiaSansSemiCondensed-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Solitreo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Solitreo-Regular.ttf"
                },
                "font_name": "Solitreo Regular",
                "font_family": "Solitreo",
                "font_postscript": "Solitreo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Solway",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Solway-ExtraBold.ttf"
                },
                "font_name": "Solway ExtraBold",
                "font_family": "Solway",
                "font_postscript": "Solway-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Solway-Medium.ttf"
                },
                "font_name": "Solway Medium",
                "font_family": "Solway",
                "font_postscript": "Solway-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Solway-Regular.ttf"
                },
                "font_name": "Solway Regular",
                "font_family": "Solway",
                "font_postscript": "Solway-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Solway-Light.ttf"
                },
                "font_name": "Solway Light",
                "font_family": "Solway",
                "font_postscript": "Solway-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Solway-Bold.ttf"
                },
                "font_name": "Solway Bold",
                "font_family": "Solway",
                "font_postscript": "Solway-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SometypeMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SometypeMono-Regular.ttf"
                },
                "font_name": "SometypeMono Regular",
                "font_family": "SometypeMono",
                "font_postscript": "SometypeMono-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SometypeMono-Italic.ttf"
                },
                "font_name": "SometypeMono Italic",
                "font_family": "SometypeMono",
                "font_postscript": "SometypeMono-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SongMyung",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SongMyung-Regular.ttf"
                },
                "font_name": "SongMyung Regular",
                "font_family": "SongMyung",
                "font_postscript": "SongMyung-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sono-Regular.ttf"
                },
                "font_name": "Sono Regular",
                "font_family": "Sono",
                "font_postscript": "Sono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SonsieOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SonsieOne-Regular.ttf"
                },
                "font_name": "SonsieOne Regular",
                "font_family": "SonsieOne",
                "font_postscript": "SonsieOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sora",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sora-Regular.ttf"
                },
                "font_name": "Sora Regular",
                "font_family": "Sora",
                "font_postscript": "Sora-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SortsMillGoudy",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SortsMillGoudy-Regular.ttf"
                },
                "font_name": "SortsMillGoudy Regular",
                "font_family": "SortsMillGoudy",
                "font_postscript": "SortsMillGoudy-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SortsMillGoudy-Italic.ttf"
                },
                "font_name": "SortsMillGoudy Italic",
                "font_family": "SortsMillGoudy",
                "font_postscript": "SortsMillGoudy-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Souliyo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Souliyo-Regular.ttf"
                },
                "font_name": "Souliyo Regular",
                "font_family": "Souliyo",
                "font_postscript": "Souliyo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SourceCodePro",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SourceCodePro-Italic.ttf"
                },
                "font_name": "SourceCodePro Italic",
                "font_family": "SourceCodePro",
                "font_postscript": "SourceCodePro-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SourceCodePro-Regular.ttf"
                },
                "font_name": "SourceCodePro Regular",
                "font_family": "SourceCodePro",
                "font_postscript": "SourceCodePro-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SourceSans3",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SourceSans3-Italic.ttf"
                },
                "font_name": "SourceSans3 Italic",
                "font_family": "SourceSans3",
                "font_postscript": "SourceSans3-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SourceSans3-Regular.ttf"
                },
                "font_name": "SourceSans3 Regular",
                "font_family": "SourceSans3",
                "font_postscript": "SourceSans3-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SourceSerif4",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SourceSerif4-Italic.ttf"
                },
                "font_name": "SourceSerif4 Italic",
                "font_family": "SourceSerif4",
                "font_postscript": "SourceSerif4-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SourceSerif4-Regular.ttf"
                },
                "font_name": "SourceSerif4 Regular",
                "font_family": "SourceSerif4",
                "font_postscript": "SourceSerif4-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SpaceGrotesk",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SpaceGrotesk-Regular.ttf"
                },
                "font_name": "SpaceGrotesk Regular",
                "font_family": "SpaceGrotesk",
                "font_postscript": "SpaceGrotesk-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SpaceMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SpaceMono-Regular.ttf"
                },
                "font_name": "SpaceMono Regular",
                "font_family": "SpaceMono",
                "font_postscript": "SpaceMono-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SpaceMono-BoldItalic.ttf"
                },
                "font_name": "SpaceMono BoldItalic",
                "font_family": "SpaceMono",
                "font_postscript": "SpaceMono-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SpaceMono-Italic.ttf"
                },
                "font_name": "SpaceMono Italic",
                "font_family": "SpaceMono",
                "font_postscript": "SpaceMono-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SpaceMono-Bold.ttf"
                },
                "font_name": "SpaceMono Bold",
                "font_family": "SpaceMono",
                "font_postscript": "SpaceMono-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SpecialElite",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SpecialElite-Regular.ttf"
                },
                "font_name": "SpecialElite Regular",
                "font_family": "SpecialElite",
                "font_postscript": "SpecialElite-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Spectral",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Spectral-ExtraLight.ttf"
                },
                "font_name": "Spectral ExtraLight",
                "font_family": "Spectral",
                "font_postscript": "Spectral-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Spectral-ExtraBold.ttf"
                },
                "font_name": "Spectral ExtraBold",
                "font_family": "Spectral",
                "font_postscript": "Spectral-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Spectral-BoldItalic.ttf"
                },
                "font_name": "Spectral BoldItalic",
                "font_family": "Spectral",
                "font_postscript": "Spectral-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Spectral-MediumItalic.ttf"
                },
                "font_name": "Spectral MediumItalic",
                "font_family": "Spectral",
                "font_postscript": "Spectral-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Spectral-Medium.ttf"
                },
                "font_name": "Spectral Medium",
                "font_family": "Spectral",
                "font_postscript": "Spectral-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Spectral-Regular.ttf"
                },
                "font_name": "Spectral Regular",
                "font_family": "Spectral",
                "font_postscript": "Spectral-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Spectral-ExtraLightItalic.ttf"
                },
                "font_name": "Spectral ExtraLightItalic",
                "font_family": "Spectral",
                "font_postscript": "Spectral-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Spectral-ExtraBoldItalic.ttf"
                },
                "font_name": "Spectral ExtraBoldItalic",
                "font_family": "Spectral",
                "font_postscript": "Spectral-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Spectral-SemiBold.ttf"
                },
                "font_name": "Spectral SemiBold",
                "font_family": "Spectral",
                "font_postscript": "Spectral-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Spectral-LightItalic.ttf"
                },
                "font_name": "Spectral LightItalic",
                "font_family": "Spectral",
                "font_postscript": "Spectral-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Spectral-Italic.ttf"
                },
                "font_name": "Spectral Italic",
                "font_family": "Spectral",
                "font_postscript": "Spectral-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Spectral-SemiBoldItalic.ttf"
                },
                "font_name": "Spectral SemiBoldItalic",
                "font_family": "Spectral",
                "font_postscript": "Spectral-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Spectral-Bold.ttf"
                },
                "font_name": "Spectral Bold",
                "font_family": "Spectral",
                "font_postscript": "Spectral-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Spectral-Light.ttf"
                },
                "font_name": "Spectral Light",
                "font_family": "Spectral",
                "font_postscript": "Spectral-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SpectralSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SpectralSC-LightItalic.ttf"
                },
                "font_name": "SpectralSC LightItalic",
                "font_family": "SpectralSC",
                "font_postscript": "SpectralSC-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SpectralSC-Light.ttf"
                },
                "font_name": "SpectralSC Light",
                "font_family": "SpectralSC",
                "font_postscript": "SpectralSC-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SpectralSC-ExtraBold.ttf"
                },
                "font_name": "SpectralSC ExtraBold",
                "font_family": "SpectralSC",
                "font_postscript": "SpectralSC-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SpectralSC-Bold.ttf"
                },
                "font_name": "SpectralSC Bold",
                "font_family": "SpectralSC",
                "font_postscript": "SpectralSC-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SpectralSC-Medium.ttf"
                },
                "font_name": "SpectralSC Medium",
                "font_family": "SpectralSC",
                "font_postscript": "SpectralSC-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SpectralSC-SemiBoldItalic.ttf"
                },
                "font_name": "SpectralSC SemiBoldItalic",
                "font_family": "SpectralSC",
                "font_postscript": "SpectralSC-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SpectralSC-MediumItalic.ttf"
                },
                "font_name": "SpectralSC MediumItalic",
                "font_family": "SpectralSC",
                "font_postscript": "SpectralSC-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SpectralSC-Regular.ttf"
                },
                "font_name": "SpectralSC Regular",
                "font_family": "SpectralSC",
                "font_postscript": "SpectralSC-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SpectralSC-SemiBold.ttf"
                },
                "font_name": "SpectralSC SemiBold",
                "font_family": "SpectralSC",
                "font_postscript": "SpectralSC-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SpectralSC-BoldItalic.ttf"
                },
                "font_name": "SpectralSC BoldItalic",
                "font_family": "SpectralSC",
                "font_postscript": "SpectralSC-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SpectralSC-ExtraLightItalic.ttf"
                },
                "font_name": "SpectralSC ExtraLightItalic",
                "font_family": "SpectralSC",
                "font_postscript": "SpectralSC-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SpectralSC-ExtraLight.ttf"
                },
                "font_name": "SpectralSC ExtraLight",
                "font_family": "SpectralSC",
                "font_postscript": "SpectralSC-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SpectralSC-ExtraBoldItalic.ttf"
                },
                "font_name": "SpectralSC ExtraBoldItalic",
                "font_family": "SpectralSC",
                "font_postscript": "SpectralSC-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SpectralSC-Italic.ttf"
                },
                "font_name": "SpectralSC Italic",
                "font_family": "SpectralSC",
                "font_postscript": "SpectralSC-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SpicyRice",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SpicyRice-Regular.ttf"
                },
                "font_name": "SpicyRice Regular",
                "font_family": "SpicyRice",
                "font_postscript": "SpicyRice-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Spinnaker",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Spinnaker-Regular.ttf"
                },
                "font_name": "Spinnaker Regular",
                "font_family": "Spinnaker",
                "font_postscript": "Spinnaker-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Spirax",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Spirax-Regular.ttf"
                },
                "font_name": "Spirax Regular",
                "font_family": "Spirax",
                "font_postscript": "Spirax-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Splash",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Splash-Regular.ttf"
                },
                "font_name": "Splash Regular",
                "font_family": "Splash",
                "font_postscript": "Splash-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SplineSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SplineSans-Regular.ttf"
                },
                "font_name": "SplineSans Regular",
                "font_family": "SplineSans",
                "font_postscript": "SplineSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SplineSansMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SplineSansMono-Italic.ttf"
                },
                "font_name": "SplineSansMono Italic",
                "font_family": "SplineSansMono",
                "font_postscript": "SplineSansMono-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SplineSansMono-Regular.ttf"
                },
                "font_name": "SplineSansMono Regular",
                "font_family": "SplineSansMono",
                "font_postscript": "SplineSansMono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SquadaOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SquadaOne-Regular.ttf"
                },
                "font_name": "SquadaOne Regular",
                "font_family": "SquadaOne",
                "font_postscript": "SquadaOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SquarePeg",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SquarePeg-Regular.ttf"
                },
                "font_name": "SquarePeg Regular",
                "font_family": "SquarePeg",
                "font_postscript": "SquarePeg-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SreeKrushnadevaraya",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SreeKrushnadevaraya-Regular.ttf"
                },
                "font_name": "SreeKrushnadevaraya Regular",
                "font_family": "SreeKrushnadevaraya",
                "font_postscript": "SreeKrushnadevaraya-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sriracha",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sriracha-Regular.ttf"
                },
                "font_name": "Sriracha Regular",
                "font_family": "Sriracha",
                "font_postscript": "Sriracha-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Srisakdi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Srisakdi-Bold.ttf"
                },
                "font_name": "Srisakdi Bold",
                "font_family": "Srisakdi",
                "font_postscript": "Srisakdi-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Srisakdi-Regular.ttf"
                },
                "font_name": "Srisakdi Regular",
                "font_family": "Srisakdi",
                "font_postscript": "Srisakdi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Staatliches",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Staatliches-Regular.ttf"
                },
                "font_name": "Staatliches Regular",
                "font_family": "Staatliches",
                "font_postscript": "Staatliches-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Stalemate",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Stalemate-Regular.ttf"
                },
                "font_name": "Stalemate Regular",
                "font_family": "Stalemate",
                "font_postscript": "Stalemate-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "StalinistOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "StalinistOne-Regular.ttf"
                },
                "font_name": "StalinistOne Regular",
                "font_family": "StalinistOne",
                "font_postscript": "StalinistOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "StardosStencil",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "StardosStencil-Bold.ttf"
                },
                "font_name": "StardosStencil Bold",
                "font_family": "StardosStencil",
                "font_postscript": "StardosStencil-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "StardosStencil-Regular.ttf"
                },
                "font_name": "StardosStencil Regular",
                "font_family": "StardosStencil",
                "font_postscript": "StardosStencil-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Stick",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Stick-Regular.ttf"
                },
                "font_name": "Stick Regular",
                "font_family": "Stick",
                "font_postscript": "Stick-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "StickNoBills",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "StickNoBills-Regular.ttf"
                },
                "font_name": "StickNoBills Regular",
                "font_family": "StickNoBills",
                "font_postscript": "StickNoBills-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "StintUltraCondensed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "StintUltraCondensed-Regular.ttf"
                },
                "font_name": "StintUltraCondensed Regular",
                "font_family": "StintUltraCondensed",
                "font_postscript": "StintUltraCondensed-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "StintUltraExpanded",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "StintUltraExpanded-Regular.ttf"
                },
                "font_name": "StintUltraExpanded Regular",
                "font_family": "StintUltraExpanded",
                "font_postscript": "StintUltraExpanded-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Stoke",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Stoke-Light.ttf"
                },
                "font_name": "Stoke Light",
                "font_family": "Stoke",
                "font_postscript": "Stoke-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Stoke-Regular.ttf"
                },
                "font_name": "Stoke Regular",
                "font_family": "Stoke",
                "font_postscript": "Stoke-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Strait",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Strait-Regular.ttf"
                },
                "font_name": "Strait Regular",
                "font_family": "Strait",
                "font_postscript": "Strait-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Strong",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Strong-Regular.ttf"
                },
                "font_name": "Strong Regular",
                "font_family": "Strong",
                "font_postscript": "Strong-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "StyleScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "StyleScript-Regular.ttf"
                },
                "font_name": "StyleScript Regular",
                "font_family": "StyleScript",
                "font_postscript": "StyleScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Stylish",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Stylish-Regular.ttf"
                },
                "font_name": "Stylish Regular",
                "font_family": "Stylish",
                "font_postscript": "Stylish-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SueEllenFrancisco",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SueEllenFrancisco-Regular.ttf"
                },
                "font_name": "SueEllenFrancisco Regular",
                "font_family": "SueEllenFrancisco",
                "font_postscript": "SueEllenFrancisco-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SuezOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SuezOne-Regular.ttf"
                },
                "font_name": "SuezOne Regular",
                "font_family": "SuezOne",
                "font_postscript": "SuezOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SulphurPoint",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SulphurPoint-Bold.ttf"
                },
                "font_name": "SulphurPoint Bold",
                "font_family": "SulphurPoint",
                "font_postscript": "SulphurPoint-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SulphurPoint-Light.ttf"
                },
                "font_name": "SulphurPoint Light",
                "font_family": "SulphurPoint",
                "font_postscript": "SulphurPoint-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "SulphurPoint-Regular.ttf"
                },
                "font_name": "SulphurPoint Regular",
                "font_family": "SulphurPoint",
                "font_postscript": "SulphurPoint-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sumana",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sumana-Regular.ttf"
                },
                "font_name": "Sumana Regular",
                "font_family": "Sumana",
                "font_postscript": "Sumana-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sumana-Bold.ttf"
                },
                "font_name": "Sumana Bold",
                "font_family": "Sumana",
                "font_postscript": "Sumana-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sunflower",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sunflower-Light.ttf"
                },
                "font_name": "Sunflower Light",
                "font_family": "Sunflower",
                "font_postscript": "Sunflower-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sunflower-Bold.ttf"
                },
                "font_name": "Sunflower Bold",
                "font_family": "Sunflower",
                "font_postscript": "Sunflower-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sunflower-Medium.ttf"
                },
                "font_name": "Sunflower Medium",
                "font_family": "Sunflower",
                "font_postscript": "Sunflower-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sunshiney",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sunshiney-Regular.ttf"
                },
                "font_name": "Sunshiney Regular",
                "font_family": "Sunshiney",
                "font_postscript": "Sunshiney-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SupermercadoOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SupermercadoOne-Regular.ttf"
                },
                "font_name": "SupermercadoOne Regular",
                "font_family": "SupermercadoOne",
                "font_postscript": "SupermercadoOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Sura",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sura-Bold.ttf"
                },
                "font_name": "Sura Bold",
                "font_family": "Sura",
                "font_postscript": "Sura-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Sura-Regular.ttf"
                },
                "font_name": "Sura Regular",
                "font_family": "Sura",
                "font_postscript": "Sura-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Suranna",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Suranna-Regular.ttf"
                },
                "font_name": "Suranna Regular",
                "font_family": "Suranna",
                "font_postscript": "Suranna-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Suravaram",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Suravaram-Regular.ttf"
                },
                "font_name": "Suravaram Regular",
                "font_family": "Suravaram",
                "font_postscript": "Suravaram-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Suwannaphum",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Suwannaphum-Thin.ttf"
                },
                "font_name": "Suwannaphum Thin",
                "font_family": "Suwannaphum",
                "font_postscript": "Suwannaphum-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Suwannaphum-Light.ttf"
                },
                "font_name": "Suwannaphum Light",
                "font_family": "Suwannaphum",
                "font_postscript": "Suwannaphum-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Suwannaphum-Regular.ttf"
                },
                "font_name": "Suwannaphum Regular",
                "font_family": "Suwannaphum",
                "font_postscript": "Suwannaphum-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Suwannaphum-Bold.ttf"
                },
                "font_name": "Suwannaphum Bold",
                "font_family": "Suwannaphum",
                "font_postscript": "Suwannaphum-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Suwannaphum-Black.ttf"
                },
                "font_name": "Suwannaphum Black",
                "font_family": "Suwannaphum",
                "font_postscript": "Suwannaphum-Black",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SwankyandMooMoo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SwankyandMooMoo-Regular.ttf"
                },
                "font_name": "SwankyandMooMoo Regular",
                "font_family": "SwankyandMooMoo",
                "font_postscript": "SwankyandMooMoo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Syncopate",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Syncopate-Bold.ttf"
                },
                "font_name": "Syncopate Bold",
                "font_family": "Syncopate",
                "font_postscript": "Syncopate-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Syncopate-Regular.ttf"
                },
                "font_name": "Syncopate Regular",
                "font_family": "Syncopate",
                "font_postscript": "Syncopate-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Syne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Syne-Regular.ttf"
                },
                "font_name": "Syne Regular",
                "font_family": "Syne",
                "font_postscript": "Syne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SyneMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SyneMono-Regular.ttf"
                },
                "font_name": "SyneMono Regular",
                "font_family": "SyneMono",
                "font_postscript": "SyneMono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "SyneTactile",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "SyneTactile-Regular.ttf"
                },
                "font_name": "SyneTactile Regular",
                "font_family": "SyneTactile",
                "font_postscript": "SyneTactile-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TacOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TacOne-Regular.ttf"
                },
                "font_name": "TacOne Regular",
                "font_family": "TacOne",
                "font_postscript": "TacOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TaiHeritagePro",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TaiHeritagePro-Regular.ttf"
                },
                "font_name": "TaiHeritagePro Regular",
                "font_family": "TaiHeritagePro",
                "font_postscript": "TaiHeritagePro-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TaiHeritagePro-Bold.ttf"
                },
                "font_name": "TaiHeritagePro Bold",
                "font_family": "TaiHeritagePro",
                "font_postscript": "TaiHeritagePro-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Tajawal",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tajawal-ExtraBold.ttf"
                },
                "font_name": "Tajawal ExtraBold",
                "font_family": "Tajawal",
                "font_postscript": "Tajawal-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tajawal-Light.ttf"
                },
                "font_name": "Tajawal Light",
                "font_family": "Tajawal",
                "font_postscript": "Tajawal-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tajawal-Bold.ttf"
                },
                "font_name": "Tajawal Bold",
                "font_family": "Tajawal",
                "font_postscript": "Tajawal-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tajawal-ExtraLight.ttf"
                },
                "font_name": "Tajawal ExtraLight",
                "font_family": "Tajawal",
                "font_postscript": "Tajawal-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tajawal-Regular.ttf"
                },
                "font_name": "Tajawal Regular",
                "font_family": "Tajawal",
                "font_postscript": "Tajawal-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tajawal-Medium.ttf"
                },
                "font_name": "Tajawal Medium",
                "font_family": "Tajawal",
                "font_postscript": "Tajawal-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tajawal-Black.ttf"
                },
                "font_name": "Tajawal Black",
                "font_family": "Tajawal",
                "font_postscript": "Tajawal-Black",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Tangerine",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tangerine-Bold.ttf"
                },
                "font_name": "Tangerine Bold",
                "font_family": "Tangerine",
                "font_postscript": "Tangerine-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tangerine-Regular.ttf"
                },
                "font_name": "Tangerine Regular",
                "font_family": "Tangerine",
                "font_postscript": "Tangerine-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Tapestry",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tapestry-Regular.ttf"
                },
                "font_name": "Tapestry Regular",
                "font_family": "Tapestry",
                "font_postscript": "Tapestry-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Taprom",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Taprom-Regular.ttf"
                },
                "font_name": "Taprom Regular",
                "font_family": "Taprom",
                "font_postscript": "Taprom-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Tauri",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tauri-Regular.ttf"
                },
                "font_name": "Tauri Regular",
                "font_family": "Tauri",
                "font_postscript": "Tauri-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Taviraj",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Taviraj-Black.ttf"
                },
                "font_name": "Taviraj Black",
                "font_family": "Taviraj",
                "font_postscript": "Taviraj-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Taviraj-ExtraBoldItalic.ttf"
                },
                "font_name": "Taviraj ExtraBoldItalic",
                "font_family": "Taviraj",
                "font_postscript": "Taviraj-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Taviraj-Medium.ttf"
                },
                "font_name": "Taviraj Medium",
                "font_family": "Taviraj",
                "font_postscript": "Taviraj-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Taviraj-BlackItalic.ttf"
                },
                "font_name": "Taviraj BlackItalic",
                "font_family": "Taviraj",
                "font_postscript": "Taviraj-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Taviraj-LightItalic.ttf"
                },
                "font_name": "Taviraj LightItalic",
                "font_family": "Taviraj",
                "font_postscript": "Taviraj-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Taviraj-Thin.ttf"
                },
                "font_name": "Taviraj Thin",
                "font_family": "Taviraj",
                "font_postscript": "Taviraj-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Taviraj-Bold.ttf"
                },
                "font_name": "Taviraj Bold",
                "font_family": "Taviraj",
                "font_postscript": "Taviraj-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Taviraj-ExtraLightItalic.ttf"
                },
                "font_name": "Taviraj ExtraLightItalic",
                "font_family": "Taviraj",
                "font_postscript": "Taviraj-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Taviraj-ExtraBold.ttf"
                },
                "font_name": "Taviraj ExtraBold",
                "font_family": "Taviraj",
                "font_postscript": "Taviraj-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Taviraj-BoldItalic.ttf"
                },
                "font_name": "Taviraj BoldItalic",
                "font_family": "Taviraj",
                "font_postscript": "Taviraj-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Taviraj-ThinItalic.ttf"
                },
                "font_name": "Taviraj ThinItalic",
                "font_family": "Taviraj",
                "font_postscript": "Taviraj-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Taviraj-ExtraLight.ttf"
                },
                "font_name": "Taviraj ExtraLight",
                "font_family": "Taviraj",
                "font_postscript": "Taviraj-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Taviraj-Regular.ttf"
                },
                "font_name": "Taviraj Regular",
                "font_family": "Taviraj",
                "font_postscript": "Taviraj-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Taviraj-SemiBold.ttf"
                },
                "font_name": "Taviraj SemiBold",
                "font_family": "Taviraj",
                "font_postscript": "Taviraj-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Taviraj-Italic.ttf"
                },
                "font_name": "Taviraj Italic",
                "font_family": "Taviraj",
                "font_postscript": "Taviraj-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Taviraj-SemiBoldItalic.ttf"
                },
                "font_name": "Taviraj SemiBoldItalic",
                "font_family": "Taviraj",
                "font_postscript": "Taviraj-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Taviraj-MediumItalic.ttf"
                },
                "font_name": "Taviraj MediumItalic",
                "font_family": "Taviraj",
                "font_postscript": "Taviraj-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Taviraj-Light.ttf"
                },
                "font_name": "Taviraj Light",
                "font_family": "Taviraj",
                "font_postscript": "Taviraj-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Teachers",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Teachers-Italic.ttf"
                },
                "font_name": "Teachers Italic",
                "font_family": "Teachers",
                "font_postscript": "Teachers-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Teachers-Regular.ttf"
                },
                "font_name": "Teachers Regular",
                "font_family": "Teachers",
                "font_postscript": "Teachers-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Teko",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Teko-Regular.ttf"
                },
                "font_name": "Teko Regular",
                "font_family": "Teko",
                "font_postscript": "Teko-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Tektur",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tektur-Regular.ttf"
                },
                "font_name": "Tektur Regular",
                "font_family": "Tektur",
                "font_postscript": "Tektur-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Telex",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Telex-Regular.ttf"
                },
                "font_name": "Telex Regular",
                "font_family": "Telex",
                "font_postscript": "Telex-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TenaliRamakrishna",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TenaliRamakrishna-Regular.ttf"
                },
                "font_name": "TenaliRamakrishna Regular",
                "font_family": "TenaliRamakrishna",
                "font_postscript": "TenaliRamakrishna-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TenorSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TenorSans-Regular.ttf"
                },
                "font_name": "TenorSans Regular",
                "font_family": "TenorSans",
                "font_postscript": "TenorSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TextMeOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TextMeOne-Regular.ttf"
                },
                "font_name": "TextMeOne Regular",
                "font_family": "TextMeOne",
                "font_postscript": "TextMeOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Texturina",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Texturina-Regular.ttf"
                },
                "font_name": "Texturina Regular",
                "font_family": "Texturina",
                "font_postscript": "Texturina-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Texturina-Italic.ttf"
                },
                "font_name": "Texturina Italic",
                "font_family": "Texturina",
                "font_postscript": "Texturina-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Thabit",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Thabit-Regular.ttf"
                },
                "font_name": "Thabit Regular",
                "font_family": "Thabit",
                "font_postscript": "Thabit-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Thabit-Oblique.ttf"
                },
                "font_name": "Thabit Oblique",
                "font_family": "Thabit",
                "font_postscript": "Thabit-Oblique",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Thabit-Bold.ttf"
                },
                "font_name": "Thabit Bold",
                "font_family": "Thabit",
                "font_postscript": "Thabit-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Thabit-BoldOblique.ttf"
                },
                "font_name": "Thabit BoldOblique",
                "font_family": "Thabit",
                "font_postscript": "Thabit-BoldOblique",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Tharlon",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tharlon-Regular.ttf"
                },
                "font_name": "Tharlon Regular",
                "font_family": "Tharlon",
                "font_postscript": "Tharlon-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Thasadith",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Thasadith-Italic.ttf"
                },
                "font_name": "Thasadith Italic",
                "font_family": "Thasadith",
                "font_postscript": "Thasadith-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Thasadith-BoldItalic.ttf"
                },
                "font_name": "Thasadith BoldItalic",
                "font_family": "Thasadith",
                "font_postscript": "Thasadith-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Thasadith-Bold.ttf"
                },
                "font_name": "Thasadith Bold",
                "font_family": "Thasadith",
                "font_postscript": "Thasadith-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Thasadith-Regular.ttf"
                },
                "font_name": "Thasadith Regular",
                "font_family": "Thasadith",
                "font_postscript": "Thasadith-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TheGirlNextDoor",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TheGirlNextDoor-Regular.ttf"
                },
                "font_name": "TheGirlNextDoor Regular",
                "font_family": "TheGirlNextDoor",
                "font_postscript": "TheGirlNextDoor-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TheNautigal",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TheNautigal-Bold.ttf"
                },
                "font_name": "TheNautigal Bold",
                "font_family": "TheNautigal",
                "font_postscript": "TheNautigal-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TheNautigal-Regular.ttf"
                },
                "font_name": "TheNautigal Regular",
                "font_family": "TheNautigal",
                "font_postscript": "TheNautigal-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Tienne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tienne-Black.ttf"
                },
                "font_name": "Tienne Black",
                "font_family": "Tienne",
                "font_postscript": "Tienne-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tienne-Regular.ttf"
                },
                "font_name": "Tienne Regular",
                "font_family": "Tienne",
                "font_postscript": "Tienne-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tienne-Bold.ttf"
                },
                "font_name": "Tienne Bold",
                "font_family": "Tienne",
                "font_postscript": "Tienne-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Tillana",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tillana-ExtraBold.ttf"
                },
                "font_name": "Tillana ExtraBold",
                "font_family": "Tillana",
                "font_postscript": "Tillana-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tillana-Bold.ttf"
                },
                "font_name": "Tillana Bold",
                "font_family": "Tillana",
                "font_postscript": "Tillana-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tillana-Regular.ttf"
                },
                "font_name": "Tillana Regular",
                "font_family": "Tillana",
                "font_postscript": "Tillana-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tillana-SemiBold.ttf"
                },
                "font_name": "Tillana SemiBold",
                "font_family": "Tillana",
                "font_postscript": "Tillana-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tillana-Medium.ttf"
                },
                "font_name": "Tillana Medium",
                "font_family": "Tillana",
                "font_postscript": "Tillana-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TiltNeon",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TiltNeon-Regular.ttf"
                },
                "font_name": "TiltNeon Regular",
                "font_family": "TiltNeon",
                "font_postscript": "TiltNeon-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TiltPrism",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TiltPrism-Regular.ttf"
                },
                "font_name": "TiltPrism Regular",
                "font_family": "TiltPrism",
                "font_postscript": "TiltPrism-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TiltWarp",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TiltWarp-Regular.ttf"
                },
                "font_name": "TiltWarp Regular",
                "font_family": "TiltWarp",
                "font_postscript": "TiltWarp-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Timmana",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Timmana-Regular.ttf"
                },
                "font_name": "Timmana Regular",
                "font_family": "Timmana",
                "font_postscript": "Timmana-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Tinos",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tinos-Regular.ttf"
                },
                "font_name": "Tinos Regular",
                "font_family": "Tinos",
                "font_postscript": "Tinos-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tinos-Italic.ttf"
                },
                "font_name": "Tinos Italic",
                "font_family": "Tinos",
                "font_postscript": "Tinos-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tinos-Bold.ttf"
                },
                "font_name": "Tinos Bold",
                "font_family": "Tinos",
                "font_postscript": "Tinos-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tinos-BoldItalic.ttf"
                },
                "font_name": "Tinos BoldItalic",
                "font_family": "Tinos",
                "font_postscript": "Tinos-BoldItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Tiny5",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tiny5-Regular.ttf"
                },
                "font_name": "Tiny5 Regular",
                "font_family": "Tiny5",
                "font_postscript": "Tiny5-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TiroBangla",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TiroBangla-Regular.ttf"
                },
                "font_name": "TiroBangla Regular",
                "font_family": "TiroBangla",
                "font_postscript": "TiroBangla-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TiroBangla-Italic.ttf"
                },
                "font_name": "TiroBangla Italic",
                "font_family": "TiroBangla",
                "font_postscript": "TiroBangla-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TiroDevanagariHindi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TiroDevanagariHindi-Regular.ttf"
                },
                "font_name": "TiroDevanagariHindi Regular",
                "font_family": "TiroDevanagariHindi",
                "font_postscript": "TiroDevanagariHindi-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TiroDevanagariHindi-Italic.ttf"
                },
                "font_name": "TiroDevanagariHindi Italic",
                "font_family": "TiroDevanagariHindi",
                "font_postscript": "TiroDevanagariHindi-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TiroDevanagariMarathi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TiroDevanagariMarathi-Regular.ttf"
                },
                "font_name": "TiroDevanagariMarathi Regular",
                "font_family": "TiroDevanagariMarathi",
                "font_postscript": "TiroDevanagariMarathi-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TiroDevanagariMarathi-Italic.ttf"
                },
                "font_name": "TiroDevanagariMarathi Italic",
                "font_family": "TiroDevanagariMarathi",
                "font_postscript": "TiroDevanagariMarathi-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TiroDevanagariSanskrit",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TiroDevanagariSanskrit-Regular.ttf"
                },
                "font_name": "TiroDevanagariSanskrit Regular",
                "font_family": "TiroDevanagariSanskrit",
                "font_postscript": "TiroDevanagariSanskrit-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TiroDevanagariSanskrit-Italic.ttf"
                },
                "font_name": "TiroDevanagariSanskrit Italic",
                "font_family": "TiroDevanagariSanskrit",
                "font_postscript": "TiroDevanagariSanskrit-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TiroGurmukhi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TiroGurmukhi-Regular.ttf"
                },
                "font_name": "TiroGurmukhi Regular",
                "font_family": "TiroGurmukhi",
                "font_postscript": "TiroGurmukhi-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TiroGurmukhi-Italic.ttf"
                },
                "font_name": "TiroGurmukhi Italic",
                "font_family": "TiroGurmukhi",
                "font_postscript": "TiroGurmukhi-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TiroKannada",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TiroKannada-Regular.ttf"
                },
                "font_name": "TiroKannada Regular",
                "font_family": "TiroKannada",
                "font_postscript": "TiroKannada-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TiroKannada-Italic.ttf"
                },
                "font_name": "TiroKannada Italic",
                "font_family": "TiroKannada",
                "font_postscript": "TiroKannada-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TiroTamil",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TiroTamil-Regular.ttf"
                },
                "font_name": "TiroTamil Regular",
                "font_family": "TiroTamil",
                "font_postscript": "TiroTamil-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TiroTamil-Italic.ttf"
                },
                "font_name": "TiroTamil Italic",
                "font_family": "TiroTamil",
                "font_postscript": "TiroTamil-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TiroTelugu",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TiroTelugu-Regular.ttf"
                },
                "font_name": "TiroTelugu Regular",
                "font_family": "TiroTelugu",
                "font_postscript": "TiroTelugu-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TiroTelugu-Italic.ttf"
                },
                "font_name": "TiroTelugu Italic",
                "font_family": "TiroTelugu",
                "font_postscript": "TiroTelugu-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TitanOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TitanOne-Regular.ttf"
                },
                "font_name": "TitanOne Regular",
                "font_family": "TitanOne",
                "font_postscript": "TitanOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TitilliumWeb",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TitilliumWeb-Black.ttf"
                },
                "font_name": "TitilliumWeb Black",
                "font_family": "TitilliumWeb",
                "font_postscript": "TitilliumWeb-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TitilliumWeb-Bold.ttf"
                },
                "font_name": "TitilliumWeb Bold",
                "font_family": "TitilliumWeb",
                "font_postscript": "TitilliumWeb-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TitilliumWeb-Regular.ttf"
                },
                "font_name": "TitilliumWeb Regular",
                "font_family": "TitilliumWeb",
                "font_postscript": "TitilliumWeb-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TitilliumWeb-SemiBold.ttf"
                },
                "font_name": "TitilliumWeb SemiBold",
                "font_family": "TitilliumWeb",
                "font_postscript": "TitilliumWeb-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TitilliumWeb-ExtraLight.ttf"
                },
                "font_name": "TitilliumWeb ExtraLight",
                "font_family": "TitilliumWeb",
                "font_postscript": "TitilliumWeb-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TitilliumWeb-BoldItalic.ttf"
                },
                "font_name": "TitilliumWeb BoldItalic",
                "font_family": "TitilliumWeb",
                "font_postscript": "TitilliumWeb-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TitilliumWeb-ExtraLightItalic.ttf"
                },
                "font_name": "TitilliumWeb ExtraLightItalic",
                "font_family": "TitilliumWeb",
                "font_postscript": "TitilliumWeb-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TitilliumWeb-SemiBoldItalic.ttf"
                },
                "font_name": "TitilliumWeb SemiBoldItalic",
                "font_family": "TitilliumWeb",
                "font_postscript": "TitilliumWeb-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TitilliumWeb-LightItalic.ttf"
                },
                "font_name": "TitilliumWeb LightItalic",
                "font_family": "TitilliumWeb",
                "font_postscript": "TitilliumWeb-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TitilliumWeb-Italic.ttf"
                },
                "font_name": "TitilliumWeb Italic",
                "font_family": "TitilliumWeb",
                "font_postscript": "TitilliumWeb-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TitilliumWeb-Light.ttf"
                },
                "font_name": "TitilliumWeb Light",
                "font_family": "TitilliumWeb",
                "font_postscript": "TitilliumWeb-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Tomorrow",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tomorrow-SemiBold.ttf"
                },
                "font_name": "Tomorrow SemiBold",
                "font_family": "Tomorrow",
                "font_postscript": "Tomorrow-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tomorrow-Medium.ttf"
                },
                "font_name": "Tomorrow Medium",
                "font_family": "Tomorrow",
                "font_postscript": "Tomorrow-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tomorrow-BlackItalic.ttf"
                },
                "font_name": "Tomorrow BlackItalic",
                "font_family": "Tomorrow",
                "font_postscript": "Tomorrow-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tomorrow-Light.ttf"
                },
                "font_name": "Tomorrow Light",
                "font_family": "Tomorrow",
                "font_postscript": "Tomorrow-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tomorrow-LightItalic.ttf"
                },
                "font_name": "Tomorrow LightItalic",
                "font_family": "Tomorrow",
                "font_postscript": "Tomorrow-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tomorrow-ExtraBold.ttf"
                },
                "font_name": "Tomorrow ExtraBold",
                "font_family": "Tomorrow",
                "font_postscript": "Tomorrow-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tomorrow-Black.ttf"
                },
                "font_name": "Tomorrow Black",
                "font_family": "Tomorrow",
                "font_postscript": "Tomorrow-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tomorrow-SemiBoldItalic.ttf"
                },
                "font_name": "Tomorrow SemiBoldItalic",
                "font_family": "Tomorrow",
                "font_postscript": "Tomorrow-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tomorrow-BoldItalic.ttf"
                },
                "font_name": "Tomorrow BoldItalic",
                "font_family": "Tomorrow",
                "font_postscript": "Tomorrow-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tomorrow-Bold.ttf"
                },
                "font_name": "Tomorrow Bold",
                "font_family": "Tomorrow",
                "font_postscript": "Tomorrow-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tomorrow-MediumItalic.ttf"
                },
                "font_name": "Tomorrow MediumItalic",
                "font_family": "Tomorrow",
                "font_postscript": "Tomorrow-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tomorrow-ExtraBoldItalic.ttf"
                },
                "font_name": "Tomorrow ExtraBoldItalic",
                "font_family": "Tomorrow",
                "font_postscript": "Tomorrow-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tomorrow-ExtraLightItalic.ttf"
                },
                "font_name": "Tomorrow ExtraLightItalic",
                "font_family": "Tomorrow",
                "font_postscript": "Tomorrow-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tomorrow-ExtraLight.ttf"
                },
                "font_name": "Tomorrow ExtraLight",
                "font_family": "Tomorrow",
                "font_postscript": "Tomorrow-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tomorrow-ThinItalic.ttf"
                },
                "font_name": "Tomorrow ThinItalic",
                "font_family": "Tomorrow",
                "font_postscript": "Tomorrow-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tomorrow-Regular.ttf"
                },
                "font_name": "Tomorrow Regular",
                "font_family": "Tomorrow",
                "font_postscript": "Tomorrow-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tomorrow-Thin.ttf"
                },
                "font_name": "Tomorrow Thin",
                "font_family": "Tomorrow",
                "font_postscript": "Tomorrow-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tomorrow-Italic.ttf"
                },
                "font_name": "Tomorrow Italic",
                "font_family": "Tomorrow",
                "font_postscript": "Tomorrow-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Tourney",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tourney-Regular.ttf"
                },
                "font_name": "Tourney Regular",
                "font_family": "Tourney",
                "font_postscript": "Tourney-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tourney-Italic.ttf"
                },
                "font_name": "Tourney Italic",
                "font_family": "Tourney",
                "font_postscript": "Tourney-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TradeWinds",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TradeWinds-Regular.ttf"
                },
                "font_name": "TradeWinds Regular",
                "font_family": "TradeWinds",
                "font_postscript": "TradeWinds-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TrainOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TrainOne-Regular.ttf"
                },
                "font_name": "TrainOne Regular",
                "font_family": "TrainOne",
                "font_postscript": "TrainOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Trirong",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trirong-Thin.ttf"
                },
                "font_name": "Trirong Thin",
                "font_family": "Trirong",
                "font_postscript": "Trirong-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trirong-Medium.ttf"
                },
                "font_name": "Trirong Medium",
                "font_family": "Trirong",
                "font_postscript": "Trirong-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trirong-ThinItalic.ttf"
                },
                "font_name": "Trirong ThinItalic",
                "font_family": "Trirong",
                "font_postscript": "Trirong-ThinItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trirong-ExtraLight.ttf"
                },
                "font_name": "Trirong ExtraLight",
                "font_family": "Trirong",
                "font_postscript": "Trirong-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trirong-SemiBold.ttf"
                },
                "font_name": "Trirong SemiBold",
                "font_family": "Trirong",
                "font_postscript": "Trirong-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trirong-Light.ttf"
                },
                "font_name": "Trirong Light",
                "font_family": "Trirong",
                "font_postscript": "Trirong-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trirong-BoldItalic.ttf"
                },
                "font_name": "Trirong BoldItalic",
                "font_family": "Trirong",
                "font_postscript": "Trirong-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trirong-Bold.ttf"
                },
                "font_name": "Trirong Bold",
                "font_family": "Trirong",
                "font_postscript": "Trirong-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trirong-SemiBoldItalic.ttf"
                },
                "font_name": "Trirong SemiBoldItalic",
                "font_family": "Trirong",
                "font_postscript": "Trirong-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trirong-ExtraBold.ttf"
                },
                "font_name": "Trirong ExtraBold",
                "font_family": "Trirong",
                "font_postscript": "Trirong-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trirong-MediumItalic.ttf"
                },
                "font_name": "Trirong MediumItalic",
                "font_family": "Trirong",
                "font_postscript": "Trirong-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trirong-ExtraLightItalic.ttf"
                },
                "font_name": "Trirong ExtraLightItalic",
                "font_family": "Trirong",
                "font_postscript": "Trirong-ExtraLightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trirong-BlackItalic.ttf"
                },
                "font_name": "Trirong BlackItalic",
                "font_family": "Trirong",
                "font_postscript": "Trirong-BlackItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trirong-Regular.ttf"
                },
                "font_name": "Trirong Regular",
                "font_family": "Trirong",
                "font_postscript": "Trirong-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trirong-LightItalic.ttf"
                },
                "font_name": "Trirong LightItalic",
                "font_family": "Trirong",
                "font_postscript": "Trirong-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trirong-Black.ttf"
                },
                "font_name": "Trirong Black",
                "font_family": "Trirong",
                "font_postscript": "Trirong-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trirong-ExtraBoldItalic.ttf"
                },
                "font_name": "Trirong ExtraBoldItalic",
                "font_family": "Trirong",
                "font_postscript": "Trirong-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trirong-Italic.ttf"
                },
                "font_name": "Trirong Italic",
                "font_family": "Trirong",
                "font_postscript": "Trirong-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Trispace",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trispace-Regular.ttf"
                },
                "font_name": "Trispace Regular",
                "font_family": "Trispace",
                "font_postscript": "Trispace-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Trocchi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trocchi-Regular.ttf"
                },
                "font_name": "Trocchi Regular",
                "font_family": "Trocchi",
                "font_postscript": "Trocchi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Trochut",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trochut-Bold.ttf"
                },
                "font_name": "Trochut Bold",
                "font_family": "Trochut",
                "font_postscript": "Trochut-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trochut-Italic.ttf"
                },
                "font_name": "Trochut Italic",
                "font_family": "Trochut",
                "font_postscript": "Trochut-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trochut-Regular.ttf"
                },
                "font_name": "Trochut Regular",
                "font_family": "Trochut",
                "font_postscript": "Trochut-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Truculenta",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Truculenta-Regular.ttf"
                },
                "font_name": "Truculenta Regular",
                "font_family": "Truculenta",
                "font_postscript": "Truculenta-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Trykker",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Trykker-Regular.ttf"
                },
                "font_name": "Trykker Regular",
                "font_family": "Trykker",
                "font_postscript": "Trykker-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TsukimiRounded",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TsukimiRounded-Medium.ttf"
                },
                "font_name": "TsukimiRounded Medium",
                "font_family": "TsukimiRounded",
                "font_postscript": "TsukimiRounded-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TsukimiRounded-Light.ttf"
                },
                "font_name": "TsukimiRounded Light",
                "font_family": "TsukimiRounded",
                "font_postscript": "TsukimiRounded-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TsukimiRounded-Bold.ttf"
                },
                "font_name": "TsukimiRounded Bold",
                "font_family": "TsukimiRounded",
                "font_postscript": "TsukimiRounded-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TsukimiRounded-Regular.ttf"
                },
                "font_name": "TsukimiRounded Regular",
                "font_family": "TsukimiRounded",
                "font_postscript": "TsukimiRounded-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TsukimiRounded-SemiBold.ttf"
                },
                "font_name": "TsukimiRounded SemiBold",
                "font_family": "TsukimiRounded",
                "font_postscript": "TsukimiRounded-SemiBold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Tuffy",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tuffy-Bold.ttf"
                },
                "font_name": "Tuffy Bold",
                "font_family": "Tuffy",
                "font_postscript": "Tuffy-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tuffy-BoldItalic.ttf"
                },
                "font_name": "Tuffy BoldItalic",
                "font_family": "Tuffy",
                "font_postscript": "Tuffy-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tuffy-Regular.ttf"
                },
                "font_name": "Tuffy Regular",
                "font_family": "Tuffy",
                "font_postscript": "Tuffy-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Tuffy-Italic.ttf"
                },
                "font_name": "Tuffy Italic",
                "font_family": "Tuffy",
                "font_postscript": "Tuffy-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TulpenOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TulpenOne-Regular.ttf"
                },
                "font_name": "TulpenOne Regular",
                "font_family": "TulpenOne",
                "font_postscript": "TulpenOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TurretRoad",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TurretRoad-Light.ttf"
                },
                "font_name": "TurretRoad Light",
                "font_family": "TurretRoad",
                "font_postscript": "TurretRoad-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TurretRoad-Medium.ttf"
                },
                "font_name": "TurretRoad Medium",
                "font_family": "TurretRoad",
                "font_postscript": "TurretRoad-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TurretRoad-Bold.ttf"
                },
                "font_name": "TurretRoad Bold",
                "font_family": "TurretRoad",
                "font_postscript": "TurretRoad-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TurretRoad-ExtraBold.ttf"
                },
                "font_name": "TurretRoad ExtraBold",
                "font_family": "TurretRoad",
                "font_postscript": "TurretRoad-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TurretRoad-ExtraLight.ttf"
                },
                "font_name": "TurretRoad ExtraLight",
                "font_family": "TurretRoad",
                "font_postscript": "TurretRoad-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "TurretRoad-Regular.ttf"
                },
                "font_name": "TurretRoad Regular",
                "font_family": "TurretRoad",
                "font_postscript": "TurretRoad-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "TwinkleStar",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "TwinkleStar-Regular.ttf"
                },
                "font_name": "TwinkleStar Regular",
                "font_family": "TwinkleStar",
                "font_postscript": "TwinkleStar-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Ubuntu",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ubuntu-Medium.ttf"
                },
                "font_name": "Ubuntu Medium",
                "font_family": "Ubuntu",
                "font_postscript": "Ubuntu-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ubuntu-LightItalic.ttf"
                },
                "font_name": "Ubuntu LightItalic",
                "font_family": "Ubuntu",
                "font_postscript": "Ubuntu-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ubuntu-Regular.ttf"
                },
                "font_name": "Ubuntu Regular",
                "font_family": "Ubuntu",
                "font_postscript": "Ubuntu-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ubuntu-Bold.ttf"
                },
                "font_name": "Ubuntu Bold",
                "font_family": "Ubuntu",
                "font_postscript": "Ubuntu-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ubuntu-MediumItalic.ttf"
                },
                "font_name": "Ubuntu MediumItalic",
                "font_family": "Ubuntu",
                "font_postscript": "Ubuntu-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ubuntu-BoldItalic.ttf"
                },
                "font_name": "Ubuntu BoldItalic",
                "font_family": "Ubuntu",
                "font_postscript": "Ubuntu-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ubuntu-Italic.ttf"
                },
                "font_name": "Ubuntu Italic",
                "font_family": "Ubuntu",
                "font_postscript": "Ubuntu-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ubuntu-Light.ttf"
                },
                "font_name": "Ubuntu Light",
                "font_family": "Ubuntu",
                "font_postscript": "Ubuntu-Light",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "UbuntuCondensed",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "UbuntuCondensed-Regular.ttf"
                },
                "font_name": "UbuntuCondensed Regular",
                "font_family": "UbuntuCondensed",
                "font_postscript": "UbuntuCondensed-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "UbuntuMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "UbuntuMono-BoldItalic.ttf"
                },
                "font_name": "UbuntuMono BoldItalic",
                "font_family": "UbuntuMono",
                "font_postscript": "UbuntuMono-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "UbuntuMono-Bold.ttf"
                },
                "font_name": "UbuntuMono Bold",
                "font_family": "UbuntuMono",
                "font_postscript": "UbuntuMono-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "UbuntuMono-Italic.ttf"
                },
                "font_name": "UbuntuMono Italic",
                "font_family": "UbuntuMono",
                "font_postscript": "UbuntuMono-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "UbuntuMono-Regular.ttf"
                },
                "font_name": "UbuntuMono Regular",
                "font_family": "UbuntuMono",
                "font_postscript": "UbuntuMono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "UbuntuSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "UbuntuSans-Italic.ttf"
                },
                "font_name": "UbuntuSans Italic",
                "font_family": "UbuntuSans",
                "font_postscript": "UbuntuSans-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "UbuntuSans-Regular.ttf"
                },
                "font_name": "UbuntuSans Regular",
                "font_family": "UbuntuSans",
                "font_postscript": "UbuntuSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "UbuntuSansMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "UbuntuSansMono-Regular.ttf"
                },
                "font_name": "UbuntuSansMono Regular",
                "font_family": "UbuntuSansMono",
                "font_postscript": "UbuntuSansMono-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "UbuntuSansMono-Italic.ttf"
                },
                "font_name": "UbuntuSansMono Italic",
                "font_family": "UbuntuSansMono",
                "font_postscript": "UbuntuSansMono-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Uchen",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Uchen-Regular.ttf"
                },
                "font_name": "Uchen Regular",
                "font_family": "Uchen",
                "font_postscript": "Uchen-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Ultra",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ultra-Regular.ttf"
                },
                "font_name": "Ultra Regular",
                "font_family": "Ultra",
                "font_postscript": "Ultra-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Unbounded",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Unbounded-Regular.ttf"
                },
                "font_name": "Unbounded Regular",
                "font_family": "Unbounded",
                "font_postscript": "Unbounded-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "UncialAntiqua",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "UncialAntiqua-Regular.ttf"
                },
                "font_name": "UncialAntiqua Regular",
                "font_family": "UncialAntiqua",
                "font_postscript": "UncialAntiqua-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Underdog",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Underdog-Regular.ttf"
                },
                "font_name": "Underdog Regular",
                "font_family": "Underdog",
                "font_postscript": "Underdog-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "UnicaOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "UnicaOne-Regular.ttf"
                },
                "font_name": "UnicaOne Regular",
                "font_family": "UnicaOne",
                "font_postscript": "UnicaOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "UnifrakturCook",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "UnifrakturCook-Bold.ttf"
                },
                "font_name": "UnifrakturCook Bold",
                "font_family": "UnifrakturCook",
                "font_postscript": "UnifrakturCook-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "UnifrakturMaguntia",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "UnifrakturMaguntia-Book.ttf"
                },
                "font_name": "UnifrakturMaguntia Book",
                "font_family": "UnifrakturMaguntia",
                "font_postscript": "UnifrakturMaguntia-Book",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Unkempt",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Unkempt-Bold.ttf"
                },
                "font_name": "Unkempt Bold",
                "font_family": "Unkempt",
                "font_postscript": "Unkempt-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Unkempt-Regular.ttf"
                },
                "font_name": "Unkempt Regular",
                "font_family": "Unkempt",
                "font_postscript": "Unkempt-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Unlock",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Unlock-Regular.ttf"
                },
                "font_name": "Unlock Regular",
                "font_family": "Unlock",
                "font_postscript": "Unlock-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Unna",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Unna-Regular.ttf"
                },
                "font_name": "Unna Regular",
                "font_family": "Unna",
                "font_postscript": "Unna-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Unna-Bold.ttf"
                },
                "font_name": "Unna Bold",
                "font_family": "Unna",
                "font_postscript": "Unna-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Unna-BoldItalic.ttf"
                },
                "font_name": "Unna BoldItalic",
                "font_family": "Unna",
                "font_postscript": "Unna-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Unna-Italic.ttf"
                },
                "font_name": "Unna Italic",
                "font_family": "Unna",
                "font_postscript": "Unna-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Updock",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Updock-Regular.ttf"
                },
                "font_name": "Updock Regular",
                "font_family": "Updock",
                "font_postscript": "Updock-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Urbanist",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Urbanist-Regular.ttf"
                },
                "font_name": "Urbanist Regular",
                "font_family": "Urbanist",
                "font_postscript": "Urbanist-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Urbanist-Italic.ttf"
                },
                "font_name": "Urbanist Italic",
                "font_family": "Urbanist",
                "font_postscript": "Urbanist-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "VT323",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "VT323-Regular.ttf"
                },
                "font_name": "VT323 Regular",
                "font_family": "VT323",
                "font_postscript": "VT323-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "VampiroOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "VampiroOne-Regular.ttf"
                },
                "font_name": "VampiroOne Regular",
                "font_family": "VampiroOne",
                "font_postscript": "VampiroOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Varela",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Varela-Regular.ttf"
                },
                "font_name": "Varela Regular",
                "font_family": "Varela",
                "font_postscript": "Varela-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "VarelaRound",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "VarelaRound-Regular.ttf"
                },
                "font_name": "VarelaRound Regular",
                "font_family": "VarelaRound",
                "font_postscript": "VarelaRound-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Varta",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Varta-Regular.ttf"
                },
                "font_name": "Varta Regular",
                "font_family": "Varta",
                "font_postscript": "Varta-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "VastShadow",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "VastShadow-Regular.ttf"
                },
                "font_name": "VastShadow Regular",
                "font_family": "VastShadow",
                "font_postscript": "VastShadow-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Vazirmatn",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Vazirmatn-Regular.ttf"
                },
                "font_name": "Vazirmatn Regular",
                "font_family": "Vazirmatn",
                "font_postscript": "Vazirmatn-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "VesperLibre",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "VesperLibre-Regular.ttf"
                },
                "font_name": "VesperLibre Regular",
                "font_family": "VesperLibre",
                "font_postscript": "VesperLibre-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "VesperLibre-Medium.ttf"
                },
                "font_name": "VesperLibre Medium",
                "font_family": "VesperLibre",
                "font_postscript": "VesperLibre-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "VesperLibre-Heavy.ttf"
                },
                "font_name": "VesperLibre Heavy",
                "font_family": "VesperLibre",
                "font_postscript": "VesperLibre-Heavy",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "VesperLibre-Bold.ttf"
                },
                "font_name": "VesperLibre Bold",
                "font_family": "VesperLibre",
                "font_postscript": "VesperLibre-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ViaodaLibre",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ViaodaLibre-Regular.ttf"
                },
                "font_name": "ViaodaLibre Regular",
                "font_family": "ViaodaLibre",
                "font_postscript": "ViaodaLibre-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Vibes",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Vibes-Regular.ttf"
                },
                "font_name": "Vibes Regular",
                "font_family": "Vibes",
                "font_postscript": "Vibes-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Vibur",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Vibur-Regular.ttf"
                },
                "font_name": "Vibur Regular",
                "font_family": "Vibur",
                "font_postscript": "Vibur-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "VictorMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "VictorMono-Italic.ttf"
                },
                "font_name": "VictorMono Italic",
                "font_family": "VictorMono",
                "font_postscript": "VictorMono-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "VictorMono-Regular.ttf"
                },
                "font_name": "VictorMono Regular",
                "font_family": "VictorMono",
                "font_postscript": "VictorMono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Vidaloka",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Vidaloka-Regular.ttf"
                },
                "font_name": "Vidaloka Regular",
                "font_family": "Vidaloka",
                "font_postscript": "Vidaloka-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Viga",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Viga-Regular.ttf"
                },
                "font_name": "Viga Regular",
                "font_family": "Viga",
                "font_postscript": "Viga-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "VinaSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "VinaSans-Regular.ttf"
                },
                "font_name": "VinaSans Regular",
                "font_family": "VinaSans",
                "font_postscript": "VinaSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Voces",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Voces-Regular.ttf"
                },
                "font_name": "Voces Regular",
                "font_family": "Voces",
                "font_postscript": "Voces-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Volkhov",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Volkhov-Italic.ttf"
                },
                "font_name": "Volkhov Italic",
                "font_family": "Volkhov",
                "font_postscript": "Volkhov-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Volkhov-Bold.ttf"
                },
                "font_name": "Volkhov Bold",
                "font_family": "Volkhov",
                "font_postscript": "Volkhov-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Volkhov-Regular.ttf"
                },
                "font_name": "Volkhov Regular",
                "font_family": "Volkhov",
                "font_postscript": "Volkhov-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Volkhov-BoldItalic.ttf"
                },
                "font_name": "Volkhov BoldItalic",
                "font_family": "Volkhov",
                "font_postscript": "Volkhov-BoldItalic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Vollkorn",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Vollkorn-Regular.ttf"
                },
                "font_name": "Vollkorn Regular",
                "font_family": "Vollkorn",
                "font_postscript": "Vollkorn-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Vollkorn-Italic.ttf"
                },
                "font_name": "Vollkorn Italic",
                "font_family": "Vollkorn",
                "font_postscript": "Vollkorn-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "VollkornSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "VollkornSC-SemiBold.ttf"
                },
                "font_name": "VollkornSC SemiBold",
                "font_family": "VollkornSC",
                "font_postscript": "VollkornSC-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "VollkornSC-Black.ttf"
                },
                "font_name": "VollkornSC Black",
                "font_family": "VollkornSC",
                "font_postscript": "VollkornSC-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "VollkornSC-Bold.ttf"
                },
                "font_name": "VollkornSC Bold",
                "font_family": "VollkornSC",
                "font_postscript": "VollkornSC-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "VollkornSC-Regular.ttf"
                },
                "font_name": "VollkornSC Regular",
                "font_family": "VollkornSC",
                "font_postscript": "VollkornSC-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Voltaire",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Voltaire-Regular.ttf"
                },
                "font_name": "Voltaire Regular",
                "font_family": "Voltaire",
                "font_postscript": "Voltaire-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "VujahdayScript",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "VujahdayScript-Regular.ttf"
                },
                "font_name": "VujahdayScript Regular",
                "font_family": "VujahdayScript",
                "font_postscript": "VujahdayScript-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "WaitingfortheSunrise",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "WaitingfortheSunrise-Regular.ttf"
                },
                "font_name": "WaitingfortheSunrise Regular",
                "font_family": "WaitingfortheSunrise",
                "font_postscript": "WaitingfortheSunrise-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Wallpoet",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Wallpoet-Regular.ttf"
                },
                "font_name": "Wallpoet Regular",
                "font_family": "Wallpoet",
                "font_postscript": "Wallpoet-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "WalterTurncoat",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "WalterTurncoat-Regular.ttf"
                },
                "font_name": "WalterTurncoat Regular",
                "font_family": "WalterTurncoat",
                "font_postscript": "WalterTurncoat-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Warnes",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Warnes-Regular.ttf"
                },
                "font_name": "Warnes Regular",
                "font_family": "Warnes",
                "font_postscript": "Warnes-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "WaterBrush",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "WaterBrush-Regular.ttf"
                },
                "font_name": "WaterBrush Regular",
                "font_family": "WaterBrush",
                "font_postscript": "WaterBrush-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Waterfall",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Waterfall-Regular.ttf"
                },
                "font_name": "Waterfall Regular",
                "font_family": "Waterfall",
                "font_postscript": "Waterfall-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Wavefont",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Wavefont-Regular.ttf"
                },
                "font_name": "Wavefont Regular",
                "font_family": "Wavefont",
                "font_postscript": "Wavefont-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Wavefont-Regular.ttf"
                },
                "font_name": "Wavefont Regular",
                "font_family": "Wavefont",
                "font_postscript": "Wavefont-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Wellfleet",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Wellfleet-Regular.ttf"
                },
                "font_name": "Wellfleet Regular",
                "font_family": "Wellfleet",
                "font_postscript": "Wellfleet-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "WendyOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "WendyOne-Regular.ttf"
                },
                "font_name": "WendyOne Regular",
                "font_family": "WendyOne",
                "font_postscript": "WendyOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Whisper",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Whisper-Regular.ttf"
                },
                "font_name": "Whisper Regular",
                "font_family": "Whisper",
                "font_postscript": "Whisper-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "WindSong",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "WindSong-Regular.ttf"
                },
                "font_name": "WindSong Regular",
                "font_family": "WindSong",
                "font_postscript": "WindSong-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "WindSong-Medium.ttf"
                },
                "font_name": "WindSong Medium",
                "font_family": "WindSong",
                "font_postscript": "WindSong-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "WireOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "WireOne-Regular.ttf"
                },
                "font_name": "WireOne Regular",
                "font_family": "WireOne",
                "font_postscript": "WireOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "WixMadeforDisplay",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "WixMadeforDisplay-Regular.ttf"
                },
                "font_name": "WixMadeforDisplay Regular",
                "font_family": "WixMadeforDisplay",
                "font_postscript": "WixMadeforDisplay-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "WixMadeforText",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "WixMadeforText-Bold.ttf"
                },
                "font_name": "WixMadeforText Bold",
                "font_family": "WixMadeforText",
                "font_postscript": "WixMadeforText-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "WixMadeforText-BoldItalic.ttf"
                },
                "font_name": "WixMadeforText BoldItalic",
                "font_family": "WixMadeforText",
                "font_postscript": "WixMadeforText-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "WixMadeforText-Italic.ttf"
                },
                "font_name": "WixMadeforText Italic",
                "font_family": "WixMadeforText",
                "font_postscript": "WixMadeforText-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "WixMadeforText-MediumItalic.ttf"
                },
                "font_name": "WixMadeforText MediumItalic",
                "font_family": "WixMadeforText",
                "font_postscript": "WixMadeforText-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "WixMadeforText-SemiBold.ttf"
                },
                "font_name": "WixMadeforText SemiBold",
                "font_family": "WixMadeforText",
                "font_postscript": "WixMadeforText-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "WixMadeforText-ExtraBold.ttf"
                },
                "font_name": "WixMadeforText ExtraBold",
                "font_family": "WixMadeforText",
                "font_postscript": "WixMadeforText-ExtraBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "WixMadeforText-Medium.ttf"
                },
                "font_name": "WixMadeforText Medium",
                "font_family": "WixMadeforText",
                "font_postscript": "WixMadeforText-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "WixMadeforText-ExtraBoldItalic.ttf"
                },
                "font_name": "WixMadeforText ExtraBoldItalic",
                "font_family": "WixMadeforText",
                "font_postscript": "WixMadeforText-ExtraBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "WixMadeforText-Regular.ttf"
                },
                "font_name": "WixMadeforText Regular",
                "font_family": "WixMadeforText",
                "font_postscript": "WixMadeforText-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "WixMadeforText-Italic.ttf"
                },
                "font_name": "WixMadeforText Italic",
                "font_family": "WixMadeforText",
                "font_postscript": "WixMadeforText-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "WixMadeforText-SemiBoldItalic.ttf"
                },
                "font_name": "WixMadeforText SemiBoldItalic",
                "font_family": "WixMadeforText",
                "font_postscript": "WixMadeforText-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "WixMadeforText-Regular.ttf"
                },
                "font_name": "WixMadeforText Regular",
                "font_family": "WixMadeforText",
                "font_postscript": "WixMadeforText-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Wonky",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Wonky-Regular.ttf"
                },
                "font_name": "Wonky Regular",
                "font_family": "Wonky",
                "font_postscript": "Wonky-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "WorkSans",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "WorkSans-Italic.ttf"
                },
                "font_name": "WorkSans Italic",
                "font_family": "WorkSans",
                "font_postscript": "WorkSans-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "WorkSans-Regular.ttf"
                },
                "font_name": "WorkSans Regular",
                "font_family": "WorkSans",
                "font_postscript": "WorkSans-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Workbench",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Workbench-Regular.ttf"
                },
                "font_name": "Workbench Regular",
                "font_family": "Workbench",
                "font_postscript": "Workbench-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "XanhMono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "XanhMono-Italic.ttf"
                },
                "font_name": "XanhMono Italic",
                "font_family": "XanhMono",
                "font_postscript": "XanhMono-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "XanhMono-Regular.ttf"
                },
                "font_name": "XanhMono Regular",
                "font_family": "XanhMono",
                "font_postscript": "XanhMono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Yaldevi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Yaldevi-Regular.ttf"
                },
                "font_name": "Yaldevi Regular",
                "font_family": "Yaldevi",
                "font_postscript": "Yaldevi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "YaldeviColombo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "YaldeviColombo-Regular.ttf"
                },
                "font_name": "YaldeviColombo Regular",
                "font_family": "YaldeviColombo",
                "font_postscript": "YaldeviColombo-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "YaldeviColombo-Medium.ttf"
                },
                "font_name": "YaldeviColombo Medium",
                "font_family": "YaldeviColombo",
                "font_postscript": "YaldeviColombo-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "YaldeviColombo-ExtraLight.ttf"
                },
                "font_name": "YaldeviColombo ExtraLight",
                "font_family": "YaldeviColombo",
                "font_postscript": "YaldeviColombo-ExtraLight",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "YaldeviColombo-SemiBold.ttf"
                },
                "font_name": "YaldeviColombo SemiBold",
                "font_family": "YaldeviColombo",
                "font_postscript": "YaldeviColombo-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "YaldeviColombo-Light.ttf"
                },
                "font_name": "YaldeviColombo Light",
                "font_family": "YaldeviColombo",
                "font_postscript": "YaldeviColombo-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "YaldeviColombo-Bold.ttf"
                },
                "font_name": "YaldeviColombo Bold",
                "font_family": "YaldeviColombo",
                "font_postscript": "YaldeviColombo-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "YanoneKaffeesatz",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "YanoneKaffeesatz-Regular.ttf"
                },
                "font_name": "YanoneKaffeesatz Regular",
                "font_family": "YanoneKaffeesatz",
                "font_postscript": "YanoneKaffeesatz-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Yantramanav",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Yantramanav-Thin.ttf"
                },
                "font_name": "Yantramanav Thin",
                "font_family": "Yantramanav",
                "font_postscript": "Yantramanav-Thin",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Yantramanav-Black.ttf"
                },
                "font_name": "Yantramanav Black",
                "font_family": "Yantramanav",
                "font_postscript": "Yantramanav-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Yantramanav-Bold.ttf"
                },
                "font_name": "Yantramanav Bold",
                "font_family": "Yantramanav",
                "font_postscript": "Yantramanav-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Yantramanav-Medium.ttf"
                },
                "font_name": "Yantramanav Medium",
                "font_family": "Yantramanav",
                "font_postscript": "Yantramanav-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Yantramanav-Light.ttf"
                },
                "font_name": "Yantramanav Light",
                "font_family": "Yantramanav",
                "font_postscript": "Yantramanav-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Yantramanav-Regular.ttf"
                },
                "font_name": "Yantramanav Regular",
                "font_family": "Yantramanav",
                "font_postscript": "Yantramanav-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Yarndings12",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Yarndings12-Regular.ttf"
                },
                "font_name": "Yarndings12 Regular",
                "font_family": "Yarndings12",
                "font_postscript": "Yarndings12-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Yarndings12Charted",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Yarndings12Charted-Regular.ttf"
                },
                "font_name": "Yarndings12Charted Regular",
                "font_family": "Yarndings12Charted",
                "font_postscript": "Yarndings12Charted-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Yarndings20",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Yarndings20-Regular.ttf"
                },
                "font_name": "Yarndings20 Regular",
                "font_family": "Yarndings20",
                "font_postscript": "Yarndings20-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Yarndings20Charted",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Yarndings20Charted-Regular.ttf"
                },
                "font_name": "Yarndings20Charted Regular",
                "font_family": "Yarndings20Charted",
                "font_postscript": "Yarndings20Charted-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "YatraOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "YatraOne-Regular.ttf"
                },
                "font_name": "YatraOne Regular",
                "font_family": "YatraOne",
                "font_postscript": "YatraOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Yellowtail",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Yellowtail-Regular.ttf"
                },
                "font_name": "Yellowtail Regular",
                "font_family": "Yellowtail",
                "font_postscript": "Yellowtail-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "YeonSung",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "YeonSung-Regular.ttf"
                },
                "font_name": "YeonSung Regular",
                "font_family": "YeonSung",
                "font_postscript": "YeonSung-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "YesevaOne",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "YesevaOne-Regular.ttf"
                },
                "font_name": "YesevaOne Regular",
                "font_family": "YesevaOne",
                "font_postscript": "YesevaOne-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Yesteryear",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Yesteryear-Regular.ttf"
                },
                "font_name": "Yesteryear Regular",
                "font_family": "Yesteryear",
                "font_postscript": "Yesteryear-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Yinmar",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Yinmar-Regular.ttf"
                },
                "font_name": "Yinmar Regular",
                "font_family": "Yinmar",
                "font_postscript": "Yinmar-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Yomogi",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Yomogi-Regular.ttf"
                },
                "font_name": "Yomogi Regular",
                "font_family": "Yomogi",
                "font_postscript": "Yomogi-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "YoungSerif",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "YoungSerif-Regular.ttf"
                },
                "font_name": "YoungSerif Regular",
                "font_family": "YoungSerif",
                "font_postscript": "YoungSerif-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Yrsa",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Yrsa-Italic.ttf"
                },
                "font_name": "Yrsa Italic",
                "font_family": "Yrsa",
                "font_postscript": "Yrsa-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Yrsa-Regular.ttf"
                },
                "font_name": "Yrsa Regular",
                "font_family": "Yrsa",
                "font_postscript": "Yrsa-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Ysabeau",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ysabeau-Regular.ttf"
                },
                "font_name": "Ysabeau Regular",
                "font_family": "Ysabeau",
                "font_postscript": "Ysabeau-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "Ysabeau-Italic.ttf"
                },
                "font_name": "Ysabeau Italic",
                "font_family": "Ysabeau",
                "font_postscript": "Ysabeau-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "YsabeauInfant",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "YsabeauInfant-Regular.ttf"
                },
                "font_name": "YsabeauInfant Regular",
                "font_family": "YsabeauInfant",
                "font_postscript": "YsabeauInfant-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "YsabeauInfant-Italic.ttf"
                },
                "font_name": "YsabeauInfant Italic",
                "font_family": "YsabeauInfant",
                "font_postscript": "YsabeauInfant-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "YsabeauOffice",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "YsabeauOffice-Regular.ttf"
                },
                "font_name": "YsabeauOffice Regular",
                "font_family": "YsabeauOffice",
                "font_postscript": "YsabeauOffice-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "YsabeauOffice-Italic.ttf"
                },
                "font_name": "YsabeauOffice Italic",
                "font_family": "YsabeauOffice",
                "font_postscript": "YsabeauOffice-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "YsabeauSC",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "YsabeauSC-Regular.ttf"
                },
                "font_name": "YsabeauSC Regular",
                "font_family": "YsabeauSC",
                "font_postscript": "YsabeauSC-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "YujiBoku",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "YujiBoku-Regular.ttf"
                },
                "font_name": "YujiBoku Regular",
                "font_family": "YujiBoku",
                "font_postscript": "YujiBoku-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "YujiHentaiganaAkari",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "YujiHentaiganaAkari-Regular.ttf"
                },
                "font_name": "YujiHentaiganaAkari Regular",
                "font_family": "YujiHentaiganaAkari",
                "font_postscript": "YujiHentaiganaAkari-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "YujiHentaiganaAkebono",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "YujiHentaiganaAkebono-Regular.ttf"
                },
                "font_name": "YujiHentaiganaAkebono Regular",
                "font_family": "YujiHentaiganaAkebono",
                "font_postscript": "YujiHentaiganaAkebono-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "YujiMai",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "YujiMai-Regular.ttf"
                },
                "font_name": "YujiMai Regular",
                "font_family": "YujiMai",
                "font_postscript": "YujiMai-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "YujiSyuku",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "YujiSyuku-Regular.ttf"
                },
                "font_name": "YujiSyuku Regular",
                "font_family": "YujiSyuku",
                "font_postscript": "YujiSyuku-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "YuseiMagic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "YuseiMagic-Regular.ttf"
                },
                "font_name": "YuseiMagic Regular",
                "font_family": "YuseiMagic",
                "font_postscript": "YuseiMagic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ZCOOLKuaiLe",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZCOOLKuaiLe-Regular.ttf"
                },
                "font_name": "ZCOOLKuaiLe Regular",
                "font_family": "ZCOOLKuaiLe",
                "font_postscript": "ZCOOLKuaiLe-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ZCOOLQingKeHuangYou",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZCOOLQingKeHuangYou-Regular.ttf"
                },
                "font_name": "ZCOOLQingKeHuangYou Regular",
                "font_family": "ZCOOLQingKeHuangYou",
                "font_postscript": "ZCOOLQingKeHuangYou-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ZCOOLXiaoWei",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZCOOLXiaoWei-Regular.ttf"
                },
                "font_name": "ZCOOLXiaoWei Regular",
                "font_family": "ZCOOLXiaoWei",
                "font_postscript": "ZCOOLXiaoWei-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ZenAntique",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenAntique-Regular.ttf"
                },
                "font_name": "ZenAntique Regular",
                "font_family": "ZenAntique",
                "font_postscript": "ZenAntique-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ZenAntiqueSoft",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenAntiqueSoft-Regular.ttf"
                },
                "font_name": "ZenAntiqueSoft Regular",
                "font_family": "ZenAntiqueSoft",
                "font_postscript": "ZenAntiqueSoft-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ZenDots",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenDots-Regular.ttf"
                },
                "font_name": "ZenDots Regular",
                "font_family": "ZenDots",
                "font_postscript": "ZenDots-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ZenKakuGothicAntique",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenKakuGothicAntique-Light.ttf"
                },
                "font_name": "ZenKakuGothicAntique Light",
                "font_family": "ZenKakuGothicAntique",
                "font_postscript": "ZenKakuGothicAntique-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenKakuGothicAntique-Bold.ttf"
                },
                "font_name": "ZenKakuGothicAntique Bold",
                "font_family": "ZenKakuGothicAntique",
                "font_postscript": "ZenKakuGothicAntique-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenKakuGothicAntique-Medium.ttf"
                },
                "font_name": "ZenKakuGothicAntique Medium",
                "font_family": "ZenKakuGothicAntique",
                "font_postscript": "ZenKakuGothicAntique-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenKakuGothicAntique-Regular.ttf"
                },
                "font_name": "ZenKakuGothicAntique Regular",
                "font_family": "ZenKakuGothicAntique",
                "font_postscript": "ZenKakuGothicAntique-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenKakuGothicAntique-Black.ttf"
                },
                "font_name": "ZenKakuGothicAntique Black",
                "font_family": "ZenKakuGothicAntique",
                "font_postscript": "ZenKakuGothicAntique-Black",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ZenKakuGothicNew",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenKakuGothicNew-Bold.ttf"
                },
                "font_name": "ZenKakuGothicNew Bold",
                "font_family": "ZenKakuGothicNew",
                "font_postscript": "ZenKakuGothicNew-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenKakuGothicNew-Light.ttf"
                },
                "font_name": "ZenKakuGothicNew Light",
                "font_family": "ZenKakuGothicNew",
                "font_postscript": "ZenKakuGothicNew-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenKakuGothicNew-Medium.ttf"
                },
                "font_name": "ZenKakuGothicNew Medium",
                "font_family": "ZenKakuGothicNew",
                "font_postscript": "ZenKakuGothicNew-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenKakuGothicNew-Black.ttf"
                },
                "font_name": "ZenKakuGothicNew Black",
                "font_family": "ZenKakuGothicNew",
                "font_postscript": "ZenKakuGothicNew-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenKakuGothicNew-Regular.ttf"
                },
                "font_name": "ZenKakuGothicNew Regular",
                "font_family": "ZenKakuGothicNew",
                "font_postscript": "ZenKakuGothicNew-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ZenKurenaido",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenKurenaido-Regular.ttf"
                },
                "font_name": "ZenKurenaido Regular",
                "font_family": "ZenKurenaido",
                "font_postscript": "ZenKurenaido-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ZenLoop",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenLoop-Regular.ttf"
                },
                "font_name": "ZenLoop Regular",
                "font_family": "ZenLoop",
                "font_postscript": "ZenLoop-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenLoop-Italic.ttf"
                },
                "font_name": "ZenLoop Italic",
                "font_family": "ZenLoop",
                "font_postscript": "ZenLoop-Italic",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ZenMaruGothic",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenMaruGothic-Bold.ttf"
                },
                "font_name": "ZenMaruGothic Bold",
                "font_family": "ZenMaruGothic",
                "font_postscript": "ZenMaruGothic-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenMaruGothic-Light.ttf"
                },
                "font_name": "ZenMaruGothic Light",
                "font_family": "ZenMaruGothic",
                "font_postscript": "ZenMaruGothic-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenMaruGothic-Medium.ttf"
                },
                "font_name": "ZenMaruGothic Medium",
                "font_family": "ZenMaruGothic",
                "font_postscript": "ZenMaruGothic-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenMaruGothic-Black.ttf"
                },
                "font_name": "ZenMaruGothic Black",
                "font_family": "ZenMaruGothic",
                "font_postscript": "ZenMaruGothic-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenMaruGothic-Regular.ttf"
                },
                "font_name": "ZenMaruGothic Regular",
                "font_family": "ZenMaruGothic",
                "font_postscript": "ZenMaruGothic-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ZenOldMincho",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenOldMincho-SemiBold.ttf"
                },
                "font_name": "ZenOldMincho SemiBold",
                "font_family": "ZenOldMincho",
                "font_postscript": "ZenOldMincho-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenOldMincho-Black.ttf"
                },
                "font_name": "ZenOldMincho Black",
                "font_family": "ZenOldMincho",
                "font_postscript": "ZenOldMincho-Black",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenOldMincho-Bold.ttf"
                },
                "font_name": "ZenOldMincho Bold",
                "font_family": "ZenOldMincho",
                "font_postscript": "ZenOldMincho-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenOldMincho-Regular.ttf"
                },
                "font_name": "ZenOldMincho Regular",
                "font_family": "ZenOldMincho",
                "font_postscript": "ZenOldMincho-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenOldMincho-Medium.ttf"
                },
                "font_name": "ZenOldMincho Medium",
                "font_family": "ZenOldMincho",
                "font_postscript": "ZenOldMincho-Medium",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ZenTokyoZoo",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZenTokyoZoo-Regular.ttf"
                },
                "font_name": "ZenTokyoZoo Regular",
                "font_family": "ZenTokyoZoo",
                "font_postscript": "ZenTokyoZoo-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "Zeyada",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "Zeyada-Regular.ttf"
                },
                "font_name": "Zeyada Regular",
                "font_family": "Zeyada",
                "font_postscript": "Zeyada-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ZhiMangXing",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZhiMangXing-Regular.ttf"
                },
                "font_name": "ZhiMangXing Regular",
                "font_family": "ZhiMangXing",
                "font_postscript": "ZhiMangXing-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ZillaSlab",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZillaSlab-MediumItalic.ttf"
                },
                "font_name": "ZillaSlab MediumItalic",
                "font_family": "ZillaSlab",
                "font_postscript": "ZillaSlab-MediumItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZillaSlab-LightItalic.ttf"
                },
                "font_name": "ZillaSlab LightItalic",
                "font_family": "ZillaSlab",
                "font_postscript": "ZillaSlab-LightItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZillaSlab-Bold.ttf"
                },
                "font_name": "ZillaSlab Bold",
                "font_family": "ZillaSlab",
                "font_postscript": "ZillaSlab-Bold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZillaSlab-Italic.ttf"
                },
                "font_name": "ZillaSlab Italic",
                "font_family": "ZillaSlab",
                "font_postscript": "ZillaSlab-Italic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZillaSlab-BoldItalic.ttf"
                },
                "font_name": "ZillaSlab BoldItalic",
                "font_family": "ZillaSlab",
                "font_postscript": "ZillaSlab-BoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZillaSlab-Medium.ttf"
                },
                "font_name": "ZillaSlab Medium",
                "font_family": "ZillaSlab",
                "font_postscript": "ZillaSlab-Medium",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZillaSlab-Light.ttf"
                },
                "font_name": "ZillaSlab Light",
                "font_family": "ZillaSlab",
                "font_postscript": "ZillaSlab-Light",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZillaSlab-SemiBoldItalic.ttf"
                },
                "font_name": "ZillaSlab SemiBoldItalic",
                "font_family": "ZillaSlab",
                "font_postscript": "ZillaSlab-SemiBoldItalic",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZillaSlab-SemiBold.ttf"
                },
                "font_name": "ZillaSlab SemiBold",
                "font_family": "ZillaSlab",
                "font_postscript": "ZillaSlab-SemiBold",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZillaSlab-Regular.ttf"
                },
                "font_name": "ZillaSlab Regular",
                "font_family": "ZillaSlab",
                "font_postscript": "ZillaSlab-Regular",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "ZillaSlabHighlight",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZillaSlabHighlight-Regular.ttf"
                },
                "font_name": "ZillaSlabHighlight Regular",
                "font_family": "ZillaSlabHighlight",
                "font_postscript": "ZillaSlabHighlight-Regular",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "ZillaSlabHighlight-Bold.ttf"
                },
                "font_name": "ZillaSlabHighlight Bold",
                "font_family": "ZillaSlabHighlight",
                "font_postscript": "ZillaSlabHighlight-Bold",
                "font_category": "custom"
            }
        ]
    },
    {
        "font_family": "jsMath",
        "fonts": [
            {
                "font_url": {
                    "type": "s3",
                    "key": "jsMath-cmr10.ttf"
                },
                "font_name": "jsMath cmr10",
                "font_family": "jsMath",
                "font_postscript": "jsMath-cmr10",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "jsMath-cmsy10.ttf"
                },
                "font_name": "jsMath cmsy10",
                "font_family": "jsMath",
                "font_postscript": "jsMath-cmsy10",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "jsMath-cmex10.ttf"
                },
                "font_name": "jsMath cmex10",
                "font_family": "jsMath",
                "font_postscript": "jsMath-cmex10",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "jsMath-cmti10.ttf"
                },
                "font_name": "jsMath cmti10",
                "font_family": "jsMath",
                "font_postscript": "jsMath-cmti10",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "jsMath-cmbx10.ttf"
                },
                "font_name": "jsMath cmbx10",
                "font_family": "jsMath",
                "font_postscript": "jsMath-cmbx10",
                "font_category": "custom"
            },
            {
                "font_url": {
                    "type": "s3",
                    "key": "jsMath-cmmi10.ttf"
                },
                "font_name": "jsMath cmmi10",
                "font_family": "jsMath",
                "font_postscript": "jsMath-cmmi10",
                "font_category": "custom"
            }
        ]
    }
];